import {
  SWAP_HISTORY_GET,
  SWAP_HISTORY_SET,
  SWAP_HISTORY_ERROR,
  SWAP_EXCHANGE_WALLETS_GET,
  SWAP_EXCHANGE_WALLETS_SET,
  SWAP_EXCHANGE_WALLETS_RESET,
  SWAP_EXCHANGE_WALLETS_ERROR,
  SWAP_OFFERS_GET,
  SWAP_OFFERS_SET,
  SWAP_OFFERS_RESET,
  SWAP_OFFERS_ERROR,
  SWAP_OFFERS_UPDATE_GET,
  SWAP_OFFERS_UPDATE_SET,
  SWAP_OFFERS_UPDATE_APPLY,
  SWAP_OFFERS_UPDATE_ERROR,
  RESET_SWAP_OFFERS_UPDATED_DATA,
  SWAP_EXCHANGE_GET,
  SWAP_EXCHANGE_SET,
  SWAP_EXCHANGE_ERROR,
  RESET_SWAP_EXCHANGE_ERROR,
  SWAP_EXCHANGE_WALLET_SET,
  SWAP_EXCHANGE_WALLET_GET,
  AUTOSWAP_GET,
  GET_SWAP_TOKENS_REQUEST,
  GET_SWAP_TOKENS_SUCCESS,
  GET_SWAP_TOKENS_FAIL,
  SWAP_RECEIVE_SET,
  SWAP_RECEIVE_GET,
  SWAP_RECEIVE_ERROR,
  SWAP_RECEIVE_RESET,
  MAKE_STABLE_SWAP_REQUEST,
  MAKE_STABLE_SWAP_SUCCESS,
  MAKE_STABLE_SWAP_FAIL,
  RESET_STABLE_SWAP_ERROR,
  SWAP_SET_APP_TWO_FA,
  SWAP_SET_EMAIL_TWO_FA,
  SWAP_SET_PHONE_TWO_FA,
  SWAP_RESET_TWO_FA,
} from '../actions/swap';

export default function reducer(
  state = {
    history: {
      data: {
        page: 0,
        pages: 0,
        countItems: 0,
        entities: [],
      },
      fetching: false,
    },
    exchangeWallets: {
      data: [null, null],
      fetching: false,
    },
    offers: {
      data: null,
      fetching: false,
      // updating: false,
    },
    updatedOffers: {
      data: null,
      fetching: false,
    },
    exchange: {
      data: null,
      error: null,
      fetching: false,
    },
    autoswapData: [],
    tokens: {
      tokens: [],
      fetching: false,
    },
    priceForSwap: {
      data: null,
      fetching: false,
      error: null,
    },
    makeStable: {
      data: null,
      fetching: false,
      error: null,
    },
    twoFa:{
      isAppTwoFa: false,
      isEmailTwoFa: false,
      isPhoneTwoFa: false,
      fetching: false,
    }
  },
  action
) {
  switch (action.type) {
    case SWAP_HISTORY_GET:
      return {
        ...state,
        history: {
          ...state.history,
          fetching: !(action.page && action.page > 1),
        },
      };
    case GET_SWAP_TOKENS_SUCCESS:
      return {
        ...state,
        tokens: { tokens: action.payload, fetching: false },
      };
    case SWAP_HISTORY_SET:
      return action.payload.page > 1
        ? {
            ...state,
            history: {
              ...state.history,
              data: {
                ...state.history.data,
                ...action.payload,
                entities: [...state.history.data.entities, ...action.payload.entities],
              },
              fetching: false,
            },
          }
        : {
            ...state,
            history: {
              ...state.history,
              data: {
                ...state.history.data,
                ...action.payload,
              },
              fetching: false,
            },
          };
    case SWAP_HISTORY_ERROR:
      return {
        ...state,
        history: {
          ...state.history,
          fetching: false,
        },
      };
    case SWAP_EXCHANGE_WALLETS_GET:
    case SWAP_EXCHANGE_WALLET_GET:
      return {
        ...state,
        exchangeWallets: {
          ...state.exchangeWallets,
          fetching: true,
        },
      };
    case SWAP_EXCHANGE_WALLETS_SET:
      return {
        ...state,
        exchangeWallets: {
          ...state.exchangeWallets,
          data: action.payload,
          fetching: false,
        },
      };
    case SWAP_EXCHANGE_WALLET_SET:
      return {
        ...state,
        exchangeWallets: {
          ...state.exchangeWallets,
          data:
            action.payload.number === 0
              ? [action.payload.data, state.exchangeWallets.data[1]]
              : [state.exchangeWallets.data[0], action.payload.data],
          fetching: false,
        },
      };
    case SWAP_EXCHANGE_WALLETS_RESET:
      return {
        ...state,
        exchangeWallets: { data: [null, null], fetching: false },
      };

    case SWAP_EXCHANGE_WALLETS_ERROR:
      return {
        ...state,
        exchangeWallets: {
          ...state.exchangeWallets,
          fetching: false,
        },
      };
    case SWAP_OFFERS_GET:
      return {
        ...state,
        offers: {
          ...state.offers,
          fetching: true,
        },
      };
    // case SWAP_OFFERS_UPDATE:
    //   return {
    //     ...state,
    //     offers: {
    //       ...state.offers,
    //       updating: true,
    //     },
    //   };
    case SWAP_OFFERS_SET:
      return {
        ...state,
        offers: {
          ...state.offers,
          data: action.payload,
          fetching: false,
        },
      };
    case SWAP_RECEIVE_GET:
      return {
        ...state,
        priceForSwap: {
          ...state.priceForSwap,
          fetching: true,
        },
      };
    case SWAP_RECEIVE_SET:
      return {
        ...state,
        priceForSwap: {
          ...state.priceForSwap,
          data: action.payload,
          fetching: false,
        },
      };
    case SWAP_RECEIVE_ERROR:
      return {
        ...state,
        priceForSwap: {
          ...state.priceForSwap,
          error: action.payload,
          fetching: false,
        },
      };
    case SWAP_RECEIVE_RESET:
      return {
        ...state,
        priceForSwap: {
          ...state.priceForSwap,
          data: null,
          fetching: false,
        },
      };
    case SWAP_OFFERS_RESET:
      return {
        ...state,
        offers: {
          data: null,
          fetching: false,
        },
      };
    case SWAP_OFFERS_ERROR:
      return {
        ...state,
        offers: {
          ...state.offers,
          fetching: false,
        },
      };
    case SWAP_OFFERS_UPDATE_GET:
      return {
        ...state,
        updatedOffers: {
          ...state.updatedOffers,
          fetching: true,
        },
      };
    case SWAP_OFFERS_UPDATE_SET:
      return {
        ...state,
        updatedOffers: {
          ...state.updatedOffers,
          data: action.payload,
          fetching: false,
        },
      };
    case SWAP_OFFERS_UPDATE_APPLY:
      return {
        ...state,
        offers: {
          ...state.offers,
          data: state.updatedOffers.data,
          fetching: false,
        },
        updatedOffers: {
          ...state.updatedOffers,
          data: null,
          fetching: false,
        },
      };
    case RESET_SWAP_OFFERS_UPDATED_DATA:
      return {
        ...state,
        updatedOffers: {
          ...state.updatedOffers,
          data: null,
        },
      };
    case SWAP_OFFERS_UPDATE_ERROR:
      return {
        ...state,
        updatedOffers: {
          ...state.offers,
          fetching: false,
        },
      };
    case SWAP_EXCHANGE_GET:
      return {
        ...state,
        exchange: {
          ...state.offers,
          error: null,
          fetching: true,
        },
      };
    case SWAP_EXCHANGE_SET:
      return {
        ...state,
        exchange: {
          ...state.offers,
          data: action.payload,
          error: null,
          fetching: false,
        },
      };
    case SWAP_EXCHANGE_ERROR:
      return {
        ...state,
        exchange: {
          ...state.offers,
          error: action.payload,
          fetching: false,
        },
      };
    case RESET_SWAP_EXCHANGE_ERROR:
      return {
        ...state,
        exchange: {
          ...state.offers,
          error: null,
          fetching: false,
        },
      };
    case AUTOSWAP_GET:
      return {
        ...state,
        autoswapData: action.payload,
      };
    case MAKE_STABLE_SWAP_REQUEST:
      return {
        ...state,
        makeStable: {
          ...state.makeStable,
          error: null,
          fetching: true,
        },
      };
    case MAKE_STABLE_SWAP_SUCCESS:
      return {
        ...state,
        makeStable: {
          ...state.makeStable,
          data: action.payload,
          error: null,
          fetching: false,
        },
      };
    case MAKE_STABLE_SWAP_FAIL:
      return {
        ...state,
        makeStable: {
          ...state.makeStable,
          error: action.payload,
          fetching: false,
        },
      };
    case RESET_STABLE_SWAP_ERROR:
      return {
        ...state,
        makeStable: {
          ...state.makeStable,
          error: null,
          fetching: false,
        },
      };
      case SWAP_SET_APP_TWO_FA:
        return {
          ...state,
          twoFa:{
            ...state.twoFa,
            isAppTwoFa: action.isAppTwoFa,
            fetching: false,
          },
           makeStable: {
          ...state.makeStable,
          fetching: false,
        },
          
        };
      case SWAP_SET_EMAIL_TWO_FA:
        return {
          ...state,
          twoFa:{
            ...state.twoFa,
            isEmailTwoFa: action.isEmailTwoFa,
            fetching: false,
          },
           makeStable: {
          ...state.makeStable,
          fetching: false,
        },
          
        };
        case SWAP_SET_PHONE_TWO_FA:
        return {
          ...state,
          twoFa:{
            ...state.twoFa,
            isPhoneTwoFa: action.isPhoneTwoFa,
            fetching: false,
          },
           makeStable: {
          ...state.makeStable,
          fetching: false,
        },
        };
      case SWAP_RESET_TWO_FA:
        return {
          ...state,
          twoFa:{
            ...state.twoFa,
            isAppTwoFa: false,
            isEmailTwoFa: false,
            isPhoneTwoFa: false,
            fetching: false,
          },
           makeStable: {
          ...state.makeStable,
          fetching: false,
        },
         
        };
    default:
      return state;
  }
}
