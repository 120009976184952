export function getContrastTextColor(hex) {
  const rgb = hexToRgb(hex);

  const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);

  // brightness > 128 by default, increased to fix some color combinations
  const textColor = brightness > 200 ? '#000000' : '#FFFFFF';
  return textColor;
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}
