import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';

const MultisendSummary = ({ currency, multisendEstimate, wallet, isMainCurrency }) => {
  const { t } = useTranslation();
  // console.log('currency', currency)

  // console.log('multisendEstimate', multisendEstimate)
  // console.log('wallet multisend confirm', wallet);

  return (
    <div className="multisend-summary">
      <div className="multisend-summary__title">{t('multisendWithdraw.summary')}</div>
      <div className="multisend-summary__table">
        <Row gutter={[2, 2]}>
          <Col span={24} md={12}>
            <div className="multisend-summary__cell">
              <span className="multisend-summary__value">{multisendEstimate.multisendWallets.length}</span>
              <span className="multisend-summary__name">{t('multisendWithdraw.totalNumberOfAddresess')}</span>
            </div>
          </Col>
          {!isMainCurrency.length ? (
            <Col span={24} md={12}>
              <div className="multisend-summary__cell">
                <span className="multisend-summary__value">
                  {multisendEstimate.allowance} {currency.title}
                </span>
                <span className="multisend-summary__name">{t('multisendWithdraw.totalNumberOfTokensToBeSent')}</span>
              </div>
            </Col>
          ) : null}
          <Col span={24} md={12}>
            <div className="multisend-summary__cell">
              <span className="multisend-summary__value">{multisendEstimate.countTxs}</span>
              <span className="multisend-summary__name">{t('multisendWithdraw.totalNumberOfTransactionsNeed')}</span>
            </div>
          </Col>
          {!isMainCurrency.length ? (
            <Col span={24} md={12}>
              <div className="multisend-summary__cell">
                <span className="multisend-summary__value">
                  {wallet?.tokens[0]?.balance?.value} {currency?.title}
                </span>
                <span className="multisend-summary__name">{t('multisendWithdraw.yourTokenBalance')}</span>
              </div>
            </Col>
          ) : null}

          <Col span={24} md={24}>
            <Row gutter={[2, 0]}>
              <Col span={24} md={12}>
                <Row gutter={[0, 2]}>
                  <Col span={24} md={24}>
                    <div className="multisend-summary__cell">
                      <span className="multisend-summary__value">
                        {multisendEstimate.minerFee} {multisendEstimate.currencyFee}
                      </span>
                      <span className="multisend-summary__name">
                        {t('multisendWithdraw.aproximateCostsOfMinerFee')}
                      </span>
                    </div>
                  </Col>
                  <Col span={24} md={24}>
                    <div className="multisend-summary__cell">
                      <span className="multisend-summary__value">
                        {multisendEstimate.systemFee} {multisendEstimate.currencyFee}
                      </span>
                      <span className="multisend-summary__name">
                        {t('multisendWithdraw.aproximateCostsOfSystemFee')}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} md={12}>
                <Row gutter={[0, 2]} className="multisend-summary__cell-united">
                  <Col span={24} md={24}>
                    <div className="multisend-summary__cell">
                      <span className="multisend-summary__value">
                        {wallet.balance.value} {multisendEstimate.currencyFee}
                      </span>
                      <span className="multisend-summary__name">
                        {t('multisendWithdraw.yourCurrencyBalance', { currency: multisendEstimate.currencyFee })}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MultisendSummary;
