import React from 'react'
import TEXT from '../Text';
import { PercentInput } from '../FormFields';

const SystemFeeSwap = ({name}) => {
  return (
    <div className="system-fee-setting">
    <TEXT.sectionTitle>% System fee for Swap</TEXT.sectionTitle>
    <TEXT.sectionDescription className="system-fee-switcher__description">
    You can choose the percentage of commission charged (1-100%)
    </TEXT.sectionDescription>
    <PercentInput name={name} className="system-fee-setting__input" />
  </div>
  )
}

export default SystemFeeSwap