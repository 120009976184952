import React, { useState } from 'react';
import JwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import useWindowSize from '../../src/utils/resizeHook';
import './Footer.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import FooterLinks from '../components/FooterLinks/FooterLinks';
import SocialLinks from '../components/SocialLinks/SocialLinks';
import twitter from '../img/footer/twitter-black.svg';
import discord from '../img/footer/discord-black.svg';
import facebook from '../img/footer/facebook-black.svg';
import linkedin from '../img/footer/linkedin-black.svg';
import RequestForm from '../components/RequestForm';

const FOOTER_LINKS = [
  {
    name: 'Twitter',
    icon: `${twitter}`,
    link: 'https://twitter.com/cpay_world',
    id: 0,
  },
  {
    name: 'Discord',
    icon: `${discord}`,
    link: 'https://discord.gg/dHbZbCUC7K',
    id: 1,
  },
  {
    name: 'Facebook',
    icon: `${facebook}`,
    link: 'https://www.facebook.com/CPAY-104884878693492/',
    id: 2,
  },
  {
    name: 'Linkedin',
    icon: `${linkedin}`,
    link: 'https://www.linkedin.com/company/cpay-world/',
    id: 3,
  },
];

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,

      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },

      match,
      location,
      history,
    };
  }, [params, match, location, history]);
}
const initialState = {
  cases: false,
  products: false,
  company: false,
  supports: false,
  personal: false,
  partner: false,
  business: false,
};

const Footer = () => {
  const { t } = useTranslation();
  const userRole = JwtDecode(localStorage.getItem('authToken')).roles;
  const pathname = useRouter().pathname;
  const [helpFormFlag, setHelpFormFlag] = useState(false);
  const linkCpay = `https://cpay.world/`;
  const Product_LINKS = [
    {
      title: t('Personal'),
      link: linkCpay,
      id: 21,
    },
    {
      title: t('business'),
      link: `${linkCpay}business`,
      id: 22,
    },
    {
      title: t('White label'),
      link: `${linkCpay}white-label-cryptocurrency-payment`,
      id: 3,
    },
    {
      title: t('Debit card'),
      link: `${linkCpay}get-debit-card`,
      id: 4,
    },
    {
      title: t('Swap tokens'),
      link: `https://app.cpay.world/swap`,
      id: 7,
      // inactive: true,
      // description: 'Soon',
    },
    // {
    //   title: 'Multi Merchant',
    //   link: `${process.env.CPAY_APP_URL}/sign-up?status=business`,
    //   id: 5,
    // },
    // {
    //   title: t('Mobile app (soon)'),
    //   id: 6,
    //   inactive: true,
    //   description: 'Soon',
    // },
    // {
    //   title: t('Gate (soon)'),
    //   id: 8,
    //   inactive: true,
    //   description: 'Soon',
    // },
  ];

  const Company_LINKS = [
    {
      title: t('Contact us'),
      link: `/`,
      id: 12,
    },
    {
      title: t('Schedule a call'),
      link: `/`,
      id: 11,
    },
    // {
    //   title:"Privacy Policy",
    //   link:"/docs/Privacy_Policy.pdf",
    //   id:998,
    // },
    // {
    //   title:"Terms of Service",
    //   link:"/docs/Terms_of_Use.pdf",
    //   id:999,
    // },
    // {
    //   title: 'Blog',
    //   innerLink: '/blog',
    //   id: 11,
    // },
    // {
    //   title: 'About us',
    //   link: '/',
    //   id: 12,
    // },
  ];

  const Support_LINKS = [
    {
      title: t('FAQ'),
      link: `${linkCpay}faq`,
      id: 13,
    },
    {
      title: t('Documentation'),
      link: `https://docs.cpay.world/`,
      id: 14,
    },
    {
      title: userRole && userRole.includes('admin') ? 'Documentation for admin panel' : '',
      link: userRole && userRole.includes('admin') ? 'https://admin-docs.cpay.world/' : '',
      id: 15,
    },

    // {
    //   title: t('CPAY API'),
    //   link: `https://docs.cpay.world/api/api-architecture/`,
    //   id: 15,
    // },
  ];

  // const Personal_LINKS = [
  //   {
  //     title: 'Merchant',
  //     link: `${process.env.CPAY_APP_URL}/sign-up?status=personal`,
  //     id: 16,
  //   },
  // ];

  const Partner_LINKS = [
    {
      title: t('List your coin'),
      link: `https://forms.gle/mHJyZEQFkQq9NhkF7`,
      id: 17,
    },
    // {
    //   title: t('Coin Price API'),
    //   link: `https://docs.coinpricelist.io/api/api-architecture/`,
    //   id: 18,
    // },
  ];

  // const Business_LINKS = [
  //   {
  //     title: 'Multi Merchant',
  //     link: `${process.env.CPAY_APP_URL}/sign-up?status=business`,
  //     id: 19,
  //   },
  // ];

  const Cases_LINKS = [
    {
      title: t('How to accept crypto donations?'),
      link: `${linkCpay}/cases/accept-crypto-donations`,
      id: 20,
    },
    {
      title: t('How to accept crypto payments?'),
      link: `${linkCpay}/cases/accept-crypto-payments`,
      id: 21,
    },
    {
      title: t('How To Exchange P2P Crypto?'),
      link: `${linkCpay}/cases/p2p-crypto-exchanges`,
      id: 22,
    },
  ];

  const footerInfo = [
    t('NEROSTO LTD, Founded. 05/25/2018,'),
    t('Reg. number in the state company register 384383,'),
    t('Address: Inomenon Ethnon, 48, Guricon House,'),
    t('6042, Larnaca, Cyprus'),
    // "Dir. Vadim Okruzhkov"
  ];

  const { currentWidth } = useWindowSize();

  const [linksAreOpened, setLinksAreOpened] = useState({
    ...initialState,
  });

  const handleIconClick = e => {
    const { name } = e.currentTarget.dataset;
    if (currentWidth < 768) {
      setLinksAreOpened(prev => ({ ...prev, [name]: !prev[name] }));
      return;
    }
  };

  return (
    <div className={cn('footer', { 'user-footer': pathname !== '/admin' })}>
      <div className="footer__container">
        <div
          className={cn('footer-logo', {
            desktop: currentWidth >= 768,
          })}
        >
          <Link to="/"></Link>
        </div>

        {
          <FooterLinks
            FOOTER_LINKS={FOOTER_LINKS}
            footerInfo={footerInfo}
            handleIconClick={handleIconClick}
            linksAreOpened={linksAreOpened}
            prodLinks={Product_LINKS}
            companyLinks={Company_LINKS}
            supportLinks={Support_LINKS}
            partnerLinks={Partner_LINKS}
            caseLinks={Cases_LINKS}
            setLinksAreOpened={setLinksAreOpened}
            setHelpFormFlag={setHelpFormFlag}
            helpFormFlag={helpFormFlag}
          />
        }

        {currentWidth < 768 && (
          <>
            <SocialLinks data={FOOTER_LINKS} isFromFooter />
            <div className="footer-privacy-wrapper">
              <div className="footer-privacy-terms">
                <a href="/docs/Privacy_Policy.pdf" download="Privacy_Policy.pdf">
                  {t('Privacy Policy')}
                </a>
                <a href="/docs/Terms_of_Use.pdf" download="Terms_of_Use.pdf">
                  {t('Terms of Service')}
                </a>
              </div>
              <p className="footer-privacy-rights">{t('All rights reserved © 2022. CPAY.world')}</p>
            </div>
          </>
        )}
      </div>
      {helpFormFlag && <RequestForm close={() => setHelpFormFlag(false)} />}
    </div>
  );
};

export default Footer;
