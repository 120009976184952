import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Button, Row } from 'antd';
import { forgottenPassword } from '../../redux/actions/user';
import { notificationsErrorShow, notificationsSuccessShow } from '../../redux/actions/notifications';
import PasswordChanged from './PasswordChanged';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { identity } from '../../utils/getIdentity';

const NewPasswordForm = ({
  forgottenPassword,
  toggleWelcomeBlock,
  notificationsErrorShow,
  notificationsSuccessShow,
}) => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [responseToken, setResponseToken] = useState('');
  const { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const handleFormSubmit = async () => {
    if (password === repeatPassword) {
      const response = await forgottenPassword(token, {
        password,
        repeatPassword,
      });

      if (response?.message === 'ok' || response?.status === '200') {
        setResponseToken(response.token);
        //  toggleWelcomeBlock(true);
        history.push('/login');
        notificationsSuccessShow({ message: t('success') });
      }
    } else {
      notificationsErrorShow({
        message: t('validation.passwordsShouldMatch'),
      });
    }
  };

  return responseToken ? (
    <PasswordChanged responseToken={responseToken} />
  ) : (
    <Form initialValues={{ remember: true }} className="login-form">
      <React.Fragment>
        <div className="login-form__label login-form__label_flex">{t('newPassword')}</div>
        <Form.Item name="password" rules={[{ required: true, message: t('validation.required') }]}>
          <Input.Password
            placeholder={t('newPassword')}
            className="login-form__input"
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Item>

        <div className="login-form__label login-form__label_flex">{t('confirmPassword')}</div>
        <Form.Item name="repeatPassword" rules={[{ required: true, message: t('validation.required') }]}>
          <Input.Password
            placeholder={t('confirmPassword')}
            className="login-form__input"
            onChange={e => setRepeatPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            className={`login-form__button login-form__button-${identity}`}
            onClick={handleFormSubmit}
          >
            {t('logIn')}
          </Button>
        </Form.Item>

        {identity !== 'finvaro' && (
          <Row>
            <div className={`login-form__text login-form__text_center login-form__text-${identity}`}>
              {t('auth.dontHaveAccount')} <Link to="/sign-up">{t('signUp')}</Link>
            </div>
          </Row>
        )}
      </React.Fragment>
    </Form>
  );
};

// const mapStateToProps = state => ({
//   fetchingUser: state.login.fetching,
// });

const mapDispatchToProps = dispatch => ({
  forgottenPassword: forgottenPassword(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
  notificationsSuccessShow: success => dispatch(notificationsSuccessShow(success)),
});

export default connect(
  // mapStateToProps,
  null,
  mapDispatchToProps
)(NewPasswordForm);
