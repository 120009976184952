import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import SwapFilters from './SwapFilters';
import SwapItem from './SwapItem';
import SwapItemMobile from './SwapItemMobile';
import SwapDetailsMobile from './SwapDetailsMobile';
import SwapEmpty from './SwapEmpty';
import { LoadingOutlined } from '@ant-design/icons';
import { getSwapHistory } from '../../redux/actions/swap';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowSize from '../../utils/resizeHook';
import './style.scss';
import { cryptoApi } from '../../service/cryptopay-api';
import { getSwapTokens } from '../../redux/actions/swap';
import { identity } from '../../utils/getIdentity';
import { compareNewTokensArrays } from '../../utils/compareNewTokensArrays';
import Button from '../Button';

const defaultHistoryFilters = {
  search: '',
  currencyFromId: '',
  currencyToId: '',
  order: 'DESC',
  limit: 30,
};

const SwapHistory = ({
  swapHistoryData,
  merchantId,
  getSwapHistory,
  swapHistoryFetching,
  currencies,
  networkFilter,
  getSwapTokens,
  swapTokens,
}) => {
  const [historyFilters, setHistoryFilters] = useState(defaultHistoryFilters);
  const [socket, setSocket] = useState(undefined);
  const [transactionDetailsData, setTransactionDetailsData] = useState(null);
  const [resetFilters, setResetFilters] = useState(null);
  const { page, pages, countItems, entities } = swapHistoryData || {};
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem('swapTab') ?? localStorage.setItem('swapTab', 'swap')
  );
  const [currenciesForStableSwap, setCurrenciesForStableSwap] = useState([]);
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();

  // const swapTab = localStorage.getItem('swapTab')

  useEffect(() => {
    setCurrenciesForStableSwap(compareNewTokensArrays(swapTokens, currencies));
  }, [currencies, swapTokens]);

  useEffect(() => {
    merchantId &&
      getSwapHistory(merchantId, {
        ...historyFilters,
        currencyFromId: historyFilters.currencyFromId || undefined,
        currencyToId: historyFilters.currencyToId || undefined,
        swapType: activeTab === 'swap' ? 'swapSpace' : 'allBridge',
      });
  }, [merchantId, historyFilters, activeTab]);

  useEffect(() => {
    // cryptoApi.getSwapTokens();
    getSwapTokens();
  }, []);

  const currentMerchant = localStorage.getItem('merchantId');

  useEffect(() => {
    socket && socket.disconnect();
    setSocket(undefined);
    createSocket(merchantId);
  }, [merchantId]);

  // console.log('activeTab', activeTab)

  const createSocket = merchantId => {
    const io = window.io;
    const socket = io(process.env.REACT_APP_API_URL, {
      allowEIO3: true,
      withCredentials: true,
    });
    socket.on('connect', () => {
      socket.emit('sign-in-merchant', { token: localStorage.getItem('authToken'), merchantId });
      setSocket(socket);
    });
    socket.on('swapStatusUpdate', () => {
      if (merchantId) {
        getSwapHistory(
          merchantId,
          {
            ...historyFilters,
            currencyFromId: historyFilters.currencyFromId || undefined,
            currencyToId: historyFilters.currencyToId || undefined,
            swapType: activeTab === 'swap' ? 'swapSpace' : 'allBridge', // try to add, Vlad check pls
          },
          true
        );
      }
    });
    socket.on('disconnect', function () {});
  };

  return (
    <div className="block-for-component">
      <div className="swap-buttons">
        <button
          className={`swap-buttons-button ${
            activeTab === 'swap' && `swap-buttons-button-active swap-buttons-button-active-${identity}`
          }`}
          onClick={() => {
            {
              setActiveTab('swap');
              localStorage.setItem('swapTab', 'swap');
            }
          }}
        >
          Swap
        </button>
        {identity === 'cpay' && (
          <button
            className={`swap-buttons-button ${
              activeTab === 'stable' && `swap-buttons-button-active swap-buttons-button-active-${identity}`
            }`}
            onClick={() => {
              setActiveTab('stable');
              localStorage.setItem('swapTab', 'stable');
            }}
          >
            Swap stable coin
          </button>
        )}
      </div>
      <div className="swap-history">
        <div className="swap-history__header">
          <div className="swap-history__title">{t('swap.title')}</div>
          <Button>
            <Link
              className={`swap-history__new swap-history__new-${identity}`}
              to={activeTab === 'stable' ? '/swap/create/stable' : '/swap/create'}
            >
              {t('swap.new')}
            </Link>
          </Button>
        </div>

        {transactionDetailsData && (
          <SwapDetailsMobile
            transactionDetailsData={transactionDetailsData}
            setTransactionDetailsData={setTransactionDetailsData}
            currencies={currencies}
            networkFilter={networkFilter}
          />
        )}

        <SwapFilters
          transactionDetailsData={transactionDetailsData}
          currencies={activeTab === 'stable' ? currenciesForStableSwap : currencies}
          historyFilters={historyFilters}
          setHistoryFilters={setHistoryFilters}
          defaultHistoryFilters={defaultHistoryFilters}
          setResetFilters={setResetFilters}
          countItems={countItems}
          swapHistoryFetching={swapHistoryFetching}
        />

        {swapHistoryFetching ? (
          <div className="swap-history__loader">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            <span>{t('swap.loading')}</span>
          </div>
        ) : entities && entities.length ? (
          <>
            <div className="swap-history__legend">
              <div className="swap-history__legend-exchangeId">{t('swap.exchangeId')}</div>
              <div className="swap-history__legend-date">{t('swap.date')}</div>
              <div className="swap-history__legend-currency">{t('currency')}</div>
              <div className="swap-history__legend-status">{t('status')}</div>
            </div>

            <div id="swap-scroll" className="swap-history__list">
              <div className="swap-history__list-scroller">
                <InfiniteScroll
                  dataLength={entities.length}
                  next={() =>
                    getSwapHistory(merchantId, {
                      ...historyFilters,
                      currencyFromId: historyFilters.currencyFromId || undefined,
                      currencyToId: historyFilters.currencyToId || undefined,
                      page: page + 1,
                    })
                  }
                  hasMore={entities.length < countItems && page < pages}
                  loader={
                    <div className="swap-history__loader">
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                      <span>{t('swap.loading')}</span>
                    </div>
                  }
                  scrollableTarget="swap-scroll"
                >
                  {entities.map(swapItem => {
                    return currentWidth > 590 ? (
                      <SwapItem
                        key={swapItem._id}
                        swapItem={swapItem}
                        currencies={currencies}
                        networkFilter={networkFilter}
                      />
                    ) : (
                      <SwapItemMobile
                        key={swapItem._id}
                        swapItem={swapItem}
                        setTransactionDetailsData={setTransactionDetailsData}
                        currencies={currencies}
                      />
                    );
                  })}
                </InfiniteScroll>
              </div>
            </div>
          </>
        ) : (
          <SwapEmpty
            resetFilters={resetFilters}
            historyFilters={historyFilters}
            defaultHistoryFilters={defaultHistoryFilters}
            activeTab={activeTab}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  swapHistoryData: state.swap.history.data,
  swapHistoryFetching: state.swap.history.fetching,
  merchantId: state.transactions.merchantId,
  currencies: state.currencies.data,
  networkFilter: state.networkFilter,
  swapTokens: state.swap.tokens.tokens,
});

const mapDispatchToProps = dispatch => ({
  getSwapHistory: getSwapHistory(dispatch),
  getSwapTokens: getSwapTokens(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapHistory);
