import React from 'react';
import { connect } from 'react-redux';
import ClientsWalletsList from '../components/WalletsPage/ClientsWalletsList';
import PageHeader from '../layout/PageHeader';
import WalletCreateModal from '../components/WalletCreateModal/WalletCreateModal';

const ClientsPage = ({ merchantId, userRole, networkFilter }) => {
  const WalletCreateComponent = () => (
    <WalletCreateModal
      merchantId={merchantId}
      typeWallet="user"
      createMode="type"
      filtersFormName="clientsFilters"
      networkFilter={networkFilter}
    />
  );

  return (
    <>
      <PageHeader showBackButton={userRole.includes('corporate')} CreateModalComponent={WalletCreateComponent} />
      <div className="component">
        <ClientsWalletsList
          formName="clientsFilters"
          merchantId={merchantId}
          typeWallet="user"
          networkFilter={networkFilter}
        />
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  userRole: state.user.data.roles,
  networkFilter: state.networkFilter,
});

export default connect(mapStateToProps)(ClientsPage);
