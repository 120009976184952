import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { cryptoApi } from '../../../service/cryptopay-api';
import Button from '../../Button';
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import { getContrastTextColor, hexToRgb } from '../../../utils/getTextColorByBg';
import styles from './clientDataForm.module.scss';
import { mainColor } from '../../../utils/getMainColor';
import { identity } from '../../../utils/getIdentity';


const ClientDataForm = ({ getChargeState, getChargeData, notificationsErrorShow }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmit = async values => {
    try {
      const response = await cryptoApi.saveCustomerDetails(getChargeState.data.id, values);
      if (response && response.status === 'success') {
        getChargeData(getChargeState.data.id);
      }
    } catch (err) {
      notificationsErrorShow(err.data.message.join(', '));
    }
  };

  return (
    <>
      <style>{`
        .${styles[`checkout-client-data__submit checkout-client-data__submit-${identity}`]} {
          background: ${getChargeState?.data?.checkout.accentColor} !important;
          color: ${getContrastTextColor(getChargeState?.data?.checkout.accentColor || mainColor)} !important;
          transition: opacity .3s ease;
          &:hover {
            opacity: 0.85 !important;
          }
        }
        .ant-input {
          &:hover {
            border-color: ${getChargeState?.data?.checkout.accentColor} !important;
          }
          &:focus {
            border-color: ${getChargeState?.data?.checkout.accentColor} !important;
            box-shadow: 0 0 0 2px rgb(${hexToRgb(getChargeState?.data?.checkout.accentColor || mainColor).join(
              ' '
            )} / 0.2) !important;
          }
        }
      `}</style>
      <div className={styles['checkout-client-data']}>
        <div className={styles['checkout-client-data__title']}>{t('checkouts.clientDataForm.title')}</div>
        <Form
          form={form}
          name="checkout-client-form"
          initialValues={{
            customerName: getChargeState.data.customerName || '',
            customerEmail: getChargeState.data.customerEmail || '',
          }}
          onFinish={handleSubmit}
        >
          {getChargeState.data.checkout.collectEmail && (
            <Form.Item
              name="customerEmail"
              rules={[{ required: true, message: t('checkouts.clientDataForm.emailMessage') }]}
            >
              <Input
                className={styles['checkout-client-data__email']}
                placeholder={t('checkouts.clientDataForm.emailHolder')}
              />
            </Form.Item>
          )}
          {getChargeState.data.checkout.collectName && (
            <Form.Item
              name="customerName"
              rules={[{ required: true, message: t('checkouts.clientDataForm.nameMessage') }]}
            >
              <Input
                className={styles['checkout-client-data__name']}
                placeholder={t('checkouts.clientDataForm.nameHolder')}
              />
            </Form.Item>
          )}
          <Button type="submit" className={styles['checkout-client-data__submit']}>
            {t('save')}
          </Button>
        </Form>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  notificationsErrorShow: error => dispatch(notificationsErrorShow({ message: error })),
});

export default connect(null, mapDispatchToProps)(ClientDataForm);
