import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { transactionsErrorTranslations } from '../helpers';
import TokenIcon from '../../TokenIcon';
import formatCurrency from '../../../utils/currencyFormatter';
import useWindowSize from '../../../utils/resizeHook';
import { tokenNodeTypeObj, tokenTypeEnd } from '../../../utils/tokenNodeTypes';
import CopyWrapper from '../../CopyWrapper';
import { ReactComponent as CopyIconCpay } from '../../../img/cpay-svg/copy-blue.svg';
import { ReactComponent as CopyIconNfg } from '../../../img/nfgpay-svg/copy-blue-nfg.svg';
import { ReactComponent as CopyIconFinvaro } from '../../../img/finvaro/copy-blue-finvaro.svg';
import { ReactComponent as CopyIconClarnium } from '../../../img/clarnium/copy-blue-clarnium.svg';
import './style.scss';
import { getHashFromLink } from '../../../utils/getHashFromLink';
import { identity } from '../../../utils/getIdentity';

const copyIcon = {
  cpay: <CopyIconCpay />,
  nfg: <CopyIconNfg />,
  finvaro: <CopyIconFinvaro />,
  clarnium: <CopyIconClarnium />
};

const Transaction = ({ transaction, currencies, networkFilter, tokenNodeType }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [transactionNodeType, setTransactionNodeType] = useState('');
  const [showMultiAddressesModal, setShowMultiAddressesModal] = useState(false);
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();

  const getTypeForTransactions = transaction => {
    return identity === 'nfg' && (transaction.systemStatus === 'Failed' || transaction.systemStatus === 'Error')
      ? 'Error'
      : identity === 'nfg' && transaction.swap
      ? 'Swap'
      : transaction.type;
  };

  useEffect(() => {
    const foundCurrency = currencies.find(currency => currency._id === transaction.info.currencyId);
    foundCurrency && setTransactionNodeType(foundCurrency.nodeType);
  }, [currencies, transaction]);

  return (
    <>
      {transaction.info.multisend && (
        <Modal
          centered={true}
          onCancel={() => setShowMultiAddressesModal(false)}
          title={`${t('multisendTransactions')}:`}
          wrapClassName="transaction__modal"
          width={400}
          visible={showMultiAddressesModal}
        >
          <div className="transaction__modal-wrap">
            {transaction.info.multisend.map(item => (
              <div className="transaction__modal-item">
                <div className="transaction__modal-label">{t('address')}:</div>
                <div className="transaction__modal-value">
                  {item.address} <CopyWrapper copyContent={item.address}>{copyIcon[identity]}</CopyWrapper>
                </div>
                <div className="transaction__modal-label">{t('amount')}:</div>
                <div>
                  <span className="transaction__modal-value">{`${item.value} ${transaction.info.currency}`}</span>
                  {' - '}
                  <span className="transaction__modal-value">{formatCurrency(+item.usd)}</span>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
      <div className={`transaction transaction-${identity} transaction-${identity}-${getTypeForTransactions(transaction).toLowerCase()}`}>
        <Row align="middle" onClick={() => setIsOpened(!isOpened)}>
          <Col span={currentWidth >= 1024 ? 1 : 2}>
            <div className="transaction__logo">
              <TokenIcon tokenName={transaction.info.currency} />
            </div>
          </Col>
          <Col span={currentWidth >= 1024 ? 6 : 8}>
            <div className="transaction__type">
              {identity === 'nfg' && transaction.swap
                ? 'Swap'
                : transaction.type === 'InternalTransfer'
                ? t('internalTransfer')
                : transaction.type === 'ExternalCall'
                ? t('externalCall')
                : t(transaction.type.toLowerCase())}{' '}
              {identity !== 'nfg' && transaction.swap && '(SWAP)'}
            </div>
            <div className="transaction__amount_tokens">
              {transaction.info.amount.value || transaction.info.amount.value === 0 ? transaction.info.currency : ''}{' '}
              {transaction.info.currencyType === 'token' ? tokenNodeTypeObj[transaction.info.nodeType] : null}
              {tokenTypeEnd(transaction.info.currencyType, transaction.info.nodeType, transaction.info.currency)}
            </div>
            {currentWidth < 1024 && (
              <div className={`transaction__status transaction__status_${transaction.systemStatus.toLowerCase()}`}>
                {t(transaction.systemStatus.toLowerCase())}
              </div>
            )}
          </Col>
          {currentWidth >= 1024 && (
            <>
              <Col span={7}>
                <div className="transaction__date">
                  {t(moment(transaction.createdAt).format('MMM').toLowerCase())}{' '}
                  {moment(transaction.createdAt).format('D, YYYY, HH:mm')}
                </div>
              </Col>
              <Col span={2}>
                <div className={`transaction__status transaction__status_${transaction.systemStatus.toLowerCase()}`}>
                  {t(transaction.systemStatus.toLowerCase())}
                </div>
              </Col>
            </>
          )}
          <Col span={currentWidth >= 1024 ? 8 : 14}>
            <div className="transaction__amount">
              {transaction.info.nft && Object.keys(transaction.info.nft).length ? (
                <div className="transaction__amount_tokens">
                  {transaction.info.amount.value} {transaction.info.nft.type}
                </div>
              ) : (
                <>
                  <div className="transaction__amount_tokens">
                    {transaction.info.amount.value}{' '}
                    {transaction.info.amount.value || transaction.info.amount.value === 0
                      ? transaction.info.currency
                      : ''}
                  </div>
                  <div className="transaction__amount_usd">${transaction.info.amount.usd}</div>
                </>
              )}
            </div>
          </Col>
        </Row>
        {transaction.errorMessage && (
          <div className="transaction__error-wrapper">
            <div className="transaction__error-title">{t('homePage.transactions.error')}</div>
            <div className="transaction__error-description">
              {transactionsErrorTranslations(t, transaction.errorMessage)}
            </div>
          </div>
        )}
        <div className={`transaction__details ${isOpened && 'transaction__details_opened'}`}>
          <Row>
            <Col>
              <div className="transaction__details-id">
                <b>{t('paymentId')}</b>: {transaction._id}
              </div>
              {transaction.checkout.charge &&
                transaction.checkout.charge.customerDetails &&
                transaction.checkout.charge.customerDetails.name && (
                  <div className="transaction__details-name">
                    <b>{t('homeTransactions.name')}</b>: {transaction.checkout.charge.customerDetails.name}
                  </div>
                )}
              {transaction.checkout.charge &&
                transaction.checkout.charge.customerDetails &&
                transaction.checkout.charge.customerDetails.email && (
                  <div className="transaction__details-email">
                    <b>{t('homeTransactions.email')}</b>: {transaction.checkout.charge.customerDetails.email}
                  </div>
                )}

              {transaction.info.to && (
                <div className="transaction__details-to">
                  <b>{t('to')}</b>: {transaction.info.to}
                  <CopyWrapper copyContent={transaction.info.to}>{copyIcon[identity]}</CopyWrapper>
                </div>
              )}

              {transaction.info.multisend && transaction.info.multisend.length ? (
                <div className="transaction__details-to">
                  <b>{t('to')}</b>:{' '}
                  <span
                    className={`transaction__modal-toggle transaction__modal-toggle-${identity}`}
                    onClick={() => setShowMultiAddressesModal(true)}
                  >
                    {t('multisendWithdraw.showMultAddresses')}
                  </span>
                </div>
              ) : (
                <></>
              )}
              <div className="transaction__details-from">
                <b>{t('from')}</b>: {transaction.info.from}
                <CopyWrapper copyContent={transaction.info.from}>{copyIcon[identity]}</CopyWrapper>
              </div>

              {transaction.info.nft && Object.keys(transaction.info.nft).length ? (
                <>
                  <div className="transaction-details">
                    <b>{t('amount')}</b>: {transaction.info.amount.value}
                  </div>
                  <div className="transaction-details">
                    <b>{t('type')}</b>: {transaction.info.nft.type}
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="transaction__details-fee">
                <b>{t('minerFee')}</b>: {transaction.info.minerFee.value} {transaction.info.minerFee.currency} ⸺ $
                {transaction.info.minerFee.usd ?? null}
              </div>
              {transaction.info.systemFee && (
                <div className="transaction__details-fee">
                  <b>{t('systemFee')}</b>: {transaction.info.systemFee.amount} {transaction.info.systemFee.currency} ⸺ $
                  {formatCurrency(transaction.info.systemFee.amountUSD)}
                </div>
              )}

              {transaction.checkout.charge && transaction.checkout.charge.outsideOrderId && (
                <div className="transaction__details-click-id">
                  <b>clickId</b>: <span>{transaction.checkout.charge.outsideOrderId}</span>
                </div>
              )}
              {transaction.type === 'ExternalCall' && (
                <div className="transaction__details-external-call">
                  {!!Object.keys(transaction.info.call).length && (
                    <>
                      <b>contract Address</b>: <span>{transaction.info.call?.contractAddress}</span>{' '}
                      <CopyWrapper copyContent={transaction.info.call?.contractAddress}>
                        {copyIcon[identity]}
                      </CopyWrapper>
                      <br />
                      <div className="transaction__details-external-call-item">
                        <b>method</b>: <span>{transaction.info.call?.method}</span>
                      </div>
                    </>
                  )}
                  <div className="transaction__details-external-call-item">
                    <b>arguments</b>: <br />
                    <ul className="transaction__details-external-call-arguments">
                      {!!Object.keys(transaction.info.call).length &&
                        transaction.info.call?.arguments?.map((item, index) => (
                          <>
                            <li>
                              {item} {index === 0 && <CopyWrapper copyContent={item}>{copyIcon[identity]}</CopyWrapper>}
                            </li>
                          </>
                        ))}
                      {Object.keys(transaction.info.call).length === 0 &&
                        !!Object.keys(transaction.info?.solanaMint) &&
                        Object.entries(transaction.info?.solanaMint)?.map(([key, value], index) => (
                          <li key={index}>
                            {key} - {value}{' '}
                            {index === 0 && <CopyWrapper copyContent={value}>{copyIcon[identity]}</CopyWrapper>}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
              <div className={`transaction__details-hash transaction__details-hash-${identity}`}>
                {transactionNodeType && transaction.info.hashs[0] && (
                  <>
                    <b>{t('homeTransactions.hash')}</b>:{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={transaction.type === 'InternalTransfer' ? null : `${transaction.info.explorerUrl.current}`}
                      className={`link link-${identity}`}
                    >
                      {getHashFromLink(transaction.info.explorerUrl.current)}
                    </a>
                    <CopyWrapper copyContent={getHashFromLink(transaction.info.explorerUrl.current)}>
                      {copyIcon[identity]}
                    </CopyWrapper>
                  </>
                )}
              </div>

              {transaction.info.explorerUrl.incoming ? (
                <div className={`transaction__details-hash transaction__details-hash-${identity}`}>
                  <>
                    <b>{t('homeTransactions.incomingHash')}</b>:{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${transaction.info.explorerUrl.incoming}`}
                      className={`link link-${identity}`}
                    >
                      {getHashFromLink(transaction.info.explorerUrl.incoming)}
                    </a>
                    <CopyWrapper copyContent={getHashFromLink(transaction.info.explorerUrl.incoming)}>
                      {copyIcon[identity]}
                    </CopyWrapper>
                  </>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Transaction;
