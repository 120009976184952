export const compareNewTokensArrays = (tokens, currency) => {
  const result = [];

  for (const token of tokens) {
    const match = currency.find(
      cur =>
        cur.contractAddress &&
        cur.contractAddress.mainnet.toLowerCase() === token.tokenAddress.toLowerCase() &&
        !cur.title.includes('ERC-20')
    );

    if (match) {
      match.chainSymbol = token.chainSymbol;
      result.push(match);
    }
  }

  return result;
};
