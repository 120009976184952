import defaultCoin from '../img/default-svg/defaultCoin.svg';

const getTokenIcon = async name => {
  const src = name.toLowerCase();

  try {
    const url = 'https://assets.coinpricelist.io/images/cryptocurrency-icons/svg/color/';
    const resp = await fetch(`${url}${src}.svg`);
    if(resp.status === 200) {
      return resp.url
    }
  }catch(err){
    console.log(err);
    return defaultCoin;
  }
};

export default getTokenIcon;
