import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import Card from '../../components/Card';
import Text from '../../components/Text';
import Switcher from '../../components/Switcher';
import SystemFeeSetting from '../../components/SystemFeeSetting';
import SystemFeeReplenishmentSetting from '../../components/SystemFeeReplenishmentSetting';
import WithdrawalSetting from '../../components/WithdrawalSetting';
import { ButtonPrimary, ButtonSecondary } from '../../components/Button';
import { getAdminSettings, updateAdminSettings } from '../../../redux/actions/adminPanel/adminSettings';
import {scrollContainerIntoView} from "../../helpers/scrollIntoView";
import './style.scss';
import SystemRefund from '../../components/SystemRefund/SystemRefund';
import SystemFeeSwap from '../../components/SystemFeeSwap/SystemFeeSwap';

const Settings = ({ adminSettingsData, adminSettingsFetching, getAdminSettings, updateAdminSettings }) => {
  const [form] = Form.useForm();
  const adminSettingsRef = useRef();

  useEffect(() => {
    getAdminSettings();
    scrollContainerIntoView(adminSettingsRef);
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [adminSettingsData]);


  return (
    <div className="admin-settings" ref={adminSettingsRef}>
      <Card>
        <Text.pageTitle>System fee settings</Text.pageTitle>
        <Form
          form={form}
          name="admin-settings-form"
          initialValues={{
            paySystemFee: adminSettingsData.paySystemFee,
            systemFeePercent: adminSettingsData.systemFeePercent,
            systemFeePercentReplenish: adminSettingsData.systemFeePercentReplenish,
            systemFeePercentSwap: adminSettingsData.systemFeePercentSwap,
            refundLimitUsd: adminSettingsData.refundLimitUsd,
            minimumWithdrawalUsd: adminSettingsData.minimumWithdrawalUsd,
          }}
          onFinish={updateAdminSettings}
        >
          <Switcher
            name="paySystemFee"
            title="System fee switcher"
            description="Turn on the switch if you want to charge a system fee"
          />
          <SystemFeeSetting name="systemFeePercent" />
          <SystemFeeReplenishmentSetting name="systemFeePercentReplenish" />
          <SystemFeeSwap name='systemFeePercentSwap' />
          <SystemRefund name='refundLimitUsd' />
          <WithdrawalSetting name="minimumWithdrawalUsd" />
        </Form>
      </Card>
      <div className="admin-settings-buttons">
        <ButtonSecondary onClick={() => form.resetFields()} disabled={adminSettingsFetching}>
          Cancel
        </ButtonSecondary>
        <ButtonPrimary
          type="submit"
          onClick={form.submit}
          disabled={adminSettingsFetching}
          loading={adminSettingsFetching}
        >
          Save
        </ButtonPrimary>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  adminSettingsData: state.adminSettings.data,
  adminSettingsFetching: state.adminSettings.fetching,
});

const mapDispatchToProps = {
  getAdminSettings,
  updateAdminSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
