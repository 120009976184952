import React from 'react'
import  './InstructionCustomURL.scss'

import { Typography } from 'antd';

export const InstructionCustomURL = () => {
    const { Title, Text } = Typography;


  return (
    <div className="component custom-instruction">
        <Title level={4} style={{margin: '0'}}>Instruction for Your domain</Title>

        <Text strong={true}>Create a CNAME record on your domain:</Text>

        <ul className="custom-instruction__list">
          <li>Log into your <strong>DNS provider</strong></li>
          <li>Go to your <strong>DNS records</strong> page</li>
          <li>Under <strong>Create a new record,</strong> click <strong>CNAME</strong></li>
          <li>Enter the following details: </li>
        </ul>

        <div className="custom-instruction__details-box">
            <p><strong>Type:</strong> CNAME</p>
            <p><strong>Name:</strong> checkouts </p>
            <p><strong>Target:</strong> checkouts.cpay.world</p>
            <p><strong>TTL:</strong> Auto</p>
          </div>

            <ol start='5'> <li> Save your settings </li> </ol>


        <div className="custom-instruction__final-box">
        <p>
        Your custom URL will be available in 10 minutes
        </p>
        </div>
    </div>
  )
}


