import React, {  } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { connect } from 'react-redux';
import { notificationsErrorShow } from '../../redux/actions/notifications';
import { loginWithSocialMedia } from '../../redux/actions/login';
import LoginMetamask from './Metamask/LoginMetamask';
import metamaskAailabilityHook from '../../utils/metamaskHook';
import './styles.scss';
import i18next from 'i18next';


const LoginSocialMedia = ({ loginWithSocialMedia, userType }) => {
  const { pathname } = useLocation();
  const { metamaskAuthIsShown } = metamaskAailabilityHook();

  const checkId = () => { console.log('CHECK ID')}


  const logInSocials = response => {
    loginWithSocialMedia(response.credential, 'google', userType);
    checkId()
  };

  const loginWithGoogleFailed = () => {
    notificationsErrorShow('Something went wrong. Please, try again');
  };

  return (
    <div className="login-options-socials">
      <div onClick={checkId} id={pathname === '/login' ? 'login_google' : 'signup_google'} style={{paddingBottom: '2px'}}>
      <GoogleLogin
        onSuccess={logInSocials}
        onError={loginWithGoogleFailed}
        locale={i18next.language}
        text={pathname === '/login' ? 'signin_with' : 'signup_with'}
        width={238}
      />
      </div>
      {metamaskAuthIsShown ? <LoginMetamask userType={userType} /> : null}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  loginWithSocialMedia: loginWithSocialMedia(dispatch),
  notificationsErrorShow: notificationsErrorShow,
});

export default connect(null, mapDispatchToProps)(LoginSocialMedia);
