import React, { useState } from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import { Form, Radio } from 'antd';
import TEXT from '../Text';
import { ButtonPrimary, ButtonSecondary } from '../Button';
import { TextInput, TextPassword } from '../../components/FormFields';
import { createAdminNewUser } from '../../../redux/actions/adminPanel/adminUsers';
import './style.scss';

const AddUserModal = ({ showAddUserModal, setShowAddUserModal, createAdminNewUser, updateUsersList }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [form] = Form.useForm();

  const handleClose = () => {
    setShowAddUserModal(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Add new user"
      visible={showAddUserModal}
      width={385}
      footerComponent={
        <div className="add-user-modal__buttons-wrap">
          <ButtonSecondary onClick={handleClose}>Cancel</ButtonSecondary>
          <ButtonPrimary loading={isFetching} disabled={isFetching} onClick={form.submit}>
            Add
          </ButtonPrimary>
        </div>
      }
      onCancel={handleClose}
    >
      <div className="add-user-modal">
        <Form
          form={form}
          name="add-user-form"
          autoComplete="off"
          initialValues={{
            email: '',
            password: '',
            roles: 'corporate',
          }}
          onFinish={async values => {
            setIsFetching(true);
            const response = await createAdminNewUser({ ...values, roles: ['corporate'] });
            setIsFetching(false);
            if (response) {
              handleClose();
              updateUsersList();
            }
          }}
        >
          <div className="add-user-modal__input-wrap">
            <TextInput
              name="email"
              label="Email"
              placeholder="Type your email"
              maxLength={false}
              rules={[{ required: true, message: 'required' }]}
            />
          </div>
          <div className="add-user-modal__input-wrap">
            <TextPassword
              name="password"
              label="Password"
              placeholder="Enter your password"
              autoComplete="new-password"
              rules={[{ required: true, message: 'required' }]}
            />
          </div>
          <button type="submit" hidden></button>
        </Form>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  createAdminNewUser,
};

export default connect(null, mapDispatchToProps)(AddUserModal);
