import React, { useState, useEffect, useRef } from 'react';
import QRCode from 'qrcode.react';
import { Popover } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Copy } from '../../../img/default-svg/copy.svg';
import { ReactComponent as InfoCircleCpay } from '../../../img/cpay-svg/info-circle.svg';
import { ReactComponent as InfoCircleNfg } from '../../../img/nfgpay-svg/info-circle-nfg.svg';
import { ReactComponent as InfoCircleFinvaro } from '../../../img/finvaro/info-circle-finvaro.svg';
import { ReactComponent as InfoCircleClarnium } from '../../../img/clarnium/info-circle-clarnium.svg';
import { tokenNodeTypeObj } from '../../../utils/tokenNodeTypes';
import usePartialPayment from '../usePartialPayment/usePartialPayment';
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const infoCircle = {
  cpay: <InfoCircleCpay/>,
  nfg: <InfoCircleNfg/>,
  finvaro: <InfoCircleFinvaro />,
  clarnium: <InfoCircleClarnium />
}

const QrCode = ({
  selectedCurrency,
  donationAmount,
  setDonationAmount,
  showAmountRequired,
  setShowAmountRequired,
  replenish,
  partialPayment,
  systemStatus,
  accentColor,
}) => {
  const [showAddressPopover, setShowAddressPopover] = useState(false);
  const [showAmountPopover, setShowAmountPopover] = useState(false);
  const addressPopoverTimer = useRef(null);
  const amountPopoverTimer = useRef(null);
  const { t } = useTranslation();

  const validateNumberValue = value => /^[0-9]*\.?[0-9]*$/.test(value);
  const { restAmountToPay } = usePartialPayment(selectedCurrency, replenish, systemStatus);
  const [amountToPay, setAmountToPay] = useState(selectedCurrency.amountCurrency);

  useEffect(() => {
    return setAmountToPay(() => {
      return partialPayment || systemStatus === 'Partial' ? restAmountToPay : selectedCurrency.amountCurrency;
    });
  }, [partialPayment, restAmountToPay, selectedCurrency.amountCurrency, systemStatus]);

  const addressCopyHandler = () => {
    navigator.clipboard.writeText(selectedCurrency.address);
    setShowAddressPopover(true);
    addressPopoverTimer.current = setTimeout(() => setShowAddressPopover(false), 1000);
  };

  const amountCopyHandler = () => {
    navigator.clipboard.writeText(amountToPay || donationAmount);
    setShowAmountPopover(true);
    amountPopoverTimer.current = setTimeout(() => setShowAmountPopover(false), 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(addressPopoverTimer);
      clearTimeout(amountPopoverTimer);
      addressPopoverTimer.current = null;
      amountPopoverTimer.current = null;
    };
  });
  return (
    <>
      <style>
        {`
          .qr-block__copy-block svg {
            fill: ${accentColor} !important;
          }
        `}
      </style>
      <div className="qr-block">
        {(selectedCurrency.amountCurrency ||
          selectedCurrency.currency.nodeType === 'eth' ||
          selectedCurrency.currency.nodeType === 'bsc') && (
          <Popover
            title=""
            content={t('copied')}
            trigger="click"
            visible={showAmountPopover}
            className="detailed-checkout__popover"
          >
            <div
              className={cn('qr-block__amount', {
                'qr-block__amount_error': showAmountRequired,
              })}
            >
              <div>{t('amount')}</div>
              <div className={`qr-block__copy-block qr-block__copy-block-${identity}`}>
                {selectedCurrency.amountCurrency ? (
                  <div>{`${amountToPay} ${selectedCurrency.currency.name}`}</div>
                ) : (
                  <>
                    <input
                      className="qr-block__amount-input"
                      placeholder={t('withdraw.enterAmount')}
                      type="text"
                      value={donationAmount}
                      onChange={e => {
                        setShowAmountRequired(!e.target.value);
                        validateNumberValue(e.target.value) && setDonationAmount(e.target.value);
                      }}
                    />
                    <div>{selectedCurrency.currency.name}</div>
                  </>
                )}
                <Copy onClick={amountCopyHandler} />
              </div>
            </div>
          </Popover>
        )}
        <Popover
          title=""
          content={t('copied')}
          trigger="click"
          visible={showAddressPopover}
          className="detailed-checkout__popover"
        >
          <div className="qr-block__address" onClick={addressCopyHandler}>
            <div>{`${selectedCurrency.currency.name} ${t('address')}`}</div>
            <div className={`qr-block__copy-block qr-block__copy-block-${identity}`}>
              <div>{`${selectedCurrency.address.slice(0, 4)}...${selectedCurrency.address.slice(-4)}`}</div>
              <Copy />
            </div>
          </div>
        </Popover>
        <div className="qr-block__tolerance">{t('checkouts.qrCode.tolerance')}: 3%</div>
        {selectedCurrency.currency.minReplenish > 0 ? (
          <div className={`qr-block__min-payment qr-block__min-payment-${identity}`}>
            {infoCircle[identity]} {t('checkouts.qrCode.minAmount')} {selectedCurrency.currency.minReplenish}{' '}
            {selectedCurrency.currency.name}{' '}
            {selectedCurrency.currency.currencyType === 'token'
              ? tokenNodeTypeObj[selectedCurrency.currency.nodeType]
              : null} {' '}

              {selectedCurrency.currency.currencyType === 'token' && selectedCurrency.currency.nodeType === 'optimism' ?
              selectedCurrency.currency.name === 'OP' ? null : 'OP' : null }

              {selectedCurrency.currency.name === 'ETH' && selectedCurrency.currency.currencyType === 'currency' && selectedCurrency.currency.nodeType === 'optimism' ? 'OP' : null}

              {selectedCurrency.currency.currencyType === 'currency' && selectedCurrency.currency.nodeType === 'arbitrum' ? selectedCurrency.currency.name === 'arbitrum' ? null : 'ARB' : null}

              {(selectedCurrency.currency.name === 'USDT' || selectedCurrency.currency.name === 'USDC' || selectedCurrency.currency.name === 'ETH') && selectedCurrency.currency.currencyType === 'token' && selectedCurrency.currency.nodeType === 'arbitrum' ? 'ARB' : null}

              {(selectedCurrency.currency.name === 'USDT' || selectedCurrency.currency.name === 'USDC') && selectedCurrency.currency.currencyType === 'token' && selectedCurrency.currency.nodeType === 'matic' ? 'MATIC' : null}

              {(selectedCurrency.currency.name === 'USDT' && selectedCurrency.currency.currencyType === 'token' && selectedCurrency.currency.nodeType === 'avax') ? 'AVAX' : null}

              {(selectedCurrency.currency.name === 'USDC' && selectedCurrency.currency.currencyType === 'token' && selectedCurrency.currency.nodeType === 'solana') ? 'SOL' : null}

              {(selectedCurrency.currency.name === 'USDT'  && selectedCurrency.currency.currencyType === 'token' && selectedCurrency.currency.nodeType === 'ftm') ? 'FTM' : null}

              {((selectedCurrency.currency.name === 'USDT' || selectedCurrency.currency.name === 'USDC') && selectedCurrency.currency.currencyType === 'token' && selectedCurrency.currency.nodeType === 'moonbeam') ? 'GLMR' : null}

          </div>
        ) : (
          <div className={`qr-block__min-payment qr-block__min-payment-${identity}`}>
            {infoCircle[identity]} {t('checkouts.qrCode.minTransAmount')}
          </div>
        )}
        <QRCode value={selectedCurrency.address} size={224} />
      </div>
    </>
  );
};

export default QrCode;
