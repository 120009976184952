import React from 'react'
import TEXT from '../Text';
import { NumberInputSettings } from '../FormFields';

const SystemRefund = ({name}) => {
  return (
    <div className="system-fee-setting">
    <TEXT.sectionTitle>Refund Limit USD</TEXT.sectionTitle>
    <TEXT.sectionDescription className="system-fee-switcher__description">
     You can choose the Refund Limit in USD
    </TEXT.sectionDescription>
    <NumberInputSettings name={name} className="system-fee-setting__input" />
  </div>
  )
}

export default SystemRefund