import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import Button from '../components/Button';
import { useTranslation } from 'react-i18next';
import PageHeader from '../layout/PageHeader';
import MerchantList from '../components/MerchantList/MerchantList';
import MerchantCreateModal from '../components/Merchant/MerchantCreateModal';
import { identity } from '../utils/getIdentity';

function Merchants({ userRole, merchants }) {
  const [isCreateMerchantModalVisible, setIsCreateMerchantModalVisible] = useState();
  const { t } = useTranslation();

  const MecahantCreateButton = () => (
    <Button className={`page-header__button-create page-header__button-create-${identity}`} onClick={() => setIsCreateMerchantModalVisible(true)}>
      {t('merchants.createNewMerchant')}
    </Button>
  );

  return userRole.includes('individual') ? (
    <Redirect to="/" />
  ) : (
    <React.Fragment>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <MerchantCreateModal
        isCreateMerchantModalVisible={isCreateMerchantModalVisible}
        setIsCreateMerchantModalVisible={setIsCreateMerchantModalVisible}
      />
      <PageHeader
        pageHeaderText={t('merchants.merchantPageDescription')}
        CreateModalComponent={
           userRole.includes('corporate') && merchants && merchants.length < 5 ? MecahantCreateButton : false
        }
      />
      <Row className="merchnats">
        <Col span="24">
          <MerchantList />
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapDispatchToProps = state => ({
  userRole: state.user.data.roles,
  merchants: state.merchants.data,
});

export default connect(mapDispatchToProps)(Merchants);
