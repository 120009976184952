import {
  GET_ADMIN_FEE_WALLETS_REQUEST,
  GET_ADMIN_FEE_WALLETS_SUCCESS,
  GET_ADMIN_FEE_WALLETS_MORE_REQUEST,
  GET_ADMIN_FEE_WALLETS_MORE_SUCCESS,
  GET_ADMIN_FEE_WALLETS_BALANCE_REQUEST,
  GET_ADMIN_FEE_WALLETS_BALANCE_SUCCESS,
  GET_ADMIN_FEE_WALLETS_BALANCE_FAIL,
} from '../../actions/adminPanel/adminFeeWallets';

export default function reducer(
  state = {
    entities: [],
    page: 1,
    pages: 0,
    countItem: 0,
    fetching: false,
    fetchingMore: false,
    adminWalletsBalance: {
      data: [],
      fetching: false,
      error: null,
    },
  },
  action
) {
  switch (action.type) {
    case GET_ADMIN_FEE_WALLETS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_ADMIN_FEE_WALLETS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
      };
    case GET_ADMIN_FEE_WALLETS_MORE_REQUEST:
      return {
        ...state,
        fetchingMore: true,
      };
    case GET_ADMIN_FEE_WALLETS_MORE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        entities: [...state.entities, ...action.payload.entities],
        fetchingMore: false,
      };
    case GET_ADMIN_FEE_WALLETS_BALANCE_REQUEST:
      return {
        ...state,
        adminWalletsBalance: { ...state.adminWalletsBalance, fetching: true },
      };
    case GET_ADMIN_FEE_WALLETS_BALANCE_SUCCESS:
      return {
        ...state,
        adminWalletsBalance: { ...state.adminWalletsBalance, fetching: false, data: action.payload.data, error: null },
      };
    case GET_ADMIN_FEE_WALLETS_BALANCE_FAIL:
      return {
        ...state,
        adminWalletsBalance: { ...state.adminWalletsBalance, fetching: false, error: action.payload },
      };

    default:
      return state;
  }
}
