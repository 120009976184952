import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

const SwapKYCLevel = ({ level }) => {
  const { t } = useTranslation();
  return (
    <div className={`swap-kyc-level swap-kyc-level_${level?.toLowerCase()}`}>
      {level ? t(`swap.offerItem.kycTooltip.${level?.toLowerCase()}`) : '-'}
    </div>
  );
};

export default SwapKYCLevel;
