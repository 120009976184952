import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../utils/resizeHook';
import { identity } from '../../utils/getIdentity';
import Button from '../Button';
import { useNftSupport } from '../../utils/nftSupportContext';

const NftSupportBlock = () => {
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();
  const { showNftSupportBlock, toggleNftSupportBlock } = useNftSupport();

  return (
    <>
      <div className="profile-block">
        <span className={`profile__title profile__title-${identity}`}>{t('profile.nftSupport')}</span>
        <span className="profile__subtitle">{t('profile.nftSupportInfo')}</span>
        <Row gutter={[20, 0]}>
          <Col span={currentWidth >= 1024 ? 4 : 24}>
            <Button type="primary" className="form__button" onClick={toggleNftSupportBlock}>
              {showNftSupportBlock ? t('profile.disable') : t('profile.enable')}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NftSupportBlock;
