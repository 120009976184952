import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { ReactComponent as ImportSVG } from '../../../img/default-svg/import.svg';
import { useTranslation } from 'react-i18next';
import { RangePicker } from '../../../adminPanel/components/FormFields';
import Button from '../../Button';
import "./style.scss";


const TransactionDownloadForm = ({ merchantId, downloadTransactionsInfo, networkFilter, transInfoFetching }) => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState(null);
    const [error, setError] = useState(null);
    const timerRef = useRef(null);

    const handleFormSubmit = () => {
        !formValues || form?.getFieldsValue(true)?.dateForFiltering?.length < 2 || !formValues.dateForFiltering
            ? setError(true)
            : downloadTransactionsInfo(form.getFieldsValue(true), merchantId, networkFilter);

        setFormValues(null);
    };

    useEffect(() => {
        timerRef.current = null;
        if (formValues?.dateForFiltering?.length === 2 && error) {
            timerRef.current = setTimeout(() => setError(null), 100);
        }
    }, [formValues,error]);

    useEffect(()=>{
        return(()=>{
            clearTimeout(timerRef.current);
        })
    },[])


    const { t } = useTranslation();

    return (
        <div className="transaction__download">
            <Form
                form={form}
                name="transaction__download_form"
                initialValues={{
                    dateForFiltering: [],

                }}
                onFieldsChange={() => setFormValues(form.getFieldsValue(true))}
                className="transaction__download_form"
            >
                <div className="transaction__download_form-wrapper">

                    <RangePicker
                        name="dateForFiltering"
                        placeholder="Choose dates for filtering"
                        submitAction={form.submit}
                        withInitial={undefined}
                        formValues={formValues}
                    />

                    {error && (
                        <div className="transaction__download_form-error">
                            <p>{t('validation.required')}</p>
                        </div>
                    )}

                </div>


                <div className="transaction__download_container">
                    <Button className="transaction__download_button" type="submit"
                        onClick={handleFormSubmit}>
                        {!transInfoFetching 
                        ? <ImportSVG />
                        :  (<div className="transaction__download_button__loader">
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color:'white' }} spin />} />
                      </div>)
                    }
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default TransactionDownloadForm;


