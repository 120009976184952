import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Row, Radio, Button } from 'antd';
import { signUp } from '../../redux/actions/user';
import PasswordStrength from '../PasswordStrength';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import emailIcon from '../../img/default-svg/email.svg';
import profileIcon from '../../img/default-svg/profile.svg';
import companyIcon from '../../img/default-svg/company.svg';
import backCpayIcon from '../../img/cpay-svg/arrow-back.svg';
import backNFGIcon from '../../img/nfgpay-svg/arrow-back-nfg.svg';
import backFinvaroIcon from '../../img/finvaro/arrow-back-finvaro.svg';
import backClarniumIcon from '../../img/clarnium/arrow-back-clarnium.svg';
import useWindowSize from '../../utils/resizeHook';
import { identity } from '../../utils/getIdentity';

const backIcon = {
  cpay: backCpayIcon,
  nfg: backNFGIcon,
  finvaro: backFinvaroIcon,
  clarnium: backClarniumIcon
}


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignupForm = ({ isFetching, signUp, setAccountType, backAction }) => {
  const query = useQuery();
  const [email, setEmail] = useState(query.get('email') || '');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('corporate');
  const recaptchaRef = useRef();
  const { t } = useTranslation();
  const { isSm } = useWindowSize();

  const handleFormSubmit = async () => {
    const recaptcha = await recaptchaRef.current.executeAsync();

    const formData = {
      email: email.trim(),
      password: password.trim(),
      roles: [userType],
      inviterId: query.get('inviterId') || localStorage.getItem('cpayInviterId') || undefined,
    };

    try {
      await signUp(formData, recaptcha);
    } catch {
      recaptchaRef.current.reset();
    }
  };

  useEffect(() => {
    if (query.get('inviterId')) {
      localStorage.setItem('cpayInviterId', query.get('inviterId'));
    }
  }, []);

  useEffect(() => {
    setAccountType(userType);
    return () => {
      setAccountType(null);
    };
  }, [userType]);

  return (
    <Form name="basic" initialValues={{ merchantComission: userType, email: email }} className="login-form">
      <p className="login-form__title">{t('auth.createAccount')}</p>
      {isSm && (
        <div style={{ marginBottom: '20px' }}>
              <span className="login-info__thesis">{t('auth.welcomeTextBusiness.thesis1')}</span>
              <span className="login-info__thesis">{t('auth.welcomeTextBusiness.thesis2')}</span>
              <span className="login-info__thesis">{t('auth.welcomeTextBusiness.thesis3')}</span>
              <span className="login-info__thesis">{t('auth.welcomeTextBusiness.thesis4')}</span>
              <span className="login-info__thesis">{t('auth.welcomeTextBusiness.thesis5')}</span>
        </div>
      )}
      <p className="login-form__label">{t('email')}</p>
      <Form.Item name="email" rules={[{ required: true, message: t('validation.required') }]}>
        <Input
          suffix={<img src={emailIcon} alt="email icon" />}
          placeholder="Username@mail.com"
          className="login-form__input"
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Item>

      <p className="login-form__label">{t('password')}</p>
      <Form.Item name="password" rules={[{ required: true, message: t('validation.required') }]}>
        <Input.Password
          name="password"
          autoComplete="new-password"
          placeholder={t('password')}
          className="login-form__input"
          onChange={e => setPassword(e.target.value)}
        />
      </Form.Item>

      <PasswordStrength password={password} />

      <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />

      <div className="login-form__buttons-wrap">
        <div className={`login-form__back-button`} onClick={backAction}>
          <img src={backIcon[identity]} alt="back" />
        </div>

        <Button
          type="primary"
          className={`login-form__button login-form__button-${identity}`}
          onClick={!isFetching && handleFormSubmit}
          loading={isFetching}
          id="signup_email"
        >
          {t('signUp')}
        </Button>
      </div>

      <Row>
        <p className={`login-form__text login-form__text_center login-form__text-${identity}`}>
          {t('auth.alreadyHaveAccount')} <Link to="/login">{t('logIn')}</Link>
        </p>
      </Row>
    </Form>
  );
};

const mapStateToProps = state => ({
  isFetching: state.user.fetching,
});

const mapDispatchToProps = dispatch => ({
  signUp: signUp(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
