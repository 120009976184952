import React, { useState, useRef, useEffect } from 'react';
import './style.scss';
import { ReactComponent as Chevron } from '../../../img/default-svg/chevron-down.svg';
import QrCode from '../QrCode';
import formatCurrency from '../../../utils/currencyFormatter';
import TokenIcon from '../../TokenIcon';
import WalletConnectButton from '../WalletConnectButton';
import { ReactComponent as ArrowUpCircleCpay } from '../../../img/cpay-svg/arrowUpCircle.svg';
import { ReactComponent as ArrowUpCircleNfg } from '../../../img/nfgpay-svg/arrowUpCircle-nfg.svg';
import { ReactComponent as ArrowUpCircleFinvaro } from '../../../img/finvaro/arrowUpCircle-finvaro.svg';
import { ReactComponent as ArrowUpCircleClarnium } from '../../../img/clarnium/arrowUpCircle-clarnium.svg';
import { useTranslation } from 'react-i18next';
import PartialWarningMsg from '../PartialWarningMsg/PartialWarningMsg';
import { hexToRgb, getContrastTextColor } from '../../../utils/getTextColorByBg';
import { mainColor } from '../../../utils/getMainColor';
import { identity } from '../../../utils/getIdentity';

const arrowUpCircleIcon = {
  cpay: <ArrowUpCircleCpay/>,
  nfg: <ArrowUpCircleNfg/>,
  finvaro: <ArrowUpCircleFinvaro />,
  clarnium: <ArrowUpCircleClarnium />
}

const Initial = ({
  wallets,
  getChargeState,
  stepHandler,
  selectedCurrency,
  initialStep = true,
  donationAmount,
  setDonationAmount,
  showAmountRequired,
  setShowAmountRequired,
  partialPayment,
  accentColor,
}) => {
  const [scrolledTop, setScrolledTop] = useState(true);
  const [scrolledBottom, setScrolledBottom] = useState(false);
  const walletsListRef = useRef(null);
  const walletRef = useRef(null);
  const { t } = useTranslation();
  const [walletsFilteredByCurrencies, setWalletsFilteredByCurrencies] = useState([]);

  useEffect(() => {
    setWalletsFilteredByCurrencies(
      ...[
        wallets.filter(el => {
          return getChargeState?.data?.checkout?.currencies?.some(item => item?._id === el?.currency?.id);
        }),
      ]
    );
  }, [wallets, getChargeState]);

  useEffect(() => {
    setScrolledBottom(!(walletsListRef.current.scrollHeight > walletsListRef.current.clientHeight));
  }, [walletsListRef]);


  return (
    <>
      <style>
        {`
        .initial-wallets:hover {
          background: ${`rgb(${hexToRgb(accentColor || mainColor).join(' ')} / 0.1)`} !important
        }
        .initial-wallets__scroll-up svg path,
        .initial-wallets__scroll-down svg path {
          stroke: ${accentColor} !important;
        }
        .checkout-payment .wallet-connect__button,
        .checkout-payment .wallet-connect__account {
          background: ${accentColor} !important;
          color: ${getContrastTextColor(accentColor || mainColor)};
          cursor: pointer;
        }
      `}
      </style>

      {(getChargeState.data?.checkout?.type === 'Sale' ||
        (getChargeState.data?.checkout?.price && getChargeState.data?.checkout?.fiatCurrency)) &&
        initialStep && (
          <div className="initial-wallets__fiat-amount">
            <span>{t('total')}:</span>
            <span className="initial-wallets__fiat-amount-sum">
              {formatCurrency(+getChargeState.data?.checkout?.price, getChargeState.data?.checkout?.fiatCurrency)}
            </span>
          </div>
        )}
      <div
        ref={walletsListRef}
        className={`initial-wallets__list ${initialStep ? 'initial-wallets__list_initial' : ''}`}
        onScroll={() => {
          if (walletsListRef.current.scrollTop === 0) {
            setScrolledTop(true);
          } else if (
            walletsListRef.current.offsetHeight + walletsListRef.current.scrollTop >=
            walletsListRef.current.scrollHeight
          ) {
            setScrolledBottom(true);
          } else {
            setScrolledTop(false);
            setScrolledBottom(false);
          }
        }}
      >
        {initialStep ? (
          <>
            {walletsFilteredByCurrencies.length &&
              walletsFilteredByCurrencies?.map(el => (
                <div key={el.address} ref={walletRef} className="initial-wallets" onClick={() => stepHandler(el)}>
                  <div className="initial-wallets__currency">
                    <TokenIcon tokenName={el.currency.name} alt="" />
                    {el.currency.title}
                  </div>
                  <Chevron />
                </div>
              ))}
          </>
        ) : (
          <>
            {partialPayment ? (
              <PartialWarningMsg
                selectedCurrency={selectedCurrency}
                replenish={getChargeState?.data?.replenish}
                systemStatus={getChargeState?.data?.systemStatus}
              />
            ) : null}
            <QrCode
              selectedCurrency={selectedCurrency}
              donationAmount={donationAmount}
              setDonationAmount={setDonationAmount}
              showAmountRequired={showAmountRequired}
              setShowAmountRequired={setShowAmountRequired}
              replenish={getChargeState?.data?.replenish}
              partialPayment={partialPayment}
              systemStatus={getChargeState?.data?.systemStatus}
              accentColor={accentColor}
            />
            {(selectedCurrency.currency.nodeType === 'eth' || selectedCurrency.currency.nodeType === 'bsc') && (
              <WalletConnectButton
                clientWalletAddress={selectedCurrency.address}
                amount={selectedCurrency.amountCurrency || donationAmount}
                currency={selectedCurrency.currency}
                checkoutType={getChargeState.data.checkout.type}
                typeNetwork={getChargeState.data.typeNetwork}
                setShowAmountRequired={setShowAmountRequired}
              />
            )}
          </>
        )}
      </div>
      {initialStep && (
        <div className="initial-wallets__list-controls">
          <div>
            {!scrolledBottom && (
              <button
                type="button"
                className="initial-wallets__scroll-up"
                onClick={() => walletsListRef.current.scrollBy({ top: 95, behavior: 'smooth' })}
              >
                {arrowUpCircleIcon[identity]}
              </button>
            )}
          </div>
          <div>
            {!scrolledTop && (
              <button
                type="button"
                className="initial-wallets__scroll-down"
                onClick={() => walletsListRef.current.scrollBy({ top: -95, behavior: 'smooth' })}
              >
                {arrowUpCircleIcon[identity]}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Initial;
