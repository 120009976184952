import { adminApi } from '../../../service/admin-api';
import { notificationsErrorShow } from '../notifications';

// GET SYSTEM FEE TRANSACTIONS

export const GET_ADMIN_TRANSACTIONS_BY_USER_REQUEST = 'GET_ADMIN_TRANSACTIONS_BY_USER_REQUEST';
export const GET_ADMIN_TRANSACTIONS_BY_USER_SUCCESS = 'GET_ADMIN_TRANSACTIONS_BY_USER_SUCCESS';
export const GET_ADMIN_TRANSACTIONS_BY_USER_MORE_REQUEST = 'GET_ADMIN_TRANSACTIONS_BY_USER_MORE_REQUEST';
export const GET_ADMIN_TRANSACTIONS_BY_USER_MORE_SUCCESS = 'GET_ADMIN_TRANSACTIONS_BY_USER_MORE_SUCCESS';

export const getAdminTransactionsByUserRequest = () => ({
  type: GET_ADMIN_TRANSACTIONS_BY_USER_REQUEST,
});

export const getAdminTransactionsByUserSuccess = payload => ({
  type: GET_ADMIN_TRANSACTIONS_BY_USER_SUCCESS,
  payload,
});

export const getAdminUserTransactionsMoreRequest = () => ({
    type: GET_ADMIN_TRANSACTIONS_BY_USER_MORE_REQUEST,
  });
  
  export const getAdminUserTransactionsMoreSuccess = payload => ({
    type: GET_ADMIN_TRANSACTIONS_BY_USER_MORE_SUCCESS,
    payload,
  });




export const getAdminTransactionsByUser =
  (body = {}) =>
  async dispatch => {
    dispatch(getAdminTransactionsByUserRequest());
    
    try {
      const response = await adminApi.getTransactionsByUser({
        ...body,
        dateRange:undefined,
        formattedDate:undefined,
        from: body?.formattedDate ? body.formattedDate[0] : undefined,
        to: body?.formattedDate ? body.formattedDate[1] : undefined,
        order: body.order || 'DESC',
        limit: 5,
      });
      dispatch(getAdminTransactionsByUserSuccess(response.data));
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };

  export const getAdminMoreTransactionsByUser  =
  (body = {}) =>
  async dispatch => {
    dispatch(getAdminUserTransactionsMoreRequest());

    try {
      const response = await adminApi.getTransactionsByUser({
        ...body,
        dateRange:undefined,
        formattedDate:undefined,
        from: body?.formattedDate ? body.formattedDate[0] : undefined,
        to: body?.formattedDate ? body.formattedDate[1] : undefined,
        order: body.order || 'DESC',
        limit: 5,
      });
      dispatch(getAdminUserTransactionsMoreSuccess(response.data));
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };


