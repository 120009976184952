import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import chevronSVG from '../../../../../img/default-svg/chevron-down.svg';
import './style.scss';
import { identity } from "../../../../../utils/getIdentity";

const SelectType = ({ setCheckoutType, nextStep }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setCheckoutType(null);
  }, []);

  return (
    <div className="checkout-select-type">
      <div
        className={`checkout-select-type__button checkout-select-type__button-${identity}`}
        onClick={() => {
          setCheckoutType('donation');
          nextStep();
        }}
      >
        <div className="checkout-select-type__title">{t('checkouts.steps.0.donationTitle')}</div>
        <div className="checkout-select-type__description">{t('checkouts.steps.0.donationDescription')}</div>
        <img className="checkout-select-type__icon" src={chevronSVG} alt="" />
      </div>
      <div
        className={`checkout-select-type__button checkout-select-type__button-${identity}`}
        onClick={() => {
          setCheckoutType('sale');
          nextStep();
        }}
      >
        <div className="checkout-select-type__title">{t('checkouts.steps.0.saleTitle')}</div>
        <div className="checkout-select-type__description">{t('checkouts.steps.0.saleDescription')}</div>
        <img className="checkout-select-type__icon" src={chevronSVG} alt="" />
      </div>
      <div
        className={`checkout-select-type__button checkout-select-type__button-${identity}`}
        onClick={() => {
          setCheckoutType('saletoken');
          nextStep();
        }}
      >
        <div className="checkout-select-type__title">{t('checkouts.sellCurrency')}</div>
        <div className="checkout-select-type__description">{t('checkouts.currencyExchange')}</div>
        <img className="checkout-select-type__icon" src={chevronSVG} alt="" />
      </div>
      <div
        className={`checkout-select-type__button checkout-select-type__button-${identity}`}
        onClick={() => {
          setCheckoutType('cart');
          nextStep();
        }}
      >
        <div className="checkout-select-type__title">{t('checkouts.steps.0.cartTitle')}</div>
        <div className="checkout-select-type__description">{t('checkouts.steps.0.cartDescription')}</div>
        <img className="checkout-select-type__icon" src={chevronSVG} alt="" />
      </div>
    </div>
  );
};

export default SelectType;
