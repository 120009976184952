import { cryptoApi } from '../../service/cryptopay-api';
import JwtDecode from 'jwt-decode';
import { softLogin } from '../../redux/actions/login';
import { notificationsSuccessShow, notificationsErrorShow } from './notifications';

// CONSTANTS
export const USER_GET = 'USER_GET';
export const USER_SET = 'USER_SET';
export const USER_SHOW_WELCOME_MODAL = 'USER_SHOW_WELCOME_MODAL';
export const USER_SHOW_APP_TOUR = 'USER_SHOW_APP_TOUR';
export const USER_EDIT = 'USER_EDIT';
export const USER_SIGN_UP = 'USER_SIGN_UP';
export const USER_SIGN_UP_BY_PHONE = 'USER_SIGN_UP_BY_PHONE';
export const USER_SHOW_PHONE_CODE_VERIFY = 'USER_SHOW_PHONE_CODE_VERIFY';
export const USER_ERROR = 'USER_ERROR';
export const USER_SET_APP_TWO_FA = 'USER_SET_APP_TWO_FA';
export const USER_SET_EMAIL_TWO_FA = 'USER_SET_EMAIL_TWO_FA';
export const USER_SET_PHONE_TWO_FA = 'USER_SET_PHONE_TWO_FA';
export const USER_CONFIRM_EMAIL = 'USER_VERIFY_EMAIL';
export const USER_SET_EMAIL_CONFIRMED_STATUS = 'USER_SET_EMAIL_CONFIRMED_STATUS';
export const USER_CHANGE_BACKUP_STATUS = 'USER_CHANGE_BACKUP_STATUS';
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const USER_CHANGE_PASSWORD_DONE = 'USER_CHANGE_PASSWORD_DONE';
export const USER_RESEND_CONFIRM_EMAIL = 'USER_RESEND_CONFIRM_EMAIL';
export const USER_RESEND_CONFIRM_EMAIL_DONE = 'USER_RESEND_CONFIRM_EMAIL_DONE';
export const USER_CONFIRM_BACKUP = 'USER_CONFIRM_BACKUP';
export const USER_CONFIRM_BACKUP_DONE = 'USER_CONFIRM_BACKUP_DONE';
export const USER_INSTALL_METAMASK = 'USER_INSTALL_METAMASK';
export const USER_INSTALL_METAMASK_SUCCESS = 'USER_INSTALL_METAMASK_SUCCESS';
export const USER_REMOVE_METAMASK = 'USER_REMOVE_METAMASK';
export const USER_REMOVE_METAMASK_SUCCESS = 'USER_REMOVE_METAMASK_SUCCESS';
export const USER_METAMASK_ERROR = 'USER_METAMASK_ERROR';
export const USER_PHONE_VERIFY = 'USER_PHONE_VERIFY';
export const USER_PHONE_VERIFY_SUCCESS = 'USER_PHONE_VERIFY_SUCCESS';
export const USER_PHONE_VERIFY_ERROR = 'USER_PHONE_VERIFY_ERROR';
export const USER_UNSUBSCRIBE = 'USER_UNSUBSCRIBE';
export const USER_UNSUBSCRIBE_SUCCESS = 'USER_UNSUBSCRIBE_SUCCESS';
export const USER_UNSUBSCRIBE_ERROR = 'USER_UNSUBSCRIBE_ERROR';

// ACTIONS GENERATORS
export const userGet = () => ({
  type: USER_GET,
  fetching: true,
});

export const userSet = data => ({
  type: USER_SET,
  fetching: false,
  payload: data,
});

export const showWelcomeModal = data => ({
  type: USER_SHOW_WELCOME_MODAL,
  payload: data,
});

export const showAppTour = data => ({
  type: USER_SHOW_APP_TOUR,
  payload: data,
});

export const userEdit = () => ({
  type: USER_EDIT,
  fetching: true,
});

export const userSignUp = () => ({
  type: USER_SIGN_UP,
  fetching: true,
});

export const userSignUpByPhone = () => ({
  type: USER_SIGN_UP_BY_PHONE,
  fetching: true,
});

export const userShowPhoneCodeVerify = showPhoneCodeVerify => ({
  type: USER_SHOW_PHONE_CODE_VERIFY,
  showPhoneCodeVerify,
  fetching: false,
});

export const userError = () => ({
  type: USER_ERROR,
});

export const userSetAppTwoFa = data => ({
  type: USER_SET_APP_TWO_FA,
  payload: data,
});

export const userSetEmailTwoFa = data => ({
  type: USER_SET_EMAIL_TWO_FA,
  payload: data,
});

export const userSetPhoneTwoFa = data => ({
  type: USER_SET_PHONE_TWO_FA,
  payload: data,
});

export const userConfirmEmail = () => ({
  type: USER_CONFIRM_EMAIL,
  fetching: true,
});

export const userSetEmailConfirmedStatus = data => ({
  type: USER_SET_EMAIL_CONFIRMED_STATUS,
  fetching: false,
  payload: data,
});

export const userChangeBackupStatus = isBackupEnabled => ({
  type: USER_CHANGE_BACKUP_STATUS,
  payload: isBackupEnabled,
});

export const userChangePassword = () => ({
  type: USER_CHANGE_PASSWORD,
});

export const userChangePasswordDone = () => ({
  type: USER_CHANGE_PASSWORD_DONE,
});

export const userResendConfirmEmail = () => ({
  type: USER_RESEND_CONFIRM_EMAIL,
});

export const userResendConfirmEmailDone = () => ({
  type: USER_RESEND_CONFIRM_EMAIL_DONE,
});

export const userConfirmBackup = () => ({
  type: USER_CONFIRM_BACKUP,
});

export const userConfirmBackupDone = () => ({
  type: USER_CONFIRM_BACKUP_DONE,
});

export const userInstallMetamask = () => ({
  type: USER_INSTALL_METAMASK,
});

export const userInstallMetamaskSuccess = () => ({
  type: USER_INSTALL_METAMASK_SUCCESS,
});

export const userRemoveMetamask = () => ({
  type: USER_REMOVE_METAMASK,
});

export const userRemoveMetamaskSuccess = () => ({
  type: USER_REMOVE_METAMASK_SUCCESS,
});

export const userMetamaskError = () => ({
  type: USER_METAMASK_ERROR,
});

export const userPhoneVerify = () => ({
  type: USER_PHONE_VERIFY,
});

export const userPhoneVerifySuccess = () => ({
  type: USER_PHONE_VERIFY_SUCCESS,
});

export const userPhoneVerifyError = () => ({
  type: USER_PHONE_VERIFY_ERROR,
});

export const userUnsubscribe = () => ({
  type: USER_UNSUBSCRIBE,
});

export const userUnsubscribeSuccess = isUnsubscribe => ({
  type: USER_UNSUBSCRIBE_SUCCESS,
  payload: isUnsubscribe,
});

export const userUnsubscribeError = () => ({
  type: USER_UNSUBSCRIBE_ERROR,
});

// ASYNC ACTIONS
export const getUser = dispatch => async () => {
  dispatch(userGet());
  let user;

  try {
    user = await cryptoApi.getUser();
  } catch (err) {
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (user && Object.keys(user).length !== 0) {
    dispatch(userSet(user));
    return;
  }

  dispatch(userError());
};

export const editUser = dispatch => async body => {
  dispatch(userEdit());
  let user;

  try {
    user = await cryptoApi.editUser(body);
  } catch (err) {
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (user) {
    dispatch(getUser(dispatch));
    dispatch(notificationsSuccessShow({ message: user.data ? user.data.message : undefined }));
    return;
  }

  dispatch(userError());
};

export const signUp = dispatch => async (body, recaptcha) => {
  dispatch(userSignUp());
  let user;

  try {
    user = await cryptoApi.signUp(body, recaptcha);
  } catch (err) {
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
    throw err;
  }

  if (user && user.token) {
    localStorage.removeItem('cpayInviterId');
    localStorage.setItem('authToken', user.token);
    const decodedToken = JwtDecode(user.token);
    if (Date.now() < decodedToken.exp * 1000) {
      dispatch(showWelcomeModal(true));
      dispatch(softLogin(user.token));
      dispatch(notificationsSuccessShow({}));
    }
    return;
  }

  dispatch(userError());
};

export const signUpByPhone = dispatch => async (body, recaptcha) => {
  dispatch(userSignUpByPhone());
  let user;

  try {
    user = await cryptoApi.signUpByPhone(body, recaptcha);

    if (user && user.data && user.data.codeSent) {
      dispatch(userShowPhoneCodeVerify(true));
    }
  } catch (err) {
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
    throw err;
  }

  if (user && user.token) {
    localStorage.removeItem('cpayInviterId');
    localStorage.setItem('authToken', user.token);
    const decodedToken = JwtDecode(user.token);
    if (Date.now() < decodedToken.exp * 1000) {
      dispatch(showWelcomeModal(true));
      dispatch(softLogin(user.token));
      dispatch(userShowPhoneCodeVerify(false));
      dispatch(notificationsSuccessShow({}));
    }
    return;
  }

  dispatch(userError());
};

export const sendPassword = dispatch => async (body, recaptcha) => {
  let response;

  try {
    response = await cryptoApi.sendPassword(body, recaptcha);
  } catch (err) {
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
    throw err;
  }

  if (response && response.message === 'ok') {
    dispatch(notificationsSuccessShow({}));
  }
  if (response && response.message && response.message !== 'ok') {
    dispatch(notificationsSuccessShow({ title: response.message }));
  }

  return;
};

export const forgottenPassword = dispatch => async (token, body) => {
  let response;

  try {
    response = await cryptoApi.forgottenPassword(token, body);
  } catch (err) {
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (response && response.token) {
    dispatch(notificationsSuccessShow({}));
  }

  return response;
};

export const resetPassword = dispatch => async (body, recaptcha) => {
  let response;

  try {
    response = await cryptoApi.resetPassword(body, recaptcha);
  } catch (err) {
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
  }

  // if (response && response.status === 'success') {
  //   localStorage.setItem('authToken', response.data.token);
  //   const decodedToken = JwtDecode(response.data.token);
  //   if (Date.now() < decodedToken.exp * 1000) dispatch(softLogin(response.data.token));
  //   dispatch(notificationsSuccessShow({}));
  // }

  return response;
};

export const changePassword = dispatch => async body => {
  dispatch(userChangePassword());
  let response;

  try {
    response = await cryptoApi.changePassword(body);
  } catch (err) {
    dispatch(userChangePasswordDone());
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (response) {
    dispatch(userChangePasswordDone());
    dispatch(notificationsSuccessShow({}));
  }

  return response;
};

export const resendEmailConfirmation = dispatch => async () => {
  let response;
  dispatch(userResendConfirmEmail());

  try {
    response = await cryptoApi.resendPassword();
  } catch (err) {
    dispatch(userResendConfirmEmailDone());
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (response) {
    dispatch(notificationsSuccessShow({}));
    dispatch(userResendConfirmEmailDone());
  }

  return;
};

export const verifyEmail = dispatch => async secretKey => {
  dispatch(userConfirmEmail());
  let response;

  try {
    response = await cryptoApi.verifyEmail(secretKey);
  } catch (err) {
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (response) {
    dispatch(userSetEmailConfirmedStatus(response));
    dispatch(notificationsSuccessShow({}));
  }

  return response;
};

export const changeTwoFactorStatus = dispatch => async isTwoFactor => {
  dispatch(userSetAppTwoFa(isTwoFactor));
};

export const changeEmailTwoFaStatus = dispatch => async isTwoFactor => {
  dispatch(userSetEmailTwoFa(isTwoFactor));
};

export const changePhoneTwoFaStatus = dispatch => async isTwoFactor => {
  dispatch(userSetPhoneTwoFa(isTwoFactor));
};

export const confirmBackup = dispatch => async words => {
  let response;
  dispatch(userConfirmBackup());

  try {
    response = await cryptoApi.confirmBackup(words);
  } catch (err) {
    dispatch(userConfirmBackupDone());
    dispatch(userError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (response && response.status === 'success') {
    dispatch(userConfirmBackupDone());
    dispatch(userChangeBackupStatus(true));
    dispatch(notificationsSuccessShow({}));
  }

  return response;
};

export const installMetamask = dispatch => async (address, signature) => {
  dispatch(userInstallMetamask());

  try {
    await cryptoApi.installMetamask(address, signature);
    dispatch(userInstallMetamaskSuccess());
    dispatch(notificationsSuccessShow({}));
  } catch (err) {
    dispatch(userMetamaskError());
    dispatch(notificationsErrorShow({ message: err.data.message }));
  }
};

export const removeMetamask = dispatch => async (address, signature) => {
  dispatch(userRemoveMetamask());

  try {
    await cryptoApi.removeMetamask(address, signature);
    dispatch(userRemoveMetamaskSuccess());
    dispatch(notificationsSuccessShow({}));
  } catch (err) {
    dispatch(userMetamaskError());
    dispatch(notificationsErrorShow({ message: err.data.message }));
  }
};

export const unsubscribeEmail = dispatch => async () => {
  try {
    await cryptoApi.unsubscribeFromEmail();
    dispatch(userUnsubscribeSuccess(true));
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    console.log('error unsubscribe', error, error.data);
    dispatch(notificationsErrorShow({ message: error.data.message }));
  }
};
