import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Modal, Form, Tabs } from 'antd';

import { useTranslation } from 'react-i18next';
import { notificationsErrorShow } from '../../../redux/actions/notifications';

import Replenish from './Replenish';
import PrivateKey from './PrivateKey';

import '../../../layout/Modal.scss';
import './WalletSettingsModal.scss';
import './styles.scss';
import MnemonicPhrase from './MnemonicPhrase';
import DownloadAutosign from './DownloadAutosign';
import {
  downloadAutosign,
  resetAutosignStep,
  resetDownloadAutosignStep,
} from "../../../redux/actions/withdraw";
import Passphrase from "./Passphrase";
import { identity } from "../../../utils/getIdentity";

const { TabPane } = Tabs;

const BTC_CODE = '5edf2767c9ca4d5a342bf8ac';

const WalletSettingsModal = ({
 isAppTwoFa,
 isEmailTwoFa,
 isPhoneTwoFa,
 openSettingsModal,
 setOpenSettingsModal,
 selectedWallet,
 wallet,
 merchantId,
 setSelectedWallet,
 getWalletPrivateKey,
 getWalletMnemonicPhrase,
 getPassphrase,
 openNotification,
 resetTwoFaState,
 setWalletAsMain,
 closeModal,
 statusAutosign,
 openSign,
 typeWallet,
 downloadAutosign,
 checkStatusPassword,
 page,
 walletsFilters,
 networkFilter,
 statusPassword,
 updateList,
 openDownload
}) => {
  const [activeTab, setActiveTab] = useState('replenish');
  const [walletKey, setWalletKey] = useState('');
  const [appTwoFa, setAppTwoFa] = useState('');
  const [emailTwoFa, setEmailTwoFa] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [serverError, setServerError] = useState('');
  const [availableAutosign, setAvailableAutosign] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedWallet(selectedWallet);
  });

  const { t } = useTranslation();

  const handleOk = () => {
    setOpenSettingsModal(false);
    setSelectedWallet(null);
  };

  const handleCancel = e => {
    setSelectedWallet(null);
    setOpenSettingsModal(false);
    setAppTwoFa('');
    setEmailTwoFa('');
    setVerifyCode('');
    setWalletKey('');
    resetTwoFaState();
    dispatch(resetAutosignStep());
    dispatch(resetDownloadAutosignStep());
  };

  const onChange = async key => {
    /*if (key === 'private') {
      //  const res = getWalletPrivateKey(merchantId, selectedWallet._id);
      const response = getWalletPrivateKey(merchantId, selectedWallet._id, '', '', '', 'corporate').then(res => {
        if (res?.status === 'fail') {
          setServerError(res?.data?.message);

          console.log(serverError);
        }
      });
    }

    if (key === 'mnemonic') {
      //  const res = getWalletPrivateKey(merchantId, selectedWallet._id);
      const response = getWalletMnemonicPhrase(merchantId, selectedWallet._id).then(res => res);
      console.log('res', response);
    }*/

    setAppTwoFa('');
    setEmailTwoFa('');
    setVerifyCode('');
    setWalletKey('');
    resetTwoFaState();

    setActiveTab(key);
  };
  // setWalletAsMain(
  //   merchantId,
  //   wallet._id,
  //   wallet.currencyId,
  //   typeWallet,
  //   page,
  //   walletsFilters,
  //   networkFilter
  // )
  const setMainWallet = async () => {
    try {
      const response = await setWalletAsMain(
        merchantId,
        selectedWallet?._id,
        selectedWallet?.currencyId,
        typeWallet,
        page,
        walletsFilters,
        networkFilter
      );
      closeModal();
    } catch (e) {
      console.error(e);
    }
  };

  const handleDownloadAutosign = async () => {
    if (statusAutosign && !statusPassword) {
      closeModal();
      openSign();
      setAvailableAutosign(false);
    } else if (statusAutosign && statusPassword) {
      openDownload();
      setAvailableAutosign(false);
    } else if (!statusAutosign && !statusPassword) {
      setAvailableAutosign(true);
    }
  }

  return (
    <Modal
      title={t('merchants.merchantWallet')}
      visible={openSettingsModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={540}
      className="modal modal-settings"
      afterClose={() => {
        setAppTwoFa('');
        setEmailTwoFa('');
        setVerifyCode('');
        setWalletKey('');
        form.resetFields();
        resetTwoFaState();
      }}
    >
      <div className={`modal modal-settings__body modal modal-settings__body-${identity}`}>
        <Tabs defaultActiveKey={activeTab} centered={true} onChange={onChange} tabBarGutter={8} tabBarStyle={{ display: 'flex', flexWrap: 'wrap' }}>
          <TabPane tab={t('merchants.replenish')} key="replenish" />
          <TabPane tab={t('privateKey')} key="private" />
          <TabPane tab={t('mnemonic')} key="mnemonic" />
          <TabPane tab={t('passphrase')} key="passphrase" />
          <TabPane tab={statusAutosign ? (
            <>
              {t('merchants.security.disable')} {t('merchants.security.autoSign')}
            </>
          ) : (
            <>
              {t('merchants.security.enable')} {t('merchants.security.autoSign')}
            </>
          )} key="autosign" />
        </Tabs>

        <div>
          <div className="autosign">
            <div className="autosign__download" onClick={handleDownloadAutosign}>
              {t('merchants.security.download')}
            </div>
            {!selectedWallet?.main ? (
              <button className="autosign__button" onClick={setMainWallet}>
                Set as default
              </button>
            ) : (
              <div></div>
            )}
          </div>
          {availableAutosign && <div className="unavailable">Autosign is disabled</div>}
        </div>

        {activeTab === 'autosign' && (
          <DownloadAutosign
            merchantId={merchantId}
            selectedWallet={selectedWallet}
            statusAutosign={statusAutosign}
            onCancel={handleCancel}
            setOpenSettingsModal={setOpenSettingsModal}
            downloadAutosign={downloadAutosign}
            updateList={updateList}
            checkStatusPassword={checkStatusPassword}
          />
        )}

        {activeTab === 'replenish' && (
          <Replenish
            selectedWallet={selectedWallet}
            wallet={wallet}
            setSelectedWallet={setSelectedWallet}
            setOpenSettingsModal={setOpenSettingsModal}
          />
        )}

        {activeTab === 'private' && (
          <PrivateKey
            merchantId={merchantId}
            selectedWallet={selectedWallet}
            setSelectedWallet={setSelectedWallet}
            openNotification={openNotification}
            getWalletPrivateKey={getWalletPrivateKey}
            setOpenSettingsModal={setOpenSettingsModal}
            walletKey={walletKey}
            appTwoFa={appTwoFa}
            emailTwoFa={emailTwoFa}
            verifyCode={verifyCode}
            setWalletKey={setWalletKey}
            setAppTwoFa={setAppTwoFa}
            setEmailTwoFa={setEmailTwoFa}
            setVerifyCode={setVerifyCode}
            serverError={serverError}
          />
        )}

        {activeTab === 'mnemonic' && (
          <MnemonicPhrase
            merchantId={merchantId}
            selectedWallet={selectedWallet}
            setSelectedWallet={setSelectedWallet}
            openNotification={openNotification}
            setOpenSettingsModal={setOpenSettingsModal}
            getWalletMnemonicPhrase={getWalletMnemonicPhrase}
            walletKey={walletKey}
            appTwoFa={appTwoFa}
            emailTwoFa={emailTwoFa}
            verifyCode={verifyCode}
            setWalletKey={setWalletKey}
            setAppTwoFa={setAppTwoFa}
            setEmailTwoFa={setEmailTwoFa}
            setVerifyCode={setVerifyCode}
          />
        )}

        {activeTab === 'passphrase' && (
          <Passphrase
            merchantId={merchantId}
            selectedWallet={selectedWallet}
            setSelectedWallet={setSelectedWallet}
            openNotification={openNotification}
            setOpenSettingsModal={setOpenSettingsModal}
            getPassphrase={getPassphrase}
            walletKey={walletKey}
            appTwoFa={appTwoFa}
            emailTwoFa={emailTwoFa}
            verifyCode={verifyCode}
            setWalletKey={setWalletKey}
            setAppTwoFa={setAppTwoFa}
            setEmailTwoFa={setEmailTwoFa}
            setVerifyCode={setVerifyCode}
          />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  isAppTwoFa: state.merchantWallets.isAppTwoFa,
  isEmailTwoFa: state.merchantWallets.isEmailTwoFa,
  isPhoneTwoFa: state.merchantWallets.isPhoneTwoFa,
  isFetching: state.merchantWallets.modalFetching,
});

const mapDispatchToProps = dispatch => ({
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
  downloadAutosign: downloadAutosign(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletSettingsModal);
