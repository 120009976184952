import React, { useState } from 'react';
import { Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import Button from '../Button';
import '../../layout/Modal.scss';
import plusCpayIcon from '../../img/cpay-svg/plus.svg';
import plusNFGIcon from '../../img/nfgpay-svg/plus-nfg.svg';
import plusFinvaroIcon from '../../img/finvaro/plus-finvaro.svg';
import plusClarniumIcon from '../../img/clarnium/plus-clarnium.svg';
import { useTranslation } from 'react-i18next';
import WalletModalType from './WalletModalType';
import WalletCreateNew from './WalletCreateNew';
import WalletAddExisting from './WalletAddExisting';

import './WalletCreateModal.scss';
import { AppConfig } from '../../config';

const plusIcon = {
  cpay: plusCpayIcon,
  nfg: plusNFGIcon,
  finvaro: plusFinvaroIcon,
  clarnium: plusClarniumIcon
}

const WalletCreateModal = ({ merchantId, typeWallet, createMode, filtersFormName, networkFilter }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [walletCreateMode, setWalletCreateMode] = useState(createMode || 'type');
  const { t } = useTranslation();
  const {pathname} = useLocation();
  const isFromMerchantsWallets = pathname.includes('/merchants/wallets') || pathname === '/wallets';
  const isFromClientsWallets = pathname.includes('/clients');

  const handleCancel = e => {
    setIsVisible(false);
    setWalletCreateMode(createMode || 'type');
  };

  const identity = AppConfig.identity

  return (
    <React.Fragment>
      <Button type="primary" className={`page-header__button-create page-header__button-create-${identity}`} onClick={() => setIsVisible(true)}>
        <img className={`page-header__button-create-icon page-header__button-create-icon-${identity}`} src={plusIcon[identity]} alt="add" />
        {t('wallets.createNewWallet')}
      </Button>
      <Modal
        title={walletCreateMode !== 'type' ? t('wallets.addWalletMode', { mode: walletCreateMode }) : "Add new wallet"}
        visible={isVisible}
        width={369}
        onOk={handleCancel}
        closable={walletCreateMode !== 'type'}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        className={`modal modal__wallet-create modal__wallet-create_${walletCreateMode}`}
        destroyOnClose={true}
      >
        {walletCreateMode === 'type' && <WalletModalType handleCancel={handleCancel} setWalletCreateMode={setWalletCreateMode} />}

        {walletCreateMode === 'new' && (
          <WalletCreateNew
            merchantId={merchantId}
            typeWallet={typeWallet}
            handleCancel={handleCancel}
            filtersFormName={filtersFormName}
            networkFilter={networkFilter}
            isFromMerchantsWallets={isFromMerchantsWallets}
          />
        )}

        {walletCreateMode === 'existing' && (
          <WalletAddExisting
            merchantId={merchantId}
            networkFilter={networkFilter}
            typeWallet={typeWallet}
            filtersFormName={filtersFormName}
            handleCancel={handleCancel}
            isFromMerchantsWallets={isFromMerchantsWallets}
            isFromClientsWallets={isFromClientsWallets}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default WalletCreateModal;
