import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PendingSVG } from '../../../img/default-svg/info-circle-warning.svg';
import './style.scss';

const Pending = () => {
  const {t} = useTranslation();

  return (
    <div className="checkout-pending">
      <PendingSVG />
      <div className="checkout-pending__title">{t('pending')}</div>
      <div className="checkout-pending__description">
        {t('checkouts.pending.transaction')} <br />
       {(t('pending'))}
      </div>
    </div>
  );
};

export default Pending;
