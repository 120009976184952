export const forbiddenChars = /[|?#$:;.@%<>&()'"/]+/;
export const forbiddenHTMLChars = ['&amp;', '&lt;', '&gt', '&quot;', '&#39;', '&#x2F;', '<script>', '</script>', 'script', 'href', 'http', 'https', 'frame', 'iframe', 'img', 'src', 'document', 'window', 'style', 'onload']

export const sanitizeInputDisable = text => forbiddenChars.test(text) || forbiddenHTMLChars.includes(text)
export const sanitizeSearchInputDisable = text => forbiddenHTMLChars.includes(text)

export const sanitizeInputValidator = {
  validator:  (_, value) =>
  forbiddenChars.test(value) || forbiddenHTMLChars.includes(value)
    ? Promise.reject(new Error("You can type only letters or numbers"))
    : Promise.resolve()
}

export const sanitizeCheckoutDescription = {
  validator:  (_, value) =>
  forbiddenHTMLChars.includes(value)
    ? Promise.reject(new Error("You cannot type blacklist words"))
    : Promise.resolve()
}