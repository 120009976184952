import cn from 'classnames';
import React from 'react';

const SocialLinks = ({ data, isFromFooter, isFromBlog, isFromBlogPost }) => {
  return (
    <>
      {isFromBlog || isFromBlogPost ? (
        <div
          className={cn(
            'social-links-container',
            'animate__animated animate__fadeInRight',
          )}
        >
          {isFromBlog || isFromBlogPost ? (
            <h3
              className={cn('social-links-title', {
                'social-links-title__post': isFromBlogPost,
              })}
            >
              {!isFromBlogPost ? 'Social media' : 'Share post'}
            </h3>
          ) : null}

          <div
            className={cn(
              { 'footer__links-social': isFromFooter },
              { 'social-links': isFromBlog },
              { 'social-links__post': isFromBlogPost },
            )}
          >
            {data.length &&
              data.map(link => (
                <a key={link.id} href={link.link} target="_blank" rel="noopener noreferrer">
                  <img src={link.icon} alt={link.name} />
                </a>
              ))}
          </div>
        </div>
      ) : (
        <div className={cn({ 'footer-links-social': isFromFooter })}>
          {data?.length &&
            data?.map(link => (
              <a key={link.id} href={link.link} target="_blank" rel="noopener noreferrer">
                <img src={link.icon} alt={link.name} />
              </a>
            ))}
        </div>
      )}
    </>
  );
};

export default SocialLinks;
