import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import {errorTranslations, successMessagesTrans} from './helpers';
import { notificationsErrorHide, notificationsSuccessHide } from '../../redux/actions/notifications';

import './Notifications.scss';

const Notifications = ({ successData, errorData, notificationsSuccessHide, notificationsErrorHide, useDataRole }) => {
  const { t } = useTranslation();
  const openSuccessNotification = (title, message) => {
    notification.success({
      message: title || (window.location.pathname.includes("admin") ? "Success" : t('success')),
      description: successMessagesTrans(t,message),
      duration: 5,
      onClose: () => {
        notificationsSuccessHide();
      },
    });
  };

  const openErrorNotification = (error, message) => {
    const description = Array.isArray(message) ? (
      message.map(item => <span key={item} className="notification__message">
        {window.location.pathname.includes("admin")
          ? item
          : errorTranslations(t, item)
        }
      </span>)
    ) : (
      <span className="notification__message">
        {window.location.pathname.includes("admin") 
          ? message
          : errorTranslations(t, message)
        }</span>
    );

    notification.error({
      message: error || (window.location.pathname.includes("admin") ? 'Error' : t('error')),
      description: description,
      duration: 10,
      onClose: () => {
        notificationsErrorHide();
      },
    });
  };

  useEffect(() => {
    if (successData) {
      openSuccessNotification(successData.title, successData.message);
    }
  }, [successData]);

  useEffect(() => {
    if (errorData) {
      openErrorNotification(errorData.error, errorData.message);
    }
  }, [errorData]);

  return <React.Fragment></React.Fragment>;
};

const mapStateToProps = state => ({
  successData: state.notifications.successData,
  errorData: state.notifications.errorData,
  useDataRole: state.user.data.role
});

const mapDispatchToProps = dispatch => ({
  notificationsSuccessHide: () => dispatch(notificationsSuccessHide()),
  notificationsErrorHide: () => dispatch(notificationsErrorHide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
