import React from "react";
import TokenIcon from "../../TokenIcon";
import { tokenNodeTypeObj, tokenTypeEnd } from "../../../utils/tokenNodeTypes";
import "./styles.scss";

export const CustomSelectOption = ({
  name,
  title,
  balanceValue,
  balanceUsd,
  currencyType,
  nodeType,
  identity,
}) => {

const tokenType = identity === 'wallet' && (
  <>
  {currencyType === 'token' ? tokenNodeTypeObj[nodeType] : null}
  {tokenTypeEnd(currencyType, nodeType, name)}
  </>
)
  return (
    <div className={`replenish__modal-container__select-option`}>
      <div className="replenish__modal-container__select-option-main">
        <TokenIcon
          tokenName={name}
          className="replenish__modal-container__select-option-main-logo"
        />
        <span>{title}</span>
      </div>
      <div className="replenish__modal-container__select-option-amount">
        <span className="replenish__modal-container__select-option-amount-value">
          {balanceValue} {name} {tokenType}
        </span>
        <span className="replenish__modal-container__select-option-amount-usd">
          ${balanceUsd}
        </span>
      </div>
    </div>
  );
};