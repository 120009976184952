import React from 'react';
import { ReactComponent as AmountFromIcon } from '../../../img/swap-svg/swap-amount-from.svg';
import { ReactComponent as AmountToIcon } from '../../../img/swap-svg/swap-amount-to.svg';
import { tokenNodeTypeObj, tokenTypeEnd } from '../../../utils/tokenNodeTypes';
import './style.scss';

const SwapItemMobile = ({ swapItem, setTransactionDetailsData, currencies }) => {
  const currencyFrom = currencies.find(currency => currency._id === swapItem.from.currency.id);
  const currencyTo = currencies.find(currency => currency._id === swapItem.to.currency.id);

  return (
    <>
      <div className="swap-history-item-mobile">
        <div className="swap-history-item-mobile__main-data" onClick={() => setTransactionDetailsData(swapItem)}>
          <div className="swap-history-item-mobile__info">
            <div className="swap-history-item-mobile__id">{swapItem.exchangeId}</div>

            <div
              className={`swap-history-item-mobile__status swap-history-item-mobile__status_${swapItem.systemStatus.toLowerCase()}`}
            >
              <span>{swapItem.systemStatus}</span>
            </div>
          </div>

          <div className="swap-history-item-mobile__currency">
            <div className="swap-history-item-mobile__currency-item">
              <AmountToIcon /> {swapItem.to.amount.value}
              <span className="swap-history-item-mobile__currency-name">
                {swapItem.to.currency.name}{' '}
                {currencyTo.currencyType === 'token' ? tokenNodeTypeObj[currencyTo.nodeType] : null}
                {tokenTypeEnd(currencyTo.currencyType, currencyTo.nodeType, currencyTo.name)}
              </span>
            </div>
            <div className="swap-history-item-mobile__currency-item">
              <AmountFromIcon /> {swapItem.from.amount.value}
              <span className="swap-history-item-mobile__currency-name">
                {swapItem.from.currency.name}{' '}
                {currencyFrom.currencyType === 'token' ? tokenNodeTypeObj[currencyFrom.nodeType] : null} {' '}
                {tokenTypeEnd(currencyFrom.currencyType, currencyFrom.nodeType, currencyFrom.name)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwapItemMobile;
