import React from "react";
import { Modal, Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { identity } from "../../utils/getIdentity";
import { unsubscribeEmail } from "../../redux/actions/user";

import "./style.scss";

const UnsubscribeLetterModal = ({ isOpenModal, closeModal, unsubscribeEmail }) => {
  const { t } = useTranslation();

  const unsubscribeUser = async () => {
     unsubscribeEmail()
     closeModal()
  }

  return (
    <Modal
      title={t("profile.unsubscribe")}
      visible={isOpenModal}
      width={458}
      onCancel={closeModal}
      footer={null}
      wrapClassName="letter-confirm__modal"
    >
      <div className="letter-confirm__modal-container">
        <p
          className={`letter-confirm__modal-container-text letter-confirm__modal-container-text-${identity}`}
        >
          {t("profile.unsubscribeConfirm")}
        </p>

        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Button
              type="primary"
              className={`modal__button modal__button-create modal__button-create-${identity}`}
              onClick={() => unsubscribeUser()}
            >
              {t("profile.unsubscribe")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              className="modal__button modal__button-cancel"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              {t("cancel")}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => ({
 unsubscribeEmail: unsubscribeEmail(dispatch)
});

export default connect(null, mapDispatchToProps)(UnsubscribeLetterModal);
