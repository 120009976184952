import { Modal, Form, Input, Button } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import './style.scss';
import { cryptoApi } from '../../service/cryptopay-api';
import { addMinutes } from '../../utils/addMinutes';
import { Timer } from '../Timer/Timer';
import notificationList from '../../utils/notifications';
import { identity } from '../../utils/getIdentity';


export const ModalPhone = ({ openConfirmModal, phone, handleConfirmModal, disableResend, setDisableResend, handleDisableResend, userData, setConfirmVerify, editUser}) => {
  const validUntil = addMinutes(1)

  const [verifyCode, setVerifyCode] = useState('')
  const [errorMessage, setErrorMessage] = useState(false)
  const [errMessage, setErrMsg] = useState('')
  const { t } = useTranslation();

  const updProfile = {
    email: userData.email,
    name: userData.name,
    surname: userData.surname,
    phone: phone,
  }

  const verifyPhoneCode = async () => {
    try {
      await cryptoApi.verifyPhone({verifyCode: verifyCode})
      setErrorMessage(false)
      setErrMsg('')
      setConfirmVerify(true)
      editUser(updProfile)
      handleConfirmModal()
    } catch (error) {
      setErrorMessage(true)
      setErrMsg(error.data.message)
    }
  }

 const resendVerifyCode = async () => {
  try {
    await cryptoApi.resendPhoneInApp({})
    setDisableResend(true)
    handleDisableResend()
  } catch (error) {
    notificationList.failed(error.data.message);
  }
 }

  return (
    <>
    <Modal
      title="Verification code"
      visible={openConfirmModal}
      width={408}
      onCancel={handleConfirmModal}
      footer={null}
      wrapClassName="phone-confirm__modal"
    >
      <div className='phone-confirm__modal-info'>
        <div className='phone-confirm__modal-info-desc'>
          <h4>Enter verification code</h4>
          <p>We’ve sent a code to phone number <span style={{fontWeight: 600}}>{phone}</span></p>
        </div>
        <Form>
        <Form.Item name="verifyCode" rules={[{ required: true }]}>
          <Input placeholder={t('code')} onChange={e => setVerifyCode(e.target.value)} />
           {errorMessage && <p style={{fontSize: '10px', color: '#E85B48' }}>{errMessage}</p>}
        </Form.Item>
          <div className='phone-confirm__modal-info-receive'>
             <p>{t('doNotReveiveCode')}</p>
             {disableResend ?
              <p style={{ color: 'grey', marginLeft: '5px'  }}> Receive in {' '}<Timer validUntil={validUntil} onExpire={() => setDisableResend(false)}/></p>
              : <p style={{ color: identity === 'cpay' ? '#2A92FF' : identity === 'nfg' ? '#48C8FF' : '#000000', cursor: 'pointer', marginLeft: '5px'  }} onClick={!disableResend && resendVerifyCode}> {t('resend')}</p>}
          </div>

          <Button
            type="primary"
            className={`form__button form__button-${identity}`}
            onClick={verifyPhoneCode}
            disabled={verifyCode.length < 6}
          >
            {t('verify')}
          </Button>
        </Form>
      </div>
    </Modal>
    </>

  )
}
