import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import { notificationsErrorShow } from '../redux/actions/notifications';
import { resendPhoneTwoFa } from '../redux/actions/twoFactor';
import { Timer } from '../components/Timer/Timer';
import { addMinutes } from '../utils/addMinutes';
import './style.scss';
import { identity } from '../utils/getIdentity';

const ResendPhone2FA = ({ resendPhoneTwoFa }) => {
  const [disableResend, setDisableResend] = useState(true);
  const { t } = useTranslation();

  const validUntil = addMinutes(1);

  const handleDisableResend = () => {
    const timeout = setTimeout(() => {
      setDisableResend(false);
    }, 60000);

    return () => clearTimeout(timeout);
  };

  const resendVerifyCode = async () => {
    try {
      await resendPhoneTwoFa();
      setDisableResend(true);
      handleDisableResend();
    } catch (error) {
      console.log('error', error);
      // notificationList.failed(error.data.message);
    }
  };

  return (
    <div className="resend-phone-twoFa">
      <span>{t('doNotReveiveCode')}</span>
      {disableResend ? (
        <span className="resend-phone-twoFa__timer">
          {' '}
          Receive in <Timer validUntil={validUntil} onExpire={() => setDisableResend(false)} />
        </span>
      ) : (
        <span
          className={`resend-phone-twoFa__button resend-phone-twoFa__button-${identity}`}
          onClick={resendVerifyCode}
        >
          {' '}
          {t('resend')}
        </span>
      )}
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  resendPhoneTwoFa: resendPhoneTwoFa(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendPhone2FA);
