import React, { useState } from 'react';
import JwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import useWindowSize from '../../src/utils/resizeHook';
import './NewFooter.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import FooterLinks from '../components/FooterLinks/FooterLinks';
import SocialLinks from '../components/SocialLinks/SocialLinks';
import twitter from '../img/footer/newX.svg';
import discord from '../img/footer/newDiscord.svg';
import facebook from '../img/footer/newFacebook.svg';
import linkedin from '../img/footer/newLinkedin.svg';
import googlePlay from '../img/footer/GooglePlay.svg';
import appStore from '../img/footer/AppStore.svg';
import logo from '../img/footer/footerLogo.svg';
import RequestForm from '../components/RequestForm';

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,

      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },

      match,
      location,
      history,
    };
  }, [params, match, location, history]);
}

const NewFooter = () => {
  const { t, i18n } = useTranslation();
  const userRole = JwtDecode(localStorage.getItem('authToken')).roles;
  const pathname = useRouter().pathname;
  const [helpFormFlag, setHelpFormFlag] = useState(false);
  const linkCpay = `https://cpay.world/`;

  const FOOTER_LINKS = [
    {
      title: t('footer.documentation'),
      link: 'https://docs.cpay.world',
      id: 4,
    },
    {
      title: t('footer.faq'),
      link: `https://cpay.world/${i18n?.language === 'es' ? 'es/' : ''}faq`,
      id: 5,
    },
    {
      title: t('footer.blog'),
      link: `https://cpay.world/${i18n?.language === 'es' ? 'es/' : ''}blog`,
      id: 6,
    },
    {
      title: t('footer.termConditions'),
      link: `https://cpay.world/${i18n?.language === 'es' ? 'es/' : ''}terms`,
      id: 7,
    },
    {
      title: t('footer.privacyPolicy'),
      link: `https://cpay.world/${i18n?.language === 'es' ? 'es/politica-de-privacidad' : 'privacy-policy'}`,
      id: 8,
    },
  ];

  const SOCIAL_LINKS = [
    {
      name: 'Facebook',
      icon: `${facebook}`,
      link: 'https://www.facebook.com/people/CPAY/100075680508294/',
      id: 0,
    },
    {
      name: 'Twitter',
      icon: `${twitter}`,
      link: 'https://twitter.com/cpay_world',
      id: 1,
    },
    {
      name: 'Linkedin',
      icon: `${linkedin}`,
      link: 'https://www.linkedin.com/company/cpay-world/',
      id: 2,
    },
    {
      name: 'Discord',
      icon: `${discord}`,
      link: 'https://discord.com/invite/dHbZbCUC7K',
      id: 3,
    },
  ];

  return (
    <div className="newFooter">
      <div className="newFooter__container">
        <div className="newFooter__block">
          <div className="newFooter__block-header">
            <img src={logo} alt="" />
            <div className="newFooter__block-header-list">
              {FOOTER_LINKS?.map(item => (
                <a key={item?.id} href={item?.link} target="_blank" rel="noopener noreferrer">
                  {item?.title}
                </a>
              ))}
              <a href=""></a>
            </div>
          </div>
          <div className="newFooter__block-buttonBlock">
            {/*<div className="newFooter__block-buttonBlock-storeButtons">
              <a
                href="https://drive.google.com/file/d/1XY3snMPd9R2TyHHMZXd221TgOv06V2EH/view?usp=drive_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={googlePlay} alt="" />
              </a>
              <a href="https://testflight.apple.com/join/bDLyJGyi" target="_blank" rel="noopener noreferrer">
                <img src={appStore} alt="" />
              </a>
            </div>*/}
            <div className="newFooter__block-buttonBlock-social-links">
              <SocialLinks data={SOCIAL_LINKS} isFromFooter />
            </div>
          </div>
          <div className="newFooter__block-bottomTextBlock">
            {/* <div className="newFooter__block-bottomTextBlock-mainText">{t('footer.mainText')}</div> */}
            <div className="newFooter__block-bottomTextBlock-secondText">{t('footer.secondText')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
