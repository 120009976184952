import React from 'react';
import { Input, Select, Button } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import emailIcon from '../img/default-svg/email.svg';
import './Support.scss';
import { useTranslation } from 'react-i18next';
import { AppConfig } from '../config';
import { identity } from '../utils/getIdentity';

const Support = () => {
  const { t } = useTranslation();

  return (
    <div className={`support support-${identity}`}>
      <div className={`support_title support_title-${identity}`}>{t('support.support')}</div>
      <label>
        {t('support.chooseIssueBelow')}
        <Select className="support_issue" defaultValue="passwordProblem">
          <Select.Option value="passwordProblem">{t('support.passwordProblem')}</Select.Option>
          <Select.Option value="licenseRequest">{t('support.licenseRequest')}</Select.Option>
          <Select.Option value="copyright">{t('support.copyright')}</Select.Option>
          <Select.Option value="withdrawalProblem">{t('support.withdrawalProblem')}</Select.Option>
        </Select>
      </label>
      <label>
        {t('email')}
        <Input
          suffix={<img src={emailIcon} alt="email icon" />}
          className="support_email"
          placeholder="Username@mail.com"
        />
        <span className="support_tip">{t('support.enterReplyAddress')}</span>
      </label>
      <label>
        {t('description')}
        <Input.TextArea className="support_description" placeholder={t('support.yourMessage')} />
      </label>
      <span className="support_tip">{t('support.enterDetailsOfRequest')}</span>
      <div className="support_captcha">
        <ReCAPTCHA sitekey="6Lc3IGMaAAAAAFGzrKCzr4himhW_7ulVJxESxuTr" onChange={e => console.log('changed', e)} />
      </div>
      <Button className={`support_submit support_submit-${AppConfig.identity}`} type="primary">
        {t('submit')}
      </Button>
    </div>
  );
};

export default Support;
