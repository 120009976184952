import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Input, Select, Spin } from "antd";
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import '../MerchantEdit/MerchantDataForm.scss';
import Button from '../Button';
import useWindowSize from '../../utils/resizeHook';
import {
  editMerchantData,
  getMerchantApiKeys,
  getMerchantData,
  getMerchantBalances,
} from '../../redux/actions/merchantData';
import { connect } from 'react-redux';
import TokenIcon from '../TokenIcon';
import { ReactComponent as SwapReverse } from '../../img/swap-svg/swap-reverse.svg';
import { ReactComponent as SwapReverseFinvaro } from '../../img/finvaro/swap-reverse.svg';
import { ReactComponent as SwapReverseClarnium } from '../../img/clarnium/swap-reverse.svg';
import {
  getSwapExchangeWallets,
  getAutoswapSetting,
  postAutoswapSetting,
  deleteAutoswapSetting,
  patchAutoswapSetting,
} from '../../redux/actions/swap';
import walletFormatter from '../../utils/walletFormatter';
import deleteSVG from '../../img/default-svg/trash.svg';
import editSVG from '../../img/swap-svg/swap-edit.svg';
import RemoveAutoswapModal from '../RemoveAutoswapModal/RemoveAutoswapModal';
import './AutoswapDataForm.scss';
import { identity } from '../../utils/getIdentity';
import EditAutoswapModal from '../EditAutoswapModal/EditAutoswapModal';
import { useLocation, useParams } from 'react-router-dom';
import AutoswapListItem from './AutoswapListItem';
import formatCurrency from '../../utils/currencyFormatter';
import { LoadingOutlined } from "@ant-design/icons";

const AutoswapDataForm = ({
  currenciesArray,
  exchangeWallets,
  merchantIds,
  networkFilter,
  getSwapExchangeWallets,
  getAutoswapSetting,
  autoswapData,
  postAutoswapSetting,
  deleteAutoswapSetting,
  patchAutoswapSetting,
  merchantBalances,
  getMerchantBalances,
}) => {
  const [openNewSwap, setOpenNewSwap] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const [editName, setEditName] = useState('');
  const [settingId, setSettingId] = useState('');
  const [editSettingId, setEditSettingId] = useState('');
  const [isEditStage, setIsEditStage] = useState(false);
  const [isLoadingNewSwap, setIsLoadingNewSwap] = useState(false);
  const [isLoadingDeleteSwap, setIsLoadingDeleteSwap] = useState(false);
  const [values, setValues] = useState({
    name: '',
    sendCurrency: '',
    getCurrency: '',
    minimumAmount: '',
    sendCurrencyName: '',
    getCurrencyName: '',
    fromWallet: undefined,
    toWallet: undefined,
  });
  const [autoswapList, setAutoswapList] = useState([]);
  const textRef = useRef(null);

  useEffect(() => {
    setAutoswapList(autoswapData);
  }, [autoswapData]);

  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const { resetFields } = form;
  const { merchantId } = useParams();

  const ids = pathname.includes('merchants/edit') ? merchantId : merchantIds;

  useEffect(() => {
    if (ids && networkFilter) {
      getMerchantBalances(ids, networkFilter, true);
    }
  }, [ids, networkFilter]);

  useEffect(() => {
    if (currenciesArray.length) {
      setValues({
        ...values,
        sendCurrency: currenciesArray[0].title,
        getCurrency: currenciesArray[1].title,
        sendCurrencyName: currenciesArray[0].name,
        getCurrencyName: currenciesArray[1].name,
      });
    }
  }, [currenciesArray]);

  const editSwap = (id, name) => {
    setOpenNewSwap(false);
    setEditSettingId(id);
    setVisibleEdit(true);
    setEditName(name);
  };

  const reverseCurrency = () => {
    const send = values.sendCurrency;
    const get = values.getCurrency;
    const sendName = values.sendCurrencyName;
    const getName = values.getCurrencyName;
    const from = values.fromWallet;
    const to = values.toWallet;

    setValues({
      ...values,
      sendCurrency: get,
      getCurrency: send,
      sendCurrencyName: getName,
      getCurrencyName: sendName,
      fromWallet: to,
      toWallet: from,
    });

    form.setFieldsValue({
      name: values.name,
      sendCurrency: get,
      getCurrency: send,
      minimumAmount: values.minimumAmount,
      sendCurrencyName: getName,
      getCurrencyName: sendName,
      fromWallet: to,
      toWallet: from,
    })
  }

  useEffect(() => {
    if (ids) {
      getAutoswapSetting(ids);
    }
  }, [ids]);

  useEffect(() => {
    const { sendCurrency, getCurrency } = values;

    if (currenciesArray.length > 1 && ids && networkFilter && sendCurrency && getCurrency) {
      const currencyFrom = currenciesArray.filter(item => item.title === sendCurrency);
      const currencyTo = currenciesArray.filter(item => item.title === getCurrency);

      getSwapExchangeWallets(ids, {
        limit: -1,
        typeWallet: 'merchant',
        typeNetwork: networkFilter,
        currencyIds: [currencyFrom[0]._id, currencyTo[0]._id],
      });
    }
  }, [currenciesArray, ids, networkFilter, values.sendCurrency, values.getCurrency]);

  const getCurrencyName = value => {
    const currency = currenciesArray.filter(item => item.title === value);

    return currency[0].name;
  };

  const closeModal = () => {
    setVisibleModal(false);
  };

  const deleteAutoswapModal = (id, name) => {
    setSettingId(id);
    setVisibleModal(true);
    setDeleteName(name);
  };

  const confirmEdit = async () => {
    setIsEditStage(true);

    const autoswapArray = autoswapList.filter(item => item._id !== editSettingId);

    setAutoswapList(autoswapArray);

    const data = autoswapList.filter(item => item.swapName === editName);

    const sendCurrency = currenciesArray.filter(item => item._id === data[0]?.fromCurrency);
    const getCurrency = currenciesArray.filter(item => item._id === data[0]?.toCurrency);

    setOpenNewSwap(true);
    setVisibleEdit(false);
    setValues({
      ...values,
      name: data[0].swapName,
      sendCurrency: sendCurrency[0].title,
      getCurrency: getCurrency[0].title,
      minimumAmount: data[0].fromAmountUSD,
      sendCurrencyName: sendCurrency[0].name,
      getCurrencyName: getCurrency[0].name,
      fromWallet: data[0].fromWallet,
      toWallet: data[0].toWallet,
    });
    form.setFieldsValue({
      name: data[0].swapName,
      sendCurrency: sendCurrency[0].title,
      getCurrency: getCurrency[0].title,
      minimumAmount: data[0].fromAmountUSD,
      sendCurrencyName: sendCurrency[0].name,
      getCurrencyName: getCurrency[0].name,
      fromWallet: data[0].fromWallet,
      toWallet: data[0].toWallet,
    });
  };

  useEffect(() => {
    if (visibleEdit) {
      form.setFieldsValue({
        name: values?.name,
        sendCurrency: values?.sendCurrency,
        getCurrency: values?.getCurrency,
        minimumAmount: values?.minimumAmount,
        sendCurrencyName: values?.sendCurrencyName,
        getCurrencyName: values?.getCurrencyName,
        fromWallet: values?.fromWallet,
        toWallet: values?.toWallet,
      });
    }
  }, [visibleEdit, values]);

  const closeForm = async () => {
    if (openNewSwap) {
      setIsEditStage(false);
      setValues({
        name: '',
        minimumAmount: '',
        sendCurrency: currenciesArray[0].title,
        getCurrency: currenciesArray[1].title,
        sendCurrencyName: currenciesArray[0].name,
        getCurrencyName: currenciesArray[1].name,
        fromWallet: undefined,
        toWallet: undefined,
      });
    }
    setAutoswapList(autoswapData);
    setOpenNewSwap(!openNewSwap);
    resetFields();
  };


  const sendNewAutoswap = debounce(async () => {
    const { sendCurrency, getCurrency, name, minimumAmount, fromWallet, toWallet } = values;

    if (ids && minimumAmount && sendCurrency && getCurrency && fromWallet && toWallet) {
      const currencyFromId = currenciesArray.filter(item => item.title === sendCurrency);
      const currencyToId = currenciesArray.filter(item => item.title === getCurrency);

      setIsLoadingNewSwap(true);

      try {
        await postAutoswapSetting(ids, {
          currencyFromId: currencyFromId[0]._id,
          currencyToId: currencyToId[0]._id,
          fromWalletId: fromWallet,
          toWalletId: toWallet,
          fromAmountUSD: minimumAmount,
          status: true,
          swapName: name,
        }).then(res => {
          if (res?.status !== 'fail') {
            setValues({
              ...values,
              name: '',
              minimumAmount: '',
              sendCurrency: currenciesArray[0].title,
              getCurrency: currenciesArray[1].title,
              sendCurrencyName: currenciesArray[0].name,
              getCurrencyName: currenciesArray[1].name,
              fromWallet: undefined,
              toWallet: undefined,
            });
            resetFields();
            getAutoswapSetting(ids);

            setOpenNewSwap(false);
          }
        });
      } catch (e) {
        console.error(e);
      }
    }

    setIsLoadingNewSwap(false);
  }, 1000);

  const deleteAutoswap = debounce(async () => {
    setIsLoadingDeleteSwap(true);

    try {
      await deleteAutoswapSetting(ids, settingId);
    } catch(e) {
      console.error(e)
    }

    setIsLoadingDeleteSwap(false);

    setVisibleModal(false);

    await getAutoswapSetting(ids);
  }, 1000);

  const editAutoswapSave = async () => {
    const { sendCurrency, getCurrency, name, minimumAmount, fromWallet, toWallet } = values;

    const currencyFromId = currenciesArray.filter(item => item.title === sendCurrency);
    const currencyToId = currenciesArray.filter(item => item.title === getCurrency);

    try {
      await patchAutoswapSetting(ids, editSettingId, {
        currencyFromId: currencyFromId[0]._id,
        currencyToId: currencyToId[0]._id,
        fromWalletId: fromWallet,
        toWalletId: toWallet,
        fromAmountUSD: minimumAmount,
        status: true,
        swapName: name,
      }).then(res => {
        if (res?.status !== 'fail') {
          setOpenNewSwap(false);
          resetFields();
          setIsEditStage(false);

          setValues({
            ...values,
            fromWallet: undefined,
            toWallet: undefined,
          });

          form.setFieldsValue({
            fromWallet: undefined,
            toWallet: undefined,
          });
        }
      });
    } catch (e) {
      console.error(e);
    }

    await getAutoswapSetting(ids);
  };

  const handleChangeNumber = value => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value)) {
      setValues({ ...values, minimumAmount: value });
      form.setFieldsValue({ minimumAmount: value });
    } else {
      setValues({ ...values, minimumAmount: values?.minimumAmount });
      form.setFieldsValue({ minimumAmount: values?.minimumAmount });
    }
  };

  const changeFromCurrency = value => {
    setValues({
      ...values,
      sendCurrency: value,
      fromWallet: undefined,
    });

    form.setFieldsValue({
      sendCurrency: value,
      fromWallet: undefined,
    });
  };

  const changeToCurrency = value => {
    setValues({
      ...values,
      getCurrency: value,
      toWallet: undefined,
    });

    form.setFieldsValue({
      getCurrency: value,
      toWallet: undefined,
    });
  };

  return (
    <div className="component merchant-data autoswap">
      <div className="component__wrapper">
        <div className="component__title">{t('merchants.autoswapTitle')}</div>
        <Button type="primary" className="form__button component__button" onClick={closeForm}>
          {!openNewSwap ? t('createNew') : t('cancel')}
        </Button>
      </div>

      {openNewSwap && (
        <Form
          form={form}
          layout="vertical"
          name="basic"
          className="form merchant-data-form"
          onFinish={!isEditStage ? sendNewAutoswap : editAutoswapSave}
          autoComplete="off"
        >
          <Row>
            <Col span={currentWidth >= 1024 ? 18 : 24}>
              <Row gutter={[20, 0]}>
                <Col span={currentWidth >= 1024 ? 12 : 24}>
                  <Form.Item
                    label={t('merchants.ruleName')}
                    name="name"
                    className="form__item merchant-data-form__website"
                    rules={[{ required: true, message: t('validation.required') }]}
                    initialValue={values.name}
                  >
                    <Input
                      className="form__input"
                      placeholder={t('merchants.typeRule')}
                      maxLength={50}
                      onChange={e => setValues({ ...values, name: e.target.value })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={currentWidth >= 1024 ? 22 : 24}>
              <Row gutter={[20, 0]}>
                <Col span={currentWidth >= 1024 ? 11 : 24}>
                  <Form.Item
                    label={t('merchants.youSend')}
                    name="sendCurrency"
                    className="form__item merchant-data-form__website"
                  >
                    <Select
                      onChange={value => changeFromCurrency(value)}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      defaultValue={
                        currenciesArray?.length > 1 ? (
                          <div className="swap-amount-field__wrapper-default-value">
                            <div>
                              <div className="swap-amount-field__token-icon">
                                <TokenIcon tokenName={getCurrencyName(values.sendCurrency)} />
                              </div>
                              {values.sendCurrency}
                            </div>
                            <div className="swap-amount-field__option-balance">
                              {merchantBalances &&
                                currenciesArray &&
                                merchantBalances
                                  .filter(merchantBalance => merchantBalance.currency.id === currenciesArray[0]._id)
                                  .map(merchantBalance => (
                                    <>
                                      <span>{merchantBalance.balance.value}</span>
                                      <span>{formatCurrency(merchantBalance.balance.usd)}</span>
                                    </>
                                  ))}
                            </div>
                          </div>
                        ) : null
                      }
                    >
                      {currenciesArray?.length > 1 &&
                        merchantBalances.length &&
                        merchantBalances.reduce((result, balance) => {
                          const currency = currenciesArray.find(currency => currency._id === balance.currency.id);

                          if (currency?.supportSwap && currency.title !== values.getCurrency) {
                            result.push(
                              <Select.Option key={currency._id} value={currency.title}>
                                <div className="swap-amount-field__option-wrap">
                                  <div className="swap-amount-field__option-currency">
                                    <div className="swap-amount-field__token-icon">
                                      <TokenIcon tokenName={currency.name} />
                                    </div>
                                    {currency.title}
                                  </div>
                                  <div className="swap-amount-field__option-balance">
                                    <span>{balance.balance.value}</span>
                                    <span>{formatCurrency(balance.balance.usd)}</span>
                                  </div>
                                </div>
                              </Select.Option>
                            );
                          }

                          return result;
                        }, [])}
                    </Select>
                  </Form.Item>
                </Col>

                <div className="swap-exchange__reverse--swap" onClick={reverseCurrency}>
                  {identity === 'finvaro' ? <SwapReverseFinvaro /> : identity === 'clarnium' ? <SwapReverseClarnium /> : <SwapReverse />}
                </div>

                <Col span={currentWidth >= 1024 ? 11 : 24}>
                  <Form.Item
                    label={t('merchants.youGet')}
                    name="getCurrency"
                    className="form__item merchant-data-form__website"
                  >
                    <Select
                      onChange={value => changeToCurrency(value)}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      defaultValue={
                        currenciesArray?.length > 1 ? (
                          <div className="swap-amount-field__wrapper-default-value">
                            <div>
                              <div className="swap-amount-field__token-icon">
                                <TokenIcon tokenName={getCurrencyName(values.getCurrency)} />
                              </div>
                              {values.getCurrency}
                            </div>

                            <div className="swap-amount-field__option-balance">
                              {merchantBalances &&
                                currenciesArray &&
                                merchantBalances
                                  .filter(merchantBalance => merchantBalance.currency.id === currenciesArray[1]._id)
                                  .map(merchantBalance => (
                                    <>
                                      <span>{merchantBalance.balance.value}</span>
                                      <span>{formatCurrency(merchantBalance.balance.usd)}</span>
                                    </>
                                  ))}
                            </div>
                          </div>
                        ) : null
                      }
                    >
                      {currenciesArray?.length > 1 &&
                        merchantBalances.length &&
                        merchantBalances.reduce((result, balance) => {
                          const currency = currenciesArray.find(currency => currency._id === balance.currency.id);

                          if (currency?.supportSwap && currency.title !== values.sendCurrency) {
                            result.push(
                              <Select.Option key={currency._id} value={currency.title}>
                                <div className="swap-amount-field__option-wrap">
                                  <div className="swap-amount-field__option-currency">
                                    <div className="swap-amount-field__token-icon">
                                      <TokenIcon tokenName={currency.name} />
                                    </div>
                                    {currency.title}
                                  </div>
                                  <div className="swap-amount-field__option-balance">
                                    <span>{balance.balance.value}</span>
                                    <span>{formatCurrency(balance.balance.usd)}</span>
                                  </div>
                                </div>
                              </Select.Option>
                            );
                          }

                          return result;
                        }, [])}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={currentWidth >= 1024 ? 18 : 24}>
              <Row gutter={[20, 0]}>
                <Col span={currentWidth >= 1024 ? 12 : 24}>
                  <Form.Item
                    label={t('merchants.minimumAmount')}
                    name="minimumAmount"
                    className="form__item merchant-data-form__website"
                    rules={[{ required: true, message: t('validation.required') }]}
                  >
                    <Input
                      className="form__input"
                      placeholder={t('merchants.typeMinimum')}
                      value={values.minimumAmount}
                      onChange={e => handleChangeNumber(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={currentWidth >= 1024 ? 22 : 24}>
              <Row gutter={[20, 0]}>
                <Col span={currentWidth >= 1024 ? 11 : 24}>
                  <Form.Item
                    label={t('merchants.sendersWallet')}
                    name="fromWallet"
                    className="form__item merchant-data-form__website"
                  >
                    {exchangeWallets[0]?.entities?.length ? (
                      <Select
                        onChange={value => setValues({ ...values, fromWallet: value })}
                        className="merchant-data-form__website"
                        defaultValue={
                          values?.fromWallet > 1 ? (
                            <div className="swap-amount-field__token-icons">
                              {<TokenIcon tokenName={getCurrencyName(values?.sendCurrency)} />}
                              {walletFormatter(
                                exchangeWallets[0]?.entities.find(item => item._id === values?.fromWallet).address
                              )}
                            </div>
                          ) : (
                            'Choose wallet address'
                          )
                        }
                      >
                        {exchangeWallets[0]?.entities.map((item, index) => (
                          <Select.Option key={index} value={item?._id}>
                            <div className="swap-amount-field__option-wrap">
                              <div className="swap-amount-field__token-icons">
                                {<TokenIcon tokenName={getCurrencyName(values?.sendCurrency)} />}
                                {walletFormatter(item?.address)}
                              </div>
                              <div className="swap-amount-field__option-balance">
                                <span>{item?.tokens.length ? item?.tokens[0]?.balance.value : item.balance.value}</span>
                                <span>
                                  {formatCurrency(
                                    item?.tokens.length ? item?.tokens[0]?.balance.usd : item.balance.usd
                                  )}
                                </span>
                              </div>
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <span>{t('swap.noWallets')}</span>
                    )}
                  </Form.Item>
                </Col>

                <Col span={currentWidth >= 1024 ? 11 : 24}>
                  <Form.Item
                    label={t('merchants.destinationWallet')}
                    name="toWallet"
                    className="form__item merchant-data-form__website"
                  >
                    {exchangeWallets[1]?.entities?.length ? (
                      <Select
                        onChange={value => setValues({ ...values, toWallet: value })}
                        defaultValue={
                          values?.toWallet > 1 ? (
                            <div className="swap-amount-field__token-icons">
                              {<TokenIcon tokenName={getCurrencyName(values?.getCurrency)} />}
                              {walletFormatter(
                                exchangeWallets[0]?.entities.find(item => item._id === values?.toWallet).address
                              )}
                            </div>
                          ) : (
                            'Choose wallet address'
                          )
                        }
                        className="merchant-data-form__website"
                      >
                        {exchangeWallets[1]?.entities.map((item, index) => (
                          <Select.Option key={index} value={item?._id}>
                            <div className="swap-amount-field__option-wrap">
                              <div className="swap-amount-field__token-icons">
                                {<TokenIcon tokenName={getCurrencyName(values?.getCurrency)} />}
                                {walletFormatter(item?.address)}
                              </div>
                              <div className="swap-amount-field__option-balance">
                                <span>{item?.tokens.length ? item?.tokens[0]?.balance.value : item.balance.value}</span>
                                <span>
                                  {formatCurrency(
                                    item?.tokens.length ? item?.tokens[0]?.balance.usd : item.balance.usd
                                  )}
                                </span>
                              </div>
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <span>{t('swap.noWallets')}</span>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={currentWidth >= 1024 ? 5 : 24}>
              <Button
                type="primary"
                className={`form__button form__button-${identity}`}
                disabled={!values.name || !values.minimumAmount || !values?.fromWallet || !values?.toWallet}
              >
                {isLoadingNewSwap ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                ) : (
                  t('submit')
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      )}

      {autoswapList.length >= 1 && openNewSwap && <hr className="autoswap__hr" />}

      {autoswapList.length >= 1 && (
        <div className="swap-amount-field__listWrapper">
          {autoswapList.map(autoswap => (
            <div className="swap-amount-field__listWrapper__block">
              <div className="swap-amount-field__listWrapper__block__inner">
                <AutoswapListItem autoswap={autoswap} editSwap={editSwap} deleteAutoswapModal={deleteAutoswapModal} />
                <img
                  onClick={() => editSwap(autoswap?._id, autoswap?.swapName)}
                  className="swap-amount-field__image"
                  src={editSVG}
                  alt="edit swap"
                />
                <img
                  onClick={() => deleteAutoswapModal(autoswap.id, autoswap.swapName)}
                  className="swap-amount-field__image"
                  src={deleteSVG}
                  alt="delete swap"
                />
              </div>
            </div>
          ))}
        </div>
      )}

      <RemoveAutoswapModal
        name={deleteName}
        deleteAutoswap={deleteAutoswap}
        id={settingId}
        closeModal={closeModal}
        visible={visibleModal}
        isLoading={isLoadingDeleteSwap}
      />

      <EditAutoswapModal
        confirmEdit={confirmEdit}
        visible={visibleEdit}
        closeModal={() => setVisibleEdit(false)}
        name={editName}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  currenciesArray: state.currencies.data,
  exchangeWallets: state.swap.exchangeWallets.data,
  networkFilter: state.networkFilter,
  merchantIds: state.transactions.merchantId,
  autoswapData: state.swap.autoswapData,
  merchantBalances: state.merchantData.balances.balances || [],
});

const mapDispatchToProps = dispatch => ({
  getMerchantBalances: getMerchantBalances(dispatch),
  getMerchantData: getMerchantData(dispatch),
  editMerchantData: editMerchantData(dispatch),
  getMerchantApiKeys: getMerchantApiKeys(dispatch),
  getSwapExchangeWallets: dispatch(getSwapExchangeWallets),
  getAutoswapSetting: dispatch(getAutoswapSetting),
  postAutoswapSetting: dispatch(postAutoswapSetting),
  deleteAutoswapSetting: dispatch(deleteAutoswapSetting),
  patchAutoswapSetting: dispatch(patchAutoswapSetting),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoswapDataForm);