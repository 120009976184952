import React from 'react';
import TEXT from '../Text';
import { Switch, Form } from 'antd';
import './style.scss';
import { AppConfig } from '../../../config';

const Switcher = ({ name, title, description }) => (
  <div className="switcher-wrapper">
    <TEXT.sectionTitle>{title}</TEXT.sectionTitle>
    <TEXT.sectionDescription className="switcher-wrapper__description">{description}</TEXT.sectionDescription>
    <Form.Item name={name} valuePropName="checked">
      <Switch className={`switcher-wrapper__switcher switcher-wrapper__switcher-${AppConfig.identity}`} />
    </Form.Item>
  </div>
);

export default Switcher;
