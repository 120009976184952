import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Radio, Spin } from "antd";
import cn from 'classnames';
import Button from '../../../../components/Button';
import {
  withdrawAdminSystemFee,
  getWithdrawalAdminEstimateMax,
  withdrawalAdminEstimateMaxReset,
  withdrawResetTwoFaAdmin,
} from '../../../../redux/actions/adminPanel/adminFeeWithdraw';
import { notificationsErrorShow } from '../../../../redux/actions/notifications';
import WithdrawTwoFaConfirm from './WithdrawTwoFaConfirm';
import { cryptoApi } from '../../../../service/cryptopay-api';
import repeatIcon from '../../../../img/default-svg/repeat.svg';
import withdrawalIcon from '../../../../img/cpay-svg/arrow-right.svg';
import TokenIcon from '../../../../components/TokenIcon';
import NumberFormat from 'react-number-format';
import './style.scss';
import { AppConfig } from '../../../../config';
import { identity } from '../../../../utils/getIdentity';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { truncate } from '../../../../utils/truncateFunction';
import dropdownIcon from '../../../../img/default-svg/chevron-down.svg';
import { LoadingOutlined } from "@ant-design/icons";

function WithdrawForm({
  currencies,
  setFrom,
  to,
  setTo,
  amount,
  setAmount,
  selectedWallet,
  setSelectedWallet,
  fetching,
  fetchingMaxAmount,
  withdrawAdminSystemFee,
  getWithdrawalAdminEstimateMax,
  withdrawalAdminEstimateMaxReset,
  notificationsErrorShow,
  feeWallets,
}) {
  const [exchange, setExchange] = useState(0);
  const [showTwoFaModal, setShowTwoFaModal] = useState(false);
  const [decimalsLimit, setDecimalsLimit] = useState(0);
  const [showDecimalsWarning, setShowDecimalsWarning] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [fees, setFees] = useState(null);
  const [isEstimateMaxFetching, setIsEstimateMaxFetching] = useState(false);
  const [loadingFees, setLoadingFees] = useState(false);
  const [comment, setComment] = useState('');
  const [visibleWalletDropdown, setVisibleWalletDropdown] = useState(false);
  const [sendToVisibleWalletDropdown, sendToSetVisibleWalletDropdown] = useState(false);
  const [sendToLabelState, setSendToLabelState] = useState('externalWallet');
  const [selectedSendToWallet, setSelectedSendToWallet] = useState(selectedWallet || null);
  const [selectedNodeType, setSelectedNodeType] = useState(null);

  const [form] = Form.useForm();
  const ratesFetchTimer = useRef();
  const { t } = useTranslation();

  const validWallets = feeWallets;

  const walletsWithoutZeroBalances = wallet => {
    return (
      (wallet.balance.usd !== 0 && wallet.tokens.length === 0) ||
      (wallet.balance.usd !== 0 && wallet.tokens.length !== 0 && wallet.tokens[0].balance.usd !== 0) ||
      (wallet.balance.usd === 0 && wallet.tokens.length !== 0 && wallet.tokens[0].balance.usd !== 0)
    );
  };

  const isMainWalletForSendTo = !selectedSendToWallet?.tokens || selectedSendToWallet?.tokens?.length === 0;

  useEffect(() => () => ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current), []);

  useEffect(() => {
    if (to) setShowWarning(false);
  }, [to]);

  useEffect(() => {
    setDecimalsLimit(currencies.find(currency => selectedWallet.currencyId === currency._id)?.decimals);
    return () => withdrawalAdminEstimateMaxReset();
  }, []);

  const setFetchTimer = (action, value) => {
    ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current);
    ratesFetchTimer.current = setTimeout(() => action(value), 500);
  };

  const getCurrenciesRate = async () => {
    const response = await cryptoApi.getCurrenciesRate();
    return response;
  };

  const clearWithdrawState = () => {
    form.setFieldsValue({
      walletTo: '',
      exchange: 0,
    });
    setFrom('');
    setTo('');
    setAmount('');
    setSelectedWallet(null);
    setExchange(0);
  };

  const handleSubmit = () => {
    withdrawAdminSystemFee(selectedWallet._id, {
      to,
      amount: `${amount}`,
      currencyToken: selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
      comment
    }).then(response => {
      if (response && response.data) {
        if (response.data.twoFactorToken || response.data.emailSent || response.data.codeSent) {
          setShowTwoFaModal(true);
        }
      }
    });
  };

  const convertTokensToUsd = async value => {
    if (showDecimalsWarning) {
      return;
    }
    const rates = await getCurrenciesRate();

    if (rates && rates.data) {
      setExchange(value * +rates.data[selectedWallet.currency] || 0);
      form.setFieldsValue({
        exchange: value * +rates.data[selectedWallet.currency] || 0,
      });
    }
  };

  const convertUsdToTokens = async value => {
    if (+value) {
      setExchange(value);
      const rates = await getCurrenciesRate();

      if (rates && rates.data) {
        setAmount((value / +rates.data[selectedWallet.currency]).toFixed(decimalsLimit) || 0);
        setShowDecimalsWarning(false);
      }
    } else {
      setAmount('');
      setExchange(0);
    }
  };

  const handleEstimateMax = async (e) => {
    setIsEstimateMaxFetching(true);
    const { _id: walletId } = selectedWallet;
    const data = {
      to,
      currencyToken: selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
    };

    if (!to) {
      setShowWarning(true);
    }

    if (selectedWallet && to) {
      await getWithdrawalAdminEstimateMax(walletId, data);
    }

    setIsEstimateMaxFetching(false);
  };

  useEffect(() => {
    if (amount) {
      setShowDecimalsWarning(false);
      setFetchTimer(convertTokensToUsd, amount);
    }
  }, [amount]);

  const handleKeyDown = e => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  // const handleInputChange = e => {
  //   const cleanedValue = e.target.value.replace(/\s/g, '');

  //   form.setFieldsValue({ walletTo: cleanedValue });
  //   setTo(cleanedValue);
  // };

  const handleInputChange = e => {
    if (e.target.value === 'internalWallet' || e.target.value === 'externalWallet') return;
    const cleanedValue = e.target.value.replace(/\s/g, '');

    form.setFieldsValue({ walletTo: cleanedValue });
    setTo(cleanedValue);
  };

  const debouncedGetFeeEstimateAdmin = useRef(
    debounce(async (...params) => {
      try {
        const response = await cryptoApi.getFeeEstimateAdmin(...params);
        setFees(response && response.data ? response.data : null);
      } catch (error) {
        notificationsErrorShow({ message: error.data.message });
      }

      setLoadingFees(false);
    }, 3000)
  );

  const handleWalletClick = () => {
    setVisibleWalletDropdown(!visibleWalletDropdown);
  };

  const handleSendToWalletClick = () => {
    sendToSetVisibleWalletDropdown(!sendToVisibleWalletDropdown);
  };

  const handleWalletChange = value => {
    if (value.tokens.length === 0) {
      const selectedWallet = feeWallets.find(wallet => wallet._id === value._id);

      setSelectedWallet(selectedWallet);
    } else {
      setSelectedWallet({ ...value.tokens[0], _id: value._id, address: value.address });
    }

    setVisibleWalletDropdown(false);
  };

  const handleSenToWalletChange = value => {
    if (value.tokens.length === 0) {
      const selectedWallet = feeWallets.find(wallet => wallet._id === value._id);

      setSelectedSendToWallet(selectedWallet);
    } else {
      setSelectedSendToWallet({ ...value.tokens[0], _id: value._id, address: value.address });
    }
    form.setFieldsValue({ walletTo: value.address });
    setTo(value.address);
    sendToSetVisibleWalletDropdown(false);
  };

  const dropdownRef = useRef(null);
  const sendToDropdownRef = useRef(null);

  useEffect(() => {
    setLoadingFees(true);
    if (amount && to) {
      debouncedGetFeeEstimateAdmin.current(
        selectedWallet._id,
        to,
        `${amount}`,
        selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined
      );
    } else {
      setLoadingFees(false);
      setFees(null);
    }
  }, [to, amount]);
  //
  useEffect(() => {
    if (selectedWallet) {
      setSelectedSendToWallet(selectedWallet);
    }
  }, []);

  useEffect(() => {
    const selectedIndex = validWallets.findIndex(wallet => wallet._id === selectedWallet._id);

    const nextIndex =
      selectedIndex === -1 || selectedIndex === 0 ? 1 : selectedIndex === validWallets.length - 1 ? 0 : 0;

    setSelectedSendToWallet(validWallets?.length > 1 ? validWallets[nextIndex] : null);

    form.setFieldsValue({
      walletTo: sendToLabelState === 'externalWallet' ? to || undefined : validWallets[nextIndex]?.address,
    });
    setTo(sendToLabelState === 'externalWallet' ? to : validWallets[nextIndex]?.address);
  }, [sendToLabelState, feeWallets]);

  useEffect(() => {
    const handleBodyClick = event => {
      const dropdown = dropdownRef.current;
      const sendToDropdown = sendToDropdownRef.current;
      const clickedElement = event.target;
      const isWalletClick = clickedElement.closest('.admin-settings-form__selected-wallet');
      const isCustomDropdownItemClick = clickedElement.closest('.admin-settings-form__custom-dropdown-item');
      const isCheckboxClick = clickedElement.closest(`.admin-settings-form__sendTo-label-block_checkbox-${identity}`);

      // Check if the click is outside the dropdown and not on a custom dropdown item
      if (
        dropdown &&
        !dropdown.contains(clickedElement) &&
        !isWalletClick &&
        !isCustomDropdownItemClick &&
        !isCheckboxClick
      ) {
        setVisibleWalletDropdown(false);
      }
      if (sendToDropdown && !sendToDropdown.contains(clickedElement) && !isWalletClick && !isCustomDropdownItemClick) {
        sendToSetVisibleWalletDropdown(false);
      }
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [visibleWalletDropdown, sendToVisibleWalletDropdown]);

  const disabledByForm =
    (selectedSendToWallet?.address === selectedWallet?.address && sendToLabelState !== 'externalWallet') ||
    !to ||
    !amount;

  useEffect(() => {
    const nodeType = currencies.find(currency => currency._id === selectedWallet.currencyId)?.nodeType;
    setSelectedNodeType(nodeType);
  }, [selectedWallet]);

  return (
    <>
      <WithdrawTwoFaConfirm
        isVisible={showTwoFaModal}
        setIsVisible={setShowTwoFaModal}
        action={withdrawAdminSystemFee}
        fetching={fetching}
        clearWithdrawState={clearWithdrawState}
        walletId={selectedWallet._id}
        body={{
          to,
          amount: `${amount}`,
          currencyToken: selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
          comment
        }}
        //  withdrawResetTwoFa={withdrawResetTwoFaAdmin}
      />
      <Form
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          walletSelect: selectedWallet.address,
          exchange: exchange,
        }}
        className="form settings-form"
      >
        <Row>
          <Col span="24">
            <Row gutter={[20, 0]} align="top">
              <Col
                span="12"
                className={cn('admin-settings-form__exchange-wrapper', {
                  'admin-settings-form__exchange-wrapper_warning': showWarning,
                })}
              >
                <div className="admin-settings-form__selected-wallet-label">Currency:</div>
                <Form.Item className="form__item custom-item">
                  <div
                    className={`admin-settings-form__selected-wallet admin-settings-form__selected-wallet-${identity}`}
                    onClick={handleWalletClick}
                  >
                    <TokenIcon tokenName={selectedWallet.currency} />
                    <div className="admin-settings-form__selected-wallet-info">
                      <span className="admin-settings-form__selected-wallet-info_text">
                        {currencies.find(currency => currency._id === selectedWallet.currencyId)?.title ??
                          selectedWallet?.currency}{' '}
                        - {truncate(selectedWallet.address, 14, '..')} - {selectedWallet.balance.value}
                      </span>

                      <img
                        className={`admin-settings-form__selected-wallet-info__arrow ${
                          visibleWalletDropdown ? `admin-settings-form__selected-wallet-info__arrow-open` : ''
                        }`}
                        src={dropdownIcon}
                        alt=""
                      />
                    </div>
                  </div>
                  {visibleWalletDropdown && (
                    <div ref={dropdownRef} className="admin-settings-form__wrapper-custom-dropdown">
                      <div className="admin-settings-form__custom-dropdown">
                        {feeWallets.map(wallet =>
                          walletsWithoutZeroBalances(wallet) ? (
                            <div
                              key={wallet._id}
                              onClick={() => handleWalletChange(wallet)}
                              className={cn('admin-settings-form__custom-dropdown-item', {
                                'admin-settings-form__custom-dropdown-item-current':
                                  wallet?._id === selectedWallet?._id,
                              })}
                            >
                              <TokenIcon
                                tokenName={wallet.tokens.length === 0 ? wallet.currency : wallet.tokens[0].currency}
                              />
                              <span>{truncate(wallet.address, 14, '..')}</span>-
                              <span className="admin-settings-form__custom-dropdown-item-balance">
                                {wallet.tokens.length === 0 ? wallet.balance.value : wallet.tokens[0].balance.value}
                              </span>
                            </div>
                          ) : null
                        )}
                      </div>
                    </div>
                  )}
                </Form.Item>
                <img src={withdrawalIcon} alt="exchange" className={`admin-settings-form__exchange-wrapper-icon`} />
              </Col>
              <Col
                span="12"
                style={{ marginTop: '-18px' }}
              >
                <Form.Item
                  label={
                    <div className="admin-settings-form__sendTo-label-block">
                      <span>{t('withdraw.sendTo')}:</span>
                      <div className={`admin-settings-form__sendTo-label-block_checkbox-${identity}`}>
                        <Radio.Group
                          disabled={validWallets?.length <= 1}
                          value={sendToLabelState}
                          onChange={e => {
                            if (e.target.value === 'externalWallet') {
                              form.setFieldsValue({
                                walletTo: undefined,
                              });
                              setTo('');
                            }
                            setSendToLabelState(e.target.value);
                          }}
                        >
                          <Radio value="externalWallet">{t('withdraw.externalWallet')}</Radio>
                          <Radio value="internalWallet">{t('withdraw.internalWallet')}</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  }
                  name="walletTo"
                  className="form__item"
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                >
                  {sendToLabelState === 'externalWallet' ? (
                    <>
                      <Input className="form__input" placeholder="Wallet address" />
                      {showWarning ? <span className="max-warning">Fill in the recipient's address first</span> : ''}
                    </>
                  ) : (
                    <>
                      <div
                        className="admin-settings-form__selected-wallet sendTo-custom-input"
                        onClick={handleSendToWalletClick}
                      >
                        <TokenIcon
                          tokenName={
                            isMainWalletForSendTo
                              ? selectedSendToWallet?.currency
                              : selectedSendToWallet?.tokens[0].currency
                          }
                        />
                        <div className="admin-settings-form__selected-wallet-info">
                          <span className="admin-settings-form__selected-wallet-info_text">
                            {currencies.find(
                              currency =>
                                currency._id ===
                                (isMainWalletForSendTo
                                  ? selectedSendToWallet.currencyId
                                  : selectedSendToWallet?.tokens[0].currencyId)
                            )?.title ??
                              (isMainWalletForSendTo
                                ? selectedSendToWallet?.currency
                                : selectedSendToWallet?.tokens[0].currency)}{' '}
                            - {truncate(selectedSendToWallet.address, 14, '..')} -{' '}
                            {isMainWalletForSendTo
                              ? selectedSendToWallet?.balance.value
                              : selectedSendToWallet?.tokens[0].balance.value}
                          </span>
                          <img
                            className={`admin-settings-form__selected-wallet-info__arrow ${
                              sendToVisibleWalletDropdown ? `admin-settings-form__selected-wallet-info__arrow-open` : ''
                            }`}
                            src={dropdownIcon}
                            alt=""
                          />
                        </div>
                      </div>
                      {sendToVisibleWalletDropdown && (
                        <div
                          ref={sendToDropdownRef}
                          className="admin-settings-form__wrapper-custom-dropdown sendTo-wrapper-custom-dropdown"
                        >
                          <div className="admin-settings-form__custom-dropdown">
                            {feeWallets.map(wallet =>
                              wallet && wallet?._id !== selectedWallet?._id ? (
                                <div
                                  key={wallet._id}
                                  onClick={() => handleSenToWalletChange(wallet)}
                                  className={cn('admin-settings-form__custom-dropdown-item', {
                                    'admin-settings-form__custom-dropdown-item-current':
                                      wallet?._id === selectedSendToWallet?._id,
                                  })}
                                >
                                  <TokenIcon
                                    tokenName={wallet.tokens.length === 0 ? wallet.currency : wallet.tokens[0].currency}
                                  />
                                  <span>{truncate(wallet.address, 14, '..')}</span>-
                                  <span className="admin-settings-form__custom-dropdown-item-balance">
                                    {wallet.tokens.length === 0 ? wallet.balance.value : wallet.tokens[0].balance.value}
                                  </span>
                                </div>
                              ) : null
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {selectedNodeType === 'ton' && (
              <Row gutter={[20, 0]}>
                <Col span="12" className="admin-settings-form__exchange-wrapper">
                  <div className="admin-settings-form__exchange-wrapper__comment">
                    <div>Comment:</div>
                    <input
                      value={comment}
                      placeholder="Comment"
                      className="form__input settings-form__input-usd withdraw-amount__input"
                      onChange={e => setComment(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Row gutter={[20, 0]}>
              <Col span="12" className="admin-settings-form__exchange-wrapper">
                <label className="admin-withdraw-amount">
                  <div className="admin-settings-form__exchange-wrapper_label withdraw-amount__label">
                    Enter amount:{' '}
                    {/* <div className="admin-settings-form__exchange-wrapper_min-value">Minimum 50$</div> */}
                  </div>
                  <div className="admin-withdraw-amount__input-wrapper">
                    <input
                      type="number"
                      value={amount}
                      placeholder="Amount"
                      className="form__input settings-form__input-usd withdraw-amount__input"
                      onChange={e => {
                        if (e.target.value < 0) {
                          setAmount('');
                          return;
                        }

                        if (
                          !e.target.value.toString().split('.')[1] ||
                          e.target.value.toString().split('.')[1].length <= decimalsLimit
                        ) {
                          setAmount(e.target.value);
                          setFetchTimer(convertTokensToUsd, e.target.value);
                          setShowDecimalsWarning(false);
                        } else {
                          setShowDecimalsWarning(true);
                          setAmount(amount);
                          setFetchTimer(convertTokensToUsd, amount);
                        }
                      }}
                    />

                    <img
                      src={repeatIcon}
                      alt="exchange"
                      className={cn('admin-settings-form__exchange-wrapper-icon', {
                        'admin-settings-form__exchange-wrapper-icon-animated': fetchingMaxAmount,
                      })}
                    />
                    <div className="max-container" onClick={handleEstimateMax}>
                      <span className={`max-text max-text-${AppConfig.identity}`}>
                        {!isEstimateMaxFetching ? (
                          'max'
                        ) : (
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        )}
                      </span>
                    </div>
                  </div>

                  {showDecimalsWarning && (
                    <span className="admin-withdraw-amount__decimals-limit">
                      The maximum decimal value for {selectedWallet.currency} is {decimalsLimit}.
                    </span>
                  )}
                </label>
              </Col>
              <Col span="12">
                <Form.Item label="Exchange rate in USD:" name="exchange" className="form__item">
                  <NumberFormat
                    value={exchange}
                    className="form__input settings-form__input-usd"
                    thousandSeparator={true}
                    prefix={'$'}
                    onValueChange={(values, sourceInfo) => {
                      if (sourceInfo.event) {
                        setFetchTimer(convertUsdToTokens, values.value);
                      }
                    }}
                    onFocus={e => e.target.select()}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    allowNegative={false}
                    allowEmptyFormatting={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="withdraw__buttons">
              <Col span="8">
                <Button type="secondary" className="form__button" onClick={clearWithdrawState}>
                  Back
                </Button>
              </Col>
              <Col span="8" offset="8">
                <Button
                  type="primary"
                  className={`form__button form__button-${identity}`}
                  disabled={disabledByForm}
                  onClick={handleSubmit}
                  loading={fetching}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <div className="withdraw-admin-fee">
        <div className={`withdraw-admin-fee__title withdraw-admin-fee__title-${identity}`}>{t('total')}</div>
        <div className="withdraw-admin-fee__value">
          <span className="withdraw-admin-fee__description">{t('wallets.feeCalcAdmin')}:</span>
          <span>
            {amount && !loadingFees ? `${amount} ${selectedWallet.currency}` : ''}
            {loadingFees ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            ) : (
              <span>
                {fees ? ` + ${fees.minerFee} ${fees.currencyMinerFee}` : ''}
              </span>
            )}
          </span>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  fetching: state.adminFeeWithdraw.fetching,
  fetchingMaxAmount: state.adminFeeWithdraw.fetchingMaxAmount,
});

// const mapDispatchToProps = {
//   withdrawAdminSystemFee,
//   withdrawAdminResetTwoFa,
//   getWithdrawalAdminEstimateMax,
//   withdrawalAdminEstimateMaxReset,
// };

// const mapDispatchToProps = dispatch => ({
//   withdrawAdminSystemFee,
//   withdrawAdminResetTwoFa,
//   getWithdrawalAdminEstimateMax,
//   withdrawalAdminEstimateMaxReset,
//   notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
// });

const mapDispatchToProps = dispatch => ({
  withdrawAdminSystemFee: withdrawAdminSystemFee(dispatch),
  // withdrawResetTwoFa: () => dispatch(withdrawResetTwoFaAdmin()),
  getWithdrawalAdminEstimateMax: getWithdrawalAdminEstimateMax(dispatch),
  withdrawalAdminEstimateMaxReset: () => dispatch(withdrawalAdminEstimateMaxReset),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawForm);
