import React from 'react';
import { Tooltip } from 'antd';
import NumberFormat from 'react-number-format';
import TokenIcon from '../../../../components/TokenIcon';
import './styles.scss';
import { identity } from '../../../../utils/getIdentity';
import { ReactComponent as InfoIcon } from '../../../../img/adminPanel/info-icon.svg';

const TopFive = ({ title, drawData, prefix, tooltipText }) => {
  const { data } = drawData;
  return (
    <div className="dashboard-topfive">
      <div className={`dashboard-topfive__title dashboard-topfive__title-${identity}`}>
        {title}

        <Tooltip title={tooltipText} placement="bottom" overlayClassName="dashboard-topfive__tooltip">
          <InfoIcon />
        </Tooltip>
      </div>
      <div className="dashboard-topfive__list">
        {data &&
          data.map((el, index) => {
            const { symbol, currency, volume, count } = el;
            return (
              <div
                className="dashboard-topfive__list-item"
                key={volume ? symbol + volume + index : symbol + count + index}
              >
                <TokenIcon tokenName={symbol} className="dashboard-topfive__list-item-img" />
                <p className="dashboard-topfive__list-item-name">{currency}</p>
                <NumberFormat
                  value={volume || count || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={prefix}
                  className="dashboard-topfive__list-item-volume"
                  decimalScale={2}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TopFive;
