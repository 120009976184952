import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { cryptoApi } from '../../../../../service/cryptopay-api';
import { notificationsErrorShow } from '../../../../../redux/actions/notifications';
import Button from '../../../../Button';
import UploadComponent from '../UploadComponent';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, Tabs, Radio, Spin, Col, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getMerchantWallets, merchantWalletsReset } from '../../../../../redux/actions/merchantWallets';
import CurrencyDropdown from '../../../../CurrencyDropdown';
import TokenIcon from '../../../../TokenIcon';
import formatCurrency from '../../../../../utils/currencyFormatter';
import { ReactComponent as DropdownIcon } from '../../../../../img/default-svg/chevron-down.svg';
import './style.scss';
import { whitespaceInputValidator } from '../../../../../utils/whitespaceInputValidator';
import { mainColor } from '../../../../../utils/getMainColor';
import { identity } from '../../../../../utils/getIdentity';
import { sanitizeCheckoutDescription } from '../../../../../utils/sanitizeInput';
import { ReactComponent as HelpCircleSVG } from '../../../../../img/default-svg/help-circle.svg';

const { TextArea } = Input;

const SetData = ({
  prevStep,
  checkoutType,
  isDuplicating,
  createCheckoutHandler,
  editCheckoutState,
  ifCreateFetching,
  ifPatchFetching,
  activeMerchantCurrencies,
  merchantId,
  typeNetwork,
  merchantWallets,
  getMerchantWallets,
  merchantWalletsReset,
  currencies,
  notificationsErrorShow,
  merchants,
}) => {
  const [titleLength, setTitleLength] = useState('');
  const [descrLength, setDescrLength] = useState('');
  const [isRateFixed, setIsRateFixed] = useState(
    editCheckoutState && editCheckoutState.type.toLowerCase() === 'saletoken'
      ? editCheckoutState.fixed.toString()
      : 'true'
  );
  const [tradedCurrencyType, setTradedCurrencyType] = useState('');
  const [isInitialWalletFetch, setIsInitialWalletFetch] = useState(true);
  const [isEstimateMaxFetching, setIsEstimateMaxFetching] = useState(false);
  const [isImageFromFile, setIsImageFromFile] = useState(
    isDuplicating && editCheckoutState && editCheckoutState.image ? false : true
  );
  const [isOpenedDropdownCurrency, setIsOpenedDropdownCurrency] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isOpenedSaleToken, setIsOpenedSaleToken] = useState(false);
  const [visibleSaleToken, setVisibleSaleToken] = useState(false);
  const [form] = Form.useForm();
  const checkType = checkoutType || editCheckoutState.type.toLowerCase();
  const isDonation = checkType === 'donation';
  const { t } = useTranslation();

  useEffect(() => {
    merchantWalletsReset();
    return () => merchantWalletsReset();
  }, []);

  useEffect(() => {
    if (editCheckoutState && merchantWallets.length && isInitialWalletFetch) {
      form.setFieldsValue({ tradedWallet: editCheckoutState.tradedWallet });
      setIsInitialWalletFetch(false);
    }
  }, [merchantWallets, editCheckoutState, isInitialWalletFetch]);

  useEffect(() => {
    if (editCheckoutState) {
      setTitleLength(
        editCheckoutState[isDonation ? 'organizationName' : checkType === 'cart' ? 'cartName' : 'productName'].length ||
          0
      );
      setDescrLength(editCheckoutState.description.length || 0);
      checkType === 'saletoken' && getMerchantWalletsByCurrency(editCheckoutState.tradedCurrency);
    }
  }, [editCheckoutState]);

  const setCurrencies = values => {
    form.setFieldsValue({ currencies: values });
  };

  const getEstimateMax = async () => {
    setIsEstimateMaxFetching(true);
    try {
      const response = await cryptoApi.getEstimateMax(merchantId, {
        ...form.getFieldsValue(['tradedWallet', 'tradedCurrency']),
        checkoutId: editCheckoutState ? editCheckoutState._id : undefined,
      });

      form.setFieldsValue({ max: response.data.max });
      setIsEstimateMaxFetching(false);
    } catch (error) {
      notificationsErrorShow({ message: error.data.message });
      setIsEstimateMaxFetching(false);
    }
  };

  const truncate = function (fullStr = '', strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars);
  };

  const removeValueFromArray = (array, value) => {
    if (!value || !array) return array;

    const index = array.indexOf(value);
    if (index < 0) return array;

    const result = [...array];
    result.splice(index, 1);
    return result;
  };

  const getMerchantWalletsByCurrency = currencyId => {
    const merchantNetwork = merchants.find(merchant => merchant._id === merchantId).typeNetwork;

    merchantWalletsReset();
    const selectedCurrencyType = currencies.find(currency => currency._id === currencyId).currencyType;
    setTradedCurrencyType(selectedCurrencyType);

    getMerchantWallets(merchantId, {
      typeNetwork: merchantNetwork,
      limit: -1,
      currencyIds: `${currencyId}`,
      order: 'DESC',
      positiveBalance: true,
    });
  };

  const CHECKOUT_INITIAL_VALUES = {
    donation: {
      organizationName: editCheckoutState ? editCheckoutState.organizationName : '',
      description: editCheckoutState ? editCheckoutState.description : '',
      currencies: editCheckoutState
        ? editCheckoutState.currencies.filter(currency =>
            activeMerchantCurrencies.find(merchantCurrency => currency === merchantCurrency)
          )
        : activeMerchantCurrencies,
      image: '',
      linkImage:
        isDuplicating && editCheckoutState && editCheckoutState.image
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.image}`
          : '',
      expireTime: editCheckoutState ? editCheckoutState.expireTime : 60,
      logoImage: '',
      linkLogoImage:
        isDuplicating && editCheckoutState && editCheckoutState.logoImage
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.logoImage}`
          : '',
      backgroundColor: editCheckoutState ? editCheckoutState.backgroundColor : '#f1f3f6',
      accentColor: editCheckoutState ? editCheckoutState.accentColor : mainColor,
      linkSite: editCheckoutState ? editCheckoutState.linkSite : '',
    },
    sale: {
      productName: editCheckoutState ? editCheckoutState.productName : '',
      description: editCheckoutState ? editCheckoutState.description : '',
      fiatCurrency: editCheckoutState ? editCheckoutState.fiatCurrency : 'USD',
      price: editCheckoutState ? editCheckoutState.price : '',
      currencies: editCheckoutState
        ? editCheckoutState.currencies.filter(currency =>
            activeMerchantCurrencies.find(merchantCurrency => currency === merchantCurrency)
          )
        : activeMerchantCurrencies,
      image: '',
      linkImage:
        isDuplicating && editCheckoutState && editCheckoutState.image
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.image}`
          : '',
      expireTime: editCheckoutState ? editCheckoutState.expireTime : 60,
      logoImage: '',
      linkLogoImage:
        isDuplicating && editCheckoutState && editCheckoutState.logoImage
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.logoImage}`
          : '',
      backgroundColor: editCheckoutState ? editCheckoutState.backgroundColor : '#f1f3f6',
      accentColor: editCheckoutState ? editCheckoutState.accentColor : mainColor,
      linkSite: editCheckoutState ? editCheckoutState.linkSite : '',
    },
    saletoken: {
      productName: editCheckoutState ? editCheckoutState.productName : '',
      description: editCheckoutState ? editCheckoutState.description : '',
      fiatCurrency: (editCheckoutState && editCheckoutState.fiatCurrency) || 'USD',
      price: editCheckoutState ? editCheckoutState.price : '',
      currencies: editCheckoutState
        ? editCheckoutState.currencies.filter(currency =>
            activeMerchantCurrencies.find(merchantCurrency => currency === merchantCurrency)
          )
        : [],
      image: '',
      linkImage:
        isDuplicating && editCheckoutState && editCheckoutState.image
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.image}`
          : '',
      expireTime: editCheckoutState ? editCheckoutState.expireTime : 60,
      min: editCheckoutState ? editCheckoutState.min : '',
      max: editCheckoutState ? editCheckoutState.max : '',
      fixed: editCheckoutState ? `${editCheckoutState.fixed}` : 'true',
      tradedCurrency: editCheckoutState ? editCheckoutState.tradedCurrency : '',
      tradedWallet: undefined,
      logoImage: '',
      linkLogoImage:
        isDuplicating && editCheckoutState && editCheckoutState.logoImage
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.logoImage}`
          : '',
      backgroundColor: editCheckoutState ? editCheckoutState.backgroundColor : '#f1f3f6',
      accentColor: editCheckoutState ? editCheckoutState.accentColor : mainColor,
      linkSite: editCheckoutState ? editCheckoutState.linkSite : '',
    },
    cart: {
      cartName: editCheckoutState ? editCheckoutState.cartName : '',
      description: editCheckoutState ? editCheckoutState.description : '',
      fiatCurrency: editCheckoutState ? editCheckoutState.fiatCurrency : 'USD',
      currencies: editCheckoutState
        ? editCheckoutState.currencies.filter(currency =>
            activeMerchantCurrencies.find(merchantCurrency => currency === merchantCurrency)
          )
        : activeMerchantCurrencies,
      image: '',
      linkImage:
        isDuplicating && editCheckoutState && editCheckoutState.image
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.image}`
          : '',
      expireTime: editCheckoutState ? editCheckoutState.expireTime : 60,
      logoImage: '',
      linkLogoImage:
        isDuplicating && editCheckoutState && editCheckoutState.logoImage
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.logoImage}`
          : '',
      backgroundColor: editCheckoutState ? editCheckoutState.backgroundColor : '#f1f3f6',
      accentColor: editCheckoutState ? editCheckoutState.accentColor : mainColor,
      linkSite: editCheckoutState ? editCheckoutState.linkSite : '',
    },
  };

  return (
    <div
      className="checkouts-form"
      onClick={() => {
        (isOpenedDropdownCurrency && !visible) || (isOpenedSaleToken && !visibleSaleToken)
          ? setIsOpenedDropdownCurrency(false)
          : console.log('nothing to see');
      }}
    >
      <Form
        initialValues={CHECKOUT_INITIAL_VALUES[checkType]}
        form={form}
        autoComplete="off"
        onFinish={values =>
          createCheckoutHandler({
            ...values,
            image: isImageFromFile ? values.image : undefined,
            linkImage: !isImageFromFile ? values.linkImage : (isDuplicating && values?.linkImage) || undefined,
            backgroundColor: values.backgroundColor || '#f1f3f6',
            accentColor: values.accentColor || mainColor,
            linkSite: values.linkSite ? values.linkSite : undefined,
          })
        }
      >
        {(values, formInstance) => (
          <div className="checkout-set-data">
            <div className="checkout-set-data__name">
              <Form.Item
                name={isDonation ? 'organizationName' : checkType === 'cart' ? 'cartName' : 'productName'}
                rules={[{ required: true, message: t('validation.required') }, whitespaceInputValidator]}
              >
                <Input
                  placeholder={t(`checkouts.steps.2.${[checkType]}.organizationName`)}
                  maxLength={120}
                  onChange={e => setTitleLength(e.target.value.length)}
                />
              </Form.Item>
              <div className="checkout-set-data__chars-limit checkout-set-data__chars-limit_centered">
                {+titleLength}/120
              </div>
            </div>
            <div className="checkout-set-data__description">
              <Form.Item
                name="description"
                rules={[{ required: true, message: t('validation.required') }, whitespaceInputValidator]}
              >
                <TextArea
                  placeholder={t('description')}
                  maxLength={300}
                  onChange={e => setDescrLength(e.target.value.length)}
                />
              </Form.Item>
              <div className="checkout-set-data__chars-limit">{+descrLength}/300</div>
            </div>
            {/* <div className="checkout-set-data__website"> */}

            <Form.Item
              name="linkSite"
              className="checkout-set-data__website"
              label={
                <div className="checkout-set-data__website_label">
                  <span>{t('website')}</span>
                  <Tooltip
                    placement="bottomRight"
                    overlayClassName="checkout-set-data__website_label_tooltip"
                    title={t('websiteTooltipInfo')}
                  >
                    <HelpCircleSVG />
                  </Tooltip>
                </div>
              }
              rules={[
                {
                  type: 'url',
                  message: t('validUrl'),
                },
              ]}
            >
              <Input placeholder={t('website')} />
            </Form.Item>

            {/* </div> */}
            <div className="checkout-set-data__payment-methods">
              <Form.Item
                name="currencies"
                valuePropName="checkoutCurrencies"
                noStyle={true}
                rules={[{ required: true, message: t('validation.required') }]}
              >
                <span>{t('checkouts.paymentMethods')}:</span>
                <div className="checkout-set-data__error-wrap">
                  <CurrencyDropdown
                    setFunc={setCurrencies}
                    multiple={true}
                    allCurrencies={false}
                    individualTokens={true}
                    excludeCurrencies={[formInstance.getFieldValue('tradedCurrency')]}
                    defaultValues={formInstance.getFieldValue('currencies')}
                    activeMerchantCurrencies={activeMerchantCurrencies}
                    isFiltersAvailable={true}
                    isOpenedDropdownCurrency={isOpenedDropdownCurrency}
                    setIsOpenedDropdownCurrency={setIsOpenedDropdownCurrency}
                    visible={visible}
                    setVisible={setVisible}
                  />
                  <span className="checkout-set-data__select-error">{formInstance.getFieldError('currencies')}</span>
                </div>
              </Form.Item>
            </div>
            {checkType === 'saletoken' && (
              <>
                <div className="checkout-set-data__traded-currency">
                  <span>{t('checkouts.receiveMethods')}:</span>
                  <Form.Item
                    name={'tradedCurrency'}
                    valuePropName="checkoutCurrencies"
                    noStyle={true}
                    rules={[{ required: true, message: t('validation.required') }]}
                  >
                    <div className="checkout-set-data__error-wrap">
                      <CurrencyDropdown
                        setFunc={id => {
                          getMerchantWalletsByCurrency(id);
                          form.setFieldsValue({
                            currencies: removeValueFromArray(form.getFieldValue('currencies'), id),
                            tradedCurrency: id,
                            tradedWallet: undefined,
                          });
                        }}
                        allCurrencies={false}
                        individualTokens={true}
                        defaultValues={editCheckoutState ? [form.getFieldValue('tradedCurrency')] : undefined}
                        isOpenedDropdownCurrency={isOpenedSaleToken}
                        setIsOpenedDropdownCurrency={setIsOpenedSaleToken}
                        visible={visibleSaleToken}
                        setVisible={setVisibleSaleToken}
                      />
                      <span className="checkout-set-data__select-error">
                        {formInstance.getFieldError('currencies')}
                      </span>
                    </div>
                  </Form.Item>
                </div>

                <div className="checkout-set-data__merchant-wallet">
                  <span>{t('checkouts.merchantWallet')}:</span>
                  <div className="checkout-set-data__error-wrap">
                    <Form.Item
                      name={'tradedWallet'}
                      noStyle={true}
                      rules={[{ required: true, message: t('validation.required') }]}
                    >
                      <Select
                        placeholder={t('checkouts.metamask.connect')}
                        suffixIcon={<DropdownIcon />}
                        getPopupContainer={trigger => trigger.parentNode}
                        notFoundContent={t('nodata')}
                      >
                        {tradedCurrencyType === 'currency'
                          ? merchantWallets.map(wallet => (
                              <Select.Option value={wallet._id} key={wallet._id}>
                                <div className="checkout-set-data__merchant-wallet-option">
                                  <div>
                                    <div className="checkout-set-data__merchant-wallet-token-name">
                                      <TokenIcon tokenName={wallet.currency} />
                                      {wallet.currency}
                                    </div>
                                    <div className="checkout-set-data__merchant-wallet-balance">
                                      {wallet.balance.value} {wallet.currency}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="checkout-set-data__merchant-wallet-address">
                                      {truncate(wallet.address, 14, '..')}
                                    </div>
                                    <div className="checkout-set-data__merchant-wallet-fiat">
                                      {formatCurrency(wallet.balance.usd)}
                                    </div>
                                  </div>
                                </div>
                              </Select.Option>
                            ))
                          : merchantWallets.map(wallet =>
                              wallet.tokens.length
                                ? wallet.tokens.map(token => (
                                    <Select.Option value={wallet._id} key={token._id}>
                                      <div className="checkout-set-data__merchant-wallet-option">
                                        <div>
                                          <div className="checkout-set-data__merchant-wallet-token-name">
                                            <TokenIcon tokenName={token.currency} />
                                            {token.currency}
                                          </div>
                                          <div className="checkout-set-data__merchant-wallet-balance">
                                            {token.balance.value} {token.currency}
                                          </div>
                                        </div>
                                        <div>
                                          <div className="checkout-set-data__merchant-wallet-address">
                                            {truncate(wallet.address, 14, '..')}
                                          </div>
                                          <div className="checkout-set-data__merchant-wallet-fiat">
                                            {formatCurrency(token.balance.usd)}
                                          </div>
                                        </div>
                                      </div>
                                    </Select.Option>
                                  ))
                                : undefined
                            )}
                      </Select>
                    </Form.Item>
                    <span className="checkout-set-data__select-error">{formInstance.getFieldError('currencies')}</span>
                  </div>
                </div>

                <div className="checkout-set-data__limits">
                  <Form.Item
                    name={'min'}
                    dependencies={['max']}
                    normalize={(value, prevValue) => (!value || +value || +value === 0 ? value : prevValue)}
                    rules={[
                      { required: true, message: t('validation.required') },
                      ({ getFieldValue }) => ({
                        validator: (rule, value) => {
                          if (!value || !getFieldValue('max') || +getFieldValue('max') >= +value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('Min must be less than or equal to max'));
                        },
                      }),
                    ]}
                    className="checkout-set-data__min"
                  >
                    <Input placeholder={'min'} min={0} />
                  </Form.Item>

                  <Form.Item
                    name={'max'}
                    dependencies={['min']}
                    normalize={(value, prevValue) => (!value || +value || +value === 0 ? value : prevValue)}
                    rules={[
                      { required: true, message: t('validation.required') },
                      ({ getFieldValue }) => ({
                        validator: (rule, value) => {
                          if (!value || !getFieldValue('min') || +getFieldValue('min') <= +value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error(t('checkouts.error')));
                        },
                      }),
                    ]}
                    className="checkout-set-data__max"
                  >
                    <Input placeholder={'max'} min={0} />
                  </Form.Item>
                  <div
                    className={`checkout-set-data__get-max checkout-set-data__get-max-${identity} ${
                      (!formInstance.getFieldValue('tradedCurrency') || !formInstance.getFieldValue('tradedWallet')) &&
                      'checkout-set-data__get-max_disabled'
                    }`}
                    onClick={() =>
                      formInstance.getFieldValue('tradedCurrency') &&
                      formInstance.getFieldValue('tradedWallet') &&
                      getEstimateMax()
                    }
                  >
                    {!isEstimateMaxFetching ? (
                      'MAX'
                    ) : (
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    )}
                  </div>
                </div>

                <div className="checkout-set-data__rate-wrapper">
                  <Form.Item name={'fixed'} className="checkout-set-data__rate">
                    <Radio.Group
                      onChange={e => {
                        setIsRateFixed(e.target.value);
                      }}
                    >
                      <Radio value="true">{t('checkouts.fixed')}</Radio>
                      <Radio value="false">{t('checkouts.market')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>

                {identity !== 'finvaro' && (
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSe89Dv3UEWij0XdZv1QO68V5RRLe_0QOvp7cgqhwxyPscCGwA/viewform"
                    target="__blank"
                    className={`checkout-set-data__add-token checkout-set-data__add-token-${identity}`}
                  >
                    {t('checkouts.addToken')}
                  </a>
                )}
              </>
            )}
            {((checkType === 'saletoken' && isRateFixed === 'true') ||
              checkType === 'sale' ||
              checkType === 'cart') && (
              <div className="checkout-set-data__sale">
                {checkType !== 'cart' && (
                  <Form.Item
                    name={'price'}
                    normalize={(value, prevValue) => {
                      const split = `${value}`.split('.');

                      let result;
                      if (!split || !split[1] || split[1].length <= 18) {
                        result = value;
                      } else {
                        result = `${split[0]}.${split[1].substring(0, 18)}`;
                      }

                      return !value || +value || +value === 0 ? result : prevValue;
                    }}
                    rules={[
                      { required: isRateFixed === 'true', message: t('validation.required') },
                      ({ getFieldValue }) => ({
                        validator: (rule, value) => {
                          if (value > 0) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error(t('checkouts.priceError')));
                        },
                      }),
                    ]}
                    className="checkout-set-data__number"
                  >
                    <Input placeholder={t('checkouts.checkoutForm.enterPrice')} min={0} />
                  </Form.Item>
                )}

                <Form.Item name={'fiatCurrency'} className="checkout-set-data__currency">
                  <Select getPopupContainer={trigger => trigger.parentNode}>
                    <Select.Option value="AED">د.إ AED</Select.Option>
                    <Select.Option value="AUD">A$ AUD</Select.Option>
                    <Select.Option value="BRL">R$ BRL</Select.Option>
                    <Select.Option value="CNY">¥ CNY</Select.Option>
                    <Select.Option value="EUR">€ EUR</Select.Option>
                    <Select.Option value="GBP">£ GBP</Select.Option>
                    <Select.Option value="NGN">₦ NGN</Select.Option>
                    <Select.Option value="RUB">₽ RUB</Select.Option>
                    <Select.Option value="TRY">₺ TRY</Select.Option>
                    <Select.Option value="UAH">₴ UAH</Select.Option>
                    <Select.Option value="USD">$ USD</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            )}
            <div className="checkout-set-data__flex">
              <Form.Item
                name={'expireTime'}
                className="checkout-set-data__expireTime"
                label={t('checkouts.timeExpiration')}
              >
                <Select getPopupContainer={trigger => trigger.parentNode}>
                  <Select.Option value={10}>10 {t('checkouts.minutes')}</Select.Option>
                  <Select.Option value={30}>30 {t('checkouts.minutes')}</Select.Option>
                  <Select.Option value={60}>60 {t('checkouts.minutes')}</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className="checkout-set-data__image">
              <span>Checkout photo:</span>
              <Tabs
                className="checkout-set-data__image-tabs"
                defaultActiveKey={isDuplicating ? '2' : '1'}
                onChange={tabId => (tabId === '1' ? setIsImageFromFile(true) : setIsImageFromFile(false))}
              >
                <Tabs.TabPane tab={t('checkouts.upload')} key="1">
                  <Form.Item name={'image'} className="checkout-set-data__currency">
                    <UploadComponent
                      handleChange={value => {
                        form.setFieldsValue({
                          image: value,
                        });
                      }}
                      image={editCheckoutState && editCheckoutState.image}
                    />
                  </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('checkouts.specify')} key="2">
                  <Form.Item name="linkImage">
                    <Input placeholder={t('checkouts.specifyLink')} />
                  </Form.Item>
                </Tabs.TabPane>
              </Tabs>
            </div>

            <div className="checkout-set-data__image">
              <span>Checkout Logo:</span>
              <Tabs
                className="checkout-set-data__image-tabs"
                defaultActiveKey={isDuplicating ? '2' : '1'}
                onChange={tabId => (tabId === '1' ? setIsImageFromFile(true) : setIsImageFromFile(false))}
              >
                <Tabs.TabPane tab={t('checkouts.upload')} key="1">
                  <Form.Item name={'logoImage'} className="checkout-set-data__currency">
                    <UploadComponent
                      handleChange={value => {
                        form.setFieldsValue({
                          logoImage: value,
                        });
                      }}
                      image={editCheckoutState && editCheckoutState.logoImage}
                    />
                  </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('checkouts.specify')} key="2">
                  <Form.Item name="linkLogoImage">
                    <Input placeholder={t('checkouts.specifyLink')} />
                  </Form.Item>
                </Tabs.TabPane>
              </Tabs>
            </div>

            <div className="checkout-set-data__color">
              <span>Background color:</span>
              <Form.Item name="backgroundColor" noStyle={true}>
                <input
                  type="color"
                  style={{ all: 'revert' }}
                  defaultValue={editCheckoutState ? editCheckoutState.backgroundColor : '#f1f3f6'}
                />
              </Form.Item>
            </div>

            <div className="checkout-set-data__color">
              <span>Accent color:</span>
              <Form.Item name="accentColor" noStyle={true}>
                <input
                  type="color"
                  style={{ all: 'revert' }}
                  defaultValue={editCheckoutState ? editCheckoutState.accentColor : mainColor}
                />
              </Form.Item>
            </div>

            <div className="checkout-set-data__button-container">
              {prevStep && (
                <Button type="secondary" className="checkouts-list__button" onClick={prevStep}>
                  {t('back')}
                </Button>
              )}
              <Button
                type="primary"
                className="checkouts-list__button"
                htmlType="submit"
                disabled={ifCreateFetching || ifPatchFetching}
                loading={ifCreateFetching || ifPatchFetching}
              >
                {editCheckoutState ? t('save') : t('create')}
              </Button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  typeNetwork: state.networkFilter,
  merchantWallets: state.merchantWallets.data,
  currencies: state.currencies.data,
  merchants: state.merchants.data,
});

const mapDispatchToProps = dispatch => ({
  merchantWalletsReset: () => dispatch(merchantWalletsReset()),
  getMerchantWallets: getMerchantWallets(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetData);
