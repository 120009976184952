import { NFT_WALLETS_GET, NFT_WALLETS_SET, NFT_TRANSACTIONS_GET, NFT_TRANSACTIONS_SET } from '../actions/nft';

export default function reducer(
  state = {
    wallets: {
      data: [],
      page: null,
      pages: null,
      countItem: null,
      fetching: false,
    },
    transactions: {
      data: [],
      page: null,
      pages: null,
      countItem: null,
      fetching: false,
    },
  },
  action
) {
  switch (action.type) {
    case NFT_WALLETS_GET:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          fetching: true,
        },
      };
    case NFT_WALLETS_SET:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          data: action.payload.entities,
          page: action.payload.page,
          pages: action.payload.pages,
          countItem: action.payload.countItem,
          fetching: false,
        },
      };
    case NFT_TRANSACTIONS_GET:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          fetching: true,
        },
      };
    case NFT_TRANSACTIONS_SET:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: action.payload.entities,
          page: action.payload.page,
          pages: action.payload.pages,
          countItem: action.payload.countItem,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
