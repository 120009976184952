import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Modal, Popover } from 'antd';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Copy } from '../../img/default-svg/copy.svg';
import '../../layout/Modal.scss';
import { identity } from '../../utils/getIdentity';
import './style.scss'

export const ReplenishModal = ({ walletAddress, walletCurrency, isReplenishModalOpen, handleReplenishButton }) => {
  const [showPopover, setShowPopover] = useState(false);
  const timer = useRef(null);
  const { t } = useTranslation();

  const copyHandler = () => {
    navigator.clipboard.writeText(walletAddress);
    setShowPopover(true);
    timer.current = setTimeout(() => setShowPopover(false), 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      timer.current = null;
    };
  });

  const handleCancel = () => {
    handleReplenishButton('', '', false);
  };

  return (
    <Modal
      title={t('merchants.replenish')}
      visible={isReplenishModalOpen}
      onCancel={handleCancel}
      footer={null}
      className="modal"
    >
      <div className='replenish-modal'>
      <Popover title="" content={t('copied')} trigger="click" visible={showPopover} className="detailed-checkout__popover">
        <div className="qr-block__address" onClick={copyHandler}>
          <div>{`${walletCurrency} ${t('address')}`}</div>
          <div className={`qr-block__copy-block qr-block__copy-block-${identity}`}>
            <div>{`${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}`}</div>
            <Copy />
          </div>
        </div>
      </Popover>
      <QRCode className="two-factor-qr" value={walletAddress} size={224} />
      <Row className="modal__buttons-wrapper" justify="center">
        <Col>
          <Button type="primary" className={`modal__button modal__button-create modal__button modal__button-create-${identity} modal__button-create=${identity}`} onClick={handleCancel}>
            {t('ok')}
          </Button>
        </Col>
      </Row>
      </div>
    </Modal>
  );
};

export default ReplenishModal;
