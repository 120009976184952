import {
  // GET_CRYPTO_CURRENCY_VOLUME
  GET_CRYPTO_CURRENCY_VOLUME_REQUEST,
  GET_CRYPTO_CURRENCY_VOLUME_SUCCESS,
  GET_CRYPTO_CURRENCY_VOLUME_FAIL,
  // GET_CRYPTO_CURRENCY_QUANTITY
  GET_CRYPTO_CURRENCY_QUANTITY_REQUEST,
  GET_CRYPTO_CURRENCY_QUANTITY_SUCCESS,
  GET_CRYPTO_CURRENCY_QUANTITY_FAIL,
  // GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE
  GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_REQUEST,
  GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_SUCCESS,
  GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_FAIL,
  // GET_NEW_USERS
  GET_NEW_USERS_REQUEST,
  GET_NEW_USERS_SUCCESS,
  GET_NEW_USERS_FAIL,
  // GET_WITHDRAW_TRANSACTIONS
  GET_WITHDRAW_TRANSACTIONS_REQUEST,
  GET_WITHDRAW_TRANSACTIONS_SUCCESS,
  GET_WITHDRAW_TRANSACTIONS_FAIL,
  // GET_REPLENISH_TRANSACTIONS
  GET_REPLENISH_TRANSACTIONS_REQUEST,
  GET_REPLENISH_TRANSACTIONS_SUCCESS,
  GET_REPLENISH_TRANSACTIONS_FAIL,
  // GET_SYSTEM_FEE_TRANSACTIONS
  GET_SYSTEM_FEE_TRANSACTIONS_REQUEST,
  GET_SYSTEM_FEE_TRANSACTIONS_SUCCESS,
  GET_SYSTEM_FEE_TRANSACTIONS_FAIL,
  GET_USER_FEE_TRANSACTIONS_REQUEST,
  GET_USER_FEE_TRANSACTIONS_SUCCESS,
  GET_USER_FEE_TRANSACTIONS_FAIL,
} from '../../actions/adminPanel/adminDashboard';

const createState = () => {
  return { data: null, fetching: false, error: null };
};

const defaultState = {
  getCryptoCurrencyVolume: createState(),
  getCryptoCurrencyQuantity: createState(),
  getCryptoCurrencyVolumeTrxByType: { ...createState(), data: { replenishment: [], withdrawal: [], systemfee: [] } },
  getNewUsers: createState(),
  getWithdrawTransactions: createState(),
  getReplenishTransactions: createState(),
  getSystemFeeTransactions: createState(),
  getUserFeeTransactions: createState(),
};

const adminDashboard = (state = defaultState, { type, payload }) => {
  switch (type) {
    // GET_CRYPTO_CURRENCY_VOLUME
    case GET_CRYPTO_CURRENCY_VOLUME_REQUEST:
      return {
        ...state,
        getCryptoCurrencyVolume: { ...state.getCryptoCurrencyVolume, fetching: true },
      };
    case GET_CRYPTO_CURRENCY_VOLUME_SUCCESS:
      return {
        ...state,
        getCryptoCurrencyVolume: { ...state.getCryptoCurrencyVolume, fetching: false, data: payload.data },
      };
    case GET_CRYPTO_CURRENCY_VOLUME_FAIL:
      return {
        ...state,
        getCryptoCurrencyVolume: { ...state.getCryptoCurrencyVolume, fetching: false, error: payload },
      };
    // GET_CRYPTO_CURRENCY_QUANTITY
    case GET_CRYPTO_CURRENCY_QUANTITY_REQUEST:
      return {
        ...state,
        getCryptoCurrencyQuantity: { ...state.getCryptoCurrencyQuantity, fetching: true },
      };
    case GET_CRYPTO_CURRENCY_QUANTITY_SUCCESS:
      return {
        ...state,
        getCryptoCurrencyQuantity: { ...state.getCryptoCurrencyQuantity, fetching: false, data: payload.data },
      };
    case GET_CRYPTO_CURRENCY_QUANTITY_FAIL:
      return {
        ...state,
        getCryptoCurrencyQuantity: { ...state.getCryptoCurrencyQuantity, fetching: false, error: payload },
      };
    // GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE
    case GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_REQUEST:
      return {
        ...state,
        getCryptoCurrencyVolumeTrxByType: { ...state.getCryptoCurrencyVolumeTrxByType, fetching: true },
      };

    case GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_SUCCESS:
      return {
        ...state,
        getCryptoCurrencyVolumeTrxByType: {
          ...state.getCryptoCurrencyVolumeTrxByType,
          fetching: false,
          data: { ...state.getCryptoCurrencyVolumeTrxByType.data, [payload.name]: [...payload.data] },
        },
      };

    case GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_FAIL:
      return {
        ...state,
        getWithdrawTransactions: { ...state.getWithdrawTransactions, fetching: false, error: payload },
      };

    // GET_NEW_USERS
    case GET_NEW_USERS_REQUEST:
      return {
        ...state,
        getNewUsers: { ...state.getNewUsers, fetching: true },
      };
    case GET_NEW_USERS_SUCCESS:
      return {
        ...state,
        getNewUsers: { ...state.getNewUsers, fetching: false, data: payload.data },
      };
    case GET_NEW_USERS_FAIL:
      return {
        ...state,
        getNewUsers: { ...state.getNewUsers, fetching: false, error: payload },
      };
    // GET_WITHDRAW_TRANSACTIONS
    case GET_WITHDRAW_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getWithdrawTransactions: { ...state.getWithdrawTransactions, fetching: true },
      };
    case GET_WITHDRAW_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getWithdrawTransactions: { ...state.getWithdrawTransactions, fetching: false, data: payload.data },
      };
    case GET_WITHDRAW_TRANSACTIONS_FAIL:
      return {
        ...state,
        getWithdrawTransactions: { ...state.getWithdrawTransactions, fetching: false, error: payload },
      };
    // GET_REPLENISH_TRANSACTIONS
    case GET_REPLENISH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getReplenishTransactions: { ...state.getReplenishTransactions, fetching: true },
      };
    case GET_REPLENISH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getReplenishTransactions: { ...state.getReplenishTransactions, fetching: false, data: payload.data },
      };
    case GET_REPLENISH_TRANSACTIONS_FAIL:
      return {
        ...state,
        getReplenishTransactions: { ...state.getReplenishTransactions, fetching: false, error: payload },
      };
    // GET_SYSTEM_FEE_TRANSACTIONS
    case GET_SYSTEM_FEE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getSystemFeeTransactions: { ...state.getSystemFeeTransactions, fetching: true },
      };
    case GET_SYSTEM_FEE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getSystemFeeTransactions: { ...state.getSystemFeeTransactions, fetching: false, data: payload.data },
      };
    case GET_SYSTEM_FEE_TRANSACTIONS_FAIL:
      return {
        ...state,
        getSystemFeeTransactions: { ...state.getSystemFeeTransactions, fetching: false, error: payload },
      };
    // GET_USER_FEE_TRANSACTIONS
    case GET_USER_FEE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getUserFeeTransactions: { ...state.getUserFeeTransactions, fetching: true },
      };
    case GET_USER_FEE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getUserFeeTransactions: { ...state.getUserFeeTransactions, fetching: false, data: payload.data },
      };
    case GET_USER_FEE_TRANSACTIONS_FAIL:
      return {
        ...state,
        getUserFeeTransactions: { ...state.getUserFeeTransactions, fetching: false, error: payload },
      };

    default:
      return state;
  }
};

export default adminDashboard;
