const formatCurrency = (amount, fiatCurrency = 'USD') => {
  try {
    return amount || amount === 0
      ? amount.toLocaleString('en-US', {
          style: 'currency',
          currency: fiatCurrency,
        })
      : `${amount} ${fiatCurrency}`;
  } catch (err) {
    return `${amount} ${fiatCurrency}`;
  }
};

export default formatCurrency;
