import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import SwapNewCard from '../components/SwapNew/SwapNewCard';
import SwapForm from '../components/SwapNew/SwapForm';
import SwapWalletsList from '../components/SwapNew/SwapWalletsList';
import SwapOffers from '../components/SwapOffers';
import SwapConfirm from '../components/SwapConfirm';
import SwapBack from '../components/SwapNew/SwapBack';
import Button from '../components/Button';
import SwapNetworkWarn from '../components/SwapNew/SwapNetworkWarn';
import { ReactComponent as WalletIcon } from '../img/default-svg/wallet.svg';
import { ReactComponent as WalletIconFinvaro } from '../img/finvaro/wallet.svg';
import { ReactComponent as WalletIconClarnium } from '../img/clarnium/wallet.svg';
import { ReactComponent as WarningIcon } from '../img/default-svg/info-circle-warning.svg';
import uploadFile from '../img/cpay-svg/upload.svg';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import {
  getSwapOffers,
  updateSwapOffers,
  swapOffersUpdateApply,
  swapOffersReset,
  swapExchangeWalletsReset,
  resetExchangeError,
  getSwapTokens,
  getReceiveSwap,
  swapReceiveReset,
  resetStableSwapError,
  getSwapExchangeWallets,
} from '../redux/actions/swap';
import { useTranslation } from 'react-i18next';
import { getMerchantBalances } from '../redux/actions/merchantData';
import { getWithdrawWallets, checkAutosignStatus, uploadNewAutosign } from '../redux/actions/withdraw';
import { getMerchantWallets } from '../redux/actions/merchantWallets';
import { compareNewTokensArrays } from '../utils/compareNewTokensArrays';
import UploadAutosignWrapper from '../components/Withdraw/UploadAutosignWrapper';
import { identity } from "../utils/getIdentity";
const SwapNew = ({
  exchangeWallets,
  exchangeWalletsFetching,
  swapOffers,
  updatedOffers,
  getSwapOffers,
  swapOffersReset,
  swapOffersFetching,
  swapWalletsReset,
  updateSwapOffers,
  swapOffersUpdateApply,
  swapOffersUpdating,
  currenciesArray,
  resetExchangeError,
  getSwapTokens,
  swapTokens,
  getReceiveSwap,
  priceForSwap,
  swapReceiveReset,
  resetStableSwapError,
  merchantId,
  networkFilter,
  getMerchantWallets,
  getSwapExchangeWallets,
  checkAutosignStatus,
  uploadNewAutosign,
  autosignStep,
  autosignStatus,
  // getMerchantBalances,
  // getWithdrawWallets,
}) => {
  const [socket, setSocket] = useState(undefined);
  const [isFetched, setIsFetched] = useState(false);
  const [walletFromId, setWalletFromId] = useState('');
  const [walletToId, setWalletToId] = useState('');
  const [selectedWalletBalance, setSelectedWalletBalance] = useState(0);
  const [showBalanceWarning, setShowBalanceWarning] = useState(false);
  const [exchangeWalletsFrom, exchangeWalletsTo] = exchangeWallets;
  const [showSwapOffers, setShowSwapOffers] = useState(false);
  const [swapOffersSort, setSwapOffersSort] = useState('rate');
  const [swapOffersType, setSwapOffersType] = useState('');
  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedSwapFixed, setSelectedSwapFixed] = useState(undefined);
  const [selectedPartnerRateId, setSelectedPartnerRateId] = useState('');
  const [summaryRate, setSummaryRate] = useState(0);
  const [swapAmountFromError, setSwapAmountFromError] = useState(null);
  const [exchangeFields, setExchangeFields] = useState([
    {
      index: 0,
      value: 0,
      selectedCurrency: '',
      chainSymbol: '',
      name: '',
    },
    {
      index: 1,
      value: 0,
      selectedCurrency: '',
      chainSymbol: '',
      name: '',
    },
  ]);

  const [polkaDotLimitWarning, setPolkaDotLimitWarning] = useState(false);
  const [dataIsFrozen, setDataIsFrozen] = useState(false);
  const [newCurrencyForSwap, setNewCurrencyForSwap] = useState([]);
  const [showSwapConfirmModal, setShowSwapConfirmModal] = useState(false);

  const [isOpenAutosignModal, setIsOpenAutosignModal] = useState(false);
  const [isShowAutosignModal, setIsShowAutosignModal] = useState(false);
  const [password, setPassword] = useState('');
  const [sign, setSign] = useState('');

  const ratesFetchTimer = useRef();
  const history = useHistory();
  const youSendAmountRef = useRef(null);
  const location = useLocation();

  const [currency, setCurrency] = useState('');

  const defaultFilterValues = {
    order: 'DESC',
    search: '',
    limit: 5,
    currencyIds: currenciesArray.filter(currency => currency.currencyType === 'currency').map(currency => currency._id),
    typeNetwork: networkFilter,
  };

  const isSwapStable = location.pathname === '/swap/create/stable';

  useEffect(() => {
    getSwapTokens();
  }, []);

  useEffect(() => {
    setNewCurrencyForSwap(compareNewTokensArrays(swapTokens, currenciesArray));
  }, [currenciesArray, swapTokens]);

  const [currencyFieldIsEdited, setCurrencyFieldIsEdited] = useState('none');

  useEffect(
    () => () => {
      ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current);
      swapOffersReset();
      swapWalletsReset();
      swapReceiveReset();
    },
    []
  );

  useEffect(() => {
    if (swapOffers && swapOffers.length) {
      setExchangeFields([
        {
          ...exchangeFields[0],
        },
        {
          ...exchangeFields[1],
          value: isSwapStable ? priceForSwap?.amountToBeReceived : swapOffers[0].toAmount,
        },
      ]);
    }
  }, [swapOffers, priceForSwap]);

  useEffect(() => {
    if (
      walletFromId &&
      exchangeFields[0].value &&
      +exchangeFields[0].value &&
      `${exchangeFields[0].value}`.split('').pop() !== '.'
    ) {
      setShowBalanceWarning(+exchangeFields[0].value > selectedWalletBalance ? true : false);
    }
  }, [exchangeFields, selectedWalletBalance, walletFromId]);

  useEffect(() => {
    if (exchangeFields[0].value && +exchangeFields[0].value && `${exchangeFields[0].value}`.split('').pop() !== '.') {
      setShowBalanceWarning(+exchangeFields[0].value > selectedWalletBalance ? true : false);
      if (isSwapStable) {
        setFetchTimer(getReceiveSwap, {
          sourceChain: exchangeFields[0].chainSymbol,
          destinationChain: exchangeFields[1].chainSymbol,
          amount: exchangeFields[0].value,
          fromToken: exchangeFields[0].name,
          toToken: exchangeFields[1].name,
        });
      } else {
        setFetchTimer(getSwapOffers, {
          fromId: exchangeFields[0].selectedCurrency,
          toId: exchangeFields[1].selectedCurrency,
          amount: exchangeFields[0].value,
          sort: swapOffersSort || undefined,
          type: swapOffersType || undefined,
        });
      }
    }
  }, [
    exchangeFields[0].value,
    exchangeFields[0].selectedCurrency,
    exchangeFields[0]?.contractAddress,
    exchangeFields[1]?.contractAddress,
    exchangeFields[1].selectedCurrency,
    swapOffersSort,
    swapOffersType,
  ]);

  useEffect(() => {
    if (!exchangeFields[0].value && exchangeFields[0].selectedCurrency && exchangeFields[1].selectedCurrency) {
      setExchangeFields([
        {
          ...exchangeFields[0],
        },
        {
          ...exchangeFields[1],
          value: 0,
        },
      ]);
    }
  }, [exchangeFields[0].value]);

  useEffect(() => {
    if (exchangeFields[0].value === 0 || exchangeFields[0].value === '') setPolkaDotLimitWarning(false);
    if (swapAmountFromError) setSwapAmountFromError(null);
  }, [exchangeFields[0].value]);

  useEffect(() => {
    if (exchangeWalletsFrom && exchangeWalletsFrom.entities.length) {
      setWalletFromId(exchangeWalletsFrom.entities[0]._id);
      if (exchangeWalletsFrom.entities[0].tokens.length) {
        setSelectedWalletBalance(+exchangeWalletsFrom.entities[0].tokens[0].balance.value);
      } else {
        setSelectedWalletBalance(+exchangeWalletsFrom.entities[0].balance.value);
      }
    }
  }, [exchangeWalletsFrom]);

  useEffect(() => {
    if (exchangeWalletsTo && exchangeWalletsTo.entities.length) {
      setWalletToId(exchangeWalletsTo.entities[0]._id);
    }
  }, [exchangeWalletsTo]);

  const setFetchTimer = async (action, value) => {
    ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current);
    ratesFetchTimer.current = setTimeout(() => action(value), 2000);
  };
  const { t } = useTranslation();

  const handleErrorMsgClick = () => {
    setSelectedPartner(undefined);
    setSelectedPartnerRateId('');
    resetExchangeError();
    resetStableSwapError();
    youSendAmountRef.current = null;
    if (isSwapStable) {
      setShowSwapConfirmModal(false);
      setDataIsFrozen(true);
    }
  };

  useEffect(() => {
    if (swapAmountFromError) {
      youSendAmountRef.current = exchangeFields[0].value;
      setExchangeFields([{ ...exchangeFields[0] }, { ...exchangeFields[1] }]);
    }
  }, [swapAmountFromError]);

  useEffect(() => {
    if (polkaDotLimitWarning) setShowSwapOffers(false);
  }, [polkaDotLimitWarning]);

  useEffect(() => {
    socket && socket.disconnect();
    setSocket(undefined);
    createSocket(merchantId);
  }, [merchantId]);

  // console.log('exchangeFields[0].selectedCurrency,...exchangeFields[1].selectedCurrency', exchangeFields[0].selectedCurrency, exchangeFields[1].selectedCurrency)

  const createSocket = merchantId => {
    const io = window.io;
    const socket = io(process.env.REACT_APP_API_URL, {
      allowEIO3: true,
      withCredentials: true,
    });
    socket.on('connect', () => {
      socket.emit('sign-in-merchant', { token: localStorage.getItem('authToken'), merchantId });
      setSocket(socket);
    });
    socket.on('updatedBalance', () => {
      const { currencyIds } = defaultFilterValues;
      if (merchantId) {
        // getMerchantWallets(merchantId, {
        //   ...defaultFilterValues,
        //   currencyIds: currencyIds.join(',') || undefined,
        //   typeWallet: "merchant",
        //   page: 1,
        //   typeNetwork: networkFilter,
        //   showTokens: false,
        // }, true);
        // getMerchantBalances(merchantId, networkFilter, true);
        // getWithdrawWallets(merchantId, currency, networkFilter, true, true);
        getSwapExchangeWallets(merchantId, {
          limit: -1,
          typeWallet: 'merchant',
          typeNetwork: networkFilter,
          currencyIds: [`${exchangeFields[0].selectedCurrency}`, `${exchangeFields[1].selectedCurrency}`] || undefined,
        });
      }
    });
    socket.on('disconnect', function () {});
  };

  const uploadAutosign = data => {
    const { password, sign } = data;
    setPassword(password || undefined);
    setSign(sign || undefined);

    setIsOpenAutosignModal(false);
  };

  useEffect(() => {
    if (walletFromId) {
      checkAutosignStatus(merchantId, walletFromId);
    }
  }, [merchantId, walletFromId]);

  useEffect(() => {
    setIsShowAutosignModal(autosignStatus);
  }, [autosignStatus]);

  return !isSwapStable && selectedPartner && selectedSwapFixed !== undefined ? (
    <>
      <SwapBack
        action={() => {
          // setSwapOffersType('');
          setSelectedPartner(undefined);
          setSelectedPartnerRateId('');
        }}
        maxWidth={790}
      />
      <SwapConfirm
        fromId={walletFromId}
        toId={walletToId}
        currencyFromId={exchangeFields[0].selectedCurrency}
        currencyToId={exchangeFields[1].selectedCurrency}
        amount={youSendAmountRef.current ? youSendAmountRef.current : exchangeFields[0].value}
        summaryRate={summaryRate}
        setSummaryRate={setSummaryRate}
        partner={selectedPartner}
        fixed={selectedSwapFixed}
        selectedPartnerRateId={selectedPartnerRateId}
        swapOffers={swapOffers}
        priceForSwap={priceForSwap}
        updatedOffers={updatedOffers}
        swapOffersReset={swapOffersReset}
        swapReceiveReset={swapReceiveReset}
        setSwapOffersType={setSwapOffersType}
        setSelectedPartner={setSelectedPartner}
        setSelectedPartnerRateId={setSelectedPartnerRateId}
        setShowSwapOffers={setShowSwapOffers}
        updateSwapOffers={updateSwapOffers}
        swapOffersUpdateApply={swapOffersUpdateApply}
        swapOffersUpdating={swapOffersUpdating}
        sort={swapOffersSort || undefined}
        type={swapOffersType || undefined}
        handleErrorMsgClick={handleErrorMsgClick}
        setSwapAmountFromError={setSwapAmountFromError}
        sign={sign}
        password={password}
      />
    </>
  ) : isSwapStable && showSwapConfirmModal ? (
    <>
      <SwapBack
        action={() => {
          // history.push('/swap/create/stable');
          setShowSwapConfirmModal(false);
          setDataIsFrozen(true);
          setExchangeFields([
            {
              ...exchangeFields[0],
            },
            {
              ...exchangeFields[1],
            },
          ]);
        }}
        maxWidth={790}
      />
      <SwapConfirm
        fromId={walletFromId}
        toId={walletToId}
        currencyFromId={exchangeFields[0].selectedCurrency}
        currencyToId={exchangeFields[1].selectedCurrency}
        amount={youSendAmountRef.current ? youSendAmountRef.current : exchangeFields[0].value}
        summaryRate={summaryRate}
        setSummaryRate={setSummaryRate}
        partner={selectedPartner}
        fixed={selectedSwapFixed}
        selectedPartnerRateId={selectedPartnerRateId}
        swapOffers={swapOffers}
        priceForSwap={priceForSwap}
        updatedOffers={updatedOffers}
        swapOffersReset={swapOffersReset}
        swapReceiveReset={swapReceiveReset}
        setSwapOffersType={setSwapOffersType}
        setSelectedPartner={setSelectedPartner}
        setSelectedPartnerRateId={setSelectedPartnerRateId}
        setShowSwapOffers={setShowSwapOffers}
        updateSwapOffers={updateSwapOffers}
        swapOffersUpdateApply={swapOffersUpdateApply}
        swapOffersUpdating={swapOffersUpdating}
        sort={swapOffersSort || undefined}
        type={swapOffersType || undefined}
        handleErrorMsgClick={handleErrorMsgClick}
        setSwapAmountFromError={setSwapAmountFromError}
        isSwapStable={isSwapStable}
        exchangeFields={exchangeFields}
        newCurrencyForSwap={newCurrencyForSwap}
        password={password}
        sign={sign}
      />
    </>
  ) : (
    <>
      <div className="swap-wallets">
        <SwapNetworkWarn />
        <SwapBack
          action={() => {
            history.push('/swap');
          }}
          maxWidth={790}
        />
        <SwapNewCard title={t('swap.steps.stepOne')} className="swap-exchange-wrap">
          {!isShowAutosignModal && (
            <div className="withdraw__autosign" onClick={() => setIsOpenAutosignModal(true)}>
              <img src={uploadFile} alt="upload" />
              <span>{!sign ? t('withdraw.uploadAutosign') : `${sign.slice(0, 15)}...`}</span>
            </div>
          )}
          <SwapForm
            exchangeFields={exchangeFields}
            setExchangeFields={setExchangeFields}
            walletFromId={walletFromId}
            exchangeWalletsFrom={exchangeWalletsFrom}
            swapOffers={swapOffers}
            priceForSwap={priceForSwap}
            swapOffersFetching={swapOffersFetching}
            swapOffersReset={swapOffersReset}
            swapReceiveReset={swapReceiveReset}
            swapOffersUpdating={swapOffersUpdating}
            polkaDotLimitWarning={polkaDotLimitWarning}
            setPolkaDotLimitWarning={setPolkaDotLimitWarning}
            dataIsFrozen={dataIsFrozen}
            setDataIsFrozen={setDataIsFrozen}
            swapAmountFromError={swapAmountFromError}
            setSwapAmountFromError={setSwapAmountFromError}
            youSendAmountRef={youSendAmountRef}
            setCurrencyFieldIsEdited={setCurrencyFieldIsEdited}
            currencyFieldIsEdited={currencyFieldIsEdited}
            isSwapStable={isSwapStable}
            newCurrencyForStableSwap={newCurrencyForSwap}
            password={password}
            sign={sign}
          />
        </SwapNewCard>
        {showBalanceWarning && (
          <div className="swap-wallets__warning">
            <WarningIcon />
            {t('swap.newSwap.warning')}
          </div>
        )}
        <SwapNewCard
          title={t('swap.steps.stepTwo')}
          buttonComponent={
            exchangeWalletsFrom &&
            exchangeWalletsFrom.entities.length > 1 && (
              <div
                className="swap-new-card__button"
                onClick={() => {
                  setWalletFromId('');
                  setSelectedWalletBalance(0);
                }}
              >
                {identity === 'finvaro' ? <WalletIconFinvaro /> : identity === 'clarnium' ? <WalletIconClarnium /> : <WalletIcon />}
                {t('swap.newSwap.change')}
              </div>
            )
          }
        >
          <SwapWalletsList
            exchangeWallets={exchangeWalletsFrom}
            activeWalletId={walletFromId}
            handleSelect={setWalletFromId}
            setSelectedWalletBalance={setSelectedWalletBalance}
            exchangeWalletsFetching={exchangeWalletsFetching}
            currenciesArray={!isSwapStable ? currenciesArray : newCurrencyForSwap}
            currencyFieldIsEdited={currencyFieldIsEdited}
            currencyNumber={0}
          />
        </SwapNewCard>

        <SwapNewCard
          title={t('swap.steps.stepThree')}
          buttonComponent={
            exchangeWalletsTo &&
            exchangeWalletsTo.entities.length > 1 && (
              <div className="swap-new-card__button" onClick={() => !swapOffersUpdating && setWalletToId('')}>
                {identity === 'finvaro' ? <WalletIconFinvaro /> : identity === 'clarnium' ? <WalletIconClarnium /> : <WalletIcon />}
                {t('swap.newSwap.change')}
              </div>
            )
          }
        >
          <SwapWalletsList
            exchangeWallets={exchangeWalletsTo}
            activeWalletId={walletToId}
            handleSelect={setWalletToId}
            exchangeWalletsFetching={exchangeWalletsFetching}
            swapOffersUpdating={swapOffersUpdating}
            currenciesArray={!isSwapStable ? currenciesArray : newCurrencyForSwap}
            currencyFieldIsEdited={currencyFieldIsEdited}
            currencyNumber={1}
          />
        </SwapNewCard>
        {!showSwapOffers && !isSwapStable && (
          <div className="swap-wallets__button">
            <Button
              disabled={
                !walletFromId ||
                !walletToId ||
                !+exchangeFields[0].value ||
                exchangeFields[1].value === 0 ||
                polkaDotLimitWarning
              }
              onClick={() => setShowSwapOffers(true)}
            >
              {t('swap.newSwap.offers')}
            </Button>
          </div>
        )}

        {isSwapStable && (
          <div className="swap-wallets__button">
            <Button
              disabled={
                !walletFromId ||
                !walletToId ||
                !+exchangeFields[0].value ||
                exchangeFields[1].value === 0 ||
                polkaDotLimitWarning
              }
              onClick={() => setShowSwapConfirmModal(true)}
            >
              {t('Send')}
            </Button>
          </div>
        )}

        {showSwapOffers && (
          <SwapOffers
            swapOffers={swapOffersType === 'fixed' ? swapOffers.filter(offer => offer.id) : swapOffers}
            updatedOffers={updatedOffers}
            setSwapOffersSort={setSwapOffersSort}
            setSwapOffersType={setSwapOffersType}
            setSelectedPartner={setSelectedPartner}
            setSelectedSwapFixed={setSelectedSwapFixed}
            setSelectedPartnerRateId={setSelectedPartnerRateId}
            setSummaryRate={setSummaryRate}
            swapOffersFetching={swapOffersFetching}
            amount={exchangeFields[0].value}
            fromId={exchangeFields[0].selectedCurrency}
            toId={exchangeFields[1].selectedCurrency}
            sort={swapOffersSort || undefined}
            type={swapOffersType || undefined}
            updateSwapOffers={updateSwapOffers}
            swapOffersUpdateApply={swapOffersUpdateApply}
            swapOffersUpdating={swapOffersUpdating}
            showBalanceWarning={showBalanceWarning}
            setDataIsFrozen={setDataIsFrozen}
            swapOffersSort={swapOffersSort}
            swapOffersType={swapOffersType}
            setSwapAmountFromError={setSwapAmountFromError}
            swapAmountFromError={swapAmountFromError}
            polkaDotLimitWarning={polkaDotLimitWarning}
          />
        )}
      </div>
      <UploadAutosignWrapper
        step={autosignStep}
        uploadAutosign={uploadAutosign}
        onCancel={() => setIsOpenAutosignModal(false)}
        isOpen={isOpenAutosignModal}
      />
    </>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  networkFilter: state.networkFilter,
  exchangeWallets: state.swap.exchangeWallets.data,
  exchangeWalletsFetching: state.swap.exchangeWallets.fetching,
  swapOffers: state.swap.offers.data,
  updatedOffers: state.swap.updatedOffers.data,
  swapOffersFetching: state.swap.offers.fetching,
  swapOffersUpdating: state.swap.updatedOffers.fetching,
  currenciesArray: state.currencies.data,
  swapTokens: state.swap.tokens.tokens,
  priceForSwap: state.swap.priceForSwap.data,
  autosignStatus: state.withdraw.autosignStatus,
  autosignError: state.withdraw.autosignError,
  autosignStep: state.withdraw.autosignStep,
});

const mapDispatchToProps = dispatch => ({
  getSwapOffers: getSwapOffers(dispatch),
  updateSwapOffers: updateSwapOffers(dispatch),
  swapOffersUpdateApply: () => dispatch(swapOffersUpdateApply()),
  swapOffersReset: () => dispatch(swapOffersReset()),
  swapWalletsReset: () => dispatch(swapExchangeWalletsReset()),
  resetExchangeError: () => dispatch(resetExchangeError()),
  getSwapTokens: getSwapTokens(dispatch),
  getReceiveSwap: getReceiveSwap(dispatch),
  swapReceiveReset: () => dispatch(swapReceiveReset()),
  resetStableSwapError: () => dispatch(resetStableSwapError()),
  getMerchantWallets: getMerchantWallets(dispatch),
  getSwapExchangeWallets: dispatch(getSwapExchangeWallets),
  checkAutosignStatus: checkAutosignStatus(dispatch),
  uploadNewAutosign: uploadNewAutosign(dispatch),
  // getMerchantBalances: getMerchantBalances(dispatch),
  // getWithdrawWallets: getWithdrawWallets(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapNew);
