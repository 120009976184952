import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FailedSVG } from '../../../img/default-svg/failed.svg';
import './style.scss';

const Failed = () => {
  const {t} = useTranslation();

  return (
    <div className="checkout-failed">
      <FailedSVG />
      <div className="checkout-failed__title">{t('checkouts.failed.title')}</div>
      <div className="checkout-failed__description">
      {t('checkouts.failed.description')} <br />{' '}
        <span>lazy.ants@mail.com</span>
      </div>
    </div>
  );
};

export default Failed;
