import React from 'react';
import SwapWalletItem from '../SwapWalletItem';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SwapWalletsEmpty from '../SwapWalletsEmpty';
import cn from 'classnames';
import './style.scss';

const SwapWalletsList = ({
  exchangeWallets,
  exchangeWalletsFetching,
  activeWalletId,
  handleSelect,
  setSelectedWalletBalance,
  currenciesArray,
  exchangeFields,
  currencyFieldIsEdited,
  currencyNumber,
}) => {
  return (
    <div
      className={cn('swap-wallets-list', {
        'swap-wallets-list_selected': activeWalletId,
      })}
      key={activeWalletId}
    >
      {!exchangeWalletsFetching || (currencyFieldIsEdited !== 'none' && currencyFieldIsEdited !== currencyNumber && exchangeWalletsFetching) ? (
        exchangeWallets && exchangeWallets.entities.length ? (
          exchangeWallets.entities.map(wallet =>
            wallet.tokens.length ? (
              wallet.tokens.map(token => (
                <SwapWalletItem
                  wallet={token}
                  id={wallet._id}
                  address={wallet.address}
                  isMain={wallet.main}
                  activeWalletId={activeWalletId}
                  handleSelect={handleSelect}
                  balance={token.balance.value}
                  setSelectedWalletBalance={setSelectedWalletBalance}
                  currenciesArray={currenciesArray}
                />
              ))
            ) : (
              <SwapWalletItem
                key={wallet._id}
                wallet={wallet}
                id={wallet._id}
                address={wallet.address}
                isMain={wallet.main}
                activeWalletId={activeWalletId}
                handleSelect={handleSelect}
                balance={wallet.balance.value}
                setSelectedWalletBalance={setSelectedWalletBalance}
                currenciesArray={currenciesArray}
                exchangeFields={exchangeFields}
              />
            )
          )
        ) : (
          <SwapWalletsEmpty />
        )
      ) : (
        <>{(currencyFieldIsEdited === currencyNumber || currencyFieldIsEdited==='none') ? <Spin className="swap-wallets-list__loader" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}</>
      )}
    </div>
  );
};

// const SwapWalletsList = ({
//   exchangeWallets,
//   exchangeWalletsFetching,
//   activeWalletId,
//   handleSelect,
//   setSelectedWalletBalance,
// }) => {
//   useEffect(() => {
//     document.querySelector('.main-layout__content-wrap').scrollTo(0, 0);
//   }, []);
//   return (
//     <div
//       className={cn('swap-wallets-list', {
//         'swap-wallets-list_selected': activeWalletId,
//       })}
//     >
//       {!exchangeWalletsFetching ? (
//         exchangeWallets && exchangeWallets.entities.length ? (
//           exchangeWallets.entities.map(wallet =>
//             wallet.tokens.length ? (
//               wallet.tokens.map(token => (
//                 <SwapWalletItem
//                   wallet={token}
//                   id={wallet._id}
//                   address={wallet.address}
//                   isMain={wallet.main}
//                   activeWalletId={activeWalletId}
//                   handleSelect={handleSelect}
//                   balance={token.balance.value}
//                   setSelectedWalletBalance={setSelectedWalletBalance}
//                 />
//               ))
//             ) : (
//               <SwapWalletItem
//                 wallet={wallet}
//                 id={wallet._id}
//                 address={wallet.address}
//                 isMain={wallet.main}
//                 activeWalletId={activeWalletId}
//                 handleSelect={handleSelect}
//                 balance={wallet.balance.value}
//                 setSelectedWalletBalance={setSelectedWalletBalance}
//               />
//             )
//           )
//         ) : (
//           <SwapWalletsEmpty />
//         )
//       ) : (
//         <Spin className="swap-wallets-list__loader" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
//       )}
//     </div>
//   );
// };

export default SwapWalletsList;


