import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { identity } from '../../../utils/getIdentity';
import { getMerchantBalances } from '../../../redux/actions/merchantData';


const WithdrawModeToggler = ({ multisendMode, setMultisendMode, merchantId, merchantsFetching, getMerchantBalances, networkFilter }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (merchantId) {
      getMerchantBalances(merchantId, networkFilter, true); // false - add tokens value commit 14 Jul
    }
  }, [merchantId, networkFilter]);

  return (
    <div className="withdraw-mode-toggler-wrapper">
      <div className="withdraw-mode-toggler">
        <span
          className={`withdraw-mode-toggler__item ${!multisendMode && `active active-${identity}`}`}
          onClick={() => setMultisendMode(false)}
        >
          {t('wallets.myWallets')}
        </span>
        <span
          className={`withdraw-mode-toggler__item ${multisendMode && `active active-${identity}`}`}
          style={{ cursor: merchantsFetching ? 'progress' : 'pointer' }}
          onClick={() => !merchantsFetching && setMultisendMode(true)}
        >
          {t('multisend')}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
  merchantId: state.transactions.merchantId,
  merchantsFetching: state.merchants.fetching,
});

const mapDispatchToProps = dispatch => ({
  getMerchantBalances: getMerchantBalances(dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(WithdrawModeToggler);
