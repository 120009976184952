import React from 'react';
import cn from 'classnames';
import './styles.scss';
import { identity } from '../../utils/getIdentity';

const Hamburger = ({ opened, handler }) => {
  return (
    <div className={cn(`hamburger-menu hamburger-menu-${identity}`, { 'hamburger-menu__opened': opened })} onClick={handler}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Hamburger;
