import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetAutosignStep } from '../../../redux/actions/withdraw';

import UploadAutosignModal from '../UploadAutosignModal';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

function UploadAutosignWrapper({ isOpen, onCancel, uploadAutosign, step, sign }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [clearForm, setClearForm] = useState(false);

  const handleCloseModal = () => {
    dispatch(resetAutosignStep());
    setClearForm(true);
    onCancel();
  };
  return (
    <Modal
      title={t('withdraw.addAutosign')}
      visible={isOpen}
      onCancel={handleCloseModal}
      width={369}
      footer={null}
      className="modal"
    >
      <UploadAutosignModal clearForm={clearForm} sign={sign} isOpen={isOpen} onCancel={onCancel} uploadAutosign={uploadAutosign} step={step} />
    </Modal>
  );
}

export default UploadAutosignWrapper;
