import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import TokenIcon from '../../TokenIcon';
import { ReactComponent as ClockIcon } from '../../../img/default-svg/lock.svg';
import { ReactComponent as DropdownIcon } from '../../../img/default-svg/chevron-down.svg';
import styles from './SaleTokenForm.module.scss';

const SaleTokenInput = ({ name, selectName, label, form, checkoutData, staticCurrency, timer, info }) => {
  const [maxDecimals, setMaxDecimals] = useState(0);
  const {t} = useTranslation();

  useEffect(() => {
    setMaxDecimals(getDecimalsLimit(staticCurrency ? staticCurrency._id : checkoutData.currencies[0]._id));
  }, [checkoutData, staticCurrency]);

  const getDecimalsLimit = selectedCurrency => {
    const currency = staticCurrency || checkoutData.currencies.find(currency => currency._id === selectedCurrency);
    return currency.decimals;
  };

  const handleInputChange = (value, prevValue) => {
    if (!value.split('.')[1] || value.split('.')[1].length <= maxDecimals) {
      return value.replaceAll('-', '');
    } else {
      return prevValue;
    }
  };

  const trimAndReplaceInputValue = maxDecimals => {
    const inputValue = form.getFieldValue(name);

    if (typeof inputValue === 'string' && inputValue.split('.')[1]) {
      const [integer, fractional] = inputValue.split('.');
      form.setFieldsValue({ [name]: `${integer}.${fractional.substring(0, maxDecimals)}` });
    }
    return;
  };

  const handleSelectChange = selectedCurrency => {
    const newMaxDecimals = getDecimalsLimit(selectedCurrency);
    setMaxDecimals(newMaxDecimals);
    trimAndReplaceInputValue(newMaxDecimals);
    return selectedCurrency;
  };

  return (
    <div className={styles['sale-token-form__input-wrapper']}>
      <span className={styles['sale-token-form__input-label']}>{label}</span>
      <div className={styles['sale-token-form__input']}>
        <Form.Item
          name={name}
          noStyle={true}
          normalize={handleInputChange}
          rules={[
            ({ getFieldValue }) => ({
              validator: (rule, value) => {
                if (value && value > 0) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t('validation.required')));
              },
            }),
          ]}
        >
          <input type="number" placeholder={t('withdraw.enterAmount')} min={0} step="any" />
        </Form.Item>
        <div className={styles['sale-token-form__input-right']}>
          {staticCurrency ? (
            <div className={styles['sale-token-form__input-currency']}>
              <TokenIcon className={styles['sale-token-form__input-currency-icon']} tokenName={staticCurrency.name} />
              {staticCurrency.title}
            </div>
          ) : (
            <Form.Item name={selectName} noStyle={true} normalize={handleSelectChange}>
              <Select className={styles['sale-token-form__select']} suffixIcon={<DropdownIcon />}>
                {checkoutData.currencies.map(checkoutCurrency =>
                  checkoutData.tradedCurrency._id !== checkoutCurrency._id ? (
                    <Select.Option
                      key={checkoutCurrency._id}
                      className={styles['sale-token-form__select-option']}
                      value={checkoutCurrency._id}
                    >
                      <TokenIcon
                        className={styles['sale-token-form__select-option-icon']}
                        tokenName={checkoutCurrency.name}
                      />
                      {checkoutCurrency.title}
                    </Select.Option>
                  ) : undefined
                )}
              </Select>
            </Form.Item>
          )}
        </div>
        <span className={styles['sale-token-form__input-error']}>{form.getFieldError(name)}</span>
        {timer && (
          <div className={styles['sale-token-form__timer']}>
            <ClockIcon />
            {t('checkouts.saleToken.fixedPrice')} {timer} {t('checkouts.saleToken.minutes')}
          </div>
        )}
      </div>
      {info && (
        <div className={styles['sale-token-form__input-info']}>
          {info.map((infoItem, index) => (
            <div key={index} className={styles['sale-token-form__input-info-child']}>
              {infoItem.label} {infoItem.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SaleTokenInput;
