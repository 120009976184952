import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from '../../img/default-svg/check.svg';
import styles from './SaleTokenProgress.module.scss';
import { getContrastTextColor } from '../../utils/getTextColorByBg';
import { mainColor } from '../../utils/getMainColor';
import { identity } from '../../utils/getIdentity';


const SaleTokenProgress = ({ getChargeState, progressStep }) => {
  const { t } = useTranslation();

  const steps = [
    t('checkouts.saleToken.steps.awaiting'),
    t('checkouts.saleToken.steps.confriming'),
    t('checkouts.saleToken.steps.exchange'),
    t('checkouts.saleToken.steps.sending'),
  ];

  return (
    <>
      <style>{`
      .custom-steps_active {
        color: ${getChargeState.data.checkout.accentColor} !important;
        .custom-step-icon {
          border-color: ${getChargeState.data.checkout.accentColor} !important;
          background: ${getChargeState.data.checkout.accentColor} !important;
          svg path {
            stroke: ${getContrastTextColor(getChargeState.data.checkout.accentColor || mainColor)}  !important;
          }
        }
      }
    `}</style>
      <div className={styles['sale-token-progress']}>
        {steps.map((step, index) => (
          <div
            key={index}
            className={cn(styles['sale-token-progress__step'], {
              [styles['sale-token-progress__step_done']]: index < progressStep,
              [`${styles[`sale-token-progress__step_active sale-token-progress__step_active-${identity}`]} custom-steps_active`]: index === progressStep,
            })}
          >
            <div className={`${styles['sale-token-progress__step-icon']} custom-step-icon`}>
              <CheckIcon />
            </div>
            <span className={styles['sale-token-progress__step-name']}>{step}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default SaleTokenProgress;
