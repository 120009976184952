import React, { useEffect, useState, useRef } from 'react';
import { Popover } from 'antd';
import { ReactComponent as CopyIcon } from '../../../img/cpay-svg/copy-blue.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessSVG } from '../../../img/default-svg/success.svg';
import network from '../../../utils/transactionScans';
import { truncate } from '../../../utils/truncateFunction';
import { tokenNodeTypeObj } from '../../../utils/tokenNodeTypes';
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const Success = ({ replenish, fiatCurrency, wallets, typeNetwork, accentColor }) => {
  const [showCopyPopover, setShowCopyPopover] = useState(false);
  const copyTimer = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (copyTimer.current) {
        clearTimeout(copyTimer.current);
        copyTimer.current = null;
      }
    };
  }, []);

  const copyHandler = value => {
    navigator.clipboard.writeText(value);
    setShowCopyPopover(true);
    copyTimer.current = setTimeout(() => setShowCopyPopover(false), 1000);
  };

  const notIncludedNodeType = ['optimism', 'matic', 'arbitrum', 'avax', 'sol', 'ftm', 'moonbeam']

  const tokenType = `${replenish?.currency}
    ${
      replenish?.currencyType === 'token' && !notIncludedNodeType.includes(replenish?.nodeType)
        ? tokenNodeTypeObj[replenish?.nodeType]
        : ''
    }
    ${
      replenish?.currencyType === 'token' && replenish?.nodeType === 'optimism'
        ? replenish?.currency === 'OP'
          ? ''
          : 'OP'
        : ''
    }
    ${
      replenish?.currency === 'ETH' && replenish?.currencyType === 'currency' && replenish?.nodeType === 'optimism'
        ? 'OP'
        : ''
    }

    ${
      replenish?.currencyType === 'currency' && replenish?.nodeType === 'arbitrum'
        ? replenish?.currency === 'arbitrum'
          ? ''
          : 'ARB'
        : ''
    }

    ${
      (replenish?.currency === 'USDT' || replenish?.currency === 'USDC' || replenish?.currency === 'ETH') &&
      replenish?.currencyType === 'token' &&
      replenish?.nodeType === 'arbitrum'
        ? 'ARB'
        : ''
    }

    ${
      (replenish?.currency === 'USDT' || replenish?.currency === 'USDC') &&
      replenish?.currencyType === 'token' &&
      replenish?.nodeType === 'matic'
        ? 'MATIC'
        : ''
    }

    ${(replenish?.currency === 'USDT' && replenish?.currencyType === 'token' && replenish?.nodeType === 'avax') ? 'AVAX' : ''}

    ${(replenish?.currency === 'USDC' && replenish?.currencyType === 'token' && replenish?.nodeType === 'solana') ? 'SOL' : ''}

   ${(replenish?.currency === 'USDT'  && replenish?.currencyType === 'token' && replenish?.nodeType === 'ftm') ? 'FTM' : ''}

     ${((replenish?.currency === 'USDT' || replenish?.currency === 'USDC') && replenish?.currencyType === 'token' && replenish?.nodeType === 'moonbeam') ? 'GLMR' : ''}
  `;

  const linkSolana = replenish?.currency === 'SOL' ? '?cluster=devnet' : ''

  return (
    <>
      <style>
        {`
          .checkout-success__info-block svg path {
            stroke: ${accentColor} !important;
          }
          .checkout-success__info-block.checkout-hash a:hover {
            color: ${accentColor} !important;
          }
        `}
      </style>
      <div className="checkout-success">
        <SuccessSVG />
        <div className="checkout-success__title">{t('checkouts.success.title')}!</div>
        <div className="checkout-success__subtitle">{t('checkouts.success.subtitle')}</div>
        {/* {fiatCurrency === 'LOTT' ? (
        <div className="checkout-success__subtitle">Payment successful. Within a few minutes</div>
      ) : (
        <div className="checkout-success__subtitle">Successful payment in the amount of</div>
      )}
      <div
        className={cn('checkout-success__sum', {
          ['checkout-success__sum_lott']: fiatCurrency === 'LOTT',
        })}
      >{`${
        wallets.find(wallet => fiatCurrency === wallet.currency.name) ? amount.usd : amount.usd.toFixed(2)
      } ${fiatCurrency}`}</div>
      {fiatCurrency === 'LOTT' && (
        <div className="checkout-success__subtitle checkout-success__subtitle-second">
          will be credited to your account.
        </div>
      )} */}
        <div className="checkout-success__info-block">
          <div>{t('amount')}</div>
          <div className="checkout-success__info-block_wrapper">{`${replenish?.amount?.value} ${tokenType}`}</div>
        </div>
        <div className="checkout-success__info-block">
          <div className="exchange-rate">{t('withdraw.exchangeRate')}</div>
          <div>{`1 ${tokenType} - ${replenish?.price} ${fiatCurrency}`}</div>
        </div>
        <div className="checkout-success__info-block checkout-hash">
          <div>Hash:</div>
          <div>
            <a
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              href={`${network[typeNetwork][replenish?.nodeType]}${replenish?.hash}${linkSolana}`}
              className={`link link-${identity}`}
            >
              {truncate(replenish?.hash, 15)}{' '}
            </a>
            <Popover title="" content={t('copied')} trigger="click" visible={showCopyPopover}>
              <CopyIcon onClick={() => copyHandler(replenish?.hash)} />
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
