import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Row } from 'antd';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { loginByPhone } from '../../redux/actions/login';
import notificationList from '../../utils/notifications';
import backCpayIcon from '../../img/cpay-svg/arrow-back.svg';
import backNFGIcon from '../../img/nfgpay-svg/arrow-back-nfg.svg';
import backFinvaroIcon from '../../img/finvaro/arrow-back-finvaro.svg';
import backClarniumIcon from '../../img/clarnium/arrow-back-clarnium.svg';
import editIcon from '../../img/default-svg/edit.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import useWindowSize from '../../utils/resizeHook';
import PhoneInput from 'react-phone-input-2';
import { Timer } from '../Timer/Timer';
import { addMinutes } from '../../utils/addMinutes';
import { cryptoApi } from '../../service/cryptopay-api';
import { identity } from '../../utils/getIdentity';

const backIcon = {
  cpay: backCpayIcon,
  nfg: backNFGIcon,
  finvaro: backFinvaroIcon,
  clarnium: backClarniumIcon
}

const LoginPhoneForm = ({
  actionLogin,
  isAppTwoFa,
  setIsAppTwoFa,
  isEmailTwoFa,
  setIsEmailTwoFa,
  loginShowPhoneCodeVerify,
  setLoginShowPhoneCodeVerify,
  fetchingUser,
  backAction,
}) => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [appTwoFaKey, setAppTwoFaKey] = useState('');
  const [emailTwoFaKey, setEmailTwoFaKey] = useState('');
  const [verifyCode, setVerifyCode] = useState(undefined);
  const [disableResend, setDisableResend] = useState(false)
  const recaptchaRef = useRef();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { isSm } = useWindowSize();

  const validUntil = addMinutes(1)


  useEffect(() => {
    form.setFieldsValue({
      appTwoFaKey: appTwoFaKey,
    });
  }, [appTwoFaKey]);

  useEffect(() => {
    form.setFieldsValue({
      emailTwoFaKey: emailTwoFaKey,
    });
  }, [emailTwoFaKey]);

  const onFinish = async () => {
    const recaptcha = await recaptchaRef.current.executeAsync();

    try {
        await actionLogin(
        phone.trim(),
        password.trim(),
        appTwoFaKey.trim(),
        emailTwoFaKey.trim(),
        verifyCode,
        recaptcha
      );
      recaptchaRef.current.reset();
      setDisableResend(true)
    } catch (err) {
      recaptchaRef.current.reset();
      notificationList.failed(err.data.message);
    }
  };

  const handleDisableResend = () => {
    const timeout = setTimeout(() => {
       setDisableResend(false)
     }, 60000)

     return () => clearTimeout(timeout)
  }

  const resendVerifyCode = async () => {
    try {
      await cryptoApi.loginResendPhoneCode(phone)
      setDisableResend(true)
      handleDisableResend()
    } catch (error) {
      notificationList.failed(error.data.message);
    }
   }

  return (
    <Form form={form} onFinish={onFinish} className="login-form">
      {isSm && <p className="login-form__title">{identity !== 'finvaro' ? t('auth.welcomeText') : t('auth.welcomeFinvaro')}</p>}
      {isAppTwoFa && (
        <>
          <p className="login-form__label">{t('auth.keyFromGA')}</p>
          <Form.Item name="appTwoFaKey" rules={[{ required: true, message: t('validation.required') }]}>
            <Input
              suffix={<img src={editIcon} alt="edit icon" />}
              placeholder={t('auth.keyFromGA')}
              className="login-form__input"
              onChange={e => setAppTwoFaKey(e.target.value)}
            />
          </Form.Item>

          <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />

          <span
            className={`login-form__link login-form__link-${identity}`}
            onClick={() => {
              setIsAppTwoFa(false);
              setAppTwoFaKey('');
            }}
          >
            {t('back')}
          </span>
          <Button type="primary" className={`login-form__button login-form__button-${identity}`} loading={fetchingUser}>
            {t('submit')}
          </Button>
        </>
      )}

      {isEmailTwoFa && !isAppTwoFa && (
        <>
          <p className="login-form__label">{t('auth.keyFromEmail')}</p>
          <Form.Item name="emailTwoFaKey" rules={[{ required: true, message: t('validation.required') }]}>
            <Input
              suffix={<img src={editIcon} alt="edit icon" />}
              placeholder={t('key')}
              className="login-form__input"
              onChange={e => setEmailTwoFaKey(e.target.value)}
            />
          </Form.Item>

          <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />

          <span
            className={`login-form__link login-form__link-${identity}`}
            onClick={() => {
              setIsEmailTwoFa(false);
              setEmailTwoFaKey('');
            }}
          >
            {t('goBack')}
          </span>
          <Button type="primary" className={`login-form__button login-form__button-${identity}`} loading={fetchingUser}>
            {t('submit')}
          </Button>
        </>
      )}

      {!isAppTwoFa && !isEmailTwoFa && (
        <>
          {!loginShowPhoneCodeVerify ? (
            <>
              <p className="login-form__label">{t('phone')}</p>
              <Form.Item name="phone">
                <PhoneInput
                  containerClass="form__input form__input-phone login-form__input-phone"
                  country={'us'}
                  onChange={phone => setPhone(`+${phone}`)}
                />
              </Form.Item>

              <div className="login-form__label login-form__label_flex">
                {t('password')}
                <Link to="/password-send" className={`login-form__link login-form__link-${identity}`}>
                  {t('auth.forgotPassword')}
                </Link>
              </div>
              <Form.Item name="password" rules={[{ required: true, message: t('validation.required') }]}>
                <Input.Password
                  placeholder={t('password')}
                  className="login-form__input"
                  onChange={e => setPassword(e.target.value)}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <p className="login-form__label">{t('auth.verifyCode')}</p>
              <Form.Item name="verifyCode" rules={[{ required: true, message: t('validation.required') }]}>
                <Input
                  name="verifyCode"
                  placeholder={t("auth.сodeFromSms")}
                  className="login-form__input"
                  onChange={e => setVerifyCode(e.target.value)}
                />
              </Form.Item>
              <div className='phone-confirm__modal-info-receive'>
             <p>{t('doNotReveiveCode')}</p>
             {disableResend ?
              <p style={{ color: 'grey', marginLeft: '5px'  }}> Receive in {' '}<Timer validUntil={validUntil} onExpire={() => setDisableResend(false)}/></p>
              : <p style={{ color: identity === 'cpay' ? '#2A92FF' : identity === 'nfg' ? '#48C8FF' : '#000000', cursor: 'pointer', marginLeft: '5px'  }} onClick={resendVerifyCode}> {t('resend')}</p>}
          </div>
            </>
          )}
          <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />

          <div className="login-form__buttons-wrap">
            <div
              className={`login-form__back-button`}
              onClick={() => (loginShowPhoneCodeVerify ? setLoginShowPhoneCodeVerify(false) : backAction())}
            >
              <img src={backIcon[identity]} alt="back" />
            </div>

            <Button type="primary" className={`login-form__button login-form__button-${identity}`} loading={fetchingUser}>
              {t('logIn')}
            </Button>
          </div>

          {identity !== 'finvaro' && (
            <Row>
              <div className={`login-form__text login-form__text_center login-form__text-${identity}`}>
                {t('auth.dontHaveAccount')} <Link to="/sign-up">{t('signUp')}!</Link>
              </div>
            </Row>
          )}
        </>
      )}
    </Form>
  );
};

const mapStateToProps = state => ({
  fetchingUser: state.login.fetching,
  isAppTwoFa: state.login.isAppTwoFa,
  isEmailTwoFa: state.login.isEmailTwoFa,
  loginShowPhoneCodeVerify: state.login.loginShowPhoneCodeVerify,
});

const mapDispatchToProps = dispatch => ({
  actionLogin: loginByPhone(dispatch),
  setIsAppTwoFa: isAppTwoFa =>
    dispatch({
      type: 'LOGIN_SET_APP_TWO_FA',
      isAppTwoFa,
    }),
  setIsEmailTwoFa: isEmailTwoFa =>
    dispatch({
      type: 'LOGIN_SET_EMAIL_TWO_FA',
      isEmailTwoFa,
    }),
  setLoginShowPhoneCodeVerify: isShowCode =>
    dispatch({
      type: 'LOGIN_SHOW_PHONE_CODE_VERIFY',
      payload: isShowCode,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPhoneForm);
