import React from 'react';
import './style.scss';
import cn from 'classnames';
import { identity } from '../../../utils/getIdentity';


const CheckoutStepsProgress = ({ step }) => {
  return (
    <div className="checkout-progress">
      <div className={cn(`checkout-progress__step checkout-progress__step-${identity}`, { 'checkout-progress__step_active': step === 1 })}></div>
      <div className={cn('checkout-progress__step checkout-progress__step-${identity}', { 'checkout-progress__step_active': step === 2 })}></div>
    </div>
  );
};

export default CheckoutStepsProgress;
