import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import TokenIcon from '../../TokenIcon';
import formatCurrency from '../../../utils/currencyFormatter';
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const SwapWalletItem = ({
  wallet,
  id,
  address,
  isMain,
  activeWalletId,
  handleSelect,
  balance,
  setSelectedWalletBalance,
  currenciesArray
}) => {
  const { t } = useTranslation();
  const [currencyProperties, setCurrencyProperties] = useState(null);

  useEffect(()=>{
    setCurrencyProperties(currenciesArray?.find(currency=>wallet.currencyId === currency._id));
  },[currenciesArray, wallet.currencyId])

  const swapWalletName = currencyProperties?.name === currencyProperties?.title ? currencyProperties?.name : currencyProperties?.title?.replace(/[{()}]/g, '');

  return (
    <div
      className={cn('swap-wallet-item', {
        'swap-wallet-item_active': activeWalletId === id,
      })}
      onClick={() => {
        handleSelect(id);
        setSelectedWalletBalance && setSelectedWalletBalance(+balance);
      }}
    >
      <div className="swap-wallet-item__left">
        <div className="swap-wallet-item__icon">
          <TokenIcon tokenName={wallet.currency} />
        </div>
        <div className="swap-wallet-item__info">
          <div className="swap-wallet-item__name">
            {swapWalletName} {isMain ? `(${t('swap.newSwap.default')})` : ''}
          </div>
          <div className={`swap-wallet-item__address swap-wallet-item__address-${identity}`}>{address}</div>
        </div>
      </div>
      <div className="swap-wallet-item__right">
        <div className="swap-wallet-item__tokens">
          {wallet.balance.value} {wallet.currency}
        </div>
        <div className="swap-wallet-item__fiat">{formatCurrency(wallet.balance.usd)}</div>
      </div>
    </div>
  );
};

export default SwapWalletItem;
