import React, { useState, useRef, useEffect } from 'react';
import Button from '../../../../Button';
import { ReactComponent as CopySVG } from '../../../../../img/default-svg/copy.svg';
import noCheckoutImgSVG from '../../../../../img/default-svg/no-checkout-image.svg';
import { ReactComponent as HelpCircleSVG } from '../../../../../img/default-svg/help-circle.svg';
import { Popover, Tabs, Checkbox, Tooltip, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';
import Highlight from 'react-highlight';
import { identity } from '../../../../../utils/getIdentity';
import { AppConfig } from '../../../../../config';


const { TabPane } = Tabs;

const DetailedCheckout = ({ detailedCheckoutState, close, merchants, merchantId }) => {
  const location = window.location.href
  const [clickIdFlag, setClickIdFlag] = useState(false);
  const [customDomainFlag, setCustomDomainFlag] = useState(false);
  const [clickIdData, setClickIdData] = useState('');
  const [showClickIdErrorMessage, setShowClickIdErrorMessage] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [activeSwitch, setActiveSwitch] = useState('link');
  const [amountAndFiatCurrencyFlag, setAmountAndFiatCurrencyFlag] = useState(false);
  const [amoundData, setAmoundData] = useState('');
  const [fiatCurrencyData, setFiatCurrencyData] = useState('');
  const [showAmoundDataMessage, setShowAmoundDataMessage] = useState(false);
  const [showFiatCurrencyDataMessage, setShowFiatCurrencyDataMessage] = useState(false);

  const { t } = useTranslation();
  const timer = useRef(null);
  const { organizationName, productName, type, description, identifier, image, cartName } = detailedCheckoutState;
  const isCustomDomain = merchants.filter(item => item._id === merchantId)[0].checkoutCustomUrl;

  const getCustomUrl = () => {
    const currentMerchant = merchants.filter(item => item._id === merchantId);

    if (currentMerchant[0].checkoutCustomUrl) {
      if (currentMerchant[0].checkoutCustomUrl.includes('https://')) {
        let url = currentMerchant[0].checkoutCustomUrl.slice(8);
        if (url.endsWith('/')) {
          url = url.replace(/\/+$/, '');
        }

        return `.${url}/`;
      } else if (currentMerchant[0].checkoutCustomUrl.includes('http://')) {
        let url = currentMerchant[0].checkoutCustomUrl.slice(7);
        if (url.endsWith('/')) {
          url = url.replace(/\/+$/, '');
        }
        return `.${url}/`;
      } else {
        let url = currentMerchant[0].checkoutCustomUrl;
        if (url.endsWith('/')) {
          url = url.replace(/\/+$/, '');
        }
        return `.${url}/`;
      }
    } else {
      return process.env.REACT_APP_URL;
    }
  };

 const checkoutLinkCPay = location.includes('demo') || location.includes('localhost') ? 'https://checkouts-demo.cpay.world/' : 'https://checkouts.cpay.world/'
 const checkoutLinkNFG = 'https://checkouts.nfg-crypto.io/';
 const checkoutLinkFinvaro = 'https://checkouts.finvaro.com/';
 const checkoutLinkClarnium = 'https://checkouts.clarnium.io/';

 const checkoutLink = {
  cpay: `${!customDomainFlag ? checkoutLinkCPay : `https://checkouts${getCustomUrl()}`}`,
  nfg: checkoutLinkNFG,
  finvaro: checkoutLinkFinvaro,
  clarnium: checkoutLinkClarnium
 }

//  const checkoutSaleLink = {
//   cpay: 'sell-token-checkout',
//   nfg: 'sale-token-checkout'
//  } // temporary for sell-token-checkout, will delete later if it works properly

  const copyHandler = () => {
    navigator.clipboard.writeText(
      activeSwitch === 'link'
        ? `${checkoutLink[identity]}${
            type === 'SaleToken' ? 'sell-token-checkout' : 'checkout'
          }/${identifier}${clickIdFlag ? `?clickId=${clickIdData}` : ''}${
            clickIdFlag && amountAndFiatCurrencyFlag ? '&' : amountAndFiatCurrencyFlag ? '?' : ''
          }${amountAndFiatCurrencyFlag ? `amount=${amoundData}&fiatCurrency=${fiatCurrencyData}` : ''}`
        : activeSwitch === 'embed'
        ? `
    <div>
      <a class="buy-with-crypto"
        href="${checkoutLink[identity]}${
            type === 'SaleToken' ? 'sell-token-checkout' : 'checkout'
          }/${identifier}${clickIdFlag ? `?clickId=${clickIdData}` : ''}${
            clickIdFlag && amountAndFiatCurrencyFlag ? '&' : amountAndFiatCurrencyFlag ? '?' : ''
          }${amountAndFiatCurrencyFlag ? `amount=${amoundData}&fiatCurrency=${fiatCurrencyData}` : ''}"
      >
      Buy with Crypto
      </a>
    </div>
    `
        : `
    <iframe src="${checkoutLink[identity]}${
            type === 'SaleToken' ? 'sell-token-checkout' : 'checkout'
          }/${identifier}${clickIdFlag ? `?clickId=${clickIdData}` : ''}${
            clickIdFlag && amountAndFiatCurrencyFlag ? '&' : amountAndFiatCurrencyFlag ? '?' : ''
          }${
            amountAndFiatCurrencyFlag ? `amount=${amoundData}&fiatCurrency=${fiatCurrencyData}` : ''
          }" frameborder="0"></iframe>
    `
    );
    setShowPopover(true);
    timer.current = setTimeout(() => setShowPopover(false), 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      timer.current = null;
    };
  });

  const validateClickId = clickId => {
    if (/[^a-zA-Z0-9\-_]/.test(clickId)) {
      setShowClickIdErrorMessage(true);
    } else {
      setClickIdData(clickId);
      setShowClickIdErrorMessage(false);
    }
  };

  const validateAmountData = e => {
    const input = e.target.value;

    if (input.trim() === '' || /^\d+(\.\d*)?$/.test(input)) {
      setAmoundData(input);
      setShowAmoundDataMessage(false);
    } else {
      setShowAmoundDataMessage(true);
    }
  };

  const validateFiatCurrencyData = fiatCurrency => {
    const allowedCurrencyCodes = ['AED', 'AUD', 'BRL', 'CNY', 'EUR', 'GBP', 'NGN', 'RUB', 'TRY', 'UAH', 'USD'];

    const sanitizedInput = fiatCurrency.toUpperCase();

    if (sanitizedInput !== '' && (!/^[A-Z]+$/.test(sanitizedInput) || !allowedCurrencyCodes.includes(sanitizedInput))) {
      setFiatCurrencyData(sanitizedInput);
      setShowFiatCurrencyDataMessage(true);
    } else {
      setFiatCurrencyData(sanitizedInput);
      setShowFiatCurrencyDataMessage(false);
    }
  };

  const callback = key => {
    setActiveSwitch(key);
  };

  const highlightedCode = `
  <div>
    <a class="buy-with-crypto"
      href="${checkoutLink[identity]}${
    type === 'SaleToken' ? 'sell-token-checkout' : 'checkout'
  }/
      ${identifier}${clickIdFlag ? `?clickId=${clickIdData}` : ''}${
    clickIdFlag && amountAndFiatCurrencyFlag ? '&' : amountAndFiatCurrencyFlag ? '?' : ''
  }${amountAndFiatCurrencyFlag ? `amount=${amoundData}&fiatCurrency=${fiatCurrencyData}` : ''}"
    >
    Buy with Crypto
    </a>
  </div>
  `;

  const highlightedCodeIFrame = `
  <iframe src="${checkoutLink[identity]}${
    type === 'SaleToken' ? 'sell-token-checkout' : 'checkout'
  }/${identifier}${clickIdFlag ? `?clickId=${clickIdData}` : ''}${
    clickIdFlag && amountAndFiatCurrencyFlag ? '&' : amountAndFiatCurrencyFlag ? '?' : ''
  }${
    amountAndFiatCurrencyFlag ? `amount=${amoundData}&fiatCurrency=${fiatCurrencyData}` : ''
  }" frameborder="0"></iframe>
  `;

  return (
    <div className="detailed-checkout">
      <div className="detailed-checkout__info-container">
        <div className="detailed-checkout__info-image">
          <img src={image ? `${process.env.REACT_APP_API_URL}${image}` : noCheckoutImgSVG} alt="" />
        </div>
        <div className="detailed-checkout__info">
          <div className="detailed-checkout__name">{organizationName || productName || cartName}</div>
          <div className="detailed-checkout__description">{description}</div>
        </div>
      </div>
      <div className="detailed-checkout__notif">{t('checkouts.linkShare')}</div>
      <div className="detailed-checkout__switch-container">
        <Tabs defaultActiveKey="link" onChange={callback} tabBarGutter={16}>
          <TabPane tab={t('checkouts.link')} key="link" />
          <TabPane tab={t('checkouts.embed')} key="embed" />
          <TabPane tab={t('checkouts.iframe')} key="iframe" />
        </Tabs>
        <Popover
          title=""
          content={t('copied')}
          trigger="click"
          visible={showPopover}
          className="detailed-checkout__popover"
        >
          <div
            className={
              activeSwitch !== 'link' ? 'detailed-checkout__link-container-embed' : 'detailed-checkout__link-container'
            }
            onClick={copyHandler}
          >
            <div className={`detailed-checkout__link detailed-checkout__link-${identity}`}>
              {activeSwitch === 'link' ? (
                <p>
                  {`${checkoutLink[identity]}${
                    type === 'SaleToken' ? 'sell-token-checkout' : 'checkout'
                  }/${identifier}`}
                  <span>{`${clickIdFlag ? `?clickId=${clickIdData}` : ''}${
                    clickIdFlag && amountAndFiatCurrencyFlag ? '&' : amountAndFiatCurrencyFlag ? '?' : ''
                  }${amountAndFiatCurrencyFlag ? `amount=${amoundData}&fiatCurrency=${fiatCurrencyData}` : ''}`}</span>
                </p>
              ) : activeSwitch === 'embed' ? (
                <Highlight className="xml">{highlightedCode}</Highlight>
              ) : (
                <Highlight className="xml">{highlightedCodeIFrame}</Highlight>
              )}
            </div>
            <div className={activeSwitch !== 'link' ? 'detailed-checkout__icon-embed' : 'detailed-checkout__icon'}>
              <CopySVG />
            </div>
          </div>
        </Popover>
      </div>
      {detailedCheckoutState?.type === 'Cart' && (
        <div className="detailed-checkout__clickid-container">
          <div
            onClick={() => setAmountAndFiatCurrencyFlag(!amountAndFiatCurrencyFlag)}
            className="detailed-checkout__clickid-container-checkbox"
          >
            <Checkbox checked={amountAndFiatCurrencyFlag} />
            <p className="detailed-checkout__clickid-container-text">{t('checkouts.amountAndFiatCurrency')}</p>
          </div>
          <Tooltip
            placement="bottomRight"
            overlayClassName="detailed-checkout__clickid-container-tooltip"
            title={t('checkouts.tooltipAmountAndFiatCurrency')}
          >
            <HelpCircleSVG />
          </Tooltip>
          {amountAndFiatCurrencyFlag && (
            <div className="detailed-checkout__clickid-container-amountAndFiatCurrencyBlock">
              <Input
                placeholder={t('checkouts.enterAmount')}
                className="detailed-checkout__clickid-container-input"
                value={amoundData}
                onChange={validateAmountData}
              />
              <Input
                placeholder={t('checkouts.enterFiatCurrency')}
                className="detailed-checkout__clickid-container-input"
                value={fiatCurrencyData}
                onChange={e => validateFiatCurrencyData(e.target.value)}
              />
            </div>
          )}
        </div>
      )}
      <div className="detailed-checkout__clickid-container">
        <div onClick={() => setClickIdFlag(!clickIdFlag)} className="detailed-checkout__clickid-container-checkbox">
          <Checkbox checked={clickIdFlag} />
          <p className="detailed-checkout__clickid-container-text">{t('checkouts.clickID')}</p>
        </div>
        <Tooltip
          placement="bottomRight"
          overlayClassName="detailed-checkout__clickid-container-tooltip"
          title={t('checkouts.tooltipTitle')}
        >
          <HelpCircleSVG />
        </Tooltip>
        {clickIdFlag && (
          <Input
            placeholder={t('checkouts.enterClickID')}
            className="detailed-checkout__clickid-container-input"
            value={clickIdData}
            onChange={e => validateClickId(e.target.value)}
          />
        )}
      </div>
      {AppConfig.showDomainInstruction && (
        <div className="detailed-checkout__clickid-container">
        <div
          onClick={() => (!isCustomDomain ? null : setCustomDomainFlag(!customDomainFlag))}
          className="detailed-checkout__clickid-container-checkbox"
        >
          <Checkbox checked={customDomainFlag} disabled={!isCustomDomain} />
          <p className="detailed-checkout__clickid-container-text">{t('checkouts.customDomain')}</p>
        </div>
        <Tooltip
          placement="bottomRight"
          overlayClassName="detailed-checkout__clickid-container-tooltip"
          title={
            <span>
              {t('checkouts.tooltipTitleDomain')}{' '}
              <a
                style={{ color: '#2A92FF' }}
                href="https://app.cpay.world/settings"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://app.cpay.world/settings
              </a>
            </span>
          }
        >
          <HelpCircleSVG />
        </Tooltip>
      </div>
      )}
      {showClickIdErrorMessage && (
        <span className="detailed-checkout__clickid-container-error">{t('checkouts.validation')}</span>
      )}
      {amountAndFiatCurrencyFlag && showAmoundDataMessage && (
        <span className="detailed-checkout__clickid-container-error">{t('checkouts.validationAmountData')}</span>
      )}
      {amountAndFiatCurrencyFlag && showFiatCurrencyDataMessage && (
        <span className="detailed-checkout__clickid-container-error">{t('checkouts.validationFiatCurrencyData')}</span>
      )}
      <Button type="primary" className="detailed-checkout__button" onClick={close}>
        {t('close')}
      </Button>
    </div>
  );
};

export default DetailedCheckout;
