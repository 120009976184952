import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import Header from './Header';
import Sider from './Sider';
import Main from './Main';
import NewFooter from '../../layout/NewFooter';
import { AppConfig } from '../../config';

const { Header: AntdHeader, Sider: AntdSider, Content: AntdContent } = Layout;

const LayoutWrapper = ({ children }) => {
  const [siderState, setSiderState] = useState(null);

  useEffect(() => {
    let sidebarMode = localStorage.getItem('adminSidebarMode');

    if (!sidebarMode) {
      sidebarMode = 'regular';
      localStorage.setItem('adminSidebarMode', 'regular');
    }

    setSiderState(sidebarMode);
  }, []);

  const toggleCollapseSider = () => {
    const newSidebarMode = localStorage.getItem('adminSidebarMode') === 'regular' ? 'collapsed' : 'regular';
    localStorage.setItem('adminSidebarMode', newSidebarMode);
    setSiderState(newSidebarMode);
  };

  return (
    <Layout>
      <AntdHeader>
        <Header toggleCollapseSider={toggleCollapseSider} />
      </AntdHeader>

      <Layout>
        <AntdSider width={siderState === 'regular' ? 90 : 40}>
          <Sider siderState={siderState}></Sider>
        </AntdSider>

        <AntdContent>
          <Main>{children}</Main>
          {AppConfig.showFooter && <NewFooter/>}
        </AntdContent>
      </Layout>
    </Layout>
  );
};

export default LayoutWrapper;
