import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Radio } from "antd";
import { identity } from "../../utils/getIdentity";

import "./styles.scss";

const NftFilters = ({ onlyNft, setOnlyNft, nftType, setNftType, setHideNft, hideNft }) => {
  const { t } = useTranslation();

  const [onlyNftValue, setOnlyNftValue] = useState(false);

  const hidingNft = () => {
    if (!hideNft) {
      setHideNft(true);
      setOnlyNft(false);
      setOnlyNftValue(false);
      localStorage.setItem('hideNft_tr', true);
      localStorage.setItem('onlyNft_tr', false)
    }

    if (hideNft) {
      console.log('sddsads')
      setHideNft(false);
      setOnlyNftValue(false);
      setOnlyNft(false);
      localStorage.setItem('hideNft_tr', false);
      localStorage.setItem('onlyNft_tr', false)
    }
  };

  const onChangeOnlyNFT = (e) => {
    setOnlyNftValue(e.target.checked);
    setOnlyNft(e.target.checked);
    setNftType(undefined);
    localStorage.setItem('hideNft_tr', false);
    localStorage.setItem('onlyNft_tr', true)
  };

  const hideShowClassName = hideNft ? "show" : "hide";

  return (
    <div className="transaction-filters__nft">
      <div className="transaction-filters__nft-title">
        <p>NFT</p>
        <p
          className={`transaction-filters__nft-title-${hideShowClassName} transaction-filters__nft-title-${hideShowClassName}-${identity}`}
          onClick={hidingNft}
        >
          {hideNft ? t("transactionHistory.showNFT") : t("transactionHistory.hideNFT")}
        </p>
      </div>
      <div className="transaction-filters__nft-box">
        <Checkbox checked={onlyNftValue} onChange={onChangeOnlyNFT} disabled={hideNft}>
          Only NFT
        </Checkbox>
        <Radio.Group
          value={nftType}
          onChange={(e) => {
            setNftType(e.target.value);
            localStorage.setItem("nftType_tr", e.target.value);
          }}
          disabled={!onlyNft}
        >
          <Radio value={undefined}>{t("all")}</Radio>
          <Radio value="erc721">NFT ERC-721</Radio>
          <Radio value="erc1155">NFT ERC-1155</Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default NftFilters;
