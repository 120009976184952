import React, { useState } from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../utils/resizeHook";
import { identity } from "../../utils/getIdentity";
import Button from "../Button";
import UnsubscribeLetterModal from "./UnsubscribeLetterModal";

const UnsubscribeLetter = ({ isUnsubscribeMail }) => {
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  return (
    <>
      <div className="profile-block">
        <span className={`profile__title profile__title-${identity}`}>
          {t("profile.unsubscribe")}
        </span>
        <span className="profile__subtitle">
          {t("profile.unsubscribeInfo")}
        </span>
        <Row gutter={[20, 0]}>
          <Col span={currentWidth >= 1024 ? 4 : 24}>
            <Button type="primary" className="form__button" onClick={openModal} disabled={isUnsubscribeMail}>
              {isUnsubscribeMail ? t("profile.unsubscribed") : t("profile.unsubscribe")}
            </Button>
          </Col>
        </Row>
      </div>

      {isOpenModal && (
        <UnsubscribeLetterModal
          isOpenModal={isOpenModal}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default UnsubscribeLetter;