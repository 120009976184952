import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningSVG } from '../../../img/default-svg/info-circle-orange.svg';
import './style.scss';
import usePartialPayment from '../usePartialPayment/usePartialPayment';

const PartialWarningMsg = ({ selectedCurrency, replenish, systemStatus }) => {
    const { t } = useTranslation();
    const {
        restAmountToPay,
        currency,
        value
    } = usePartialPayment(selectedCurrency, replenish,systemStatus);


    return (
        <div className="checkout__warning-block">
            <WarningSVG />
            <div>
                <p className='title'>{t('checkouts.partial.warnTitle', { payment: value, currency: currency, total: selectedCurrency?.amountCurrency })}</p>
                <p className='text'>{t('checkouts.partial.warnText', { amount: restAmountToPay, currency: currency })}</p>
            </div>
        </div>
    )
}


export default PartialWarningMsg;
