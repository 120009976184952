import React from 'react';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { AppConfig } from '../../config';

const EmailVerified = ({ handleClick }) => {

  const { t } = useTranslation();

  return (
    <div className="email-verified">
      <div className="email-verified__content">
        <span className={`email-verified__title email-verified__title-${AppConfig.identity}`}>{t('messages.emailHasBeenVerified')}</span>
        <span className="email-verified__subtitle">{t('messages.profileActivated')}</span>
        <Button type="primary" className={`login-form__button login-form__button-${AppConfig.identity}`} onClick={handleClick}>
          {t('messages.browseCryptopay', { name: AppConfig.titleSecondary})}
        </Button>
      </div>
    </div>
  );
};

export default EmailVerified;
