import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';

export const NFT_WALLETS_GET = 'NFT_WALLETS_GET';
export const NFT_WALLETS_SET = 'NFT_WALLETS_SET';

const nftWalletsGet = () => ({
  type: NFT_WALLETS_GET,
});
const nftWalletsSet = payload => ({
  type: NFT_WALLETS_SET,
  payload,
});

export const getNftWallets =
  dispatch =>
  async (merchantId, { positiveBalance, ...params }) => {
    dispatch(nftWalletsGet());
    let wallets;

    try {
      wallets = await cryptoApi.getMerchantWallets(merchantId, params, positiveBalance);
    } catch (err) {
      console.log('err in getNftWallets', err, err.data)
      dispatch(notificationsErrorShow(err.data));
      return;
    }

    if (wallets && wallets.data) {
      dispatch(nftWalletsSet(wallets.data));
      return;
    }
  };

export const NFT_TRANSACTIONS_GET = 'NFT_TRANSACTIONS_GET';
export const NFT_TRANSACTIONS_SET = 'NFT_TRANSACTIONS_SET';

const nftTransactionsGet = () => ({
  type: NFT_TRANSACTIONS_GET,
});
const nftTransactionsSet = payload => ({
  type: NFT_TRANSACTIONS_SET,
  payload,
});

export const getNftTransactions = dispatch => async (merchantId, params) => {
  dispatch(nftTransactionsGet());
  let transactions;

  try {
    transactions = await cryptoApi.getTransactions(merchantId, params);
  } catch (err) {
    dispatch(notificationsErrorShow(err.data));
    return;
  }

  if (transactions && transactions.data) {
    dispatch(nftTransactionsSet(transactions.data));
    return;
  }
};
