import React, { useState, useEffect } from "react";
import { Modal, Button } from 'antd';
import { useTranslation } from "react-i18next";
import '../../layout/Modal.scss';
import './style.scss';

const EditAutoswapModal = ({ visible, closeModal, name, confirmEdit }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsVisible(visible)
  }, [visible]);

  const handleCancel = e => {
    setIsVisible(false);
    closeModal();
  };

  return (
    <React.Fragment>
      <Modal
        width={275}
        visible={isVisible}
        onCancel={handleCancel}
        footer={null}
        className="remove-autoswap"
      >
        <div className="remove-autoswap-content">
          <div className="modal-form__title">Edit</div>
          <div className="modal-form__subtitle">Do you want to edit “{name}”</div>
          <div className="modal-form__btnWrapper">
            <Button type="default" className="form__button component__button remove-autoswap-btn" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="primary" className="form__button component__button" onClick={confirmEdit}>
              Edit
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
};

export default EditAutoswapModal;