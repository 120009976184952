import React from 'react';
import TEXT from '../Text';
import { FiatAmountInput } from '../FormFields';
import './style.scss';

const WithdrawalSetting = ({ name }) => (
  <div className="withdrawal-setting">
    <TEXT.sectionTitle>Withdrawal settings</TEXT.sectionTitle>
    <TEXT.sectionDescription className="system-fee-switcher__description">
      You can set the withdrawal threshold, i.e. from what amount in USD a user can withdraw cryptocurrency
    </TEXT.sectionDescription>
    <FiatAmountInput
      name={name}
      className="withdrawal-setting__input"
      rules={[
        {
          validator: (rule, value) => {
            if (value === 0) {
              return Promise.reject('Minimum withdrawal amount must be greater than 0');
            }
            return Promise.resolve();
          },
        },
      ]}
    />
  </div>
);

export default WithdrawalSetting;
