import {
  TWO_FACTOR_GET,
  TWO_FACTOR_SET,
  TWO_FACTOR_ERROR,
  TWO_FACTOR_EMAIL_CHANGE,
  TWO_FACTOR_EMAIL_DONE,
  TWO_FACTOR_APP_CHANGE,
  TWO_FACTOR_APP_DONE,
  TWO_FACTOR_PHONE_CHANGE,
  TWO_FACTOR_PHONE_DONE
} from '../actions/twoFactor';

const initState = {
  data: [],
  fetching: false,
  emailTwoFaFetching: false,
  appTwoFaFetching: false,
  phoneTwoFaFetching: false
}

export default function reducer(
  state = initState,
  action
) {
  switch (action.type) {
    case TWO_FACTOR_GET:
      return {
        ...state,
        fetching: true,
      };
    case TWO_FACTOR_SET:
      return {
        ...state,
        data: action.payload.data,
        fetching: false,
      };
    case TWO_FACTOR_EMAIL_CHANGE:
      return {
        ...state,
        emailTwoFaFetching: true,
      };
    case TWO_FACTOR_EMAIL_DONE:
      return {
        ...state,
        emailTwoFaFetching: false,
      };
    case TWO_FACTOR_APP_CHANGE:
      return {
        ...state,
        appTwoFaFetching: true,
      };
    case TWO_FACTOR_APP_DONE:
      return {
        ...state,
        appTwoFaFetching: false,
      };
    case TWO_FACTOR_PHONE_CHANGE:
      return {
        ...state,
        phoneTwoFaFetching: true,
      };
    case TWO_FACTOR_PHONE_DONE:
      return {
        ...state,
        phoneTwoFaFetching: false,
      };
    case TWO_FACTOR_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
