import React, { useState } from 'react';
import Button from '../Button';
import { ReactComponent as SendLogo } from '../../img/default-svg/send-home.svg';
import { ReactComponent as ReceiveLogo } from '../../img/default-svg/receive-home.svg';
import { ReactComponent as SwapLogo } from '../../img/default-svg/arrow-swap-horizontal-1.svg';
import { useHistory } from 'react-router-dom';

import ReplenishModal from './Modals/ReplenishModal';
import WithdrawModal from './Modals/WithdrawModal';

import './styles.scss';

const HomeActions = ({ merchantId, networkFilter, currencyId, setCurrencyId, merchantBalances }) => {
  const [openReplenish, setOpenReplenish] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);

  const handleOpenReplenish = () => setOpenReplenish(true);
  const handleOpenWithdraw = () => setOpenWithdraw(true);

  const handleCloseReplenish = () => setOpenReplenish(false);
  const handleCloseWithdraw = () => setOpenWithdraw(false);

  const history = useHistory();

  const goToSwap = () => {
    history.push('/swap');
  };

  return (
    <>
      <div className="home-action">
        <Button onClick={handleOpenWithdraw}>
          <SendLogo /> Send
        </Button>
        <Button onClick={handleOpenReplenish}>
          <ReceiveLogo /> Receive
        </Button>
        <Button onClick={() => goToSwap()}>
          <SwapLogo /> Swap
        </Button>
      </div>

      {openReplenish && (
        <ReplenishModal
          openReplenish={openReplenish}
          handleCloseReplenish={handleCloseReplenish}
          merchantId={merchantId}
          networkFilter={networkFilter}
          currencyId={currencyId}
          merchantBalances={merchantBalances}
        />
      )}
      {openWithdraw && (
        <WithdrawModal
          openWithdraw={openWithdraw}
          handleCloseWithdraw={handleCloseWithdraw}
          merchantId={merchantId}
          networkFilter={networkFilter}
          currencyId={currencyId}
          merchantBalances={merchantBalances}
        />
      )}
    </>
  );
};

export default HomeActions;
