import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIconSvg } from '../../../img/default-svg/empty-search.svg';
import './style.scss';

const SwapWalletsEmpty = () => {
  const {t} = useTranslation();

  return (
    <div className="swap-wallets-empty">
      <div className="swap-wallets-empty__icon">
        <SearchIconSvg />
      </div>
      <div className="swap-wallets-empty__text">{t('swap.noParameters')}</div>
    </div>
  );
};

export default SwapWalletsEmpty;
