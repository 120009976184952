import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Modal, Form, Input } from 'antd';
import '../../layout/Modal.scss';
import './MerchantDeleteModal.scss';
import { useTranslation } from 'react-i18next';
import { deleteMerchant, merchantsResetTwoFa } from '../../redux/actions/merchants';
import { notificationsErrorShow } from '../../redux/actions/notifications';
import { identity } from '../../utils/getIdentity';
import ResendPhone2FA from '../../ResendPhone2FA';
import ResendEmail2FA from '../ResendEmail2FA';

const MerchantDeleteModal = ({
  merchantId,
  merchantName,
  isLastMerchant,
  deleteMerchant,
  notificationsErrorShow,
  isFetching,
  isAppTwoFa,
  isEmailTwoFa,
  isPhoneTwoFa,
  merchantsResetTwoFa,
  showDelete,
  setShowDelete,
}) => {
  const [appTwoFaCode, setAppTwoFaCode] = useState('');
  const [emailTwoFaCode, setEmailTwoFaCode] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [merchantDeleteForm] = Form.useForm();
  const { t } = useTranslation();

  const handleOk = e => {
    merchantDeleteForm.validateFields();
    if (isAppTwoFa && !appTwoFaCode) {
      notificationsErrorShow({ message: t('validation.twoFaRequired') });
      return;
    }

    if (isEmailTwoFa && !emailTwoFaCode) {
      notificationsErrorShow({ message: t('validation.emailTwoFaRequired') });
      return;
    }

    if (isPhoneTwoFa && !verifyCode) {
      notificationsErrorShow({ message: t('validation.phoneTwoFaRequired') });
      return;
    }

    deleteMerchant(merchantId, appTwoFaCode, emailTwoFaCode, verifyCode).then(response => {
      if (response) {
        setShowDelete(false);
      }
    });
  };

  const handleCancel = e => {
    setShowDelete(false);
    merchantDeleteForm.resetFields();
    setAppTwoFaCode('');
    setEmailTwoFaCode('');
    setVerifyCode('');
    merchantsResetTwoFa();
  };
  return (
    <React.Fragment>
      <Modal
        title={t('merchants.createMerchant')}
        visible={showDelete}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="modal modal-delete-merc"
      >
        <div className='delete-modal-merc'>
        <Row>
          <Col span="24">
            <p className="modal__text">
              {t('merchants.deleteMerchantConfirm')} <b>{merchantName}</b>?
            </p>
          </Col>
        </Row>
        <Form
          form={merchantDeleteForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          className="modal-form"
        >
          {isPhoneTwoFa && !isEmailTwoFa && !isAppTwoFa && (
            <>
              <Form.Item label={t('auth.сodeFromSms')} name="verifyCode" className="modal-form__label" 
              rules={[{ required: true, message: t('validation.phoneTwoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.сodeFromSms')}
                  className="modal-form__input"
                  maxLength="6"
                  onChange={e => setVerifyCode(e.target.value)}
                />
              </Form.Item>
              <ResendPhone2FA />
            </>
          )}
          {isAppTwoFa && (
            <Form.Item
              label={t('auth.keyFromGA')}
              name="appTwoFaCode"
              className="modal-form__label"
              rules={[{ required: true, message: t('validation.emailTwoFaRequired') }]}
            >
              <Input
                placeholder={t('auth.keyFromGA')}
                className="modal-form__input"
                maxLength="6"
                onChange={e => setAppTwoFaCode(e.target.value)}
              />
            </Form.Item>
          )}
          {isEmailTwoFa && !isAppTwoFa && (
            <>
              <Form.Item
                label={t('auth.keyFromEmail')}
                name="emailTwoFaCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.emailTwoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.keyFromEmail')}
                  className="modal-form__input"
                  maxLength="6"
                  onChange={e => setEmailTwoFaCode(e.target.value)}
                />
              </Form.Item>
              <ResendEmail2FA />
            </>
          )}
        </Form>
        <Row className="modal__buttons-wrapper modal-delete-merc__buttons-wrapper">
          <Col>
            <Button
              type="primary"
              className={`modal__button modal__button-create modal__button modal__button-create-${identity} modal__button-create=${identity}`}
              onClick={handleOk}
              loading={isFetching}
            >
              {t('delete')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </Col>
        </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isFetching: state.merchants.fetching,
  isAppTwoFa: state.merchants.isAppTwoFa,
  isEmailTwoFa: state.merchants.isEmailTwoFa,
  isPhoneTwoFa: state.merchants.isPhoneTwoFa,
});

const mapDispatchToProps = dispatch => ({
  deleteMerchant: deleteMerchant(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
  merchantsResetTwoFa: () => dispatch(merchantsResetTwoFa()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDeleteModal);
