import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import { notificationsErrorShow } from '../redux/actions/notifications';
import { resendEmailTwoFa } from '../../redux/actions/twoFactor';
import { Timer } from '../Timer/Timer';
import { addMinutes } from '../../utils/addMinutes';
import './style.scss';
import { identity } from '../../utils/getIdentity';

const ResendEmail2FA = ({ emailFromLogin, resendEmailTwoFa, userData }) => {
  const [disableResend, setDisableResend] = useState(true);
  const { t } = useTranslation();

  const validUntil = addMinutes(1);

  const handleDisableResend = () => {
    const timeout = setTimeout(() => {
      setDisableResend(false);
    }, 60000);

    return () => clearTimeout(timeout);
  };

  const resendVerifyCode = async () => {
    try {
      await resendEmailTwoFa(emailFromLogin ? emailFromLogin : userData?.email);
      setDisableResend(true);
      handleDisableResend();
    } catch (error) {
      console.log('error', error);
      // notificationList.failed(error.data.message);
    }
  };

  return (
    <div className="resend-email-twoFa">
      <span>{t('doNotReveiveCode')}</span>
      {disableResend ? (
        <span className="resend-email-twoFa__timer">
          {' '}
          Receive in <Timer validUntil={validUntil} onExpire={() => setDisableResend(false)} />
        </span>
      ) : (
        <span
          className={`resend-email-twoFa__button resend-email-twoFa__button-${identity}`}
          onClick={resendVerifyCode}
        >
          {' '}
          {t('resend')}
        </span>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  userData: state.user.data,
});

const mapDispatchToProps = dispatch => ({
  resendEmailTwoFa: resendEmailTwoFa(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendEmail2FA);
