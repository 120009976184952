export const tokenNodeTypeObj = {
  eth: 'ERC-20',
  bsc: 'BEP-20',
  trx: 'TRC-20',
};


export const notIncludedNodeType = ['optimism', 'matic', 'arbitrum', 'avax', 'solana', 'ftm', 'moonbeam']

export const tokenTypeEnd = (currencyType, nodeType, currency ) => {
  const currencyUSDTorUSDC = currency === 'USDT' || currency === 'USDC' || currency === 'TUSD';

  if (currencyType === 'token' && nodeType === 'optimism' && currency !== 'OP') {
    return "OP"
  }
  if (currency === 'ETH' && currencyType === 'currency' && nodeType === 'optimism') {
    return "OP"
  }
  if (currencyType === 'currency' && nodeType === 'arbitrum' && currency !== 'arbitrum') {
    return 'ARB'
  }
  if ((currencyUSDTorUSDC || currency === 'ETH') && currencyType === 'token' && nodeType === 'arbitrum') {
    return "ARB"
  }
  if (currencyUSDTorUSDC && currencyType === 'token' && nodeType === 'matic') {
    return 'MATIC'
  }
  if (currency === 'USDT' && currencyType === 'token' && nodeType === 'avax') {
    return 'AVAX'
  }
  if (currency === 'USDC' && currencyType === 'token' && nodeType === 'solana') {
    return "SOL"
  }
  if (currency === 'USDT' && currencyType === 'token' && nodeType === 'ftm') {
    return "FTM"
  }
  if (currencyUSDTorUSDC && currencyType === 'token' && nodeType === 'moonbeam') {
    return "GLMR"
  }
  if (currency === 'ETH' && currencyType === 'currency' && nodeType === 'blast') {
    return "Blast"
  }
  if (currencyUSDTorUSDC && currencyType === 'token' && nodeType === 'ton') {
    return "TON"
  }
}