import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as WarningIcon } from '../../../../img/default-svg/info-circle-warning.svg';
import { ReactComponent as UploadIcon } from '../../../../img/default-svg/upload.svg';
import { useTranslation } from 'react-i18next';
import Button from '../../../Button';
import './style.scss';

const MultisendForm = ({ text, setText, data, setData, currency, wallet, validation, setValidation, handleSubmit }) => {
  const [duplicates, setDuplicates] = useState([]);
  const [duplicatesAmounts, setDuplicatesAmounts] = useState({});
  const [ignoreDuplicates, setIgnoreDuplicates] = useState(false);
  const [texareaScrollTop, setTexareaScrollTop] = useState('');
  const [isFileDropperActive, setIsFileDropperActive] = useState(false);
  const { t } = useTranslation();
  const lineNumbersRef = useRef(null);

  useEffect(() => {
    lineNumbersRef.current.scrollTop = texareaScrollTop;
  }, [texareaScrollTop]);

  useEffect(() => {
    if (text) {
      setData(parseTextarea(text));
    } else {
      setData([]);
      setDuplicates([]);
      setDuplicatesAmounts({});
      setIgnoreDuplicates(false);
    }
  }, [text]);

  useEffect(() => {
    handleDataArray(data);
  }, [data, ignoreDuplicates]);

  const parseTextarea = text => {
    const lines = text.split('\n');
    const addressValueArr = lines.map(line => line.split(';').map(str => str.trim()));
    return addressValueArr;
  };

  const handleFileUpload = fileArray => {
    if (fileArray && fileArray[0]) {
      const myFile = fileArray[0];
      const reader = new FileReader();

      reader.addEventListener('load', function (e) {
        let csvdata = e.target.result.replace('address;amount\r\n', '').replace('Address;Amount\r\n', '');
        setText(csvdata);
        setIsFileDropperActive(false);
      });

      reader.readAsBinaryString(myFile);
    }
  };

  const findDuplicates = data => {
    const duplicates = [];
    const duplicatesAmounts = {};

    for (let i = 0; i < data.length; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (data[i][0] === data[j][0] && +data[j][1]) {
          duplicates.push(j);
          duplicatesAmounts[data[i][0]] = (duplicatesAmounts[data[i][0]] || 0) + +data[j][1];
          break;
        }
      }
    }

    return [duplicates, duplicatesAmounts];
  };

  const keepFirstDuplicate = () => {
    const filteredResult = data.filter((item, index) => !duplicates.includes(index));
    setText(filteredResult.map(item => item.join(';')).join('\n'));
    setDuplicates([]);
    setDuplicatesAmounts({});
  };

  const mergeDuplicatesAmounts = () => {
    const filteredResult = data.filter((item, index) => !duplicates.includes(index));
    setText(
      filteredResult
        .map(item => {
          item[1] = duplicatesAmounts[item[0]] && +item[1] ? +item[1] + duplicatesAmounts[item[0]] : item[1];
          return item.join(';');
        })
        .join('\n')
    );
    setDuplicates([]);
    setDuplicatesAmounts({});
  };

  const keepDuplicates = () => {
    setDuplicates([]);
    setDuplicatesAmounts({});
    setIgnoreDuplicates(true);
  };

  const handleDataArray = data => {
    if (!data.length) {
      setValidation('');
      return;
    }

    let validationString = '';

    const [duplicates, duplicatesAmounts] = !ignoreDuplicates ? findDuplicates(data) : [undefined, undefined];

    if ((duplicates, duplicatesAmounts)) {
      setDuplicates(duplicates);
      setDuplicatesAmounts(duplicatesAmounts);
    }

    for (let i = 0; i < data.length; i++) {
      const [address, amount] = data[i];

      // multisend.lineAmountValidation
      // multisend.lineAddressValidation
      // multisend.lineDuplicated

      if (!address && (!amount || !+amount)) {
        validationString += `${validationString && '\n'}${t('multisendWithdraw.lineAddressAndAmount', {
          line: i + 1,
          address: address || '',
        })}`;
        continue;
      }

      if (!address) {
        validationString += `${validationString && '\n'}${t('multisendWithdraw.lineAddressValidation', {
          line: i + 1,
          address: address || '',
        })}`;
        continue;
      }

      if (!amount || !+amount) {
        validationString += `${validationString && '\n'}${t('multisendWithdraw.lineAmountValidation', {
          line: i + 1,
          amount: amount || '',
        })}`;
        continue;
      }

      if (!ignoreDuplicates && duplicates.includes(i)) {
        validationString += `${validationString && '\n'}${t('multisendWithdraw.lineDuplicated', {
          line: i + 1,
          address: address || '',
        })}`;
        continue;
      }
    }

    setValidation(validationString);
  };

  return (
    <div className="multisend-form">
      <div className="multisend-form__textarea-wrap">
        <div className="multisend-form__textarea-header">
          <span className="multisend-form__textarea-title">{t('multisendWithdraw.addressesWithAmounts')}</span>
          <span className="multisend-form__file-switcher" onClick={() => setIsFileDropperActive(!isFileDropperActive)}>
            {isFileDropperActive ? t('multisendWithdraw.insertManually') : t('multisendWithdraw.uploadFile')}
          </span>
        </div>

        <div className="multisend-form__textarea">
          <ul ref={lineNumbersRef}>
            {text.split('\n').map((item, index) => (
              <li key={index}>{index}</li>
            ))}
          </ul>
          <textarea
            value={text}
            onChange={e => setText(e.target.value)}
            onScroll={e => setTexareaScrollTop(e.target.scrollTop)}
            spellCheck="false"
          ></textarea>
          {isFileDropperActive && (
            <label
              className="multisend-form__files"
              onDragEnter={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragOver={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragLeave={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrop={e => {
                e.preventDefault();
                e.stopPropagation();
                handleFileUpload(e.dataTransfer.files);
              }}
            >
              <input required type="file" onChange={e => handleFileUpload(e.target.files)} />
              <UploadIcon />
              <span className="multisend-form__files-tip">{t('multisendWithdraw.dropYourFilesOrClickToUpload')}</span>
            </label>
          )}
        </div>
        <div className="multisend-form__textarea-footer">
          <span className="multisend-form__textarea-tip">
            {t('multisendWithdraw.theAddressesAndAmountSeparateWithSemicolon')}
          </span>
          <span
            className="multisend-form__textarea-examples"
            onClick={() =>
              setText(
                '0x3df332e44a0bbff025838c187873d77f92caf5e9;0.001\n0x76d31966abf3edeb29e599eac4adcb72fba85e6a;1\n0xC8c30Fa803833dD1Fd6DBCDd91Ed0b301EFf87cF;3.45'
              )
            }
          >
            {t('multisendWithdraw.showExamples')}
          </span>
        </div>
      </div>

      {duplicates.length ? (
        <div className="multisend-form__duplicates">
          <span className="multisend-form__duplicates-title">{t('multisendWithdraw.duplicatedWalletAddresses')}</span>
          <span className="multisend-form__duplicates-button" onClick={keepFirstDuplicate}>
            {t('multisendWithdraw.keepTheFirstOne')}
          </span>
          <span className="multisend-form__duplicates-button" onClick={mergeDuplicatesAmounts}>
            {t('multisendWithdraw.mergeBalances')}
          </span>
          <span className="multisend-form__duplicates-button" onClick={keepDuplicates}>
            {t('multisendWithdraw.keepDuplicateAddresses')}
          </span>
        </div>
      ) : null}

      {validation && (
        <div className="multisend-form__validation">
          {validation.split('\n').map(line => (
            <div key={line} className="multisend-form__validation-item">
              <WarningIcon />
              <span>{line}</span>
            </div>
          ))}
        </div>
      )}

      <Button
        className="multisend-form__button"
        onClick={() => handleSubmit(data)}
        disabled={validation || !text || !currency || !wallet}
      >
        {t('next')}
      </Button>
    </div>
  );
};

export default MultisendForm;
