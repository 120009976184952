import React, { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import SaleTokenWrapper from '../components/SaleTokenCheckout/SaleTokenWrapper/SaleTokenWrapper';
import SaleTokenPayment from '../components/SaleTokenPayment';
import SaleTokenProgress from '../components/SaleTokenProgress';
import Expired from '../components/CheckoutPayment/Expired';
import NotFound from '../components/CheckoutPayment/NotFound';
import SaleTokenDone from '../components/SaleTokenDone';
import ClientDataForm from '../components/SaleTokenCheckout/ClientDataForm';
import {
  getSaleTokenChargeData,
  createSaleTokenChargeIdReset,
  getSaleTokenChargeDataReset,
} from '../redux/actions/checkoutPayment';
import { AppConfig } from '../config';
import { Helmet } from 'react-helmet';

const SaleTokenPaymentPage = ({
  getChargeState,
  getSaleTokenChargeData,
  createSaleTokenChargeIdReset,
  getSaleTokenChargeDataReset,
}) => {
  const [progressStep, setProgressStep] = useState(0);
  const [expired, setExpired] = useState(false);
  const [timer, setTimer] = useState(null);
  const [showCustomerDataForm, setShowCustomerDataForm] = useState(false);
  const { chargeId } = useParams();
  const history = useHistory();
  const timerRef = useRef(null);
  const { t } = useTranslation();
  const [checkoutDeleted, setCheckoutDeleted] = useState(false);

  useEffect(() => {
    if (!getChargeState || !getChargeState.data) return;

    setShowCustomerDataForm(
      (getChargeState.data?.checkout?.collectName && !getChargeState.data.customerName) ||
        (getChargeState.data?.checkout?.collectEmail && !getChargeState.data.customerEmail)
    );
  }, [getChargeState]);

  useEffect(
    () => () => {
      clearInterval(timerRef.current);
      timerRef.current = null;
    },
    []
  );

  useEffect(() => {
    if (timerRef.current && timer <= 0) {
      clearInterval(timerRef.current);
      setExpired(true);
      setTimer(null);
      timerRef.current = null;
    }
  }, [timer]);

  useEffect(() => {
    if (getChargeState.data) {
      const { expiredDate } = getChargeState.data;
      const timeLeft = new Date(expiredDate) - new Date();
      if (timeLeft > 0) {
        setTimer(timeLeft);
        if (!timerRef.current) {
          timerRef.current = setInterval(() => {
            setTimer(timer => (timer ? timer - 1000 : timeLeft));
          }, 1000);
        }
      } else {
        setExpired(true);
      }
    }
  }, [getChargeState.data]);

  useEffect(() => {
    if (getChargeState.data) {
      if (getChargeState.data.systemStatus === 'receiveComplete' || getChargeState.data.systemStatus === 'Failed') {
        clearInterval(timerRef.current);
        timerRef.current = null;
        setExpired(false);
        setTimer(null);
      }

      if (localStorage.getItem('isRedirected') !== getChargeState?.data?.checkout?.identifier) {
        if (
          getChargeState?.data?.systemStatus !== 'receiveComplete' &&
          getChargeState?.data?.checkout?.tradedCurrency._id !== getChargeState?.data?.currencyToId
        ) {
          setTimeout(() => {
            history.push({
              pathname: `/sale-token-checkout/${getChargeState?.data?.checkout?.identifier}`,
              state: { from: 'saleTokenPaymentPage' },
            });
          }, 500);
        }
        return;
      }

      setCheckoutDeleted(() => (!getChargeState?.data?.checkout ? true : false));
    }
  }, [getChargeState, history]);

  useEffect(() => {
    if (chargeId) {
      createSocket(chargeId);
      getSaleTokenChargeData(chargeId);
    }
  }, [chargeId]);

  const millisToMinutesAndSeconds = millis => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  };

  const buttonRestartHandler = () => {
    const { identifier } = getChargeState?.data?.checkout || {};
    createSaleTokenChargeIdReset();
    getSaleTokenChargeDataReset();
    history.push(`/sale-token-checkout/${identifier}${chargeId ? `?chargeId=${chargeId}` : ''}`);
  };

  const createSocket = chargeId => {
    const io = window.io;
    const socket = io(process.env.REACT_APP_API_URL,
      {
        allowEIO3: true,
        withCredentials: true,
        // extraHeaders: {
        //   'Access-Control-Allow-Credentials': true
        // }
      }
    );
    socket.on('connect', () => {
      socket.emit('sign-in-charge', chargeId);
    });
    socket.on('sign-in-charge', data => {});
    socket.on('depositPending', data => {
      getSaleTokenChargeData(chargeId);
    });
    socket.on('depositComplete', data => {
      setProgressStep(1);
      getSaleTokenChargeData(chargeId);
    });
    socket.on('exchanging', data => {
      setProgressStep(2);
      getSaleTokenChargeData(chargeId);
    });
    socket.on('receiveComplete', data => {
      setProgressStep(3);
      getSaleTokenChargeData(chargeId);
    });
    socket.on('disconnect', function () {});
  };

  return (
    <>
      {AppConfig.identity === 'cpay' && (
          <Helmet>
            <link rel="icon" href="/cpay-icons/favicon.ico" type="image/x-icon" />
          </Helmet>
        )}

        {AppConfig.identity === 'nfg' && (
          <Helmet>
          <link rel="icon" href="/nfgpay-icons/favicon.ico" type="image/x-icon" />
        </Helmet>
        )}

      {AppConfig.identity === 'finvaro' && (
        <Helmet>
          <link rel="icon" href="/finvaro-icons/favicon.ico" type="image/x-icon" />
        </Helmet>
      )}

      {AppConfig.identity === 'clarnium' && (
        <Helmet>
          <link rel="icon" href="/clarnium-icons/favicon.ico" type="image/x-icon" />
        </Helmet>
      )}

      {getChargeState?.fetching && !checkoutDeleted ? (
        <div className="checkout-payment__loading-container">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />
        </div>
      ) : (
        <>
          {getChargeState.data && !checkoutDeleted ? (
            showCustomerDataForm ? (
              <SaleTokenWrapper
                title={getChargeState.data?.checkout?.productName}
                description={getChargeState.data?.checkout?.description}
                hideSubtitle={true}
                image={getChargeState.data?.checkout?.image}
                logoImage={getChargeState.data?.checkout?.logoImage}
                backgroundColor={getChargeState.data?.checkout?.backgroundColor}
              >
                <ClientDataForm getChargeState={getChargeState} getChargeData={getSaleTokenChargeData} />
              </SaleTokenWrapper>
            ) : (
              <SaleTokenWrapper
                title={getChargeState.data?.checkout?.productName}
                description={getChargeState.data?.checkout?.description}
                hideSubtitle={true}
                image={getChargeState.data?.checkout?.image}
                logoImage={getChargeState.data?.checkout?.logoImage}
                backgroundColor={getChargeState.data?.checkout?.backgroundColor}
              >
                {expired ? (
                  <Expired buttonRestartHandler={buttonRestartHandler} />
                ) : getChargeState.data ? (
                  getChargeState.data.systemStatus === 'receiveComplete' ? (
                    <SaleTokenDone getChargeState={getChargeState} buttonRestartHandler={buttonRestartHandler} />
                  ) : (
                    <>
                      {getChargeState.data && <SaleTokenPayment getChargeState={getChargeState} />}
                      {timer && !expired && (
                        <div className="checkout-payment__timer">{`${t(
                          'checkouts.pending.exchangeExp'
                        )} ${millisToMinutesAndSeconds(timer)}`}</div>
                      )}
                      <SaleTokenProgress getChargeState={getChargeState} progressStep={progressStep} />
                    </>
                  )
                ) : (
                  <></>
                )}
              </SaleTokenWrapper>
            )
          ) : (
            <NotFound title={t('checkouts.checkoutForm.charge')} />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  getChargeState: state.checkoutPayment.getCharge,
});

const mapDispatchToProps = {
  getSaleTokenChargeData,
  createSaleTokenChargeIdReset,
  getSaleTokenChargeDataReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleTokenPaymentPage);
