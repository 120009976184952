import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Modal, Form, Input } from 'antd';
import '../../../layout/Modal.scss';
import './WalletDeleteModal.scss';
import { useTranslation } from 'react-i18next';
import { resetTwoFaState } from '../../../redux/actions/merchantWallets';
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import { identity } from '../../../utils/getIdentity';
import ResendPhone2FA from '../../../ResendPhone2FA';
import ResendEmail2FA from '../../ResendEmail2FA';

const WalletDeleteModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  merchantId,
  walletId,
  walletAddress,
  deleteMerchantWallet,
  resetTwoFaState,
  notificationsErrorShow,
  isFetching,
  isAppTwoFa,
  isEmailTwoFa,
  isPhoneTwoFa,
  typeWallet,
  page,
  pages,
  countItem,
  walletsFilters,
  networkFilter,
  isFromMerchantsWallets,
}) => {
  const [appTwoFaCode, setAppTwoFaCode] = useState('');
  const [emailTwoFaCode, setEmailTwoFaCode] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleOk = e => {
    form.validateFields();
    if (isAppTwoFa && !appTwoFaCode) {
      notificationsErrorShow({ message: t('validation.twoFaRequired') });
      return;
    }

    if (isEmailTwoFa && !emailTwoFaCode) {
      notificationsErrorShow({ message: t('validation.emailTwoFaRequired') });
      return;
    }

    if (isPhoneTwoFa && !verifyCode) {
      notificationsErrorShow({ message: t('validation.phoneTwoFaRequired') });
      return;
    }

    deleteMerchantWallet(
      merchantId,
      walletId,
      typeWallet,
      page,
      pages,
      countItem,
      walletsFilters,
      appTwoFaCode,
      emailTwoFaCode,
      verifyCode,
      networkFilter,
      isFromMerchantsWallets
    ).then(response => response && setOpenDeleteModal(false));
  };

  const handleCancel = e => {
    setOpenDeleteModal(false);
    setAppTwoFaCode('');
    setEmailTwoFaCode('');
    setVerifyCode('');
    resetTwoFaState();
  };

  return (
    <>
      <Modal
        title={t('delete')}
        visible={openDeleteModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="modal modal-delete-merc"
        afterClose={() => {
          setAppTwoFaCode('');
          setEmailTwoFaCode('');
          setVerifyCode('');
          form.resetFields();
          resetTwoFaState();
        }}
      >
        <div className="delete-modal-wallet">
          <Row>
            <Col span="24">
              <p className="modal__text">
                {t('wallets.deleteWalletConfirm')} <b>{walletAddress}</b>?
              </p>
            </Col>
          </Row>
          <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="basic" className="modal-form">
            {isPhoneTwoFa && !isEmailTwoFa && !isAppTwoFa && (
              <>
                <Form.Item
                  label={t('auth.сodeFromSms')}
                  name="verifyCode"
                  className="modal-form__label"
                  rules={[{ required: true, message: t('validation.phoneTwoFaRequired') }]}
                >
                  <Input
                    placeholder={t('auth.сodeFromSms')}
                    className="modal-form__input"
                    maxLength="6"
                    onChange={e => setVerifyCode(e.target.value)}
                  />
                </Form.Item>
                <ResendPhone2FA />
              </>
            )}
            {isAppTwoFa && (
              <Form.Item
                label={t('auth.keyFromGA')}
                name="appTwoFaCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.twoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.keyFromGA')}
                  className="modal-form__input"
                  maxLength="6"
                  onChange={e => setAppTwoFaCode(e.target.value)}
                />
              </Form.Item>
            )}
            {isEmailTwoFa && !isAppTwoFa && (
              <>
                <Form.Item
                  label={t('auth.keyFromEmail')}
                  name="emailTwoFaCode"
                  className="modal-form__label"
                  rules={[{ required: true, message: t('validation.emailTwoFaRequired') }]}
                >
                  <Input
                    placeholder={t('auth.keyFromEmail')}
                    className="modal-form__input"
                    maxLength="6"
                    onChange={e => setEmailTwoFaCode(e.target.value)}
                  />
                </Form.Item>
                <ResendEmail2FA />
              </>
            )}
          </Form>
          <Row className="modal__buttons-wrapper modal-delete-merc__buttons-wrapper">
            <Col>
              <Button
                type="primary"
                className={`modal__button modal__button-create modal__button modal__button-create-${identity} modal__button-create=${identity}`}
                onClick={handleOk}
                loading={isFetching}
              >
                {t('delete')}
              </Button>
            </Col>
            <Col>
              <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
                {t('cancel')}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  isFetching: state.merchantWallets.modalFetching,
  isAppTwoFa: state.merchantWallets.isAppTwoFa,
  isEmailTwoFa: state.merchantWallets.isEmailTwoFa,
  isPhoneTwoFa: state.merchantWallets.isPhoneTwoFa,
  networkFilter: state.networkFilter,
});

const mapDispatchToProps = dispatch => ({
  resetTwoFaState: resetTwoFaState(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletDeleteModal);
