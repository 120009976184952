import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowBackIcon } from '../../../img/swap-svg/swap-back.svg';
import { ReactComponent as ArrowBackIconFinvaro } from '../../../img/finvaro/swap-back.svg';
import { ReactComponent as ArrowBackIconClarnium } from '../../../img/clarnium/swap-back.svg';
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const SwapBack = ({ action, maxWidth }) => {
  const {t} = useTranslation();

  return (
    <div className={`swap-back swap-back-${identity}`} style={{ maxWidth }}>
      <div className={`swap-back__button swap-back__button-${identity}`} onClick={action}>
        {identity === 'finvaro' ? <ArrowBackIconFinvaro /> : identity === 'clarnium' ? <ArrowBackIconClarnium /> : <ArrowBackIcon />} {t('swap.backButton')}
      </div>
    </div>
  );
};

export default SwapBack;
