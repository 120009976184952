import React, { createContext, useState, useEffect, useContext } from 'react';

const NftSupportContext = createContext();

export const useNftSupport = () => useContext(NftSupportContext);

export const NftSupportProvider = ({ children }) => {
  const [showNftSupportBlock, setShowNftSupportBlock] = useState(
    JSON.parse(localStorage.getItem('showNftSupportBlock')) ?? true
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setShowNftSupportBlock(JSON.parse(localStorage.getItem('showNftSupportBlock')) ?? true);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const toggleNftSupportBlock = () => {
    const newValue = !showNftSupportBlock;
    setShowNftSupportBlock(newValue);
    localStorage.setItem('showNftSupportBlock', JSON.stringify(newValue));
  };

  return (
    <NftSupportContext.Provider value={{ showNftSupportBlock, toggleNftSupportBlock }}>
      {children}
    </NftSupportContext.Provider>
  );
};
