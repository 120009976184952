import React, { useState } from 'react';
import { Form, Select } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import TokenIcon from '../../TokenIcon';
import { ReactComponent as SearchIcon } from '../../../img/swap-svg/swap-search.svg';
import { ReactComponent as SelectArrowIcon } from '../../../img/swap-svg/swap-select-arrow.svg';

const CurrencySelect = ({ name, label, className, currencies, action }) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const { t } = useTranslation();

  return (
    <Form.Item name={name} label={label} className={className}>
      <Select
        className={cn('swap-history-filters__select', {
          'swap-history-filters__select_opened': isDropdownOpened,
        })}
        showSearch
        optionFilterProp="children"
        suffixIcon={!isDropdownOpened ? <SelectArrowIcon /> : <SearchIcon />}
        open={isDropdownOpened}
        onDropdownVisibleChange={setIsDropdownOpened}
        onChange={action}
        notFoundContent={t('nodata')}
      >
        <Select.Option value="">{t('swap.filters.showAll')}</Select.Option>
        {currencies.length &&
          currencies
            .filter(currency => currency.supportSwap && currency.name !== 'BNB')
            .map(currency => (
              <Select.Option value={currency._id} key={currency._id} title={currency.title}>
                <div className="swap-history-filters__token-icon">
                  <TokenIcon tokenName={currency.name} />
                </div>
                {currency.title}
              </Select.Option>
            ))}
      </Select>
    </Form.Item>
  );
};

export default CurrencySelect;
