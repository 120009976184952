import React, { useState } from 'react';
import TransactionsWrapper from './Transactions/TransactionsWrapper';
import NftsWrapper from './Nfts/NftsWrapper';
import styles from './style.module.scss';

const NftWalletWrapper = () => {
  const [showTransactionsModal, setShowTransactionsModal] = useState(false);

  return (
    <div className={styles['wrapper']}>
      <TransactionsWrapper
        showTransactionsModal={showTransactionsModal}
        setShowTransactionsModal={setShowTransactionsModal}
      />
      <NftsWrapper setShowTransactionsModal={setShowTransactionsModal} />
    </div>
  );
};

export default NftWalletWrapper;
