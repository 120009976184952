import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, InputNumber, Popover, Radio } from "antd";
import Button from '../Button';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { editMerchantData } from '../../redux/actions/merchantData';
import CurrencyDropdown from '../CurrencyDropdown';

import './MerchantSettings.scss';
import { identity } from '../../utils/getIdentity';
import helpCircleIcon from "../../img/default-svg/help-circle.svg";
import helpCircleActiveIcon from "../../img/default-svg/help-circle-blue.svg";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const MerchantSettings = ({ merchantData, editMerchantData, isFetching, currencies }) => {
  const [merchantCurrencies, setMerchantCurrencies] = useState([]);
  const [merchantPayerCommission, setMerchantPayerCommission] = useState('');
  const [merchantPayerCommissionReplenish, setMerchantPayerCommissionReplenish] = useState('');
  const [merchantFee, setMerchantFee] = useState('');
  const [merchantFeeReplenish, setMerchantFeeReplenish] = useState('');
  const [isFetchingLocal, setIsFetchingLocal] = useState(false);
  const [isOpenedDropdownCurrency, setIsOpenedDropdownCurrency] = useState(false);
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();
  const { merchantId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setMerchantCurrencies(merchantData.currencies);
    setMerchantPayerCommission(merchantData.payerCommission);
    setMerchantPayerCommissionReplenish(merchantData.payerCommissionReplenish);
    setMerchantFee(merchantData.fee);
    setMerchantFeeReplenish(merchantData.feeReplenish);

    form.resetFields();
  }, [merchantData]);

  const handleFormSubmit = () => {
    setIsFetchingLocal(true);
    const { name, status, typeNetwork, middleWallet } = merchantData;

    const formData = {
      currencies: merchantCurrencies,
      payerCommission: merchantPayerCommission,
      fee: merchantFee !== null ? +merchantFee : 0,
      feeReplenish: merchantFeeReplenish !== null ? +merchantFeeReplenish : 0,
      payerCommissionReplenish: merchantPayerCommissionReplenish,
      name,
      status,
      typeNetwork,
      middleWallet,
    };

    editMerchantData(merchantId, formData).then(() => setIsFetchingLocal(false));
  };

  const currenciesIds = currencies.map(({_id}) => _id)

  return (
    <div className="component merchant-settings"
       onClick={() => {isOpenedDropdownCurrency && !visible ? setIsOpenedDropdownCurrency(false) : console.log('nothing to see')}}
    >
      <div className="component__title">{t('merchants.merchantSettings')}</div>
      <Form
        {...layout}
        form={form}
        name="basic"
        className="merchant-settings-form"
        initialValues={{
          merchantCurrencies: merchantData.currencies,
          merchantPayerCommission: merchantData.payerCommission,
          merchantPayerCommissionReplenish: merchantData.payerCommissionReplenish,
          merchantFee: merchantData.fee,
          merchantFeeReplenish: merchantData.feeReplenish,
        }}
      >
        <Form.Item
          label={t('merchants.paymentMethods')}
          name="merchantCurrencies"
          className="merchant-settings-form__payment-methods"
        >
          <CurrencyDropdown
            setFunc={setMerchantCurrencies}
            multiple={true}
            defaultValues={merchantCurrencies}
            merchantId={merchantId}
            isFiltersAvailable={true}
            activeMerchantCurrencies={currenciesIds}
            isOpenedDropdownCurrency={isOpenedDropdownCurrency}
            setIsOpenedDropdownCurrency={setIsOpenedDropdownCurrency}
            visible={visible}
            setVisible={setVisible}
          />
        </Form.Item>

        {/* withdrawal */}
        <Form.Item
          label={
            <div>
              {t('merchants.whoPaysComission')}
              <Popover
                overlayClassName="popover__wrapper"
                className="merchant-settings-form__commission-pays-info__popover"
                placement="rightTop"
                content={<p className="password-strength__popover-text">{t('merchants.whoPaysComissionTooltip')}</p>}
              >
                <img className="password-strength__popover-icon" src={helpCircleIcon} alt="Help popup" />
                <img className="password-strength__popover-icon_active" src={helpCircleActiveIcon} alt="Help popup" />
              </Popover>
            </div>
          }
          name="merchantPayerCommission"
          className={`merchant-settings-form__commission-pays merchant-settings-form__commission-pays-${identity}`}
          onClick={() => setIsOpenedDropdownCurrency(false)}
        >
          <Radio.Group style={{ width: '100%' }} onChange={e => setMerchantPayerCommission(e.target.value)}>
            <Radio value="buyer">{t('merchants.customer')}</Radio>
            <Radio value="merchant">{t('merchants.me')}</Radio>
          </Radio.Group>
        </Form.Item>

        {/* replenish */}
        <Form.Item
          label={t('merchants.whoPaysComissionReplenish')}
          name="merchantPayerCommissionReplenish"
          className={`merchant-settings-form__commission-pays merchant-settings-form__commission-pays-${identity}`}
          onClick={() => setIsOpenedDropdownCurrency(false)}
        >
          <Radio.Group style={{ width: '100%' }} onChange={e => setMerchantPayerCommissionReplenish(e.target.value)}>
            <Radio value="buyer">{t('merchants.customer')}</Radio>
            <Radio value="merchant">{t('merchants.me')}</Radio>
          </Radio.Group>
        </Form.Item>

        {/* withdrawal */}
        <Form.Item
          label={
            <div>
              {t('merchants.merchantFee')}
              <Popover
                overlayClassName="popover__wrapper"
                className="merchant-settings-form__commission-pays-info__popover"
                placement="rightTop"
                content={<p className="password-strength__popover-text">{t('merchants.whoPaysComissionReplenishTooltip')}</p>}
              >
                <img className="password-strength__popover-icon" src={helpCircleIcon} alt="Help popup" />
                <img className="password-strength__popover-icon_active" src={helpCircleActiveIcon} alt="Help popup" />
              </Popover>
            </div>
          }
          name="merchantFee"
          className="merchant-settings-form__comission"
          onClick={() => setIsOpenedDropdownCurrency(false)}
          >
          <InputNumber
            placeholder="0"
            min={0}
            max={100}
            onChange={value => setMerchantFee(value > 100 ? 100 : value)}
          />
        </Form.Item>

        {/* replenish */}
        <Form.Item
          label={t('merchants.merchantFeeReplenish')}
          name="merchantFeeReplenish"
          className="merchant-settings-form__comission"
          onClick={() => setIsOpenedDropdownCurrency(false)}
        >
          <InputNumber
            placeholder="0"
            min={0}
            max={100}
            onChange={value => setMerchantFeeReplenish(value > 100 ? 100 : value)}
          />
        </Form.Item>

        <p className="merchant-settings-form__text">{t('merchants.commissionDescription')}</p>

        {/* <div className="merchant-settings-form__comission-info">{t('merchants.systemFee')}</div> */}
        {identity === 'cpay' && (
          <a
            href="https://docs.cpay.world/for-developers/general-concepts-about-cpay/"
            target="_blank"
            rel="noopener noreferrer"
            className="merchant-settings-form__fee-docs"
          >
            {t('merchants.feeDocs')}
          </a>
        )}

        {identity === 'finvaro' && (
          <a
            href="https://docs.finvaro.com/for-developers/general-concepts-about-finvaro/"
            target="_blank"
            rel="noopener noreferrer"
            className="merchant-settings-form__fee-docs-finvaro"
          >
            {t('merchants.feeDocs')}
          </a>
        )}

        {identity === 'clarnium' && (
          <a
            href="https://docs.clarnium.io/for-developers/general-concepts-about-clarnium/"
            target="_blank"
            rel="noopener noreferrer"
            className="merchant-settings-form__fee-docs-clarnium"
          >
            {t('merchants.feeDocs')}
          </a>
        )}
        <Button
          type="primary"
          className="merchant-settings-form__button"
          onClick={handleFormSubmit}
          loading={isFetchingLocal && isFetching}
        >
          {t('submit')}
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  merchantData: state.merchantData.data,
  isFetching: state.merchantData.fetching,
  currencies: state.currencies.data,
});

const mapDispatchToProps = dispatch => ({
  editMerchantData: editMerchantData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSettings);
