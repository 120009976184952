import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col } from 'antd';
import { getFeeTransactions, getMoreFeeTransactions } from '../../../../redux/actions/adminPanel/adminFeeTransactions';
import { InputSearch, InputSelect } from '../../FormFields';
import CurrencyDropdown from '../../CurrencyDropdown';
import Transaction from './Transaction';
import emptyCpayImage from '../../../../img/cpay-svg/empty.svg';
import emptyNFGImage from '../../../../img/nfgpay-svg/empty-nfg.svg';
import emptyFinvaroImage from '../../../../img/finvaro/empty-finvaro.svg';
import emptyClarniumImage from '../../../../img/clarnium/empty-clarnium.svg';
import Button from '../../../../components/Button';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';
import { identity } from '../../../../utils/getIdentity';
import { sanitizeSearchInputDisable } from '../../../../utils/sanitizeInput';

const emptyImage = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage,
  finvaro: emptyFinvaroImage,
  clarnium: emptyClarniumImage
};

const UserTransactionsList = ({
  transactions,
  getFeeTransactions,
  transactionsFetching,
  getMoreFeeTransactions,
  moreTransactionsFetching,
  countItem,
  page,
  typeNetwork,
  currency,
  setBlanceCurrency,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!currency) {
      getFeeTransactions({ order: 'DESC', typeNetwork });
    }
  }, [typeNetwork, currency]);

  const setCurrency = value => {
    form.setFieldsValue({ currencyId: value });
    setBlanceCurrency(value);
  };
  useEffect(() => {
    if (currency) {
      setCurrency(currency ? currency : undefined);
      form.submit();
    }
  }, [currency, typeNetwork]);

  return (
    <div className="fee-transactions">
      <Form
        form={form}
        name="admin-fee-transactions-form"
        initialValues={{
          search: '',
          order: 'DESC',
          currencyId: currency || undefined,
        }}
        onFinish={values => getFeeTransactions({ ...values, typeNetwork })}
      >
        <div className="fee-transactions__filters">
          <InputSearch
            name="search"
            onSearch={() => {
              // form.submit();
              const searchWords = form?.getFieldsValue()?.search
              if (searchWords) {
                !sanitizeSearchInputDisable(searchWords) && form.submit();

               if (sanitizeSearchInputDisable(searchWords)) {
                form.resetFields();
                form.submit();
               }
              }
            }}
            onReset={() => {
              form.resetFields();
              form.submit();
            }}
          />
          <InputSelect
            name="order"
            options={[
              { value: 'DESC', label: 'Newest on top' },
              { value: 'ASC', label: 'Oldest on top' },
            ]}
            onChange={form.submit}
          />
          <Form.Item name="currencyId" valuePropName="merchantCurrencies">
            <CurrencyDropdown
              setFunc={setCurrency}
              onChangeAction={form.submit}
              defaultValues={currency ? [currency] : []}
              currencyItem={currency}
              isCurrency={true}
            />
          </Form.Item>
        </div>
        {!transactionsFetching ? (
          <>
            {transactions.length ? (
              <Row className="fee-transactions__legend">
                <Col span={1}>Type</Col>
                <Col span={7} offset={7}>
                  Data/Time
                </Col>
                <Col span={2}>Status</Col>
                <Col span={7} className="fee-transactions__legend-amount">
                  Amount
                </Col>
              </Row>
            ) : null}
            <div className="fee-transactions-list">
              {transactions.length ? (
                transactions.map(transaction => <Transaction key={transaction._id} transaction={transaction} />)
              ) : (
                <div className="fee-transactions-list__empty">
                  <span className="fee-transactions-list__empty-title">All your transactions will show up here.</span>
                  <img src={emptyImage[identity]} className="fee-transactions-list__empty-image" alt="empty" />
                </div>
              )}
            </div>
            {transactions.length && transactions.length < countItem ? (
              <>
                <Button
                  type="button"
                  className={`fee-transactions__load-more fee-transactions__load-more-${identity}`}
                  loading={moreTransactionsFetching}
                  onClick={() =>
                    !moreTransactionsFetching &&
                    getMoreFeeTransactions({ ...form.getFieldsValue(), page: page + 1, typeNetwork })
                  }
                >
                  Load more
                </Button>
              </>
            ) : null}
          </>
        ) : (
          <div className="fee-transactions__loader">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  transactions: state.adminFeeTransactions.entities,
  transactionsFetching: state.adminFeeTransactions.fetching,
  moreTransactionsFetching: state.adminFeeTransactions.fetchingMore,
  countItem: state.adminFeeTransactions.countItem,
  page: state.adminFeeTransactions.page,
  typeNetwork: state.networkFilter,
});

const mapDispatchToProps = {
  getFeeTransactions,
  getMoreFeeTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTransactionsList);
