import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { softLogin } from '../../redux/actions/login';
import JwtDecode from 'jwt-decode';
import { identity } from '../../utils/getIdentity';

const PasswordChanged = ({ responseToken, softLogin }) => {
  const logIn = () => {
    localStorage.setItem('authToken', responseToken);
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      const decodedToken = JwtDecode(authToken);
      if (Date.now() < decodedToken.exp * 1000) softLogin(authToken);
    }
  };

  return (
    <div className="password-changed">
      <div className="password-changed__content">
        <span className={`password-changed__title password-changed__title-${identity}`}>Password Changed</span>
        <span className="password-changed__subtitle">Your profile is activate you can start working</span>
        <Button type="primary" className={`login-form__button login-form__button-${identity}`} onClick={logIn}>
          Log In
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  softLogin: token => dispatch(softLogin(token)),
});

export default connect(null, mapDispatchToProps)(PasswordChanged);
