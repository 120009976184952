import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Radio, Spin } from 'antd';
import cn from 'classnames';
import Button from '../../Button';
import {
  makeWithdraw,
  withdrawResetTwoFa,
  getWithdrawalEstimateMax,
  withdrawalEstimateMaxReset,
  getWithdrawWallets,
  getCurrenciesRate,
  checkAutosignStatus,
  uploadNewAutosign,
} from '../../../redux/actions/withdraw';
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import WithdrawTwoFaConfirm from './WithdrawTwoFaConfirm';
import { cryptoApi } from '../../../service/cryptopay-api';
import './style.scss';
import repeatIcon from '../../../img/default-svg/repeat.svg';
import withdrawalIcon from '../../../img/cpay-svg/arrow-right.svg';
import dropdownIcon from '../../../img/default-svg/chevron-down.svg';
import TokenIcon from '../../TokenIcon';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { identity } from '../../../utils/getIdentity';
import useWindowSize from '../../../utils/resizeHook';
import { getMerchantBalances } from '../../../redux/actions/merchantData';
import { truncate } from '../../../utils/truncateFunction';
import debounce from 'lodash/debounce';
import { addMinutes } from '../../../utils/addMinutes';
import { Timer } from '../../Timer/Timer';
import { LoadingOutlined } from '@ant-design/icons';
import { tokenTypeEnd } from '../../../utils/tokenNodeTypes';
import uploadFile from '../../../img/cpay-svg/upload.svg';
import UploadAutosignWrapper from '../UploadAutosignWrapper';

function WithdrawForm({
  merchantId,
  setCurrency,
  currencies,
  currency,
  networkFilter,
  setFrom,
  to,
  setTo,
  amount,
  setAmount,
  selectedWallet,
  setSelectedWallet,
  autosignStatus,
  fetching,
  fetchingMaxAmount,
  makeWithdraw,
  withdrawResetTwoFa,
  getWithdrawalEstimateMax,
  withdrawalEstimateMaxReset,
  notificationsErrorShow,
  checkAutosignStatus,
  uploadNewAutosign,
  autosignStep,
  getMerchantBalances,
  getWithdrawWallets,
  autosignError,
  withdrawWallets,
  userData,
}) {
  const [exchange, setExchange] = useState(0);
  const [showTwoFaModal, setShowTwoFaModal] = useState(false);
  const [fees, setFees] = useState(null);
  const [decimalsLimit, setDecimalsLimit] = useState(0);
  const [showDecimalsWarning, setShowDecimalsWarning] = useState(false);
  const [isOpenAutosignModal, setIsOpenAutosignModal] = useState(false);
  const [form] = Form.useForm();
  const ratesFetchTimer = useRef();
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();
  const [showToRequired, setShowToRequired] = useState(false);
  const [showAmountRequired, setShowAmountRequired] = useState(false);
  const [withdrawalInfo, setWithdrawalInfo] = useState();
  const [visibleWalletDropdown, setVisibleWalletDropdown] = useState(false);
  const [sendToVisibleWalletDropdown, sendToSetVisibleWalletDropdown] = useState(false);
  const [sendToLabelState, setSendToLabelState] = useState('externalWallet');
  const [selectedSendToWallet, setSelectedSendToWallet] = useState(selectedWallet || null);
  const [disableResend, setDisableResend] = useState(false);
  const [priority, setPriority] = useState('low');
  const [loadingFees, setLoadingFees] = useState(false);
  const [comment, setComment] = useState('');
  const [isShowAutosignModal, setIsShowAutosignModal] = useState(false);
  const [password, setPassword] = useState('');
  const [sign, setSign] = useState('');

  const isEstimatePriority =
    (selectedWallet?.nodeType === 'eth' && networkFilter === 'mainnet') || selectedWallet?.nodeType === 'btc';
  // console.log('usData', userData)
  const validWallets = withdrawWallets;

  const walletsWithoutZeroBalances = wallet => {
    return (
      (wallet.balance.usd !== 0 && wallet.tokens.length === 0) ||
      (wallet.balance.usd !== 0 && wallet.tokens.length !== 0 && wallet.tokens[0].balance.usd !== 0) ||
      (wallet.balance.usd === 0 && wallet.tokens.length !== 0 && wallet.tokens[0].balance.usd !== 0)
    );
  };
  const validUntil = addMinutes(1);

  const handleDisableResend = () => {
    const timeout = setTimeout(() => {
      setDisableResend(false);
    }, 60000);

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    if (amount && to) {
      setLoadingFees(true);

      debouncedGetFeeEstimate.current(
        merchantId,
        selectedWallet._id,
        to,
        `${amount}`,
        selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
        isEstimatePriority ? priority : undefined,
        password || undefined,
        sign || undefined
      );
    }
  }, [sign, selectedWallet]);

  const getWithdrawalInfo = async (merchantId, walletId) => {
    const response = await cryptoApi.getWithdrawalInfo(merchantId, walletId);
    const data = response.data;
    return setWithdrawalInfo(data);
  };

  const uploadAutosign = data => {
    const { password, sign } = data;
    setPassword(password || undefined);
    setSign(sign || undefined);

    setIsOpenAutosignModal(false);
  };

  useEffect(() => {
    document.querySelector('.main-layout__content-wrap').scrollTo(0, 0);
  }, []);

  useEffect(() => () => ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current), []);

  useEffect(() => {
    if (to) setShowToRequired(false);
  }, [to]);

  useEffect(() => {
    if (amount) setShowAmountRequired(false);
  }, [amount]);

  useEffect(() => {
    const currenctCurrency = currencies.find(currency => selectedWallet.currencyId === currency._id);
    setDecimalsLimit(currenctCurrency ? currenctCurrency.decimals : 18);

    return () => withdrawalEstimateMaxReset();
  }, []);

  useEffect(() => {
    if (amount && to) {
      setLoadingFees(true);

      debouncedGetFeeEstimate.current(
        merchantId,
        selectedWallet._id,
        to,
        `${amount}`,
        selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
        isEstimatePriority ? priority : undefined,
        password || undefined,
        sign || undefined
      );
    } else {
      setLoadingFees(false);
      setFees(null);
    }
  }, [to, amount, priority]);

  useEffect(() => {
    if (merchantId && selectedWallet._id) {
      getWithdrawalInfo(merchantId, selectedWallet._id);
    }
  }, [merchantId, selectedWallet._id]);

  useEffect(() => {
    if (selectedWallet) {
      setSelectedSendToWallet(selectedWallet);
    }
  }, []);

  const setFetchTimer = (action, value) => {
    ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current);
    ratesFetchTimer.current = setTimeout(() => action(value), 500);
  };

  const debouncedGetFeeEstimate = useRef(
    debounce(async (...params) => {
      try {
        const response = await cryptoApi.getFeeEstimate(...params);
        setFees(response && response.data ? response.data : null);
      } catch (error) {
        notificationsErrorShow({ message: error?.data?.message || '' });
      }

      setLoadingFees(false);
    }, 3000)
  );

  const clearWithdrawState = () => {
    form.setFieldsValue({
      walletTo: '',
      exchange: 0,
    });
    setFrom('');
    setTo('');
    setAmount('');
    setCurrency('');
    setSelectedWallet(null);
    setSelectedSendToWallet(null);
    setExchange(0);
  };

  const handleSubmit = () => {
    setDisableResend(true);
    !to && setShowToRequired(true);
    !amount && setShowAmountRequired(true);

    if (!to || !amount) {
      return;
    }

    handleDisableResend();

    makeWithdraw(
      selectedWallet._id,
      to,
      `${amount}`,
      merchantId,
      selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
      isEstimatePriority ? priority : undefined,
      comment,
      password || undefined,
      sign || undefined
    ).then(response => {
      if (response && response.data) {
        if (response.data.twoFactorToken || response.data.emailSent || response.data.codeSent) {
          setShowTwoFaModal(true);
        }
      }
    });
  };

  const convertTokensToUsd = async value => {
    const rates = await getCurrenciesRate();

    if (rates && rates.data) {
      setExchange(value * +rates.data[selectedWallet.currency] || 0);
      form.setFieldsValue({
        exchange: value * +rates.data[selectedWallet.currency] || 0,
      });
    }
  };

  const convertUsdToTokens = async value => {
    if (+value) {
      setExchange(value);
      const rates = await getCurrenciesRate();

      if (rates && rates.data) {
        setAmount((value / +rates.data[selectedWallet.currency]).toFixed(decimalsLimit) || 0);
        setShowDecimalsWarning(false);
      }
    } else {
      setAmount('');
      setExchange(0);
    }
  };

  const handleEstimateMax = e => {
    const { _id: walletId } = selectedWallet;
    const data = {
      to,
      currencyToken: selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
      priority: isEstimatePriority ? priority : undefined,
      password: password || undefined,
      sign: sign || undefined,
    };

    if (!to) {
      setShowToRequired(true);
    }

    if (merchantId && to && selectedWallet) {
      getWithdrawalEstimateMax(merchantId, walletId, data);
    }
  };

  useEffect(() => {
    if (amount) {
      setFetchTimer(convertTokensToUsd, amount);
    }
  }, [amount, showDecimalsWarning]);

  const handleKeyDown = e => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const selectedIndex = validWallets.findIndex(wallet => wallet._id === selectedWallet._id);

    const nextIndex =
      selectedIndex === -1 || selectedIndex === 0 ? 1 : selectedIndex === validWallets.length - 1 ? 0 : 0;

    setSelectedSendToWallet(validWallets?.length > 1 ? validWallets[nextIndex] : null);

    form.setFieldsValue({
      walletTo: sendToLabelState === 'externalWallet' ? to || undefined : validWallets[nextIndex]?.address,
    });
    setTo(sendToLabelState === 'externalWallet' ? to : validWallets[nextIndex]?.address);
  }, [sendToLabelState, withdrawWallets]);

  const handleInputChange = e => {
    if (e.target.value === 'internalWallet' || e.target.value === 'externalWallet') return;
    const cleanedValue = e.target.value.replace(/\s/g, '');

    form.setFieldsValue({ walletTo: cleanedValue });
    setTo(cleanedValue);
  };

  const systemFeeWithdrawal = withdrawalInfo?.systemFeeWithdrawal ?? 0;

  const handleWalletClick = () => {
    setVisibleWalletDropdown(!visibleWalletDropdown);
  };

  const handleSendToWalletClick = () => {
    sendToSetVisibleWalletDropdown(!sendToVisibleWalletDropdown);
  };

  const handleWalletChange = value => {
    if (value.tokens.length === 0) {
      const selectedWallet = withdrawWallets.find(wallet => wallet._id === value._id);

      setSelectedWallet(selectedWallet);
    } else {
      setSelectedWallet({ ...value.tokens[0], _id: value._id, address: value.address, nodeType: value.nodeType });
    }

    setVisibleWalletDropdown(false);
  };

  const handleSenToWalletChange = value => {
    if (value.tokens.length === 0) {
      const selectedWallet = withdrawWallets.find(wallet => wallet._id === value._id);

      setSelectedSendToWallet(selectedWallet);
    } else {
      setSelectedSendToWallet({ ...value.tokens[0], _id: value._id, address: value.address });
    }
    form.setFieldsValue({ walletTo: value.address });
    setTo(value.address);
    sendToSetVisibleWalletDropdown(false);
  };

  const dropdownRef = useRef(null);
  const sendToDropdownRef = useRef(null);

  useEffect(() => {
    const handleBodyClick = event => {
      const dropdown = dropdownRef.current;
      const sendToDropdown = sendToDropdownRef.current;
      const clickedElement = event.target;
      const isWalletClick = clickedElement.closest('.settings-form__selected-wallet');
      const isCustomDropdownItemClick = clickedElement.closest('.settings-form__custom-dropdown-item');
      const isCheckboxClick = clickedElement.closest(`.settings-form__sendTo-label-block_checkbox-${identity}`);

      // Check if the click is outside the dropdown and not on a custom dropdown item
      if (
        dropdown &&
        !dropdown.contains(clickedElement) &&
        !isWalletClick &&
        !isCustomDropdownItemClick &&
        !isCheckboxClick
      ) {
        setVisibleWalletDropdown(false);
      }
      if (sendToDropdown && !sendToDropdown.contains(clickedElement) && !isWalletClick && !isCustomDropdownItemClick) {
        sendToSetVisibleWalletDropdown(false);
      }
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [visibleWalletDropdown, sendToVisibleWalletDropdown]);

  const selectedCurrencyType = currencies.find(currency => currency._id === selectedWallet.currencyId).currencyType;

  const disabledByForm =
    (selectedSendToWallet?.address === selectedWallet?.address && sendToLabelState !== 'externalWallet') ||
    !to ||
    !amount;

  const fullInfoAboutSelectedCurrency =
    selectedWallet && withdrawWallets?.find(item => item._id === selectedWallet?._id);
  const selectedTokenTypeEnd =
    (fullInfoAboutSelectedCurrency &&
      tokenTypeEnd(
        fullInfoAboutSelectedCurrency.currencyType,
        fullInfoAboutSelectedCurrency.nodeType,
        fullInfoAboutSelectedCurrency.currency
      )) ||
    '';

  useEffect(() => {
    checkAutosignStatus(merchantId, selectedWallet._id);
  }, [merchantId, selectedWallet._id]);

  useEffect(() => {
    setIsShowAutosignModal(autosignStatus);
  }, [autosignStatus]);

  return (
    <React.Fragment>
      <WithdrawTwoFaConfirm
        isVisible={showTwoFaModal}
        setIsVisible={setShowTwoFaModal}
        action={makeWithdraw}
        fetching={fetching}
        clearWithdrawState={clearWithdrawState}
        body={[
          selectedWallet._id,
          to,
          `${amount}`,
          merchantId,
          selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
          isEstimatePriority ? priority : undefined,
          comment,
          password || undefined,
          sign || undefined,
        ]}
        withdrawResetTwoFa={withdrawResetTwoFa}
        page="withdraw"
      />
      <div className="component" id="topComponent">
        <div className="component__title">{t('withdraw.sendMoney')}</div>
        <Form
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{
            walletSelect: selectedWallet.address,
            exchange: exchange,
          }}
          className="form settings-form"
        >
          <Row>
            <Col span={currentWidth >= 1200 ? 17 : currentWidth >= 1024 && currentWidth < 1200 ? 20 : 24}>
              <Row gutter={[20, 0]} align="top">
                <Col span={currentWidth >= 1024 ? 12 : 24} className={'settings-form__exchange-wrapper'}>
                  <div
                    className={`settings-form__selected-wallet-label settings-form__selected-wallet-label-${identity}`}
                  >
                    {t('currency')}:
                  </div>

                  <Form.Item className="form__item custom-item">
                    <div className="settings-form__selected-wallet" onClick={handleWalletClick}>
                      <TokenIcon tokenName={selectedWallet.currency} />
                      <div className="settings-form__selected-wallet-info">
                        <span className="settings-form__selected-wallet-info_text">
                          {currencies.find(currency => currency._id === selectedWallet.currencyId).title} -{' '}
                          {truncate(selectedWallet.address, 14, '..')} - {selectedWallet.balance.value}
                        </span>

                        <img
                          className={`settings-form__selected-wallet-info__arrow ${
                            visibleWalletDropdown ? `settings-form__selected-wallet-info__arrow-open` : ''
                          }`}
                          src={dropdownIcon}
                          alt=""
                        />
                      </div>
                    </div>
                    {visibleWalletDropdown && (
                      <div ref={dropdownRef} className="settings-form__wrapper-custom-dropdown">
                        <div className="settings-form__custom-dropdown">
                          {withdrawWallets.map(wallet =>
                            (walletsWithoutZeroBalances(wallet) && wallet?._id !== selectedSendToWallet?._id) ? (
                              <div
                                key={wallet._id}
                                onClick={() => handleWalletChange(wallet)}
                                className={cn('settings-form__custom-dropdown-item', {
                                  'settings-form__custom-dropdown-item-current': wallet?._id === selectedWallet?._id,
                                })}
                              >
                                <TokenIcon
                                  tokenName={wallet.tokens.length === 0 ? wallet.currency : wallet.tokens[0].currency}
                                />
                                <span>{truncate(wallet.address, 14, '..')}</span>-
                                <span className="settings-form__custom-dropdown-item-balance">
                                  {wallet.tokens.length === 0 ? wallet.balance.value : wallet.tokens[0].balance.value}
                                </span>
                              </div>
                            ) : null
                          )}
                        </div>
                      </div>
                    )}
                    {selectedWallet && selectedCurrencyType === 'token' && (
                      <div className="settings-form__token">
                        {fullInfoAboutSelectedCurrency?.currency} {selectedTokenTypeEnd}:{' '}
                        {`${fullInfoAboutSelectedCurrency?.balance?.value} ${fullInfoAboutSelectedCurrency?.currency} ${selectedTokenTypeEnd} - $${fullInfoAboutSelectedCurrency?.balance?.usd}`}
                      </div>
                    )}
                  </Form.Item>
                  <img
                    style={{ marginBottom: '10px' }}
                    src={withdrawalIcon}
                    alt="exchange"
                    className="settings-form__exchange-wrapper-icon"
                  />
                </Col>
                <Col span={currentWidth >= 1024 ? 12 : 24} className="forms">
                  <Form.Item
                    label={
                      <div className="settings-form__sendTo-label-block">
                        <span>{t('withdraw.sendTo')}:</span>
                        <div className={`settings-form__sendTo-label-block_checkbox-${identity}`}>
                          <Radio.Group
                            disabled={validWallets?.length <= 1}
                            value={sendToLabelState}
                            onChange={e => {
                              if (e.target.value === 'externalWallet') {
                                form.setFieldsValue({
                                  walletTo: undefined,
                                });
                                setTo('');
                              }
                              setSendToLabelState(e.target.value);
                            }}
                          >
                            <Radio value="externalWallet">{t('withdraw.externalWallet')}</Radio>
                            <Radio value="internalWallet">{t('withdraw.internalWallet')}</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    }
                    name="walletTo"
                    className="form__item"
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                  >
                    {to !== '' && userData.id === '609a6340e5e1cf02156d0df7' && to}
                    {sendToLabelState === 'externalWallet' ? (
                      <>
                        <Input className="form__input" placeholder={t('wallets.walletAddress')} />
                        {showToRequired ? (
                          <span className="max-warning withdraw-to__warning">
                            Fill in the recipient's address first
                          </span>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className="settings-form__selected-wallet sendTo-custom-input"
                          onClick={handleSendToWalletClick}
                        >
                          <TokenIcon
                            tokenName={
                              !selectedSendToWallet?.tokens || selectedSendToWallet?.tokens?.length === 0
                                ? selectedSendToWallet?.currency
                                : selectedSendToWallet?.tokens[0].currency
                            }
                          />
                          <div className="settings-form__selected-wallet-info">
                            <span className="settings-form__selected-wallet-info_text">
                              {
                                currencies.find(
                                  currency =>
                                    currency._id ===
                                    (!selectedSendToWallet?.tokens || selectedSendToWallet?.tokens?.length === 0
                                      ? selectedSendToWallet.currencyId
                                      : selectedSendToWallet?.tokens[0].currencyId)
                                ).title
                              }{' '}
                              - {truncate(selectedSendToWallet.address, 14, '..')} -{' '}
                              {!selectedSendToWallet?.tokens || selectedSendToWallet?.tokens?.length === 0
                                ? selectedSendToWallet?.balance.value
                                : selectedSendToWallet?.tokens[0].balance.value}
                            </span>
                            <img
                              className={`settings-form__selected-wallet-info__arrow ${
                                sendToVisibleWalletDropdown ? `settings-form__selected-wallet-info__arrow-open` : ''
                              }`}
                              src={dropdownIcon}
                              alt=""
                            />
                          </div>
                        </div>
                        {sendToVisibleWalletDropdown && (
                          <div
                            ref={sendToDropdownRef}
                            className="settings-form__wrapper-custom-dropdown sendTo-wrapper-custom-dropdown"
                          >
                            <div className="settings-form__custom-dropdown">
                              {withdrawWallets.map(wallet =>
                                wallet && wallet?._id !== selectedWallet?._id ? (
                                  <div
                                    key={wallet._id}
                                    onClick={() => handleSenToWalletChange(wallet)}
                                    className={cn('settings-form__custom-dropdown-item', {
                                      'settings-form__custom-dropdown-item-current':
                                        wallet?._id === selectedSendToWallet?._id,
                                    })}
                                  >
                                    <TokenIcon
                                      tokenName={
                                        wallet.tokens.length === 0 ? wallet.currency : wallet.tokens[0].currency
                                      }
                                    />
                                    <span>{truncate(wallet.address, 14, '..')}</span>-
                                    <span className="settings-form__custom-dropdown-item-balance">
                                      {wallet.tokens.length === 0
                                        ? wallet.balance.value
                                        : wallet.tokens[0].balance.value}
                                    </span>
                                  </div>
                                ) : null
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {selectedWallet?.nodeType === 'ton' && (
                <Row gutter={[20, 0]}>
                  <Col span={currentWidth >= 1024 ? 12 : 24} className="settings-form__exchange-wrapper">
                    <div className="withdraw-amount__input-wrapper withdraw-amount__input-wrapper__comment">
                      <div>Comment:</div>
                      <input
                        value={comment}
                        className="form__input settings-form__input-usd"
                        placeholder="Comment"
                        onChange={e => setComment(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              <Row gutter={[20, 0]}>
                <Col span={currentWidth >= 1024 ? 12 : 24} className="settings-form__exchange-wrapper">
                  <label className="withdraw-amount">
                    <div className="settings-form__exchange-wrapper_label withdraw-amount__label">
                      {t('withdraw.enterAmount')}:{' '}
                      <div className="settings-form__exchange-wrapper_min-value">
                        Minimum {withdrawalInfo?.minimumWithdrawalUsd ?? '0'}$
                      </div>
                    </div>
                    <div className="withdraw-amount__input-wrapper">
                      <input
                        type="number"
                        value={amount}
                        placeholder={t('amount')}
                        className="form__input settings-form__input-usd withdraw-amount__input"
                        onChange={e => {
                          if (e.target.value < 0) {
                            setAmount('');
                            return;
                          }

                          if (
                            !e.target.value.toString().split('.')[1] ||
                            e.target.value.toString().split('.')[1].length <= decimalsLimit
                          ) {
                            setAmount(e.target.value);
                            setFetchTimer(convertTokensToUsd, e.target.value);
                            setShowDecimalsWarning(false);
                          } else {
                            setShowDecimalsWarning(true);
                            setAmount(amount);
                            setFetchTimer(convertTokensToUsd, amount);
                          }
                        }}
                      />
                      <img
                        src={repeatIcon}
                        alt="exchange"
                        className={cn('settings-form__exchange-wrapper-icon-bottom', {
                          'settings-form__exchange-wrapper-icon-animated': fetchingMaxAmount,
                        })}
                      />
                      <div
                        className={`max-container ${fetchingMaxAmount && 'max-container__disabled'}`}
                        onClick={handleEstimateMax}
                      >
                        {fetchingMaxAmount ? (
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
                        ) : (
                          <span className={`max-text max-text-${identity}`}>max</span>
                        )}
                      </div>
                    </div>

                    {showDecimalsWarning && (
                      <span className="withdraw-amount__decimals-limit">
                        The maximum decimal value for {selectedWallet.currency} is {decimalsLimit}.
                      </span>
                    )}
                    {showAmountRequired ? (
                      <span className="max-warning withdraw-amount__warning">{t('validation.required')}</span>
                    ) : (
                      ''
                    )}
                  </label>
                </Col>
                <Col span={currentWidth >= 1024 ? 12 : 24}>
                  <Form.Item label={`${t('withdraw.exchangeRate')} in USD:`} name="exchange" className="form__item">
                    <NumberFormat
                      value={exchange}
                      className="form__input settings-form__input-usd"
                      thousandSeparator={true}
                      prefix={'$'}
                      onValueChange={(values, sourceInfo) => {
                        if (sourceInfo.event) {
                          setFetchTimer(convertUsdToTokens, values.value);
                        }
                      }}
                      onFocus={e => e.target.select()}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowEmptyFormatting={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {isEstimatePriority && (
                <div
                  className={`withdraw-amount__transaction-speed withdraw-amount__transaction-speed-${identity} ${
                    showDecimalsWarning ? 'withdraw-amount__transaction-speed-extra-margin' : ''
                  }`}
                >
                  <Radio.Group
                    // disabled={validWallets?.length <= 1}
                    value={priority}
                    onChange={e => {
                      setPriority(e.target.value);
                    }}
                  >
                    <Radio value="low">{t('withdraw.low')}</Radio>
                    <Radio value="medium">{t('withdraw.medium')}</Radio>
                    <Radio value="high">{t('withdraw.high')}</Radio>
                  </Radio.Group>
                </div>
              )}
              {!isShowAutosignModal && (
                <div className="withdraw__autosign" onClick={() => setIsOpenAutosignModal(true)}>
                  <img src={uploadFile} alt="upload" />
                  <span>{!sign ? t('withdraw.uploadAutosign') : `${sign.slice(0, 15)}...`}</span>
                </div>
              )}

              <Row className="withdraw__buttons">
                <Col span={currentWidth >= 1024 ? 8 : 11}>
                  <Button type="secondary" className="form__button" onClick={clearWithdrawState}>
                    {t('back')}
                  </Button>
                </Col>
                <Col span={currentWidth >= 1024 ? 8 : 11} offset={currentWidth >= 1024 ? 8 : 1}>
                  <Button
                    type="primary"
                    className="form__button"
                    onClick={handleSubmit}
                    loading={fetching}
                    disabled={disableResend || disabledByForm}
                  >
                    {disableResend ? (
                      <>
                        {' '}
                        {t('submit')} in <Timer validUntil={validUntil} onExpire={() => setDisableResend(false)} />{' '}
                      </>
                    ) : (
                      t('submit')
                    )}
                  </Button>
                </Col>
              </Row>
              <div className="settings-form__system-fee">{t('wallets.payment', { systemFeeWithdrawal })}</div>
            </Col>
          </Row>
        </Form>
        <div className="withdraw-fee">
          <div className={`withdraw-fee__title withdraw-fee__title-${identity}`}>{t('total')}</div>
          <div className="withdraw-fee__value">
            <span className="withdraw-fee__description">{t('wallets.feeCalc')}:</span>
            <span>
              {amount && !loadingFees ? `${amount} ${selectedWallet.currency}` : ''}
              {loadingFees ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              ) : (
                <span>
                  {fees ? ` + ${fees.minerFee} ${fees.currencyMinerFee} + ${fees.systemFee} ${fees.currencyFee}` : ''}
                </span>
              )}
            </span>
          </div>
        </div>
      </div>

      <UploadAutosignWrapper
        step={autosignStep}
        uploadAutosign={uploadAutosign}
        onCancel={() => setIsOpenAutosignModal(false)}
        isOpen={isOpenAutosignModal}
      />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  fetching: state.withdraw.fetching,
  fetchingMaxAmount: state.withdraw.fetchingMaxAmount,
  withdrawWallets: state.withdraw.wallets.data,
  userData: state.user.data,
  autosignStatus: state.withdraw.autosignStatus,
  autosignError: state.withdraw.autosignError,
  autosignStep: state.withdraw.autosignStep,
});

const mapDispatchToProps = dispatch => ({
  makeWithdraw: makeWithdraw(dispatch),
  withdrawalEstimateMaxReset: () => dispatch(withdrawalEstimateMaxReset()),
  withdrawResetTwoFa: () => dispatch(withdrawResetTwoFa()),
  getWithdrawalEstimateMax: getWithdrawalEstimateMax(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
  getMerchantBalances: getMerchantBalances(dispatch),
  getWithdrawWallets: getWithdrawWallets(dispatch),
  checkAutosignStatus: checkAutosignStatus(dispatch),
  uploadNewAutosign: uploadNewAutosign(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawForm);
