import React from 'react';
import { useTranslation } from 'react-i18next';
import enFlag from '../../img/default-svg/en.svg';
import esFlag from '../../img/default-svg/es.svg';
import arrowIcon from '../../img/default-svg/arrow.svg';
import './style.scss';
import { identity } from '../../utils/getIdentity';

const langs = [
  {
    alias: 'en',
    name: 'English',
    icon: enFlag,
  },
  {
    alias: 'es',
    name: 'Español',
    icon: esFlag,
  },
];

const LanguageSelector = ({ isFromSaleTokenCheckout }) => {
  const { i18n, t } = useTranslation();

  return (
    <div className={!isFromSaleTokenCheckout ? 'langSelector' : 'langSelectorTokenCheckout'}>
      <div className={`langSelector__label langSelector__label-${identity}`}>{t('language')}</div>
      <div className="langSelector__current">
        <img
          className="langSelector__selectedFlag"
          src={langs.find(lang => i18n.language.includes(lang.alias))?.icon}
          alt="Current language"
        />
        <img className="langSelector__arrow" src={arrowIcon} alt="Current language" />
      </div>
      <div className="langSelector__dropdown">
        {langs.map((lang, index) => (
          <div
            className={`langSelector__option ${lang.alias === i18n.language ? 'langSelector__option_selected' : ''}`}
            onClick={() => i18n.changeLanguage(lang.alias)}
            key={index}
          >
            <div className="langSelector__flag">
              <img src={lang.icon} alt={lang.name} />
            </div>
            <span className={`langSelector__name langSelector__name-${identity}`}>{lang.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelector;
