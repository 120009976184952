import React, { useState, useEffect, useRef } from 'react';
import { Popover, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const CopyWrapper = ({ children, copyContent, type }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const timer = useRef(null);
  const { t } = useTranslation();

  useEffect(
    () => () => {
      clearTimeout(timer);
      timer.current = null;
    },
    []
  );

  const handleCopy = (text, event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
    setShowTooltip(false);
    setShowPopover(true);
    timer.current = setTimeout(() => setShowPopover(false), 1000);
  };

  return (
    <Tooltip title={t('clickToCopy')} visible={showTooltip} onVisibleChange={isVisible => setShowTooltip(isVisible)}>
      <Popover content={t('copied')} trigger="click" visible={showPopover} hidden={true}>
        <div className="copy-wrapper" style={{ cursor: 'pointer', width: `${type === 'wallet' && 'auto'}` }} onClick={e => handleCopy(copyContent, e)}>
          {children}
        </div>
      </Popover>
    </Tooltip>
  );
};

export default CopyWrapper;
