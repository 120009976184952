import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input } from 'antd';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import { confirmBackup } from '../../../redux/actions/user';
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import { identity } from '../../../utils/getIdentity';

const BackupPhraseStepTwo = ({
  backupPhraseFetching,
  backupWords,
  okButtonAction,
  handleCancel,
  confirmBackup,
  notificationsErrorShow,
}) => {
  const [randomNumbers, setRandomNumbers] = useState([]);
  const [firstWord, setFirstWord] = useState('');
  const [secondWord, setSecondWord] = useState('');
  const [thirdWord, setThirdWord] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setRandomNumbers(getRandomWordNumbers());
  }, []);

  const getRandomWordNumbers = () => {
    const randomNumbers = [];

    while (randomNumbers.length < 3) {
      let randNum = Math.floor(Math.random() * Math.floor(12));

      if (randomNumbers.includes(randNum)) {
        continue;
      }

      randomNumbers.push(randNum);
    }

    randomNumbers.sort((a, b) => a - b);

    return randomNumbers;
  };

  const checkEnteredWords = () => {
    if (
      firstWord === backupWords[randomNumbers[0]] &&
      secondWord === backupWords[randomNumbers[1]] &&
      thirdWord === backupWords[randomNumbers[2]]
    ) {
      return true;
    } else {
      return false;
    }
  };

  const sendBackupPhrase = () => {
    if (checkEnteredWords()) {
      confirmBackup(backupWords).then(() => {
        okButtonAction();
      });
    } else {
      notificationsErrorShow({ message: t('profile.wordsDontMatch') });
    }
  };

  return (
    <React.Fragment>
      <div className="modal-backup__tip">{t('profile.writeWordsToConfirmBackupPhrase')}</div>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name="secret-words-check"
        className="modal-form modal-backup__form"
        onFinish={sendBackupPhrase}
      >
        <Form.Item
          label={`${t('profile.word')} ${randomNumbers[0] + 1}`}
          name="first-word"
          className="modal-form__label"
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input
            placeholder={t('profile.word')}
            className="modal-form__input"
            onChange={e => setFirstWord(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={`${t('profile.word')} ${randomNumbers[1] + 1}`}
          name="second-word"
          className="modal-form__label"
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input
            placeholder={t('profile.word')}
            className="modal-form__input"
            onChange={e => setSecondWord(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={`${t('profile.word')} ${randomNumbers[2] + 1}`}
          name="third-one"
          className="modal-form__label"
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input
            placeholder={t('profile.word')}
            className="modal-form__input"
            onChange={e => setThirdWord(e.target.value)}
          />
        </Form.Item>

        <Row className="modal__buttons-wrapper modal-backup__buttons-wrapper">
          <Col span="12">
            <Button
              type="primary"
              className={`modal__button modal__button-create modal__button modal__button-create-${identity} modal__button-create=${identity}`}
              loading={backupPhraseFetching}
              disabled={backupPhraseFetching}
            >
              {t('profile.gotIt')}
            </Button>
          </Col>
          <Col span="12">
            <Button
              type="primary"
              className="modal__button modal__button-cancel"
              onClick={handleCancel}
              disabled={backupPhraseFetching}
            >
              {t('profile.doItLater')}
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  backupPhraseFetching: state.user.backupPhraseFetching,
});

const mapDispatchToProps = dispatch => ({
  confirmBackup: confirmBackup(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupPhraseStepTwo);
