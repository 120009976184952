/* eslint-disable no-lone-blocks */
// works only for antd forms, put into rules array

// example:

{/* <Form.Item name={'name'} rules={[ { required: true, message: 'message' }, whitespaceInputValidator ]}> {...} </Form.Item> */}

export const whitespaceInputValidator = {
    validator: (_, value) =>
      value.replace(/\s/g, '').length
        ? Promise.resolve()
        : Promise.reject(new Error("You can not type only whitespaces"))
  }
