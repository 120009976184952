import React from 'react';
import { connect } from 'react-redux';
import PageHeader from '../layout/PageHeader';
import WalletsList from '../components/WalletsPage/WalletsList';
import WalletCreateModal from '../components/WalletCreateModal/WalletCreateModal';

const WalletsPage = ({ merchantId, userRole, networkFilter }) => {
  const WalletCreateComponent = () => (
    <WalletCreateModal
      merchantId={merchantId}
      typeWallet="merchant"
      filtersFormName="walletsFilters"
      networkFilter={networkFilter}
    />
  );

  return (
    <React.Fragment>
      <PageHeader showBackButton={userRole.includes('corporate')} CreateModalComponent={WalletCreateComponent} />
      <div className="block-for-component">
        <div className="component">
          <WalletsList
            formName="walletsFilters"
            merchantId={merchantId}
            typeWallet="merchant"
            networkFilter={networkFilter}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  userRole: state.user.data.roles,
  networkFilter: state.networkFilter,
});

export default connect(mapStateToProps)(WalletsPage);
