import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import { useLocation } from 'react-router-dom';
import SaleTokenInput from './SaleTokenInput';
import styles from './SaleTokenForm.module.scss';
import { tokenNodeTypeObj } from '../../../utils/tokenNodeTypes';
import { ReactComponent as WarningIcon } from '../../../img/default-svg/info-circle-warning.svg';
import { getContrastTextColor } from '../../../utils/getTextColorByBg';
import { mainColor } from '../../../utils/getMainColor';
import { identity } from '../../../utils/getIdentity';


const YOU_SEND_FIELD_NAME = 'amount';
const YOU_GET_FIELD_NAME = 'amountSecond';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SaleTokenForm = ({
  identifier,
  estimate,
  setEstimate,
  checkoutData,
  setShowSummary,
  summaryData,
  setSummaryData,
  getCheckoutData,
  getEstimate,
  isMinMoreThanMax,
  timer,
  isEstimateExpired,
  estimateDirection,
  setEstimateDirection,
}) => {
  // TODO: use fetching state to display loader
  // eslint-disable-next-line no-unused-vars
  const [estimateFetching, setEstimateFetching] = useState(false);
  const [showRateUpdate, setShowRateUpdate] = useState(false);
  const fetchDelayTimer = useRef();
  const checkoutDataUpdateTimer = useRef(null);
  const [form] = Form.useForm();
  const query = useQuery();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location && location?.state?.from === 'saleTokenPaymentPage') {
      localStorage.setItem('isRedirected', identifier);
    }
  }, [location?.state?.from]);

  useEffect(() => {
    !summaryData && getInitialEstimate();
    checkoutDataUpdateTimer.current = setInterval(
      () => getCheckoutData(identifier, form.getFieldValue('recipient')),
      15000
    );
  }, [summaryData]);

  useEffect(
    () => () => {
      fetchDelayTimer.current && clearTimeout(fetchDelayTimer.current);
      checkoutDataUpdateTimer.current && clearInterval(checkoutDataUpdateTimer.current);
    },
    []
  );

  useEffect(() => {
    isEstimateExpired && setShowRateUpdate(true);
  }, [isEstimateExpired]);

  const getExchangeTimer = async () => {
    const response = await getEstimate(
      form.getFieldValue('currencyFrom'),
      (estimateDirection === 'direct'
        ? form.getFieldValue(YOU_SEND_FIELD_NAME)
        : form.getFieldValue(YOU_GET_FIELD_NAME)) || 0,
      estimateDirection
    );
    setEstimate(response.data);
    setShowRateUpdate(false);
  };

  const setFetchDelay = (action, value, delay = 500) => {
    fetchDelayTimer.current && clearTimeout(fetchDelayTimer.current);
    fetchDelayTimer.current = setTimeout(() => action(value), delay);
  };

  const getInitialEstimate = async () => {
    const response = await getEstimate(form.getFieldValue('currencyFrom'), 0, 'direct');
    setEstimate(response.data);
  };

  const resetInputs = () => {
    form.setFieldsValue({
      [YOU_SEND_FIELD_NAME]: '',
      [YOU_GET_FIELD_NAME]: '',
    });
    form.validateFields([YOU_SEND_FIELD_NAME, YOU_GET_FIELD_NAME]);
    setEstimateFetching(false);
  };

  const skipValidationOfFields = changedValues => {
    if (changedValues['currencyFrom'] || changedValues['recipient'] || changedValues['recipient'] === '') {
      return true;
    }

    if (changedValues['currencyFrom'] && changedValues['recipient']) {
      return true;
    }
  };

  const handleFormChange = async values => {
    const [changedValues, allValues] = values;

    if (changedValues.hasOwnProperty('recipient')) {
      getCheckoutData(identifier, changedValues.recipient)
        .then(res => {
          if (checkoutDataUpdateTimer.current) return;
          checkoutDataUpdateTimer.current = setInterval(
            () => getCheckoutData(identifier, form.getFieldValue('recipient')),
            15000
          );
        })
        .catch(err => {
          if (checkoutDataUpdateTimer.current) {
            clearInterval(checkoutDataUpdateTimer.current);
            checkoutDataUpdateTimer.current = null;
          }
        });
    }

    if (
      (changedValues.hasOwnProperty(YOU_SEND_FIELD_NAME) && !changedValues[YOU_SEND_FIELD_NAME]) ||
      (changedValues.hasOwnProperty(YOU_GET_FIELD_NAME) && !changedValues[YOU_GET_FIELD_NAME])
    ) {
      resetInputs();
      return;
    }

    let response;

    if (changedValues.hasOwnProperty(YOU_SEND_FIELD_NAME) || changedValues.hasOwnProperty(YOU_GET_FIELD_NAME)) {
      response = await getEstimate(
        allValues.currencyFrom,
        changedValues[YOU_SEND_FIELD_NAME] || changedValues[YOU_GET_FIELD_NAME],
        changedValues[YOU_SEND_FIELD_NAME] ? 'direct' : 'reverse'
      );

      setEstimateDirection(changedValues[YOU_GET_FIELD_NAME] ? 'reverse' : 'direct');
    } else {
      response = await getEstimate(
        allValues.currencyFrom,
        (estimateDirection === 'direct' ? allValues[YOU_SEND_FIELD_NAME] : allValues[YOU_GET_FIELD_NAME]) || 0,
        estimateDirection
      );
      setEstimate(response.data);
    }

    if (skipValidationOfFields(changedValues)) return;

    if (!response || !response.data) return;

    form.setFieldsValue({
      [response.data.type === 'direct' ? YOU_GET_FIELD_NAME : YOU_SEND_FIELD_NAME]:
        response.data.estimatedAmount === '0' ? '' : response.data.estimatedAmount,
    });

    form.validateFields([[response.data.type === 'direct' ? YOU_GET_FIELD_NAME : YOU_SEND_FIELD_NAME]]);

    setEstimate(response.data);
    setShowRateUpdate(false);
  };

  const notIncludedNodeType = ['optimism', 'matic', 'arbitrum', 'avax', 'solana', 'ftm', 'moonbeam']

  const placeholderText = `${t('checkouts.saleToken.enter')} ${checkoutData.tradedCurrency.name} ${checkoutData?.tradedCurrency?.currencyType === 'token' && !notIncludedNodeType.includes(checkoutData.tradedCurrency.nodeType) ? tokenNodeTypeObj[checkoutData?.tradedCurrency?.nodeType] : ''}
  ${checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'optimism' ?
  checkoutData.tradedCurrency.name === 'OP' ? '' : 'OP' : ''}
  ${checkoutData.tradedCurrency.name === 'ETH' && checkoutData.tradedCurrency.currencyType === 'currency' && checkoutData.tradedCurrency.nodeType === 'optimism' ?
  'OP' : ''}
  ${checkoutData.tradedCurrency.currencyType === 'currency' && checkoutData.tradedCurrency.nodeType === 'arbitrum' ? checkoutData.tradedCurrency.name === 'arbitrum' ? '' : 'ARB' : ''}
  ${(checkoutData.tradedCurrency.name === 'USDT' || checkoutData.tradedCurrency.name === 'USDC' || checkoutData.tradedCurrency.name === 'ETH') && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'arbitrum' ? 'ARB' : ''}
  ${(checkoutData.tradedCurrency.name === 'USDT' || checkoutData.tradedCurrency.name === 'USDC') && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'matic' ? 'MATIC' : ''}
  ${(checkoutData.tradedCurrency.name === 'USDT' && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'avax') ? 'AVAX' : ''}
  ${(checkoutData.tradedCurrency.name === 'USDC' && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'solana') ? 'SOL' : ''}
  ${(checkoutData.tradedCurrency.name === 'USDT'  && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'ftm') ? 'FTM' : ''}
  ${((checkoutData.tradedCurrency.name === 'USDT' || checkoutData.tradedCurrency.name === 'USDC') && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'moonbeam') ? 'GLMR' : ''}
  ${t('checkouts.saleToken.wallet')}`

  return (
    <>
      <style>
        {`
          .sale-token-form__custom-button:not([disabled]),
          .sale-token-summary__rates-button {
            background: ${checkoutData.accentColor} !important;
            color: ${getContrastTextColor(checkoutData.accentColor || mainColor)} !important;
          }
        `}
      </style>
      <div className={styles['sale-token-form']}>
        {showRateUpdate && (
          <div className={`${styles['sale-token-form__rate-update']}  ${styles[`sale-token-form__rate-update-${identity}`]}`}>       
            <span className={styles['sale-token-form__rate-update-title']}>{t('swap.saleToken.updRateMesg')}</span>
            <span className={styles['sale-token-form__rate-update-subtitle']}>{t('swap.saleToken.updContText')}</span>
            <Button
              className={`${styles['sale-token-form__rate-update-button']} sale-token-summary__rates-button`}
              onClick={async () => {
                getExchangeTimer();
              }}
            >
              {t('swap.saleToken.btnText')}
            </Button>
          </div>
        )}
        <Form
          form={form}
          name="sale-token-checkout"
          initialValues={{
            [YOU_SEND_FIELD_NAME]: summaryData ? summaryData.amount : '',
            [YOU_GET_FIELD_NAME]: summaryData ? summaryData.amountSecond : '',
            currencyFrom: summaryData ? summaryData.currencyFrom : checkoutData ? checkoutData.currencies[0]._id : '',
            recipient: summaryData ? summaryData.recipient : '',
          }}
          onValuesChange={(changedValues, allValues) => {
            setFetchDelay(handleFormChange, [changedValues, allValues]);
          }}
          onFinish={values => {
            setSummaryData({
              ...values,
              estimate: estimate || summaryData.estimate,
              clickId: query.get('clickId'),
            });
            setShowSummary(true);
          }}
        >
          {(values, formInstance) => (
            <>
              <SaleTokenInput
                name={YOU_SEND_FIELD_NAME}
                selectName="currencyFrom"
                label={t('checkouts.saleToken.send')}
                form={formInstance}
                checkoutData={checkoutData}
                info={[
                  {
                    label: `${t('checkouts.saleToken.exchRateEst')}:`,
                    value:
                      estimate &&
                      `1 ${
                        checkoutData.currencies.find(
                          currency => currency._id === formInstance.getFieldValue('currencyFrom')
                        ).name
                      } - ${estimate.fixedRate} ${checkoutData.tradedCurrency.name}`,
                  },
                ]}
              />

              <SaleTokenInput
                name={YOU_GET_FIELD_NAME}
                label={t('checkouts.saleToken.get')}
                form={formInstance}
                checkoutData={checkoutData}
                staticCurrency={checkoutData.tradedCurrency}
                timer={timer}
                info={[
                  {
                    label: t('checkouts.saleToken.min'),
                    value: `${checkoutData.min} ${checkoutData.tradedCurrency.name}`,
                  },
                  {
                    label: t('checkouts.saleToken.max'),
                    value: `${checkoutData.max} ${checkoutData.tradedCurrency.name}`,
                  },
                ]}
              />

              {isMinMoreThanMax && (
                <div className={styles['sale-token-form__warning']}>
                  <WarningIcon /> {t('checkouts.saleToken.emptyWallet')}
                </div>
              )}

              <div className={styles['sale-token-form__input-wrapper']}>
                <span className={styles['sale-token-form__input-label']}>{t('checkouts.summary.recepient')}</span>
                <div className={`${styles['sale-token-form__input']} ${styles['sale-token-form__input-text']}`}>
                  <Form.Item name="recipient" noStyle={true} rules={[{ required: true, message: t('required') }]}>
                    <input
                      type="text"
                      placeholder={placeholderText.replace(/\s\s+/g, ' ')}
                    />
                  </Form.Item>
                  <span className={styles['sale-token-form__input-error']}>
                    {formInstance.getFieldError('recipient')}
                  </span>
                </div>
              </div>

              <button
                type="submit"
                disabled={isMinMoreThanMax || showRateUpdate}
                className={`${styles['sale-token-form__submit']}  ${styles[`sale-token-form__submit-${identity}`]} sale-token-form__custom-button`}
              >
                {t('next')}
              </button>
            </>
          )}
        </Form>
      </div>
    </>
  );
};

export default SaleTokenForm;
