import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { showReleaseNotes } from '../../redux/actions/releaseNotes';
import { useTranslation } from 'react-i18next';
import releaseNotesArray from '../ReleaseNotes/releaseNotesArray';
import { identity } from '../../utils/getIdentity';

import './VersionOverlay.scss';

const VersionOverlay = ({ showReleaseNotes }) => {
  const { t } = useTranslation();
  const releases = releaseNotesArray(t);
  const {pathname} = useLocation();

  return (
    <div
      className={`version-overlay version-overlay-${identity} ${pathname !== '/checkouts' && (pathname.includes('/checkout') || pathname.includes('/charge') || pathname.includes('/sale-token-checkout') || pathname.includes('/sale-token-charge')) ? 'checkout' : null}`}
      onClick={() => showReleaseNotes(releases.find(release => release.version === process.env.REACT_APP_VERSION))}
    >
      v. {process.env.REACT_APP_VERSION}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  showReleaseNotes: showReleaseNotes(dispatch),
});

export default connect(null, mapDispatchToProps)(VersionOverlay);
