import jwt from 'jwt-decode';
import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';
import { showWelcomeModal } from './user';

// CONSTANTS
export const LOGIN = 'LOGIN';
export const LOGIN_SIGN_IN = 'LOGIN_SET_TOKEN';
export const LOGIN_SOFT_SIGN_IN = 'LOGIN_SOFT_SIGN_IN';
export const LOGIN_SIGN_OUT = 'LOGIN_SIGN_OUT';
export const LOGIN_SET_APP_TWO_FA = 'LOGIN_SET_APP_TWO_FA';
export const LOGIN_SET_EMAIL_TWO_FA = 'LOGIN_SET_EMAIL_TWO_FA';
export const LOGIN_SHOW_PHONE_CODE_VERIFY = 'LOGIN_SHOW_PHONE_CODE_VERIFY';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';
export const LOGIN_METAMASK = 'LOGIN_METAMASK';

// ACTIONS GENERATORS
export const loginSignIn = () => ({
  type: LOGIN,
});

export const loginSignOut = () => ({
  type: LOGIN_SIGN_OUT,
});

export const loginSetToken = data => ({
  type: LOGIN_SIGN_IN,
  payload: data,
});

export const loginSetAppTwoFa = data => ({
  type: LOGIN_SET_APP_TWO_FA,
  payload: data,
});

export const loginSetEmailTwoFa = data => ({
  type: LOGIN_SET_EMAIL_TWO_FA,
  payload: data,
});

export const loginShowPhoneCodeVerify = loginShowPhoneCodeVerify => ({
  type: LOGIN_SHOW_PHONE_CODE_VERIFY,
  payload: loginShowPhoneCodeVerify,
});

export const loginError = err => ({
  type: LOGIN_ERROR,
  payload: err,
});

export const softLogin = jwtToken => ({
  type: LOGIN_SOFT_SIGN_IN,
  payload: { ...jwt(jwtToken), jwt: jwtToken },
  fetching: 'done',
});

export const googleLogin=()=>({
  type: LOGIN_GOOGLE
});

export const metamaskLogin=()=>({
  type: LOGIN_METAMASK
});


// ASYNC ACTIONS
export const logout = dispatch => () => {
  if (localStorage.getItem('authToken')) {
    cryptoApi.logout();
    localStorage.removeItem('authToken');
    localStorage.setItem('swapTab', 'swap')
  }
  localStorage.removeItem('cpayNetworkFilter');
  localStorage.setItem('swapTab', 'swap')
  dispatch(loginSignOut());
};

export const login = dispatch => async (login, password, appTwoFaKey, emailTwoFaKey, verifyCode, recaptcha) => {
  dispatch(loginSignIn());
  let response;
  try {
    response = await cryptoApi.login(login, password, appTwoFaKey, emailTwoFaKey, verifyCode, recaptcha);
  } catch (err) {
    dispatch(loginError(err));
    throw err;
  }
  if (response.token) {
    try {
      localStorage.setItem('authToken', response.token);
       window.location.href = '/welcome-user';
      return true;
    } catch (err) {
      localStorage.removeItem('authToken');
      dispatch(loginError());
      return false;
    }
  }

  if (response.data.twoFactorToken) {
    dispatch(loginSetAppTwoFa(response.data.twoFactorToken));
    dispatch(loginShowPhoneCodeVerify(false));
    return true;
  }

  if (response.data.emailSent) {
    dispatch(loginSetEmailTwoFa(response.data.emailSent));
    dispatch(loginShowPhoneCodeVerify(false));
    return true;
  }

  if (response.data.codeSent) {
    dispatch(loginShowPhoneCodeVerify(true));
    return true;
  }

  dispatch(loginError());
  return false;
};

export const loginByPhone = dispatch => async (phone, password, appTwoFaKey, emailTwoFaKey, verifyCode, recaptcha) => {
  dispatch(loginSignIn());
  let response;
  try {
    response = await cryptoApi.loginByPhone(phone, password, appTwoFaKey, emailTwoFaKey, verifyCode, recaptcha);
  } catch (err) {
    dispatch(loginError(err));
    throw err;
  }
  if (response.token) {
    try {
      localStorage.setItem('authToken', response.token);
      window.location.href = '/welcome-user';
      return true;
    } catch (err) {
      localStorage.removeItem('authToken');
      dispatch(loginShowPhoneCodeVerify(false));
      dispatch(loginError());
      return false;
    }
  }

  if (response.data.twoFactorToken) {
    dispatch(loginSetAppTwoFa(response.data.twoFactorToken));
    dispatch(loginShowPhoneCodeVerify(false));
    return true;
  }

  if (response.data.emailSent) {
    dispatch(loginSetEmailTwoFa(response.data.emailSent));
    dispatch(loginShowPhoneCodeVerify(false));
    return true;
  }

  if (response.data.codeSent) {
    dispatch(loginShowPhoneCodeVerify(true));
    return true;
  }

  dispatch(loginError());
  return false;
};

const standardLogIn=(response,dispatch)=>{
  if(response.token && window.location.href.includes('login')) {
    localStorage.setItem('authToken', response.token);
    window.location.href = '/welcome-user';
    return true;
  }
  if (response.token && window.location.href.includes('sign-up')) {
    localStorage.setItem('authToken', response.token);
    dispatch(showWelcomeModal(true))
    window.location.href = '/registration-completed';
    return true;
  }
}

export const loginWithSocialMedia=dispatch=>async(accessToken, network, role)=>{
  dispatch(googleLogin());

  try {
    const response = await cryptoApi.loginWithSocials(accessToken,network,role);
    standardLogIn(response,dispatch);
  }catch(err) {
    dispatch(loginError(err.data));
    dispatch(notificationsErrorShow(err.data));
    localStorage.removeItem('authToken');
  }
}

export const loginWithMetamask=dispatch=>async(data)=>{
  const {address,signature,roles} = data;
  dispatch(metamaskLogin());
  let result;

  try {
    const response = await cryptoApi.loginWithMetamask(address,signature,roles);
    standardLogIn(response,dispatch);
    result = true
  }catch(err) {
    dispatch(loginError(err.data));
    dispatch(notificationsErrorShow(err.data));
    localStorage.removeItem('authToken');
    result=false;
  }

  return result;
}
