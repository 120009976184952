import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import tickCircleIcon from '../../../img/default-svg/tickCircle.svg';
import dropdownIcon from '../../../img/default-svg/chevron-down.svg';
import TokenIcon from '../../../components/TokenIcon';
import './style.scss';

const removeKey = (key, { [key]: removed, ...rest }) => rest;

const CurrencyDropdown = ({
  currencies,
  merchantCurrencies,
  setFunc,
  multiple = false,
  allCurrencies = true,
  defaultValues,
  noTokens,
  onChangeAction,
  currenciesFetching,
  setCurrencyName,
  preselectedCurrency,
  setCurrencyObject,
  currencyItem,
  isCurrency,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(preselectedCurrency || {});
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (isCurrency) {
      if (!currencyItem) {
        setSelectedCurrency({});
        setFunc(undefined);
        if (setCurrencyObject) setCurrencyObject({});
      }
    }
  }, [currencyItem]);

  useEffect(() => {
    if (defaultValues && defaultValues.length && currencies.length && !currenciesFetching) {
      const result = defaultValues.reduce((obj, item) => {
        obj[item] = currencies.find(currency => currency._id === item);
        return obj;
      }, {});

      setSelectedCurrency(result);
      if (setCurrencyObject) setCurrencyObject(result);
    }
  }, [defaultValues, currencies, currenciesFetching]);

  const handleSelect = currency => {
    if (multiple) {
      let value;
      let isRemoving;

      if (!selectedCurrency[currency._id]) {
        isRemoving = false;
      } else {
        isRemoving = true;
      }

      if (currency.currencyType === 'currency') {
        if (isRemoving) {
          value = Object.values(selectedCurrency).reduce((acc, item) => {
            return item.nodeType !== currency.nodeType ? { ...acc, [item._id]: item } : acc;
          }, {});
        } else {
          value = { ...selectedCurrency, [currency._id]: currency };
        }
      } else if (currency.currencyType === 'token') {
        if (isRemoving) {
          value = removeKey(currency._id, selectedCurrency);
        } else {
          const currencyToAdd = currencies.find(
            item => currency.nodeType === item.nodeType && item.currencyType === 'currency'
          );

          value = { ...selectedCurrency, [currency._id]: currency, [currencyToAdd._id]: currencyToAdd };
        }
      }

      setSelectedCurrency(value);
      setFunc(Object.keys(value));
      if (setCurrencyObject) setCurrencyObject(value);
    } else {
      if (!selectedCurrency[currency._id]) {
        setSelectedCurrency({ [currency._id]: currency });
        if (setCurrencyObject) setCurrencyObject({ [currency._id]: currency });
        setFunc(currency._id);
        if (setCurrencyName) setCurrencyName(currency?.title);
      } else {
        setSelectedCurrency({});
        if (setCurrencyObject) setCurrencyObject({});
        setFunc(undefined);
      }
    }

    onChangeAction && onChangeAction();
  };

  return (
    <div className="admin-currencies-dropdown" onMouseLeave={() => setIsOpened(false)}>
      <img
        src={dropdownIcon}
        className={`admin-currencies-dropdown__arrow ${isOpened ? 'admin-currencies-dropdown__arrow_opened' : ''}`}
        alt="Dropdows"
        onClick={() => setIsOpened(!isOpened)}
      />
      <div className="admin-currencies-dropdown__selected" onClick={() => setIsOpened(!isOpened)}>
        {Object.values(selectedCurrency).length
          ? Object.values(selectedCurrency).map(currency => (
              <div className="admin-currencies-dropdown__selected-item" key={currency._id}>
                <TokenIcon tokenName={currency.name} className="admin-currencies-dropdown__logo" />
                {currency.title}
              </div>
            ))
          : 'Choose currency'}
      </div>
      {isOpened && (
        <div className="admin-currencies-dropdown__list">
          <div className="admin-currencies-dropdown__search">
            <input
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </div>
          {currencies.map(currency => {
            const isMatch =
              currency.title.toLowerCase().includes(searchValue.toLowerCase()) ||
              currency.name.toLowerCase().includes(searchValue.toLowerCase());

            if (
              (noTokens && currency.currencyType === 'token') ||
              (!allCurrencies && !merchantCurrencies.find(merchantCurrency => merchantCurrency === currency._id))
            ) {
              return false;
            } else {
              return (
                <div
                  key={currency._id}
                  className={`admin-currencies-dropdown__item ${
                    searchValue && !isMatch ? 'admin-currencies-dropdown__item_hidden' : ''
                  }`}
                  onClick={() => handleSelect(currency)}
                >
                  {selectedCurrency[currency._id] && (
                    <img src={tickCircleIcon} className="admin-currencies-dropdown__tick" alt="selected" />
                  )}
                  <TokenIcon tokenName={currency.name} className="admin-currencies-dropdown__logo" />
                  <div className="admin-currencies-dropdown__title">{currency.title}</div>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currencies: state.currencies.data,
  merchantCurrencies: state.merchantData.data.currencies,
  currenciesFetching: state.currencies.fetching,
});

export default connect(mapStateToProps)(CurrencyDropdown);
