import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import Button from '../Button';
import EmailTwoFaDisableModal from './EmailTwoFaDisableModal';
import { useTranslation } from 'react-i18next';
import { activateEmailTwoFactor } from '../../redux/actions/twoFactor';
import { changeEmailTwoFaStatus } from '../../redux/actions/user';
import useWindowSize from '../../utils/resizeHook';
import { identity } from '../../utils/getIdentity';

const EmailTwoFactor = ({
  isEmailConfirmed,
  isEmailTwoFactorEnabled,
  isUserHasEmail,
  emailTwoFaFetching,
  activateEmailTwoFactor,
  changeEmailTwoFaStatus,
}) => {
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  const handleSubmit = () => {
    activateEmailTwoFactor().then(() => {
      changeEmailTwoFaStatus(true);
    });
  };

  return (
    <div className="profile-block">
      <span className={`profile__title profile__title-${identity}`}>
        {t('profile.enableEmailTwoFa')} (
        <span className={isEmailTwoFactorEnabled ? 'profile__title_enabled' : 'profile__title_disabled'}>
          {isEmailTwoFactorEnabled ? t('profile.enabled') : t('profile.disabled')}
        </span>
        )
      </span>
      <span className="profile__subtitle">
        {isUserHasEmail ? t('profile.enableEmailTwoFaForSecurity') : t('profile.completeEmail')}
      </span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 4 : 24}>
          {isEmailTwoFactorEnabled ? (
            <EmailTwoFaDisableModal emailTwoFaFetching={emailTwoFaFetching} />
          ) : (
            <Button
              type="primary"
              disabled={emailTwoFaFetching || !isEmailConfirmed || !isUserHasEmail}
              loading={emailTwoFaFetching}
              className="profile__button"
              onClick={handleSubmit}
            >
              {t('profile.enableTwoFa')}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  emailTwoFaFetching: state.twoFactor.emailTwoFaFetching,
  phoneTwoFaFetching: state.twoFactor.phoneTwoFaFetching
});

const mapDispatchToProps = dispatch => ({
  activateEmailTwoFactor: activateEmailTwoFactor(dispatch),
  changeEmailTwoFaStatus: changeEmailTwoFaStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailTwoFactor);
