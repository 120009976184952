import { adminApi } from '../../../service/admin-api';
import { notificationsErrorShow } from '../notifications';

// GET_CRYPTO_CURRENCY_VOLUME
export const GET_CRYPTO_CURRENCY_VOLUME_REQUEST = 'GET_CRYPTO_CURRENCY_VOLUME_REQUEST';
export const GET_CRYPTO_CURRENCY_VOLUME_SUCCESS = 'GET_CRYPTO_CURRENCY_VOLUME_SUCCESS';
export const GET_CRYPTO_CURRENCY_VOLUME_FAIL = 'GET_CRYPTO_CURRENCY_VOLUME_FAIL';

const getCryptoCurrencyVolumeRequest = () => ({
  type: GET_CRYPTO_CURRENCY_VOLUME_REQUEST,
});

const getCryptoCurrencyVolumeSuccess = payload => ({
  type: GET_CRYPTO_CURRENCY_VOLUME_SUCCESS,
  payload,
});

const getCryptoCurrencyVolumeFail = payload => ({
  type: GET_CRYPTO_CURRENCY_VOLUME_FAIL,
  payload,
});

export const getCryptoCurrencyVolume = data => async dispatch => {
  dispatch(getCryptoCurrencyVolumeRequest());
  try {
    const response = await adminApi.getCryptoCurrencyVolume(data);
    dispatch(getCryptoCurrencyVolumeSuccess(response));
  } catch (error) {
    dispatch(getCryptoCurrencyVolumeFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET_CRYPTO_CURRENCY_QUANTITY
export const GET_CRYPTO_CURRENCY_QUANTITY_REQUEST = 'GET_CRYPTO_CURRENCY_QUANTITY_REQUEST';
export const GET_CRYPTO_CURRENCY_QUANTITY_SUCCESS = 'GET_CRYPTO_CURRENCY_QUANTITY_SUCCESS';
export const GET_CRYPTO_CURRENCY_QUANTITY_FAIL = 'GET_CRYPTO_CURRENCY_QUANTITY_FAIL';

const getCryptoCurrencyQuantityRequest = () => ({
  type: GET_CRYPTO_CURRENCY_QUANTITY_REQUEST,
});

const getCryptoCurrencyQuantitySuccess = payload => ({
  type: GET_CRYPTO_CURRENCY_QUANTITY_SUCCESS,
  payload,
});

const getCryptoCurrencyQuantityFail = payload => ({
  type: GET_CRYPTO_CURRENCY_QUANTITY_FAIL,
  payload,
});

export const getCryptoCurrencyQuantity = data => async dispatch => {
  dispatch(getCryptoCurrencyQuantityRequest());
  try {
    const response = await adminApi.getCryptoCurrencyQuantity(data);
    dispatch(getCryptoCurrencyQuantitySuccess(response));
  } catch (error) {
    dispatch(getCryptoCurrencyQuantityFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET_NEW_USERS
export const GET_NEW_USERS_REQUEST = 'GET_NEW_USERS_REQUEST';
export const GET_NEW_USERS_SUCCESS = 'GET_NEW_USERS_SUCCESS';
export const GET_NEW_USERS_FAIL = 'GET_NEW_USERS_FAIL';

const getNewUsersRequest = () => ({
  type: GET_NEW_USERS_REQUEST,
});

const getNewUsersSuccess = payload => ({
  type: GET_NEW_USERS_SUCCESS,
  payload,
});

const getNewUsersFail = payload => ({
  type: GET_NEW_USERS_FAIL,
  payload,
});

export const getNewUsers = data => async dispatch => {
  dispatch(getNewUsersRequest());
  try {
    const response = await adminApi.getNewUsers(data);
    dispatch(getNewUsersSuccess(response));
  } catch (error) {
    dispatch(getNewUsersFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET_WITHDRAW_TRANSACTIONS
export const GET_WITHDRAW_TRANSACTIONS_REQUEST = 'GET_WITHDRAW_TRANSACTIONS_REQUEST';
export const GET_WITHDRAW_TRANSACTIONS_SUCCESS = 'GET_WITHDRAW_TRANSACTIONS_SUCCESS';
export const GET_WITHDRAW_TRANSACTIONS_FAIL = 'GET_WITHDRAW_TRANSACTIONS_FAIL';

const getWithdrawTransactionsRequest = () => ({
  type: GET_WITHDRAW_TRANSACTIONS_REQUEST,
});

const getWithdrawTransactionsSuccess = payload => ({
  type: GET_WITHDRAW_TRANSACTIONS_SUCCESS,
  payload,
});

const getWithdrawTransactionsFail = payload => ({
  type: GET_WITHDRAW_TRANSACTIONS_FAIL,
  payload,
});

export const getWithdrawTransactions = data => async dispatch => {
  dispatch(getWithdrawTransactionsRequest());
  try {
    const response = await adminApi.getAdminTransactions(data);
    dispatch(getWithdrawTransactionsSuccess(response));
  } catch (error) {
    dispatch(getWithdrawTransactionsFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET_REPLENISH_TRANSACTIONS
export const GET_REPLENISH_TRANSACTIONS_REQUEST = 'GET_REPLENISH_TRANSACTIONS_REQUEST';
export const GET_REPLENISH_TRANSACTIONS_SUCCESS = 'GET_REPLENISH_TRANSACTIONS_SUCCESS';
export const GET_REPLENISH_TRANSACTIONS_FAIL = 'GET_REPLENISH_TRANSACTIONS_FAIL';

const getReplenishTransactionsRequest = () => ({
  type: GET_REPLENISH_TRANSACTIONS_REQUEST,
});

const getReplenishTransactionsSuccess = payload => ({
  type: GET_REPLENISH_TRANSACTIONS_SUCCESS,
  payload,
});

const getReplenishTransactionsFail = payload => ({
  type: GET_REPLENISH_TRANSACTIONS_FAIL,
  payload,
});

export const getReplenishTransactions = data => async dispatch => {
  dispatch(getReplenishTransactionsRequest());
  try {
    const response = await adminApi.getAdminTransactions(data);
    dispatch(getReplenishTransactionsSuccess(response));
  } catch (error) {
    dispatch(getReplenishTransactionsFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET_SYSTEM_FEE_TRANSACTIONS
export const GET_SYSTEM_FEE_TRANSACTIONS_REQUEST = 'GET_SYSTEM_FEE_TRANSACTIONS_REQUEST';
export const GET_SYSTEM_FEE_TRANSACTIONS_SUCCESS = 'GET_SYSTEM_FEE_TRANSACTIONS_SUCCESS';
export const GET_SYSTEM_FEE_TRANSACTIONS_FAIL = 'GET_SYSTEM_FEE_TRANSACTIONS_FAIL';

const getSystemFeeTransactionsRequest = () => ({
  type: GET_SYSTEM_FEE_TRANSACTIONS_REQUEST,
});

const getSystemFeeTransactionsSuccess = payload => ({
  type: GET_SYSTEM_FEE_TRANSACTIONS_SUCCESS,
  payload,
});

const getSystemFeeTransactionsFail = payload => ({
  type: GET_SYSTEM_FEE_TRANSACTIONS_FAIL,
  payload,
});

export const getSystemFeeTransactions = data => async dispatch => {
  dispatch(getSystemFeeTransactionsRequest());
  try {
    const response = await adminApi.getAdminTransactions(data);
    dispatch(getSystemFeeTransactionsSuccess(response));
  } catch (error) {
    dispatch(getSystemFeeTransactionsFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET_USER_FEE_TRANSACTIONS
export const GET_USER_FEE_TRANSACTIONS_REQUEST = 'GET_USER_FEE_TRANSACTIONS_REQUEST';
export const GET_USER_FEE_TRANSACTIONS_SUCCESS = 'GET_USER_FEE_TRANSACTIONS_SUCCESS';
export const GET_USER_FEE_TRANSACTIONS_FAIL = 'GET_USER_FEE_TRANSACTIONS_FAIL';

const getUserFeeTransactionsRequest = () => ({
  type: GET_USER_FEE_TRANSACTIONS_REQUEST,
});

const getUserFeeTransactionsSuccess = payload => ({
  type: GET_USER_FEE_TRANSACTIONS_SUCCESS,
  payload,
});

const getUserFeeTransactionsFail = payload => ({
  type: GET_USER_FEE_TRANSACTIONS_FAIL,
  payload,
});

export const getUserFeeTransactions = data => async dispatch => {
  dispatch(getUserFeeTransactionsRequest());
  try {
    const response = await adminApi.getAdminUserFeeTransactions(data);
    dispatch(getUserFeeTransactionsSuccess(response));
  } catch (error) {
    dispatch(getUserFeeTransactionsFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET_CRYPTO_CURRENCY_VOLUMETRXBYTYPE
export const GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_REQUEST = 'GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_REQUEST';
export const GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_SUCCESS = 'GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_SUCCESS';
export const GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_FAIL = 'GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_FAIL';

const getCryptoCurrencyVolumeTrxByTypeRequest = () => ({
  type: GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_REQUEST,
});

const getCryptoCurrencyVolumeTrxByTypeSuccess = payload => ({
  type: GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_SUCCESS,
  payload,
});

const getCryptoCurrencyVolumeTrxByTypeFail = payload => ({
  type: GET_CRYPTO_CURRENCY_VOLUME_TRX_BY_TYPE_FAIL,
  payload,
});

export const getCryptoCurrencyVolumeTrxByType = data => async dispatch => {
  const name = data.type.toLowerCase();

  dispatch(getCryptoCurrencyVolumeTrxByTypeRequest());
  try {
    const response = await adminApi.getCryptoCurrencyVolumeTrxByType(data);

    dispatch(getCryptoCurrencyVolumeTrxByTypeSuccess({ ...response, name }));
  } catch (error) {
    dispatch(getCryptoCurrencyVolumeTrxByTypeFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};
