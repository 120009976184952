import React from 'react';
import { Row, Col, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { identity } from '../../../utils/getIdentity';


const CheckoutsPagination = ({ page, pages, search, countItem, limit, getCheckoutListHandler }) => {
  const { t } = useTranslation();

  return countItem ? (
    <div className="checkouts-pagination">
      <Row align="middle">
        <Col span={2}>
          <div className="checkouts-pagination__position">
            <span>{t('pageCurrentOfTotal', { page, pages })}</span>
          </div>
        </Col>
        <Col span={20} className={`checkouts-pagination__wrapper checkouts-pagination__wrapper-${identity}`}>
          <Pagination
            current={page}
            total={countItem}
            pageSize={limit}
            showTotal={false}
            showSizeChanger={false}
            size="small"
            onChange={page => getCheckoutListHandler(page, search)}
          />
        </Col>
      </Row>
    </div>
  ) : (
    ''
  );
};

export default CheckoutsPagination;
