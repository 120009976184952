import React from 'react';
import { connect } from 'react-redux';
import Tour from 'reactour'
import { showAppTour } from '../../redux/actions/user';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { identity } from '../../utils/getIdentity';

const CpayTour = ({ userRole, isShowAppTour, setShowAppTour }) => {
  const { t } = useTranslation();

  const steps = {
    individual: [
      {
        selector: '.header__user-name',
        content: t('repeatGuide.profile'),
      },
      {
        selector: '.network-filter',
        content: t('repeatGuide.chooseNetwork'),
      },
      {
        selector: '.side-bar__settings',
        content: t('repeatGuide.settings'),
      },
      {
        selector: '.side-bar__wallets',
        content: t('repeatGuide.merchant'),
      },
      {
        selector: '.side-bar__checkouts',
        content: t('repeatGuide.invoices'),
      },
      {
        selector: '.side-bar__withdraw',
        content: t('repeatGuide.planWithdrawal'),
      },
      {
        selector: '.side-bar__guide',
        content: t('repeatGuide.rewatch'),
      },
    ],
    corporate: [
      {
        selector: '.header__user-name',
        content: t('repeatGuide.profile'),
      },
      {
        selector: '.network-filter',
        content: t('repeatGuide.chooseNetwork'),
      },
      {
        selector: '.side-bar__settings',
        content: t('repeatGuide.corpSettings'),
      },
      {
        selector: '.side-bar__merchants',
        content: t('repeatGuide.corpMerchant'),
      },
      {
        selector: '.side-bar__wallets',
        content: t('repeatGuide.merchant'),
      },
      {
        selector: '.side-bar__checkouts',
        content: t('repeatGuide.corpCheckouts'),
      },
      {
        selector: '.side-bar__withdraw',
        content: t('repeatGuide.planWithdrawal'),
      },
      {
        selector: '.side-bar__guide',
        content: t('repeatGuide.rewatch'),
      },
    ],
  };

  const cpaySteps = userRole && userRole.includes('corporate') ? steps.corporate : steps.individual
  const nfgpaySteps = userRole && userRole.includes('corporate') ? steps.corporate.slice(0, -1) : steps.individual.slice(0, -1)

  const mySteps = {
    cpay: cpaySteps,
    nfg: nfgpaySteps
  }

  return (
    <div>
      <Tour
      steps={mySteps[identity]}
      isOpen={isShowAppTour}
      onRequestClose={() => setShowAppTour(false)}
      scrollSmooth
      />
    </div>
  );
};

const mapStateToProps = state => ({
  userRole: state.login.data.roles,
  isShowAppTour: state.user.showAppTour,
});

const mapDispatchToProps = dispatch => ({
  setShowAppTour: data => dispatch(showAppTour(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CpayTour);
