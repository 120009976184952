const releaseNotesArray = t => {
  return [
    {
      version: '4.4.1',
      date: 'October 9, 2024',
      month: 'October',
      time: '9, 2024',
      link: 'https://docs.cpay.world/changes/release-notes/release-4.4.1',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionFourFourOne.sections.first.changes.1'),
            t('releases.versionFourFourOne.sections.first.changes.2'),
            t('releases.versionFourFourOne.sections.first.changes.3'),
            t('releases.versionFourFourOne.sections.first.changes.4'),
            t('releases.versionFourFourOne.sections.first.changes.5'),
            t('releases.versionFourFourOne.sections.first.changes.6'),
            t('releases.versionFourFourOne.sections.first.changes.7'),
            t('releases.versionFourFourOne.sections.first.changes.8'),
          ],
        },
      ],
    },
    {
      version: '4.4',
      date: 'September 18, 2024',
      month: 'September',
      time: '18, 2024',
      link: 'https://docs.cpay.world/changes/release-notes/release-4.4',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionFourFour.sections.first.changes.1'),
          ],
        },
      ],
    },
    {
      version: '4.3',
      date: 'June 25, 2024',
      month: 'June',
      time: '25, 2024',
      link: 'https://docs.cpay.world/changes/release-notes/release-4.3',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionFourThree.sections.first.changes.1'),
            t('releases.versionFourThree.sections.first.changes.2'),
            t('releases.versionFourThree.sections.first.changes.3'),
          ],
        },
      ],
    },
     {
      version: '4.2.2',
      date: 'April 11, 2024',
      month: 'April',
      time: '11, 2024',
      // link: 'https://docs.cpay.world/changes/release-notes/release-4.2',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionFourTwoTwo.sections.first.changes.1'),
            t('releases.versionFourTwoTwo.sections.first.changes.2'),
            t('releases.versionFourTwoTwo.sections.first.changes.3'),
          ],
        },
      ],
    },
    {
      version: '4.2.1',
      date: 'March 11, 2024',
      month: 'March',
      time: '11, 2024',
      // link: 'https://docs.cpay.world/changes/release-notes/release-4.2',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionFourTwoOne.sections.first.changes.1'),
            t('releases.versionFourTwoOne.sections.first.changes.2'),
            t('releases.versionFourTwoOne.sections.first.changes.3'),
          ],
        },
      ],
    },
    {
      version: '4.2',
      date: 'January 18, 2024',
      month: 'January',
      time: '18, 2024',
      link: 'https://docs.cpay.world/changes/release-notes/release-4.2',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionFourTwo.sections.first.changes.1'),
            t('releases.versionFourTwo.sections.first.changes.2'),
            t('releases.versionFourTwo.sections.first.changes.3'),
            t('releases.versionFourTwo.sections.first.changes.4'),
            t('releases.versionFourTwo.sections.first.changes.5'),
            t('releases.versionFourTwo.sections.first.changes.6'),
            t('releases.versionFourTwo.sections.first.changes.7'),
            t('releases.versionFourTwo.sections.first.changes.8'),
            t('releases.versionFourTwo.sections.first.changes.9'),
            t('releases.versionFourTwo.sections.first.changes.10'),
            t('releases.versionFourTwo.sections.first.changes.11'),
            t('releases.versionFourTwo.sections.first.changes.12'),
            t('releases.versionFourTwo.sections.first.changes.13'),
          ],
        },
        {
          title: t('releases.improvedFixed'),
          changes: [
            t('releases.versionFourTwo.sections.second.changes.1'),
            t('releases.versionFourTwo.sections.second.changes.2'),
            t('releases.versionFourTwo.sections.second.changes.3'),
            t('releases.versionFourTwo.sections.second.changes.4'),
          ],
        },
      ],
    },
    {
      version: '4.1',
      date: 'September 7, 2023',
      month: 'September',
      time: '7, 2023',
      link: 'https://docs.cpay.world/changes/release-notes/release-4.1',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionFourOne.sections.first.changes.1'),
            t('releases.versionFourOne.sections.first.changes.2'),
            t('releases.versionFourOne.sections.first.changes.3'),
            t('releases.versionFourOne.sections.first.changes.4'),
            t('releases.versionFourOne.sections.first.changes.5'),
            t('releases.versionFourOne.sections.first.changes.6'),
            t('releases.versionFourOne.sections.first.changes.7'),
            t('releases.versionFourOne.sections.first.changes.8'),
          ],
        },
        {
          title: t('releases.improved'),
          changes: [t('releases.versionFourOne.sections.second.changes.1')],
        },
      ],
    },
    {
      version: '4.0',
      date: 'January 11, 2023',
      month: 'January',
      time: '11, 2023',
      link: 'https://docs.cpay.world/changes/release-notes/release-4.0',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionFourZero.sections.first.changes.1'),
            t('releases.versionFourZero.sections.first.changes.2'),
            t('releases.versionFourZero.sections.first.changes.3'),
          ],
        },
        {
          title: t('releases.improved'),
          changes: [
            t('releases.versionFourZero.sections.second.changes.1'),
            t('releases.versionFourZero.sections.second.changes.2'),
            t('releases.versionFourZero.sections.second.changes.3'),
            t('releases.versionFourZero.sections.second.changes.4'),
          ],
        },
      ],
    },
    {
      version: '3.9',
      date: 'November 10, 2022',
      month: 'November',
      time: '10, 2022',
      link: 'https://docs.cpay.world/changes/release-notes/release-3.9',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionThreeNine.sections.first.changes.1'),
            t('releases.versionThreeNine.sections.first.changes.2'),
            t('releases.versionThreeNine.sections.first.changes.3'),
            t('releases.versionThreeNine.sections.first.changes.4'),
            t('releases.versionThreeNine.sections.first.changes.5'),
            t('releases.versionThreeNine.sections.first.changes.6'),
            t('releases.versionThreeNine.sections.first.changes.7'),
            t('releases.versionThreeNine.sections.first.changes.8'),
            t('releases.versionThreeNine.sections.first.changes.9'),
          ],
        },
      ],
    },
    {
      version: '3.8',
      date: 'October 18, 2022',
      month: 'October',
      time: '18, 2022',
      link: 'https://docs.cpay.world/changes/release-notes/release-3.8',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionThreeEight.sections.first.changes.1'),
            t('releases.versionThreeEight.sections.first.changes.2'),
            t('releases.versionThreeEight.sections.first.changes.3'),
            t('releases.versionThreeEight.sections.first.changes.4'),
          ],
        },
        {
          title: t('releases.improved'),
          changes: [
            t('releases.versionThreeEight.sections.second.changes.1'),
            t('releases.versionThreeEight.sections.second.changes.2'),
            t('releases.versionThreeEight.sections.second.changes.3'),
          ],
        },
      ],
    },
    {
      version: '3.7',
      date: 'September 15, 2022',
      month: 'September',
      time: '15, 2022',
      link: 'https://docs.cpay.world/changes/release-notes/release-3.7',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionThreeSeven.sections.first.changes.1'),
            t('releases.versionThreeSeven.sections.first.changes.2'),
            t('releases.versionThreeSeven.sections.first.changes.3'),
            t('releases.versionThreeSeven.sections.first.changes.4'),
            t('releases.versionThreeSeven.sections.first.changes.5'),
            t('releases.versionThreeSeven.sections.first.changes.6'),
          ],
        },
        {
          title: t('releases.improved'),
          changes: [
            t('releases.versionThreeSeven.sections.second.changes.1'),
            t('releases.versionThreeSeven.sections.second.changes.2'),
          ],
        },
      ],
    },
    {
      version: '3.6',
      date: 'August 4, 2022',
      month: 'August',
      time: '4, 2022',
      link: 'https://docs.cpay.world/changes/release-notes/release-3.6',
      sections: [
        {
          title: t('releases.new'),
          changes: [t('releases.versionThreeSix.sections.first.changes.changeFirst')],
        },
        {
          title: t('releases.improved'),
          changes: [
            t('releases.versionThreeSix.sections.second.changes.changeFirst'),
            t('releases.versionThreeSix.sections.second.changes.changeSecond'),
            t('releases.versionThreeSix.sections.second.changes.changeThird'),
          ],
        },
      ],
    },
    {
      version: '3.5',
      date: 'May 12, 2022',
      month: 'May',
      time: '12, 2022',
      link: 'https://docs.cpay.world/changes/release-notes/release-3.5',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionThreeFive.sections.first.changes.changeFirst'),
            t('releases.versionThreeFive.sections.first.changes.changeSecond'),
            t('releases.versionThreeFive.sections.first.changes.changeThird'),
          ],
        },
        {
          title: t('releases.improved'),
          changes: [
            t('releases.versionThreeFive.sections.second.changes.changeFirst'),
            t('releases.versionThreeFive.sections.second.changes.changeSecond'),
            t('releases.versionThreeFive.sections.second.changes.changeThird'),
          ],
        },
      ],
    },
    {
      version: '3.4.1',
      date: 'March 22, 2022',
      month: 'March',
      time: '22, 2022',
      link: 'https://docs.cpay.world/changes/release-notes/release-3.4.1',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionThreeFourOne.sections.first.changes.changeFirst'),
            t('releases.versionThreeFourOne.sections.first.changes.changeSecond'),
            t('releases.versionThreeFourOne.sections.first.changes.changeThird'),
            t('releases.versionThreeFourOne.sections.first.changes.changeFourth'),
          ],
        },
        {
          title: t('releases.improvedFixed'),
          changes: [
            t('releases.versionThreeFourOne.sections.second.changes.changeFirst'),
            t('releases.versionThreeFourOne.sections.second.changes.changeSecond'),
            t('releases.versionThreeFourOne.sections.second.changes.changeThird'),
            t('releases.versionThreeFourOne.sections.second.changes.changeFourth'),
          ],
        },
      ],
    },
    {
      version: '3.4',
      date: 'March 4, 2022',
      month: 'March',
      time: '4, 2022',
      link: 'https://docs.cpay.world/changes/release-notes/release-3.4',
      sections: [
        {
          title: t('releases.new'),

          changes: [
            t('releases.versionThreeFour.sections.first.changes.changeFirst'),
            t('releases.versionThreeFour.sections.first.changes.changeThird'),
            t('releases.versionThreeFour.sections.first.changes.changeFour'),
            t('releases.versionThreeFour.sections.first.changes.changeFive'),
            t('releases.versionThreeFour.sections.first.changes.changeSix'),
            t('releases.versionThreeFour.sections.first.changes.changeSeven'),
            t('releases.versionThreeFour.sections.first.changes.changeEight'),
            t('releases.versionThreeFour.sections.first.changes.changeNine'),
            t('releases.versionThreeFour.sections.first.changes.changeTen'),
            t('releases.versionThreeFour.sections.first.changes.changeEleven'),
            t('releases.versionThreeFour.sections.first.changes.changeTwelve'),
            t('releases.versionThreeFour.sections.first.changes.changeThirteen'),
            t('releases.versionThreeFour.sections.first.changes.changeFourteen'),
            t('releases.versionThreeFour.sections.first.changes.changeFifteen'),
            t('releases.versionThreeFour.sections.first.changes.changeSixteen'),
            t('releases.versionThreeFour.sections.first.changes.changeSeventeen'),
            t('releases.versionThreeFour.sections.first.changes.changeEighteen'),
            t('releases.versionThreeFour.sections.first.changes.changeNineteen'),
            t('releases.versionThreeFour.sections.first.changes.changeTwenty'),
          ],
        },
        {
          title: t('releases.improvedFixed'),
          changes: [
            t('releases.versionThreeFour.sections.second.changes.changeFirst'),
            t('releases.versionThreeFour.sections.second.changes.changeSecond'),
            t('releases.versionThreeFour.sections.second.changes.changeThird'),
            t('releases.versionThreeFour.sections.second.changes.changeFour'),
            t('releases.versionThreeFour.sections.second.changes.changeFive'),
            t('releases.versionThreeFour.sections.second.changes.changeSix'),
            t('releases.versionThreeFour.sections.second.changes.changeSeven'),
            t('releases.versionThreeFour.sections.second.changes.changeEight'),
            t('releases.versionThreeFour.sections.second.changes.changeNine'),
          ],
        },
      ],
    },
    {
      version: '3.3.2',
      date: 'January 25, 2022',
      month: 'January',
      time: '25, 2022',
      link: 'https://docs.cpay.world/changes/release-notes/release-3.3.2',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionThreeThreeTwo.sections.first.changes.changeFirst'),
            t('releases.versionThreeThreeTwo.sections.first.changes.changeSecond'),
            t('releases.versionThreeThreeTwo.sections.first.changes.changeThird'),
            t('releases.versionThreeThreeTwo.sections.first.changes.changeFour'),
          ],
        },
        {
          title: t('releases.improvedFixed'),
          changes: [
            t('releases.versionThreeThreeTwo.sections.second.changes.changeFirst'),
            t('releases.versionThreeThreeTwo.sections.second.changes.changeSecond'),
          ],
        },
      ],
    },
    {
      version: '3.3.1',
      date: 'January 21, 2022',
      month: 'January',
      time: '21, 2022',
      link: 'https://docs.cpay.world/changes/release-notes/release-3.3.1',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionThreeThreeOne.sections.first.changes.changeFirst'),
            t('releases.versionThreeThreeOne.sections.first.changes.changeSecond'),
            t('releases.versionThreeThreeOne.sections.first.changes.changeThird'),
          ],
        },
      ],
    },
    {
      version: '3.3',
      date: 'January 17, 2022',
      month: 'January',
      time: '17, 2022',
      link: 'https://docs.cpay.world/changes/release-notes/release-3.3',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            t('releases.versionThreeThree.sections.first.changes.changeFirst'),
            t('releases.versionThreeThree.sections.first.changes.changeSecond'),
            t('releases.versionThreeThree.sections.first.changes.changeThird'),
            t('releases.versionThreeThree.sections.first.changes.changeFour'),
          ],
        },
        {
          title: t('releases.improvedFixed'),
          changes: [
            t('releases.versionThreeThree.sections.second.changes.changeFirst'),
            t('releases.versionThreeThree.sections.second.changes.changeSecond'),
          ],
        },
      ],
    },
  ];
};

export default releaseNotesArray;
