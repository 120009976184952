import { adminApi } from '../../../service/admin-api';
import { notificationsErrorShow } from '../notifications';
import moment from 'moment';
import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';


// const exportToExcel = (apiData, fileName) => {
//   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//   const fileExtension = '.xlsx';

//   const ws = XLSX.utils.json_to_sheet(apiData);
//   const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
//   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//   const data = new Blob([excelBuffer], { type: fileType });
//   FileSaver.saveAs(data, fileName + fileExtension);
// };

// GET USERS EMAILS LIST
export const GET_ADMIN_MARKETING_INFO_REQUEST = 'GET_ADMIN_MARKETING_INFO_REQUEST';
export const GET_ADMIN_MARKETING_INFO_SUCCESS = 'GET_ADMIN_MARKETING_INFO_SUCCESS';
export const GET_ADMIN_MARKETING_INFO_ERROR = 'GET_ADMIN_MARKETING_INFO_ERROR';

export const getMarketingInfoRequest = () => ({
  type: GET_ADMIN_MARKETING_INFO_REQUEST,
});

export const getMarketingInfoSuccess = payload => ({
  type: GET_ADMIN_MARKETING_INFO_SUCCESS,
  payload,
});

export const getMarketingInfoError = () => ({
    type: GET_ADMIN_MARKETING_INFO_ERROR
  });

export const getMarketingInfo = body => async dispatch => {
  dispatch(getMarketingInfoRequest());

  const data = {
    ...body,
    dateForFiltering: undefined,
    dateFrom:
      body.dateForFiltering && body.dateForFiltering[0]
        ? moment(body.dateForFiltering[0]).format('YYYY-MM-DD') + 'T00:00:01'
        : undefined,
    dateTo:
      body.dateForFiltering && body.dateForFiltering[1]
        ? moment(body.dateForFiltering[1]).format('YYYY-MM-DD') + 'T23:59:59'
        : undefined,
  };

  try {
    const response = await adminApi.getMarketingInfo(data);
    const file = await response.blob();
    FileSaver.saveAs(file, `marketing-${moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss')}.xlsx`);
    dispatch(getMarketingInfoSuccess());

  } catch (error) {
    const notif = await error.json();
    dispatch(notificationsErrorShow(notif.data));
    dispatch(getMarketingInfoError());
  }
};
