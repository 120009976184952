import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import AdminCurrencyItem from './AdminCurrencyItem';
import emptyCpayImage from '../../../../img/cpay-svg/empty.svg';
import emptyNFGImage from '../../../../img/nfgpay-svg/empty-nfg.svg';
import emptyFinvaroImage from '../../../../img/finvaro/empty-finvaro.svg';
import emptyClarniumImage from '../../../../img/clarnium/empty-clarnium.svg';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';
import { identity } from '../../../../utils/getIdentity';

const emptyImage = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage,
  finvaro: emptyFinvaroImage,
  clarnium: emptyClarniumImage
}

const AdminCurrencySelector = ({
  getAdminBalances,
  adminBalancesFetching,
  currency,
  setCurrency,
  balancesTotal,
  getAdminFeeWalletsBalance,

}) => {
  const { t } = useTranslation();

  const filteredMerchantBalances = getAdminBalances?.filter(balance => balance.balance.usd > 0)?.sort((a, b) => b?.balance?.usd - a?.balance?.usd)

  return (
    <Row gutter={[0, 12]}>
     
      <Col span={24}>
        <div className="admin-withdraw-currencies">
          <div className="admin-withdraw-currencies__title">
            {t('coin')}
            {currency && (
              <div className={`admin-withdraw-currencies__select-all withdraw-currencies__select-all-${identity}`} onClick={() => setCurrency('')}>
                {t('showAllCurrency')}
              </div>
            )}
          </div>
          <div className="admin-withdraw-currencies__items-wrapper">
            {!adminBalancesFetching ? (
              getAdminBalances.length ? (
                filteredMerchantBalances.map(currencyBalance => (
                  <AdminCurrencyItem
                    currency={currency}
                    setCurrency={setCurrency}
                    key={currencyBalance.currency.id}
                    currencyBalance={currencyBalance}
                    title={currencyBalance.currency.title}
                  />
                ))
              ) : (
                <div className="withdraw-wallets__empty">
                  <span className="transactions-list__empty-title">{t('wallets.emptyList')}</span>
                  <img src={emptyImage[identity]} className="transactions-list__empty-image" alt="empty" />
                </div>
              )
            ) : (
              <div className="admin-withdraw-currencies__loader">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AdminCurrencySelector;
