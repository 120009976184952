export const errorTranslations = (t, text) => {
  if (text.includes('Wait')) {
    const textArray = text.split(' ');
    return t('backendErrors.waitingTime', { min: textArray[2], sec: textArray[5] });
  } else {
    const errors = {
      'email must be an email': t('backendErrors.emailValidation'),
      'To continue working with the service, you need to confirm your email.': t('backendErrors.emailConf'),
      'name should not be empty': t('backendErrors.nameValidation'),
      'surname should not be empty': t('backendErrors.surnameValidation'),
      'password should not be empty': t('backendErrors.passwordEmpty'),
      'Password must contain at least 8 characters, 1 uppercase letter and 1 digit.': t('backendErrors.passwordValid'),
      'repeatPassword should not be empty': t('backendErrors.repeatPassword'),
      'Passwords must match.': t('backendErrors.matchPasswords'),
      // 'Wait 4.93 minutes before next email verification request': t('backendErrors.waitingTime'),
      'Code is required.': t('backendErrors.code'),
      'Wrong confirmation code': t('backendErrors.verfiCode'),
      'twoFactorToken should not be empty': t('backendErrors.twoFactorToken'),
      'Wrong 2fa token.': t('backendErrors.wrongToken'),
      "Words don't match with the phrase": t('backendErrors.wordsMatching'),
      'The user already exists': t('backendErrors.existingUser'),
      'You need to confirm your email before changing it': t('backendErrors.emailNotConf'),
      'Code expired or does not exist': t('backendErrors.codeExpiration'),
      'PrivateKey is Invalid': t('backendErrors.privateKey'),
      'Wrong E-mail 2FA': t('backendErrors.wrongEmail'),
      'Wrong 2FA key': t('backendErrors.wrongKey'),
      'payerCommission must be a valid enum value': t('backendErrors.payerCommission'),
      'amount must be a number string': t('backendErrors.stringAmount'),
      'Address INVALID': t('backendErrors.invalidAddress'),
      "Currency is required": t('backendErrors.requiredCurrency'),
      "Private key is required": t('backendErrors.requiredPrivateKey'),
      "You need to enable 2FA first": t('backendErrors.enablingTwoFa'),
      "customerEmail must be an email": t('backendErrors.emailVerification'),
      "Address \"recipient\" is invalid.": t('backendErrors.recAddress'),
      "Charge not found.": t('backendErrors.notFoundCharge'),
      "Wallet not found or no permission.": t('backendErrors.walletNotFound'),
      "Amount must not be less than the min value.": t('backendErrors.minAmount'),
      "Amount must not be greater than the max value.": t('backendErrors.maxAmount'),
      "Signature verification failed.":t('backendErrors.metamaskSignature'),
      "This address already install.": t('backendErrors.metamaskDoubleInstall'),
      "To avoid losing your account, you need to set up an email or phone.": t('backendErrors.metamaskSetUpEmailPhone'),
      "This address is not installed." : t('backendErrors.metamaskNotInstalled'),
      "Mnemonic phrase is invalid":t('backendErrors.mnemonicAddWallet')
    };

    return errors[text] ? errors[text] : text;
  }
};

export const successMessagesTrans = (t, text) => {
  const successMessages = {
    'To continue working with the service, you need to confirm your email.': t('backendSuccess.changeMail'),
    'To continue working with the service, you need to confirm your phone.': t('backendSuccess.changePhone')
  };

  return successMessages[text];
};
