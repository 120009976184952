import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { identity } from '../../../utils/getIdentity';


const NotFound = ({ title }) => {
  const {t} = useTranslation();

  return (
    <div className="checkout-not-found__wrapper">
      <div className="checkout-not-found">
        <div className={`checkout-not-found__code checkout-not-found__code-${identity}`}>404</div>
        <div className={`checkout-not-found__title checkout-not-found__title-${identity}`}>{title || 'Checkout'} {t('checkouts.notFound.notFound')}</div>
        <div className={`checkout-not-found__description`}>
        {t('checkouts.notFound.description')} <br /> {t('checkouts.notFound.descr')}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
