import React from 'react';
import './style.scss';

const InputFeeForFilterTransactions = ({ title, amount }) => {
  return (
    <div className="inputFeeForFilter">
      <div className="inputFeeForFilter__title">{title}</div>
      <div className="inputFeeForFilter__block">
        <span className="inputFeeForFilter__amount">{amount || 0}</span>
        <span className="inputFeeForFilter__currency">USD</span>
      </div>
    </div>
  );
};

export default InputFeeForFilterTransactions;
