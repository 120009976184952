import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import TEXT from '../../components/Text';
import UserTransactionsList from '../../components/UserTransactionsList';
import { resetAdminUserState, getAdminUserById } from '../../../redux/actions/adminPanel/adminUsers';
import { scrollContainerIntoView } from '../../helpers/scrollIntoView';
import backCpayIcon from '../../../img/cpay-svg/arrow-back.svg';
import backNFGIcon from '../../../img/nfgpay-svg/arrow-back-nfg.svg';
import backFinvaroIcon from '../../../img/finvaro/arrow-back-finvaro.svg';
import backClarniumIcon from '../../../img/clarnium/arrow-back-clarnium.svg';
import { identity } from '../../../utils/getIdentity';

const backIcon = {
  cpay: backCpayIcon,
  nfg: backNFGIcon,
  finvaro: backFinvaroIcon,
  clarnium: backClarniumIcon
};

const TransactionsByUser = ({ getAdminUserById, resetAdminUserState, currentUserData }) => {
  const { userId } = useParams();
  const { phone, email, name, surname, metamask, id } = currentUserData || {};
  const adminUserTransactionRef = useRef();

  useEffect(() => () => resetAdminUserState(), []);

  useEffect(() => {
    if (userId) {
      getAdminUserById(userId);
    }
  }, [userId]);

  useEffect(() => {
    scrollContainerIntoView(adminUserTransactionRef);
  }, []);

  return (
    <div className="admin-user-transactions" ref={adminUserTransactionRef}>
      <Card>
        <Link
          className={`admin-user-transactions__arrow-back admin-user-transactions__arrow-back-${identity}`}
          to="/admin/users"
        >
          <img src={backIcon[identity]} alt="arrow back" /> <span>Go Back</span>
        </Link>
        <TEXT.pageTitle>Transactions by user</TEXT.pageTitle>
        <UserTransactionsList
          userSearchParams={email || phone || metamask?.address}
          userName={name && surname ? `${name} ${surname}` : name}
          userId={id}
          isFromTransactionsByUser
        />
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUserData: state.adminUsers.editableUser.data,
});

const mapDispatchToProps = {
  getAdminUserById,
  resetAdminUserState,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsByUser);
