import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import CurrencyItem from './CurrencyItem';
import emptyCpayImage from '../../../../img/cpay-svg/empty.svg';
import emptyNFGImage from '../../../../img/nfgpay-svg/empty-nfg.svg';
import emptyFinvaroImage from '../../../../img/finvaro/empty-finvaro.svg';
import emptyClarniumImage from '../../../../img/clarnium/empty-clarnium.svg';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import './style.scss';
import { identity } from '../../../../utils/getIdentity';

const emptyImage = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage,
  finvaro: emptyFinvaroImage,
  clarnium: emptyClarniumImage
}


const CurrencySelector = ({ currencies, currenciesFetching, currency, setCurrency, currencyRef, merchantBalances }) => {
  const { t } = useTranslation();

  const loader = <div className="withdraw-currencies__loader">
  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
</div>

const supportedMultisend = currencies.filter(c => c.supportMultisend)

const tokensAvailable = supportedMultisend && supportedMultisend.map((curr) => {
  const currency = merchantBalances.find(item => item.currency.id === curr._id)

  return currency
})?.filter(balance => balance?.balance?.usd > 0)?.sort((a, b) => Number(b?.balance?.usd) - Number(a?.balance?.usd))

  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <div className="withdraw-currencies">
          <div className="withdraw-currencies__title">{t('multisendWithdraw.tokenMultipayment')}</div>
          {merchantBalances.length ?
          (
            <div className="withdraw-currencies__items-wrapper">
            {!currenciesFetching ? (
              currencies?.length ? (
                tokensAvailable?.map(
                  currencyItem =>
                     (
                      <CurrencyItem
                        currency={currency}
                        setCurrency={setCurrency}
                        key={currencyItem?.currency?.id}
                        currencyItem={currencyItem}
                        currencyRef={currencyRef}
                        merchantBalances={merchantBalances}
                      />
                    )
                )
              ) : (
                <div className="withdraw-wallets__empty">
                  <span className="transactions-list__empty-title">{t('wallets.emptyList')}</span>
                  <img src={emptyImage[identity]} className="transactions-list__empty-image" alt="empty" />
                </div>
              )
            ) : loader}
          </div>
          ) : loader }
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  merchantBalances: state.merchantData.balances.balances || [],
});


export default connect(mapStateToProps)(CurrencySelector);
