import React, { useEffect, useRef } from 'react';

import Card from '../../components/Card';
import TEXT from '../../components/Text';
import UserTransactionsList from '../../components/UserTransactionsList';
import { scrollContainerIntoView } from '../../helpers/scrollIntoView';
import './style.scss';

const Transactions = () => {
  const transactionsContainerRef = useRef();

  useEffect(() => {
    scrollContainerIntoView(transactionsContainerRef);
  }, []);

  return (

    <div className="admin-user-transactions" ref={transactionsContainerRef}>
      <Card>
        <TEXT.pageTitle>User's Transactions</TEXT.pageTitle>
        <UserTransactionsList />
      </Card>
    </div>
  )
};

export default Transactions;
