import React, { useEffect } from 'react';
import { ReactComponent as ExpiredSVG } from '../../../img/default-svg/expired.svg';
import { useTranslation } from 'react-i18next';
import './style.scss';

const Expired = ({ hasPrice, buttonRestartHandler }) => {
  const { t } = useTranslation();

  useEffect(() => {
    !hasPrice && buttonRestartHandler();
  }, []);

  return (
    <div className="checkout-expired">
      <ExpiredSVG />
      <div className="checkout-expired__title">{t('checkouts.expired.title')}</div>
      <div className="checkout-expired__description">
        {t('checkouts.expired.description')}
        <br />
        {t('checkouts.expired.tryAgain')}
      </div>
    </div>
  );
};

export default Expired;
