import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as SearchIconSvg } from '../../../img/default-svg/empty-search.svg';
import { ReactComponent as ResetCpaySvg } from '../../../img/cpay-svg/reset.svg';
import { ReactComponent as ResetNfgSvg } from '../../../img/nfgpay-svg/reset-nfg.svg';
import { ReactComponent as ResetFinvaroSvg } from '../../../img/finvaro/reset-finvaro.svg';
import { ReactComponent as ResetClarniumSvg } from '../../../img/clarnium/reset-clarnium.svg';
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const resetIcon = {
  cpay: <ResetCpaySvg/>,
  nfg: <ResetNfgSvg/>,
  finvaro: <ResetFinvaroSvg />,
  clarnium: <ResetClarniumSvg />
}

const SwapEmpty = ({ resetFilters, historyFilters, defaultHistoryFilters, activeTab }) => {
  const [isFiltersActive, setIsFiltersActive] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (
      historyFilters.search === defaultHistoryFilters.search &&
      historyFilters.currencyFromId === defaultHistoryFilters.currencyFromId &&
      historyFilters.currencyToId === defaultHistoryFilters.currencyToId &&
      historyFilters.order === defaultHistoryFilters.order
    ) {
      setIsFiltersActive(false);
    } else {
      setIsFiltersActive(true);
    }
  }, []);

  return (
    <div className="swap-empty">
      <div className="swap-empty__icon">
        <SearchIconSvg />
      </div>
      <div className="swap-empty__text">
        {isFiltersActive
          ? t('swap.noResults')
          : t('swap.emptyHistory')}
      </div>
      {isFiltersActive ? (
        <div className={`swap-empty__reset swap-empty__reset-${identity}`} onClick={resetFilters}>
          {resetIcon[identity]} {t('swap.resetSearch')}
        </div>
      ) : (
        <Link
          className={`swap-empty__new swap-empty__new-${identity}`}
          to={activeTab === 'stable' ? '/swap/create/stable' : '/swap/create'}
        >
          {t('swap.new')}
        </Link>
      )}
    </div>
  );
};

export default SwapEmpty;
