import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import Button from '../../../../Button';
import './style.scss';

const CustomerInfo = ({ editCheckoutState, checkoutCustomerInfo, setCheckoutCustomerInfo, nextStep, prevStep }) => {
  const  {t} = useTranslation();

  useEffect(() => {
    if (editCheckoutState) {
      setCheckoutCustomerInfo({
        collectName: editCheckoutState.collectName || checkoutCustomerInfo.collectName,
        collectEmail: editCheckoutState.collectEmail || checkoutCustomerInfo.collectEmail,
      });
    }
  }, [editCheckoutState]);

  return (
    <div className="checkout-customer-info">
      <span className="checkout-customer-info__title">{t('checkouts.customerInfo.title')}</span>
      <div className="checkout-customer-info__settings">
        <Checkbox
          checked={!checkoutCustomerInfo.collectName && !checkoutCustomerInfo.collectEmail}
          onChange={e =>
            setCheckoutCustomerInfo({
              collectName: !e.target.checked,
              collectEmail: !e.target.checked,
            })
          }
        >
          {t('checkouts.customerInfo.settings')}
        </Checkbox>
        <Checkbox
          checked={checkoutCustomerInfo.collectName}
          onChange={e =>
            setCheckoutCustomerInfo({
              collectName: e.target.checked,
              collectEmail: checkoutCustomerInfo.collectEmail,
            })
          }
        >
          {t('checkouts.customerInfo.fullName')}
        </Checkbox>
        <Checkbox
          checked={checkoutCustomerInfo.collectEmail}
          onChange={e =>
            setCheckoutCustomerInfo({
              collectEmail: e.target.checked,
              collectName: checkoutCustomerInfo.collectName,
            })
          }
        >
          {t('checkouts.customerInfo.email')}
        </Checkbox>
        <div className="checkout-customer-info__button-container">
          <Button type="primary" className="checkouts-list__button" onClick={nextStep}>
            {t('next')}
          </Button>
          {prevStep && !editCheckoutState && (
            <Button type="secondary" className="checkouts-list__button" onClick={prevStep}>
              {t('backButton')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
