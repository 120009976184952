import React, { useState, useRef } from 'react';
// Components
import emptyCpayImage from '../../../img/cpay-svg/empty.svg';
import emptyNFGImage from '../../../img/nfgpay-svg/empty-nfg.svg';
import emptyFinvaroImage from '../../../img/finvaro/empty-finvaro.svg';
import emptyClarniumImage from '../../../img/clarnium/empty-clarnium.svg';
import noCheckoutImgSVG from '../../../img/default-svg/no-checkout-image.svg';
import moreSVG from '../../../img/default-svg/more.svg';
import transactionsSVG from '../../../img/default-svg/checkoutTransactions.svg';
import editSVG from '../../../img/default-svg/edit.svg';
import deleteSVG from '../../../img/default-svg/trash.svg';
import copySVG from '../../../img/default-svg/copyCheckout.svg';
import duplicateSVG from '../../../img/default-svg/duplicate.svg';
import Button from '../../Button';
import { Menu, Dropdown, Modal, Popover } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Utils
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Style
import './style.scss';
//icons
import donationIcon from '../../../img/checkout-types/donation.svg';
import p2pIcon from '../../../img/checkout-types/p2p.svg';
import sellIcon from '../../../img/checkout-types/sell.svg';

import { identity } from '../../../utils/getIdentity';

const checkoutsNoDataSVG = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage,
  finvaro: emptyFinvaroImage,
  clarnium: emptyClarniumImage
}


const { confirm } = Modal;

function showPromiseConfirm(t, name, type, action, merchantId, checkoutId, page) {
  confirm({
    title: `${t('checkouts.deleteTitle')} ${t(type.toLowerCase())}?`,
    icon: <ExclamationCircleOutlined />,
    content: name,
    onOk() {
      return action({ merchantId, checkoutId }, page);
    },
    onCancel() {},
    okText: t('okButton'),
    cancelText: t('cancelButton'),
  });
}

const iconObj = {
  donation: donationIcon,
  sale: sellIcon,
  saletoken: p2pIcon,
  cart: sellIcon,
};

const CheckoutsList = ({
  data,
  page,
  fetching,
  setIsVisible,
  deleteCheckout,
  merchantId,
  openDetailedHandler,
  openEditHandler,
  openDuplicateHandler,
}) => {
  const [showAddressCopiedPopover, setShowAddressCopiedPopover] = useState(false);
  const { t } = useTranslation();
  const addressCopyPopoverTimer = useRef(null);

  const location = window.location.href
  const checkoutLinkCPay = location.includes('demo') || location.includes('localhost') ? 'https://checkouts-demo.cpay.world/' : 'https://checkouts.cpay.world/'
  const checkoutLinkNFG = 'https://checkouts.nfg-crypto.io/';
  const checkoutLinkFinvaro = 'https://checkouts.finvaro.com/';
  const checkoutLinkClarnium = 'https://checkouts.clarnium.io/';

  const checkoutLink = {
   cpay: checkoutLinkCPay,
   nfg: checkoutLinkNFG,
   finvaro: checkoutLinkFinvaro,
   clarnium: checkoutLinkClarnium
  }

  // const checkoutSaleLink = {
  //   cpay: 'sell-token-checkout',
  //   nfg: 'sale-token-checkout'
  //  }

  const addressCopyHandler = (identifier, type) => {
    navigator.clipboard.writeText(`${checkoutLink[identity]}${type === 'saletoken' ? 'sell-token-checkout' : 'checkout'}/${identifier}`);
    setShowAddressCopiedPopover(true);
    addressCopyPopoverTimer.current = setTimeout(() => setShowAddressCopiedPopover(false), 1000);
  };

  return (
    <div className="checkouts-list">
      {fetching ? (
        <div>{t('swap.loading')}...</div>
      ) : data && data.length !== 0 ? (
        data.map(el => (
          <div key={el._id} className="checkouts-list__checkout">
            <div className="checkouts-list__checkout-image" onClick={() => openDetailedHandler(el)}>
              <img src={el.image ? `${process.env.REACT_APP_API_URL}${el.image}` : noCheckoutImgSVG} alt="" />
            </div>
            <div className="checkouts-list__checkout-info" onClick={() => openDetailedHandler(el)}>
              <div className="checkouts-list__checkout-info-name">
                {el.organizationName || el.productName || el.cartName}
              </div>
              <div className="checkouts-list__checkout-info-description" onClick={() => openDetailedHandler(el)}>
                {el.description}
              </div>
            </div>
            <div className={`checkouts-list__checkout-type checkouts-list__checkout-type-${identity}`} onClick={() => openDetailedHandler(el)}>
              <div>
                <img
                  src={iconObj[el.type.toLowerCase()]}
                  alt={el.type}
                  className={`checkouts-list__checkout-type_icon checkouts-list__checkout-type-${identity}_icon`}
                />
                {t(el.type.toLowerCase())}
              </div>

              {el.price && el.fiatCurrency && (
                <div className="checkouts-list__checkout-price" onClick={() => openDetailedHandler(el)}>
                  <span className={el.price.length >= 5 ? 'long-price' : ''}>{el.price}</span>{' '}
                  <span>{el.fiatCurrency}</span>
                </div>
              )}
            </div>
            <Dropdown
              placement="bottomLeft"
              overlay={
                <Menu>
                  <Menu.Item key="0">
                    <Link to={`/?checkoutId=${el.identifier}`} className="checkouts-list__checkout-drop-item">
                      <div className="checkouts-list__checkout-drop-item">
                        <img src={transactionsSVG} alt="" />
                        {t('transactions')}
                      </div>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Popover
                      title=""
                      content={t('copied')}
                      trigger="click"
                      visible={showAddressCopiedPopover}
                      className="detailed-checkout__popover"
                    >
                      <div
                        className="checkouts-list__checkout-drop-item"
                        onClick={() => addressCopyHandler(el.identifier, el.type.toLowerCase())}
                      >
                        <img src={copySVG} alt="" />
                        {t('copyLink')}
                      </div>
                    </Popover>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <div className="checkouts-list__checkout-drop-item" onClick={() => openDuplicateHandler(el)}>
                      <img src={duplicateSVG} alt="" />
                      {t('duplicate')}
                    </div>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <div className="checkouts-list__checkout-drop-item" onClick={() => openEditHandler(el)}>
                      <img src={editSVG} alt="" />
                      {t('edit')}
                    </div>
                  </Menu.Item>
                  <Menu.Item key="5">
                    <div
                      className="checkouts-list__checkout-drop-item"
                      onClick={() => {
                        const newPage = page < 2 ? 1 : data && data.length - 1 > 0 ? page : page - 1;
                        showPromiseConfirm(
                          t,
                          el.organizationName,
                          el.type,
                          deleteCheckout,
                          merchantId,
                          el._id,
                          newPage
                        );
                      }}
                    >
                      <img src={deleteSVG} alt="" /> {t('delete')}
                    </div>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <div className="checkouts-list__checkout-actions">
                <img src={moreSVG} alt="" />
              </div>
            </Dropdown>
          </div>
        ))
      ) : (
        <div className="checkouts-list__empty">
          <div className="checkouts-list__empty-text">{t('checkouts.noCheckouts')}</div>
          <img src={checkoutsNoDataSVG[identity]} alt="" />
          <Button type="primary" className="checkouts-list__button" onClick={() => setIsVisible(true)}>
            {t('checkouts.createCheckout')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CheckoutsList;
