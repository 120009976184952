import { adminApi } from '../../../service/admin-api';
import { notificationsSuccessShow, notificationsErrorShow } from '../notifications';

// GET SYSTEM FEE WALLETS LIST

export const GET_ADMIN_FEE_WALLETS_REQUEST = 'GET_ADMIN_FEE_WALLETS_REQUEST';
export const GET_ADMIN_FEE_WALLETS_SUCCESS = 'GET_ADMIN_FEE_WALLETS_SUCCESS';
export const GET_ADMIN_FEE_WALLETS_MORE_REQUEST = 'GET_ADMIN_FEE_WALLETS_MORE_REQUEST';
export const GET_ADMIN_FEE_WALLETS_MORE_SUCCESS = 'GET_ADMIN_FEE_WALLETS_MORE_SUCCESS';

export const GET_ADMIN_FEE_WALLETS_BALANCE_REQUEST = 'GET_ADMIN_FEE_WALLETS_BALANCE_REQUEST';
export const GET_ADMIN_FEE_WALLETS_BALANCE_SUCCESS = 'GET_ADMIN_FEE_WALLETS_BALANCE_SUCCESS';
export const GET_ADMIN_FEE_WALLETS_BALANCE_FAIL = 'GET_ADMIN_FEE_WALLETS_BALANCE_FAIL';

export const getAdminFeeWalletsRequest = () => ({
  type: GET_ADMIN_FEE_WALLETS_REQUEST,
});

export const getAdminFeeWalletsSuccess = payload => ({
  type: GET_ADMIN_FEE_WALLETS_SUCCESS,
  payload,
});

export const getAdminFeeWalletsMoreRequest = () => ({
  type: GET_ADMIN_FEE_WALLETS_MORE_REQUEST,
});

export const getAdminFeeWalletsMoreSuccess = payload => ({
  type: GET_ADMIN_FEE_WALLETS_MORE_SUCCESS,
  payload,
});

export const getAdminFeeWallets =
  (body = {}) =>
  async dispatch => {
    dispatch(getAdminFeeWalletsRequest());

    try {
      const response = await adminApi.getAdminFeeWallets({ ...body });
      dispatch(getAdminFeeWalletsSuccess(response.data));
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };

export const getMoreAdminFeeWallets =
  (body = {}) =>
  async dispatch => {
    dispatch(getAdminFeeWalletsMoreRequest());

    try {
      const response = await adminApi.getAdminFeeWallets({ ...body, limit: 5 });
      dispatch(getAdminFeeWalletsMoreSuccess(response.data));
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };

// CREATE NEW SYSTEM FEE WALLET

export const CREATE_ADMIN_FEE_WALLET_REQUEST = 'CREATE_ADMIN_FEE_WALLET_REQUEST';
export const CREATE_ADMIN_FEE_WALLET_SUCCESS = 'CREATE_ADMIN_FEE_WALLET_SUCCESS';

export const createAdminFeeWalletRequest = () => ({
  type: CREATE_ADMIN_FEE_WALLET_REQUEST,
});

export const createAdminFeeWalletSuccess = payload => ({
  type: CREATE_ADMIN_FEE_WALLET_SUCCESS,
});

export const createAdminFeeWallets =
  (currencyId, body = {}) =>
  async dispatch => {
    dispatch(createAdminFeeWalletRequest());

    try {
      const response = await adminApi.createAdminFeeWallets(currencyId, body);
      dispatch(createAdminFeeWalletSuccess(response.data));
      dispatch(notificationsSuccessShow({}));
      return true;
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };

// GET_ADMIN_FEE_WALLETS_BALANCE

export const getAdminFeeWalletsBalanceRequest = () => ({
  type: GET_ADMIN_FEE_WALLETS_BALANCE_REQUEST,
});

export const getAdminFeeWalletsBalanceSuccess = payload => ({
  type: GET_ADMIN_FEE_WALLETS_BALANCE_SUCCESS,
  payload,
});

export const getAdminFeeWalletsBalanceFail = payload => ({
  type: GET_ADMIN_FEE_WALLETS_BALANCE_FAIL,
  payload,
});

export const getAdminFeeWalletsBalance = data => async dispatch => {
  dispatch(getAdminFeeWalletsBalanceRequest());
  try {
    const response = await adminApi.getAdminFeeWalletsBalance(data);
    dispatch(getAdminFeeWalletsBalanceSuccess(response));
  } catch (error) {
    dispatch(getAdminFeeWalletsBalanceFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};
