import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Input } from 'antd';
import Button from '../Button';
import { changePassword } from '../../redux/actions/user';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../utils/resizeHook';
import { identity } from '../../utils/getIdentity';

const ChangePasswordForm = ({ passwordFetching, changePassword }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();
  const [form] = Form.useForm();

  const handlePasswordFormSubmit = () => {
    const formData = {
      currentPassword: currentPassword.trim(),
      password: newPassword.trim(),
      repeatPassword: confirmNewPassword.trim(),
    };

    changePassword(formData).then(response => {
      if (response) {
        setNewPassword('');
        setConfirmNewPassword('');
        setCurrentPassword('');
        form.resetFields();
      }
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        remember: true,
      }}
      className="form"
      onFinish={handlePasswordFormSubmit}
    >
      <span className={`form__title form__title-${identity}`}>{t('password')}</span>
      <span className="form__subtitle">{t('profile.passwordUsedToAccessToAccount')}</span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item
            label={t('currentPassword')}
            name="profileCurrentPassword"
            className="form__item"
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input.Password
              className="form__input"
              placeholder={t('currentPassword')}
              onChange={e => setCurrentPassword(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item
            label={t('newPassword')}
            name="profileNewPassword"
            className="form__item"
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input.Password
              className="form__input"
              placeholder={t('newPassword')}
              onChange={e => setNewPassword(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item
            label={t('confirmPassword')}
            name="profileConfirmPassword"
            className="form__item"
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input.Password
              className="form__input"
              placeholder={t('confirmPassword')}
              onChange={e => setConfirmNewPassword(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 4 : 24}>
          <Button type="primary" className="form__button" disabled={passwordFetching} loading={passwordFetching}>
            {t('submit')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = state => ({
  userDataFetching: state.user.fetching,
  passwordFetching: state.user.passwordFetching,
});

const mapDispatchToProps = dispatch => ({
  changePassword: changePassword(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
