import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ReactComponent as DropdownIcon } from '../../../../img/swap-svg/swap-select-arrow.svg';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import CheckboxItem from './WalletCheckboxItem';
import { identity } from '../../../../utils/getIdentity';


const WalletSelect = ({ nftWallets, selectedWallets, setSelectedWallets }) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div className={styles['wallets-dropdown']}>
          {selectedWallets.length ? (
            <div>
              {t('selected')}:{' '}
              <span className={styles[`wallets-dropdown__counter-${identity}`]}>
                {selectedWallets.length} {selectedWallets.length > 1 ? t('wallets.wallets') : t('wallets.wallet')}
              </span>
            </div>
          ) : (
            t('wallets.allWallets')
          )}
          <DropdownIcon
            className={cn(styles['wallets-dropdown__icon'], {
              [styles['wallets-dropdown__icon_selected']]: selectedWallets.length,
            })}
          />
        </div>
      </DropdownMenu.Trigger>

    <DropdownMenu.Portal>
         <DropdownMenu.Content className={styles['DropdownMenuContent']} sideOffset={5}>
        {!nftWallets.length ? ( <div className="currencies-dropdown__empty">{t('nodata')}</div>) :
          (nftWallets.map(wallet => (
              <CheckboxItem
                wallet={wallet}
                selectedWallets={selectedWallets}
                setSelectedWallets={setSelectedWallets}
                key={wallet._id}
              />
           )))
        }
         <DropdownMenu.Arrow className={styles['DropdownMenuArrow']} />
       </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}


export default WalletSelect;
