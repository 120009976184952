import React, { useState, useEffect } from 'react';
import { Switch, Form } from 'antd';
import './DownloadAutosign.scss';
import UploadAutosignModal from '../../Withdraw/UploadAutosignModal';
import { uploadNewAutosign, disableNewAutosign, resetAutosignStep } from '../../../redux/actions/withdraw';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { identity } from "../../../utils/getIdentity";

const DownloadAutosign = ({
  uploadNewAutosign,
  autosignStep,
  merchantId,
  selectedWallet,
  statusAutosign,
  disableNewAutosign,
  onCancel,
  downloadAutosign,
  updateList,
  checkStatusPassword
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const uploadAutosign = data => {
    uploadNewAutosign(merchantId, selectedWallet?._id, data);
  };

  useEffect(() => {
    if (autosignStep === 4) {
      onCancel();
      updateList(password);
      checkStatusPassword();
    }
  }, [autosignStep])

  const disableAutosign = data => {
    setPassword(data?.password)
    disableNewAutosign(merchantId, selectedWallet?._id, data);

    if (autosignStep === 4) {
      downloadAutosign(merchantId, selectedWallet?._id, data);
    }
  };

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
    dispatch(resetAutosignStep());
  };

  return (
    <div>
      <div className={`autosigns autosigns-${identity}`}>
        <div className="autosigns__title">{`${statusAutosign ? t('merchants.security.disable') : t('merchants.security.enable')} ${t('merchants.security.autoSignature')}`}</div>
        <Form.Item valuePropName="checked">
          <Switch checked={isChecked} onChange={handleSwitchChange} />
        </Form.Item>
      </div>
      {isChecked && (
        <div>
          <UploadAutosignModal
            onCancel={onCancel}
            step={autosignStep}
            uploadAutosign={uploadAutosign}
            disableAutosign={disableAutosign}
            statusAutosign={statusAutosign}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  autosignStep: state.withdraw.autosignStep,
});

const mapDispatchToProps = dispatch => ({
  uploadNewAutosign: uploadNewAutosign(dispatch),
  disableNewAutosign: disableNewAutosign(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAutosign);
