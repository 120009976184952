import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';
import { AppConfig } from '../../config';

const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

const Button = ({ className, children, loading, ...props }) => {
  return (
    <button className={`button button-${AppConfig.identity} ${className} ${loading ? `button_loading button_loading-${AppConfig.identity}` : ''}`} {...props}>
      <Spin className="button_spin" indicator={antIcon} />
      {React.Children.toArray(children).map(child => child)}
    </button>
  );
};

export default Button;
