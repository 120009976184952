import React from "react";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const EmptyTransactions = () => {
  const { t } = useTranslation();

  return (
    <div className="nodata">
      <Empty description={t("nodata")} />
    </div>
  );
};

export default EmptyTransactions;
