import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import TokenIcon from '../../../TokenIcon';
import { ReactComponent as CheckIcon } from '../../../../img/default-svg/wallet-select-indicator.svg';
import { truncate } from '../../../../utils/truncateFunction';
import styles from './style.module.scss';

const CheckboxItem = ({ wallet, selectedWallets, setSelectedWallets }) => {
    const handleChecked = isChecked => {
      const newValue = [...selectedWallets];

      isChecked ? newValue.push(wallet._id) : newValue.splice(newValue.indexOf(wallet._id), 1);

      setSelectedWallets(newValue);
    };

    return (
      <DropdownMenu.CheckboxItem
        key={wallet._id}
        className={styles['DropdownMenuCheckboxItem']}
        checked={selectedWallets.includes(wallet._id)}
        onCheckedChange={handleChecked}
        onSelect={e => e.preventDefault()}
      >
        <div className={styles['WalletSelectItem']}>
          <div className={styles['WalletSelectItem__name']}>
            <TokenIcon tokenName={wallet.currency} /> {wallet.currency} {' '}
            {wallet.currency === 'ETH' && wallet.currencyType === 'currency' && wallet.nodeType === 'optimism' ?  'OP' : null}
          </div>
          <div className={styles['WalletSelectItem__address']}>{truncate(wallet.address, 33, '...')}</div>
        </div>
        <DropdownMenu.ItemIndicator className={styles['DropdownMenuItemIndicator']}>
          <CheckIcon />
        </DropdownMenu.ItemIndicator>
      </DropdownMenu.CheckboxItem>
    );
  };

  export default CheckboxItem