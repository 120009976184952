import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MultisendRecipients from './MultisendRecipients';
import MultisendSummary from './MultisendSummary';
import { Modal, Spin, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Button from '../../../Button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '../../../../img/default-svg/success.svg';
import './style.scss';
import { identity } from '../../../../utils/getIdentity';
import ResendPhone2FA from '../../../../ResendPhone2FA';
import ResendEmail2FA from '../../../ResendEmail2FA';
import uploadFile from '../../../../img/cpay-svg/upload.svg';
import UploadAutosignWrapper from '../../UploadAutosignWrapper';

const MultisendConfirm = ({
  currency,
  setText,
  data,
  setData,
  multisendEstimate,
  wallet,
  setShowConfirm,
  multisendWithdraw,
  merchantId,
  handleEstimate,
  multisendFetching,
  isMainCurrency,
  uploadNewAutosign,
  autosignStep,
  autosignStatus,
  checkAutosignStatus,
}) => {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [isAppTwoFa, setIsAppTwoFa] = useState(false);
  const [isEmailTwoFa, setIsEmailTwoFa] = useState(false);
  const [isPhoneTwoFa, setIsPhoneTwoFa] = useState(false);
  const [appTwoFaRequired, setAppTwoFaRequired] = useState(false);
  const [appTwoFa, setAppTwoFa] = useState('');
  const [emailTwoFa, setEmailTwoFa] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [emailTwoFaRequired, setEmailTwoFaRequired] = useState(false);
  const [phoneTwoFaRequired, setPhoneTwoFaRequired] = useState(false);
  const [modalSuccessStatus, setModalSuccessStatus] = useState(false);
  const [isOpenAutosignModal, setIsOpenAutosignModal] = useState(false);
  const [isShowAutosignModal, setIsShowAutosignModal] = useState(false);
  const [password, setPassword] = useState('');
  const [sign, setSign] = useState(''); 

  const { t } = useTranslation();

  const handleSubmit = async () => {
    setShowStatusModal(true);

    try {
      const response = await multisendWithdraw(
        merchantId,
        wallet._id,
        multisendEstimate.estimationId,
        password || undefined,
        sign || undefined,
        appTwoFa || undefined,
        emailTwoFa || undefined,
        verifyCode || undefined
      );

      if (response && response.data && response.data.twoFactorToken) {
        setIsAppTwoFa(true);
      }

      if (response && response.data && response.data.emailSent) {
        setIsEmailTwoFa(true);
      }

      if (response && response.data && response.data.codeSent) {
        setIsPhoneTwoFa(true);
      }

      if (response && response.status === 'success') {
        resetTwoFa();
        setModalSuccessStatus(true);
      }
    } catch (error) {
      console.error(error)
    }
  };

  const uploadAutosign = data => {
    const { password, sign } = data;
    setPassword(password || undefined);
    setSign(sign || undefined);

    setIsOpenAutosignModal(false)
  };

  const resetTwoFa = () => {
    setIsAppTwoFa(false);
    setIsEmailTwoFa(false);
    setIsPhoneTwoFa(false);
    setAppTwoFa('');
    setEmailTwoFa('');
    setVerifyCode('');
  };
  useEffect(() => {
    if (wallet?._id) {
      checkAutosignStatus(merchantId, wallet._id);
    }
  }, [merchantId, wallet]);

  useEffect(() => {
    setIsShowAutosignModal(autosignStatus);
  }, [autosignStatus]);
  const twoFaFields = (
    <>
      {isPhoneTwoFa && !isAppTwoFa && !isEmailTwoFa && (
        <>
          <div className={`multisend-confirm__twoFa ${phoneTwoFaRequired && 'multisend-confirm__twoFa_error'}`}>
            <span>{t('auth.keyFromPhone')}</span>
            <Input type="text" onChange={e => setVerifyCode(e.target.value)} />
            {phoneTwoFaRequired && (
              <span className="multisend-confirm__twoFa_error-message">{t('validation.phoneTwoFaRequired')}</span>
            )}
            <div className="multisend-confirm__twoFa-resend">
              <ResendPhone2FA />
            </div>
            <Button
              onClick={() => {
                if (!verifyCode) {
                  setPhoneTwoFaRequired(true);
                } else {
                  setPhoneTwoFaRequired(false);
                  handleSubmit();
                }
              }}
            >
              {t('multisendWithdraw.confirm')}
            </Button>
          </div>
        </>
      )}
      {isAppTwoFa && (
        <div
          className={`multisend-confirm__twoFa multisend-confirm__twoFa-${identity} ${
            appTwoFaRequired && 'multisend-confirm__twoFa_error'
          }`}
        >
          <span>{t('auth.keyFromGA')}</span>
          <Input type="text" onChange={e => setAppTwoFa(e.target.value)} />
          {appTwoFaRequired && (
            <span className="multisend-confirm__twoFa_error-message">{t('validation.twoFaRequired')}</span>
          )}
          <Button
            onClick={() => {
              if (!appTwoFa) {
                setAppTwoFaRequired(true);
              } else {
                setAppTwoFaRequired(false);
                handleSubmit();
              }
            }}
          >
            {t('multisendWithdraw.confirm')}
          </Button>
        </div>
      )}

      {isEmailTwoFa && !isAppTwoFa && (
        <div className={`multisend-confirm__twoFa ${emailTwoFaRequired && 'multisend-confirm__twoFa_error'}`}>
          <span>{t('auth.keyFromEmail')}</span>
          <Input type="text" onChange={e => setEmailTwoFa(e.target.value)} />
          {emailTwoFaRequired && (
            <span className="multisend-confirm__twoFa_error-message">{t('validation.emailTwoFaRequired')}</span>
          )}
          <div className="multisend-confirm__twoFa-resend">
            <ResendEmail2FA />
          </div>
          <Button
            onClick={() => {
              if (!emailTwoFa) {
                setEmailTwoFaRequired(true);
              } else {
                setEmailTwoFaRequired(false);
                handleSubmit();
              }
            }}
          >
            {t('multisendWithdraw.confirm')}
          </Button>
        </div>
      )}
    </>
  );

  return (
    <>
      <Modal
        centered={true}
        wrapClassName="multisend-confirm__modal"
        width={400}
        visible={showStatusModal}
        onCancel={() => {
          setShowStatusModal(false);
          setIsAppTwoFa(false);
          setIsEmailTwoFa(false);
          setIsPhoneTwoFa(false);
          setAppTwoFa('');
          setEmailTwoFa('');
          setVerifyCode('');
          setModalSuccessStatus(false);
        }}
        closable={modalSuccessStatus ? false : true}
        maskClosable={modalSuccessStatus ? false : true}
      >
        <div className="multisend-confirm__modal-wrap">
          {multisendFetching ? (
            <>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
              <span className="multisend-confirm__modal-text">{t('multisendWithdraw.sending')}</span>
            </>
          ) : isPhoneTwoFa || isAppTwoFa || isEmailTwoFa ? (
            twoFaFields
          ) : modalSuccessStatus ? (
            <>
              <SuccessIcon />
              <span className="multisend-confirm__modal-text multisend-confirm__modal-text-success">
                {t('multisendWithdraw.success')}
              </span>
              <span className="multisend-confirm__modal-subtext">
                {t('multisendWithdraw.theTransactionHasBeenSuccessfullyProcessed')}
              </span>
              <div className="multisend-confirm__modal-buttons">
                <Link to="/">
                  <Button>{t('multisendWithdraw.goToTransactions')}</Button>
                </Link>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </Modal>
      <div className="multisend-confirm">
        {!isShowAutosignModal && (
          <div className="withdraw__autosign" onClick={() => setIsOpenAutosignModal(true)}>
            <img src={uploadFile} alt="upload" />
            <span>{!sign ? t('withdraw.uploadAutosign') : `${sign?.slice(0, 15)}...`}</span>
          </div>
        )}
        <MultisendRecipients
          currency={currency}
          setText={setText}
          data={data}
          setData={setData}
          handleEstimate={handleEstimate}
          setShowConfirm={setShowConfirm}
        />
        <MultisendSummary
          currency={currency}
          multisendEstimate={multisendEstimate}
          wallet={wallet}
          isMainCurrency={isMainCurrency}
        />
        <div className="multisend-confirm__buttons">
          <Button
            type="secondary"
            className="multisend-confirm__button multisend-confirm__button"
            onClick={() => setShowConfirm(false)}
          >
            {t('back')}
          </Button>
          <Button className="multisend-confirm__button" onClick={() => handleSubmit(data)}>
            {t('multisendWithdraw.confirm')}
          </Button>
        </div>
      </div>
      <UploadAutosignWrapper
        step={autosignStep}
        uploadAutosign={uploadAutosign}
        onCancel={() => setIsOpenAutosignModal(false)}
        isOpen={isOpenAutosignModal}
      />
    </>
  );
};

export default MultisendConfirm;
