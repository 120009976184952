import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip, Popover } from 'antd';
import TEXT from '../Text';
import moment from 'moment';
import cn from 'classnames';
import { Dropdown, Menu } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import emptyCpayImage from '../../../img/cpay-svg/empty.svg';
import emptyNFGImage from '../../../img/nfgpay-svg/empty-nfg.svg';
import emptyFinvaroImage from '../../../img/finvaro/empty-finvaro.svg';
import emptyClarniumImage from '../../../img/clarnium/empty-clarnium.svg';
import { ReactComponent as SortingArrow } from '../../../img/adminPanel/sorting-arrow.svg';
import { ReactComponent as SelectArrowIcon } from '../../../img/swap-svg/swap-select-arrow.svg';

import { identity } from '../../../utils/getIdentity';

import './style.scss';

const emptyImage = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage,
  finvaro: emptyFinvaroImage,
  clarnium: emptyClarniumImage
};

const UsersList = ({
  usersList,
  setSelectedUserId,
  usersFetching,
  handleClick,
  setSortRole,
  sortRole,
  handleClickSort,
  setSortBalance,
  selectedOrder,
  setSelectedOrder,
  handleClickProfile,
  setSortProfile,
  sortProfile,
}) => {
  const [sortingIsClicked, setSortingIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [sortingRoleIsClicked, setSortingRoleIsClicked] = useState(false);
  const [sortingProfileIsClicked, setSortingProfileIsClicked] = useState(false);

  const { t } = useTranslation();

  const timer = useRef(null);

  const handleChange = e => {
    if (selectedOrder) {
      return;
    }
    setSortingIsClicked(prev => !prev);
  };

  const handleChangeSort = e => {
    if (sortRole) {
      return;
    }
    setSortingRoleIsClicked(prev => !prev);
  };
  const handleChangeProfile = e => {
    if (sortProfile || (!sortProfile && sortProfile !== undefined)) {
      return;
    }
    setSortingProfileIsClicked(prev => !prev);
  };

  useEffect(() => {
    return sortRole ? setSortingRoleIsClicked(true) : setSortingRoleIsClicked(false);
  }, [sortRole]);

  useEffect(() => {
    return selectedOrder ? setSortingIsClicked(true) : setSortingIsClicked(false);
  }, [selectedOrder]);

  useEffect(() => {
    return sortProfile !== undefined ? setSortingProfileIsClicked(true) : setSortingProfileIsClicked(false);
  }, [sortProfile]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      timer.current = null;
    };
  }, []);

  useEffect(() => {
    showPopover && setShowTooltip(false);
  }, [showPopover]);

  const copyHandler = (email, phone, metamaskAddress, e) => {
    const credential = email || phone || metamaskAddress;
    e.stopPropagation();
    navigator.clipboard.writeText(credential);
    setShowPopover(true);
    timer.current = setTimeout(() => setShowPopover(false), 500);
  };

  const mouseEnterHandler = userId => {
    setCurrentUser(userId);
    setShowTooltip(true);
    setShowPopover(false);
  };

  return (
    <div className="admin-users__list">
      <div className="admin-users__list-row admin-users__list-row_legend">
        <div className="admin-users__list-item admin-users__filter">
          <TEXT.grayBold>Profile</TEXT.grayBold>
          <div className={cn('sorting-icon-filter', { 'sorting-icon-filter-clicked': sortingProfileIsClicked })}>
            <Dropdown
              placement="bottomRight"
              trigger={['click', 'hover']}
              onVisibleChange={handleChangeProfile}
              overlay={
                <div className="admin-users__filter-list">
                  <div
                    className={cn('admin-users__filter-list-item', {
                      'admin-users__filter-list-item-active': sortProfile,
                    })}
                    onClick={() => {
                      handleClickProfile(sortProfile === true ? undefined : true);
                      setSortProfile(sortProfile === true ? undefined : true);
                    }}
                  >
                    Active
                  </div>
                  <div
                    className={cn('admin-users__filter-list-item', {
                      'admin-users__filter-list-item-active': sortProfile === false,
                    })}
                    onClick={() => {
                      handleClickProfile(sortProfile === false ? undefined : false);
                      setSortProfile(sortProfile === false ? undefined : false);
                    }}
                  >
                    Non-active
                  </div>
                </div>
              }
            >
              <div className="sorting-icon-filter__icon-wrapper">
                <SelectArrowIcon />
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="admin-users__list-item">
          <TEXT.grayBold>Email/Phone/Metamask address</TEXT.grayBold>
        </div>
        <div className="admin-users__list-item">
          <TEXT.grayBold>Date of registration</TEXT.grayBold>
        </div>
        <div className="admin-users__list-item">
          <TEXT.grayBold>Date of last login</TEXT.grayBold>
        </div>
        <div className="admin-users__list-item admin-users__filter">
          <TEXT.grayBold>Role</TEXT.grayBold>
          <div className={cn('sorting-icon-filter', { 'sorting-icon-filter-clicked': sortingRoleIsClicked })}>
            <Dropdown
              placement="bottomRight"
              trigger={['click', 'hover']}
              onVisibleChange={handleChangeSort}
              overlay={
                <div className="admin-users__filter-list">
                  <div
                    className={cn('admin-users__filter-list-item', {
                      'admin-users__filter-list-item-active': sortRole === 'corporate',
                    })}
                    onClick={() => {
                      handleClickSort(sortRole === 'corporate' ? undefined : 'corporate');
                      setSortRole(sortRole === 'corporate' ? undefined : 'corporate');
                    }}
                  >
                    Corporate
                  </div>
                  <div
                    className={cn('admin-users__filter-list-item', {
                      'admin-users__filter-list-item-active': sortRole === 'individual',
                    })}
                    onClick={() => {
                      handleClickSort(sortRole === 'individual' ? undefined : 'individual');
                      setSortRole(sortRole === 'individual' ? undefined : 'individual');
                    }}
                  >
                    Individual
                  </div>
                </div>
              }
            >
              <div className="sorting-icon-filter__icon-wrapper">
                <SelectArrowIcon />
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="admin-users__list-item">
          <TEXT.grayBold>Ref ID</TEXT.grayBold>
        </div>
        <div className="admin-users__list-item admin-users__balance">
          <TEXT.grayBold>
            Mainnet <br /> Balance
          </TEXT.grayBold>
          <div className={cn('sorting-icon', { 'sorting-icon-clicked': sortingIsClicked })}>
            <Dropdown
              placement="bottomRight"
              trigger={['click', 'hover']}
              onVisibleChange={handleChange}
              overlay={
                <Menu>
                  <Menu.Item
                    className={selectedOrder === 'DESC' ? `admin-users__balance-item-active` : null}
                    onClick={() => {
                      handleClick('DESC', selectedOrder === 'DESC' ? undefined : 'balanceUSD');
                      setSelectedOrder(selectedOrder === 'DESC' ? undefined : 'DESC');
                      setSortBalance(selectedOrder === 'DESC' ? undefined : 'balanceUSD');
                    }}
                  >
                    From highest to lowest
                  </Menu.Item>
                  <Menu.Item
                    className={selectedOrder === 'ASC' ? `admin-users__balance-item-active` : null}
                    onClick={() => {
                      handleClick(
                        selectedOrder === 'ASC' ? 'DESC' : 'ASC',
                        selectedOrder === 'ASC' ? undefined : 'balanceUSD'
                      );
                      setSelectedOrder(selectedOrder === 'ASC' ? undefined : 'ASC');
                      setSortBalance(selectedOrder === 'ASC' ? undefined : 'balanceUSD');
                    }}
                  >
                    From lowest to highest
                  </Menu.Item>
                </Menu>
              }
            >
              <SortingArrow className="icon" />
            </Dropdown>
          </div>
        </div>
      </div>

      {!usersFetching ? (
        <>
          {usersList.length ? (
            usersList.map(user => (
              <div className="admin-users__list-row" key={user.id}>
                <div className="admin-users__list-item admin-users__user-name">
                  <div
                    className={`admin-users__color-status ${
                      user.isActive ? 'admin-users__color-status_active' : 'admin-users__color-status_inactive'
                    }`}
                  ></div>
                  <TEXT.darkBold>
                    {user.name} {user.surname}
                  </TEXT.darkBold>
                </div>
                <Tooltip title={t('clickToCopy')} visible={user.id === currentUser && showTooltip} placement="top">
                  <Popover
                    title=""
                    content={t('copied')}
                    trigger="click"
                    visible={user.id === currentUser && showPopover}
                    hidden={true}
                  >
                    <div
                      className={`admin-users__list-item admin-users__email`}
                      onClick={e => copyHandler(user.email, user.phone, user.metamask?.address, e)}
                      onMouseEnter={() => mouseEnterHandler(user.id)}
                      onMouseLeave={() => setCurrentUser(null)}
                    >
                      <TEXT.darkBold>{user.email || user.phone || user?.metamask?.address}</TEXT.darkBold>
                      {user.email && (
                        <TEXT.grayBoldSmall>{user.emailConfirmed ? 'Confirmed' : 'Not confirmed'}</TEXT.grayBoldSmall>
                      )}
                    </div>
                  </Popover>
                </Tooltip>

                <div className="admin-users__list-item">
                  <TEXT.darkBold>{moment(user.createdAt).format('MMMM D, YYYY, HH:mm')}</TEXT.darkBold>
                </div>
                <div className="admin-users__list-item">
                  <TEXT.darkBold>
                    {user.lastLogin ? moment(user.lastLogin).format('MMMM D, YYYY, HH:mm') : '-'}
                  </TEXT.darkBold>
                </div>
                <div className="admin-users__list-item">
                  <TEXT.darkBold>{user.role || '-'}</TEXT.darkBold>
                </div>
                <div className="admin-users__list-item">
                  <TEXT.darkBold>{user.refId || '-'}</TEXT.darkBold>
                </div>
                <div className="admin-users__list-item">
                  <TEXT.darkBold>{user.balanceUSD.toFixed(2) || 0}$</TEXT.darkBold>
                </div>
                <div className="admin-users__list-item">
                  <Dropdown
                    placement="bottomRight"
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <div onClick={() => setSelectedUserId(user.id)}>Profile</div>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to={`/admin/user-edit/${user.id}`}>Edit</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to={`/admin/user-merchants/${user.id}`}>Merchants</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to={`/admin/user-transactions/${user.id}`}>Transactions</Link>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <span className="admin-users__actions">...</span>
                  </Dropdown>
                </div>
              </div>
            ))
          ) : (
            <div className="admin-users__empty">
              <span className="admin-users__empty-title">Users will show up here.</span>
              <img src={emptyImage[identity]} className="admin-users__empty-image" alt="empty" />
            </div>
          )}
        </>
      ) : (
        <div className="admin-users__loader">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  );
};

export default UsersList;
