import fetchData from '../fetch';

class TwoFactorApi {
  async getTwoFactor() {
    const response = await fetchData('user/two-factor');
    return response;
  }

  async confirmTwoFactor(twoFactorToken) {
    const response = await fetchData('user/confirm-two-factor', {
      body: { twoFactorToken },
    });
    return response;
  }

  async disableTwoFactor(twoFactorToken) {
    const response = await fetchData('user/delete-two-factor', {
      method: 'DELETE',
      body: { twoFactorToken },
    });
    return response;
  }

  async activateEmailTwoFactor() {
    const response = await fetchData('user/activate-email-2fa', {
      method: 'POST',
    });
    return response;
  }

  async deactivateEmailTwoFactor(code) {
    const response = await fetchData('user/deactivate-email-2fa', {
      method: 'POST',
      body: { code },
    });
    return response;
  }

  async activatePhoneTwoFactor() {
    const response = await fetchData('user/activate-phone-2fa', {
      method: 'POST',
    });
    return response;
  }

  async deactivatePhoneTwoFactor(verifyCode) {
    const response = await fetchData('user/deactivate-phone-2fa', {
      method: 'POST',
      body: verifyCode,
    });
    return response;
  }
}

export const twoFactorApi = new TwoFactorApi();
