import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TEXT from '../Text';
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const Button = ({ children, className, loading, ...rest }) => {
  return (
    <button className={`admin-button ${loading ? 'admin-button_loading' : ''} ${className}`} {...rest}>
      {loading && <Spin className="admin-button__spin" indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />}
      {children}
    </button>
  );
};

export const ButtonPrimary = ({ children, className, ...rest }) => {
  return (
    <Button type="button" className={`admin-button__primary admin-button__primary-${identity} ${className || ''}`} {...rest}>
      <TEXT.button>{children}</TEXT.button>
    </Button>
  );
};

export const ButtonSecondary = ({ children, className, ...rest }) => {
  return (
    <Button type="button" className={`admin-button__secondary ${className || ''}`} {...rest}>
      <TEXT.button>{children}</TEXT.button>
    </Button>
  );
};
