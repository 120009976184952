import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";

import "./styles.scss";

const TypesFilter = ({ setType, checkedList, setCheckedList, indeterminate, setIndeterminate}) => {

  const defValue = "replenishment,withdrawal,Error,refund,multisend,InternalTransfer,ExternalCall";

  const options = [
    { label: "Replenishment", value: "replenishment" },
    { label: "Withdrawal", value: "withdrawal" },
    { label: "Refund", value: "refund" },
    { label: "Multisend", value: "multisend" },
    { label: "Internal Transfer", value: "InternalTransfer" },
    { label: "External Call", value: "ExternalCall" },
    { label: "Error", value: "Error" },
  ];

  const optValue = options.flatMap((opts) => opts.value);

  // const [checkDefault, setCheckDefault] = useState(true)
  const { t } = useTranslation();

  const onChange = (list) => {
    // setIndeterminate(!!list.length && list.length < optValue.length);
    if (!!list.length && list.length === optValue.length) {
      setCheckedList([]);
      setIndeterminate(false);
    } else {
      setCheckedList(list);
      setIndeterminate(true);
    }
    const valueToString = list.toString();
    setType(valueToString);
    localStorage.setItem('type_tr', valueToString)

  };

  const onCheckAllChange = (e) => {
    console.log(e.target.checked);
    // setType(e.target.checked ? defValue : "");
    setType(defValue);
    localStorage.setItem('type_tr',defValue)
    if (!!checkedList.length && checkedList.length < optValue.length) {
      setIndeterminate(false);
      setCheckedList([]);
    }
  };

  return (
    <div className="transaction-filters__types">
      <div className="transaction-filters__types-title">
        <p>{t("transactionHistory.type")}</p>
      </div>
      <div className="transaction-filters__types-box">
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={!indeterminate}
          //  defaultChecked={!indeterminate}
        >
          All
        </Checkbox>

        <Checkbox.Group options={options} value={checkedList} onChange={onChange} />
      </div>
    </div>
  );
};

export default TypesFilter;