import React from 'react';
import { connect } from 'react-redux';
import { Field, Form as ReduxForm, reduxForm } from 'redux-form';
import { Row, Col, Pagination, Select } from 'antd';
import useWindowSize from '../../utils/resizeHook';
import { useTranslation } from 'react-i18next';
import { identity } from '../../utils/getIdentity';

const RenderSelect = ({ input, placeholder, options = [], className }) => {
  return (
    <Select {...input} className={className} placeholder={placeholder}>
      {options.map((opt, index) => (
        <Select.Option key={index} value={opt.value}>
          {opt.title}
        </Select.Option>
      ))}
    </Select>
  );
};

const ClientsWalletsPagination = ({ page, pages, walletsFilters, loadMoreWallets, submitFilters }) => {
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();

  return (
    <Row className="pagination">
      {currentWidth >= 1024 && (
        <Col span="4">
          <div className="pagination__info">
            <span>{t('pageCurrentOfTotal', { page, pages })}</span>
          </div>
        </Col>
      )}
      <Col span={currentWidth >= 1024 ? 16 : 24}>
        {walletsFilters.limit && (
          <Pagination
            className="pagination__pages"
            size="small"
            showSizeChanger={false}
            total={walletsFilters.limit * pages}
            current={page}
            pageSize={walletsFilters.limit}
            onChange={page => loadMoreWallets(page)}
          />
        )}
      </Col>
      {currentWidth < 1024 && (
        <Col span="12">
          <div className="pagination__info">
            <span>{t('pageCurrentOfTotal', { page, pages })}</span>
          </div>
        </Col>
      )}
      <Col span={currentWidth >= 1024 ? 4 : 12}>
        <div className="pagination__limit-wrapper">
          {t('show')}
          <ReduxForm onSubmit={submitFilters}>
            <Field
              name="limit"
              className={`pagination__limit pagination__limit-${identity}`}
              component={RenderSelect}
              placeholder={'Limit'}
              options={[
                { value: 5, title: '5' },
                { value: 10, title: '10' },
                { value: 15, title: '15' },
                { value: 20, title: '20' },
              ]}
              onFocus={e => e.preventDefault()}
              onBlur={e => e.preventDefault()}
            />
          </ReduxForm>
          {t('perPage')}
        </div>
      </Col>
    </Row>
  );
};

const FiltersFormContainer = reduxForm({ destroyOnUnmount: false })(ClientsWalletsPagination);

export default connect((state, { formName }) => ({
  form: formName,
}))(FiltersFormContainer);
