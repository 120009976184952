import React, { useState, useEffect } from 'react';
import getSwapPartnerTrustscore from '../../../utils/swapPartnerTrustscore';
import { ReactComponent as StarEmptyIcon } from '../../../img/default-svg/star-empty.svg';
import { ReactComponent as StarFullIcon } from '../../../img/default-svg/star-full.svg';
import { ReactComponent as StarHalfIcon } from '../../../img/default-svg/star-half.svg';
import { ReactComponent as LinkIcon } from '../../../img/default-svg/link.svg';
import './style.scss';

const SwapOfferTrustscore = ({ partnerName }) => {
  const [stars, setStars] = useState(null);

  useEffect(() => {
    if (partnerName) {
      const partnerData = getSwapPartnerTrustscore(partnerName);
      if (partnerData && !!partnerData.score) {
        const partnerScoreParsed = partnerData.score.toString().split('.');

        const starsArray = Array(5).fill(<StarEmptyIcon />);
        starsArray.fill(<StarFullIcon />, 0, +partnerScoreParsed[0]);
        if (partnerScoreParsed[1]) starsArray[+partnerScoreParsed[0]] = <StarHalfIcon />;

        setStars(starsArray);
      }
    }
  }, [partnerName]);

  return (
    <div className="swap-offer-trustscore">
      {stars ? stars.map(star => star) : '-'}
      {stars ? (
        <div className="swap-offer-trustscore__link">
          <a
            href={getSwapPartnerTrustscore(partnerName) && getSwapPartnerTrustscore(partnerName).link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon />
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default SwapOfferTrustscore;
