import React, { useState, useEffect, useRef } from 'react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoCircleCpay } from '../../img/cpay-svg/info-circle.svg';
import { ReactComponent as InfoCircleNfg } from '../../img/nfgpay-svg/info-circle-nfg.svg';
import { ReactComponent as InfoCircleFinvaro } from '../../img/finvaro/info-circle-finvaro.svg';
import { ReactComponent as InfoCircleClarnium } from '../../img/clarnium/info-circle-clarnium.svg';
import QRCode from 'qrcode.react';
import WalletConnectButton from '../CheckoutPayment/WalletConnectButton';
import { tokenNodeTypeObj } from '../../utils/tokenNodeTypes';
import styles from './SaleTokenPayment.module.scss';
import { ReactComponent as CopyIcon } from '../../img/default-svg/copy.svg';
import { hexToRgb, getContrastTextColor } from '../../utils/getTextColorByBg';
import { mainColor } from '../../utils/getMainColor';
import { identity } from '../../utils/getIdentity';

const infoCircle = {
  cpay: <InfoCircleCpay/>,
  nfg: <InfoCircleNfg/>,
  finvaro: <InfoCircleFinvaro />,
  clarnium: <InfoCircleClarnium />
}

const SaleTokenPayment = ({ getChargeState }) => {
  const [showAddressPopover, setShowAddressPopover] = useState(false);
  const [showAmountPopover, setShowAmountPopover] = useState(false);
  const addressPopoverTimer = useRef(null);
  const amountPopoverTimer = useRef(null);
  const { t } = useTranslation();

  const addressCopyHandler = value => {
    navigator.clipboard.writeText(value);
    setShowAddressPopover(true);
    addressPopoverTimer.current = setTimeout(() => setShowAddressPopover(false), 1000);
  };

  const amountCopyHandler = value => {
    navigator.clipboard.writeText(value);
    setShowAmountPopover(true);
    amountPopoverTimer.current = setTimeout(() => setShowAmountPopover(false), 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(addressPopoverTimer);
      clearTimeout(amountPopoverTimer);
      addressPopoverTimer.current = null;
      amountPopoverTimer.current = null;
    };
  });

  return (
    <>
      <style>{`
        .initial-wallets:hover {
          background: ${`rgb(${hexToRgb(getChargeState.data.checkout.accentColor || mainColor).join(
            ' '
          )} / 15%)`} !important
        }
        .custom-copy path {
          fill: ${getChargeState.data.checkout.accentColor} !important;
        }
        .wallet-connect__button, .wallet-connect__account {
          background: ${getChargeState.data.checkout.accentColor} !important;
          color: ${getContrastTextColor(getChargeState.data.checkout.accentColor || mainColor)} !important;
        }
    `}</style>
      <div className={styles['sale-token-payment']}>
        <div className={styles['sale-token-payment__amount']}>
          {t('amount')}
          <div className={styles['sale-token-payment__field']}>
            {getChargeState.data.expectedAmountFrom} {getChargeState.data.wallets[0].currency.name}{' '}

            {getChargeState.data.wallets[0].currency.currencyType === 'token'
              ? tokenNodeTypeObj[getChargeState.data.wallets[0].currency.nodeType]
              : null}

            {getChargeState.data.wallets[0].currency.currencyType === 'token' &&
            getChargeState.data.wallets[0].currency.nodeType === 'optimism'
              ? getChargeState.data.wallets[0].currency.name === 'OP'
                ? null
                : 'OP'
              : null}

            {getChargeState.data.wallets[0].currency.name === 'ETH' &&
            getChargeState.data.wallets[0].currency.currencyType === 'currency' &&
            getChargeState.data.wallets[0].currency.nodeType === 'optimism'
              ? 'OP'
              : null}

            {getChargeState.data.wallets[0].currency.currencyType === 'currency' &&
            getChargeState.data.wallets[0].currency.nodeType === 'arbitrum'
              ? getChargeState.data.wallets[0].currency.name === 'arbitrum'
                ? null
                : 'ARB'
              : null}

            {(getChargeState.data.wallets[0].currency.name === 'USDT' ||
              getChargeState.data.wallets[0].currency.name === 'USDC' ||
              getChargeState.data.wallets[0].currency.name === 'ETH') &&
            getChargeState.data.wallets[0].currency.currencyType === 'token' &&
            getChargeState.data.wallets[0].currency.nodeType === 'arbitrum'
              ? 'ARB'
              : null}

            {(getChargeState.data.wallets[0].currency.name === 'USDT' ||
              getChargeState.data.wallets[0].currency.name === 'USDC') &&
            getChargeState.data.wallets[0].currency.currencyType === 'token' &&
            getChargeState.data.wallets[0].currency.nodeType === 'matic'
              ? 'MATIC'
              : null}

              {getChargeState.data.wallets[0].currency.name === "USDT" &&
          getChargeState.data.wallets[0].currency.currencyType === "token" &&
          getChargeState.data.wallets[0].currency.nodeType === "avax"
            ? "AVAX"
            : null}

        {getChargeState.data.wallets[0].currency.name === "USDC" &&
          getChargeState.data.wallets[0].currency.currencyType === "token" &&
          getChargeState.data.wallets[0].currency.nodeType === "solana"
            ? "SOL"
            : null}

            {getChargeState.data.wallets[0].currency.name === "USDT" &&
                  getChargeState.data.wallets[0].currency.currencyType === "token" &&
                  getChargeState.data.wallets[0].currency.nodeType === "ftm"
                    ? "FTM"
                    : null
                }

             {(getChargeState.data.wallets[0].currency.name === "USDT" ||
                  getChargeState.data.wallets[0].currency.name === "USDC") &&
                  getChargeState.data.wallets[0].currency.currencyType === "token" &&
                  getChargeState.data.wallets[0].currency.nodeType === "moonbeam"
                    ? "GLMR"
                    : null
                }
            <div className={styles['sale-token-payment__field-copy']}>
              <Popover
                title=""
                content={t('copied')}
                trigger="click"
                visible={showAmountPopover}
                // className="detailed-checkout__popover"
              >
                <CopyIcon
                  className="custom-copy"
                  onClick={() => amountCopyHandler(getChargeState.data.expectedAmountFrom)}
                />
              </Popover>
            </div>
          </div>
        </div>
        <div className={styles['sale-token-payment__address']}>
          {t('address')}
          <div className={styles['sale-token-payment__field']}>
            {getChargeState.data.wallets[0].address}
            <div className={styles['sale-token-payment__field-copy']}>
              <Popover
                title=""
                content={t('copied')}
                trigger="click"
                visible={showAddressPopover}
                // className="detailed-checkout__popover"
              >
                <CopyIcon
                  className="custom-copy"
                  onClick={() => addressCopyHandler(getChargeState.data.wallets[0].address)}
                />
              </Popover>
            </div>
          </div>
        </div>
        <div
          className={`${styles['sale-token-payment__min-trans-info']} ${
            styles[`sale-token-payment__min-trans-info-${identity}`]
          }`}
        >
          {getChargeState.data.wallets[0].currency.minReplenish > 0 ? (
            <>
              {infoCircle[identity]} {t('checkouts.qrCode.minAmount')} {getChargeState.data.wallets[0].currency.minReplenish}{' '}
              {getChargeState.data.wallets[0].currency.name}{' '}
              {getChargeState.data.wallets[0].currency.currencyType === 'token'
                ? tokenNodeTypeObj[getChargeState.data.wallets[0].currency.nodeType]
                : null}
              {getChargeState?.data?.wallets[0]?.currency?.currencyType === 'token' &&
              getChargeState?.data?.wallets[0]?.currency?.nodeType === 'optimism'
                ? getChargeState?.data?.wallets[0]?.currency?.name === 'OP'
                  ? null
                  : 'OP'
                : null}
              {getChargeState?.data?.wallets[0]?.currency?.name === 'ETH' &&
              getChargeState?.data?.wallets[0]?.currency?.currencyType === 'currency' &&
              getChargeState?.data?.wallets[0]?.currency?.nodeType === 'optimism'
                ? 'OP'
                : null}
              {getChargeState.data.wallets[0].currency.currencyType === 'currency' &&
              getChargeState.data.wallets[0].currency.nodeType === 'arbitrum'
                ? getChargeState.data.wallets[0].currency.currency === 'arbitrum'
                  ? null
                  : 'ARB'
                : null}
              {(getChargeState.data.wallets[0].currency.name === 'USDT' ||
                getChargeState.data.wallets[0].currency.name === 'USDC' ||
                getChargeState.data.wallets[0].currency.name === 'ETH') &&
              getChargeState.data.wallets[0].currency.currencyType === 'token' &&
              getChargeState.data.wallets[0].currency.nodeType === 'arbitrum'
                ? 'ARB'
                : null}
              {(getChargeState.data.wallets[0].currency.name === 'USDT' ||
                getChargeState.data.wallets[0].currency.name === 'USDC') &&
              getChargeState.data.wallets[0].currency.currencyType === 'token' &&
              getChargeState.data.wallets[0].currency.nodeType === 'matic'
                ? 'MATIC'
                : null}

            {(getChargeState.data.wallets[0].currency.name === 'USDT' && getChargeState.data.wallets[0].currency.currencyType === 'token' && getChargeState.data.wallets[0].currency.nodeType === 'avax') ? 'AVAX' : null}

            {(getChargeState.data.wallets[0].currency.name === 'USDC' && getChargeState.data.wallets[0].currency.currencyType === 'token' && getChargeState.data.wallets[0].currency.nodeType === 'solana') ? 'SOL' : null}

            {(getChargeState.data.wallets[0].currency.name === 'USDT'  && getChargeState.data.wallets[0].currency.currencyType === 'token' && getChargeState.data.wallets[0].currency.nodeType === 'ftm') ? 'FTM' : null}

            {((getChargeState.data.wallets[0].currency.name === 'USDT' || getChargeState.data.wallets[0].currency.name === 'USDC') && getChargeState.data.wallets[0].currency.currencyType === 'token' && getChargeState.data.wallets[0].currency.nodeType === 'moonbeam') ? 'GLMR' : null}
            </>
          ) : (
            <>
              {infoCircle[identity]} {t('checkouts.qrCode.minTransAmount')}
            </>
          )}
        </div>
        <div className={styles['sale-token-payment__get']}>
          <span>{t('checkouts.saleToken.get')}:</span> {getChargeState.data.expectedAmountTo}{' '}
          {getChargeState.data?.checkout?.tradedCurrency?.name}{' '}
          {getChargeState.data?.checkout?.tradedCurrency?.currencyType === 'token'
            ? tokenNodeTypeObj[getChargeState.data?.checkout?.tradedCurrency?.nodeType]
            : null}
          {getChargeState.data?.checkout?.tradedCurrency?.currencyType === 'token' &&
          getChargeState.data?.checkout?.tradedCurrency?.nodeType === 'optimism'
            ? getChargeState.data?.checkout?.tradedCurrency?.name === 'OP'
              ? null
              : 'OP'
            : null}
          {getChargeState.data?.checkout?.tradedCurrency?.name === 'ETH' &&
          getChargeState.data?.checkout?.tradedCurrency?.currencyType === 'currency' &&
          getChargeState.data?.checkout?.tradedCurrency?.nodeType === 'optimism'
            ? 'OP'
            : null}
          {getChargeState.data?.checkout?.tradedCurrency?.currencyType === 'currency' &&
          getChargeState.data?.checkout?.tradedCurrency?.nodeType === 'arbitrum'
            ? getChargeState.data?.checkout?.tradedCurrency?.name === 'arbitrum'
              ? null
              : 'ARB'
            : null}
          {(getChargeState.data?.checkout?.tradedCurrency?.name === 'USDT' ||
            getChargeState.data?.checkout?.tradedCurrency?.name === 'USDC' ||
            getChargeState.data?.checkout?.tradedCurrency?.name === 'ETH') &&
          getChargeState.data?.checkout?.tradedCurrency?.currencyType === 'token' &&
          getChargeState.data?.checkout?.tradedCurrency?.nodeType === 'arbitrum'
            ? 'ARB'
            : null}
          {(getChargeState.data?.checkout?.tradedCurrency?.name === 'USDT' ||
            getChargeState.data?.checkout?.tradedCurrency?.name === 'USDC') &&
          getChargeState.data?.checkout?.tradedCurrency?.currencyType === 'token' &&
          getChargeState.data?.checkout?.tradedCurrency?.nodeType === 'matic'
            ? 'MATIC'
            : null}

            {(getChargeState.data?.checkout?.tradedCurrency?.name === 'USDT' && getChargeState.data?.checkout?.tradedCurrency?.currencyType === 'token' && getChargeState.data?.checkout?.tradedCurrency?.nodeType === 'avax') ? 'AVAX' : null}

          {(getChargeState.data?.checkout?.tradedCurrency?.name === 'USDC' && getChargeState.data?.checkout?.tradedCurrency?.currencyType === 'token' && getChargeState.data?.checkout?.tradedCurrency?.nodeType === 'solana') ? 'SOL' : null}

          {(getChargeState.data?.checkout?.tradedCurrency?.name === 'USDT'  && getChargeState.data?.checkout?.tradedCurrency?.currencyType === 'token' && getChargeState.data?.checkout?.tradedCurrency?.nodeType === 'ftm') ? 'FTM' : null}

          {((getChargeState.data?.checkout?.tradedCurrency?.name === 'USDT' || getChargeState.data?.checkout?.tradedCurrency?.name === 'USDC') && getChargeState.data?.checkout?.tradedCurrency?.currencyType === 'token' && getChargeState.data?.checkout?.tradedCurrency?.nodeType === 'moonbeam') ? 'GLMR' : null}
        </div>
        <div className={styles['sale-token-payment__recepient']}>
          <span>{t('checkouts.summary.recepient')}:</span> {getChargeState.data.recipient}
        </div>
        <QRCode
          className={styles['sale-token-payment__qrcode']}
          value={getChargeState.data.wallets[0].address}
          size={224}
        />

        {(getChargeState.data.wallets[0].currency.nodeType === 'eth' ||
          getChargeState.data.wallets[0].currency.nodeType === 'bsc') && (
          <WalletConnectButton
            clientWalletAddress={getChargeState.data.wallets[0].address}
            amount={getChargeState.data.expectedAmountFrom}
            currency={getChargeState.data.wallets[0].currency}
            typeNetwork={getChargeState.data.typeNetwork}
          />
        )}
      </div>
    </>
  );
};

export default SaleTokenPayment;
