import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

const SwapKYCTooltip = () => {
  const { t } = useTranslation();
  const swapInfoArray = [
    {
      name: 'low',
      title: t('swap.offerItem.kycTooltip.low'),
      info: t('swap.offerItem.kycTooltip.lowInfo'),
    },
    {
      name: 'medium',
      title: t('swap.offerItem.kycTooltip.medium'),
      info: t('swap.offerItem.kycTooltip.mediumInfo'),
    },
    {
      name: 'rare',
      title: t('swap.offerItem.kycTooltip.rare'),
      info: t('swap.offerItem.kycTooltip.rareInfo'),
    },
    {
      name: 'required',
      title: t('swap.offerItem.kycTooltip.required'),
      info: t('swap.offerItem.kycTooltip.requiredInfo'),
    },
  ];

  return (
    <div className="swapKycTooltip">
      <span className="swapKycTooltip__title">{t('swap.offerItem.kycTooltip.title')}</span>
      <div className="swapKycTooltip__list">
        {swapInfoArray.map((item, index) => (
          <div className="swapKycTooltip__list-item" key={index}>
            <div className={`swapKycTooltip__list-item_subtitle swapKycTooltip__list-item_subtitle_${item.name.toLowerCase()} `}>{item.title}</div>
            <div className="swapKycTooltip__list-item_info">{item.info}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SwapKYCTooltip;
