import React, { useState, useEffect, useMemo } from 'react';
import Button from '../Button';
import { Empty, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getNftWallets } from '../../redux/actions/nft';
import { connect } from 'react-redux';
import cn from 'classnames';
import TokenIcon from '../TokenIcon';
import NftWithdraw from '../NftWallet/NftWithdraw';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';
import { identity } from '../../utils/getIdentity';
import { sanitizeInputDisable } from '../../utils/sanitizeInput';

const HomeNftPreview = ({
  merchantId,
  typeNetwork,
  nftWallets,
  nftWalletsFetching,
  getNftWallets,
  submitSearch,
  search,
}) => {
  const { t } = useTranslation();

  const [nftItems, setNftItems] = useState([]);
  const [selectedNft, setSelectedNft] = useState(null);

  useEffect(() => {
    if (merchantId) {
      getNftWallets(merchantId, {
        typeNetwork,
        limit: 3,
        order: 'DESC',
        showNfts: true,
        onlyNft: true,
      });
    }
  }, [merchantId, typeNetwork]);

  useEffect(() => {
    setNftItems(
      nftWallets.reduce((result, wallet) => {
        result.push(
          ...wallet.nfts.erc721.map(nft => ({
            ...nft,
            walletId: wallet._id,
            network: 'erc721',
            currency: wallet.currency,
          })),
          ...wallet.nfts.erc1155.map(nft => ({
            ...nft,
            walletId: wallet._id,
            network: 'erc1155',
            currency: wallet.currency,
          }))
        );

        return result;
      }, [])
    );
  }, [nftWallets]);

  const nftData = useMemo(() => {
    const nfts = nftItems.length ? (
      [...nftItems].splice(0, 3).map((nft, index) => (
        <div key={crypto.randomUUID()} className={styles['home-nft-item']}>
          {nft.imageURL ? (
            <img className={styles['home-nft-item__image']} src={nft.imageURL} alt={nft.name} />
          ) : (
            <Empty className={styles['home-nft-item__empty']} description="" />
          )}

          <div className={styles['home-nft-item__info']}>
            <div className={styles['home-nft-item__name']}>{nft.name}</div>

            <div className={styles['home-nft-item__token-info']}>
              <div className={styles['home-nft-item__token-data']}>
                <div className={styles['home-nft-item__token']}>
                  <TokenIcon className={styles['home-nft-item__currency-icon']} tokenName={nft.currency} />
                  {nft.network}
                </div>
                <div className={styles['home-nft-item__balance']}>
                  {t('balance')}: {nft.balance}
                </div>
              </div>

              <div
                className={`${styles['home-nft-item__button']} ${styles[`home-nft-item__button-${identity}`]}`}
                onClick={() => setSelectedNft(nft)}
              >
                {t('transfer')}
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <Empty
        className={cn('', {
          [styles['home-nft__loading']]: nftWalletsFetching,
        })}
        image={nftWalletsFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : undefined}
        description={nftWalletsFetching ? '' : undefined}
      />
    );

    return nfts;
  }, [nftItems, nftWalletsFetching, t]);

  return (
    <>
      <NftWithdraw
        merchantId={merchantId}
        selectedNft={selectedNft}
        setSelectedNft={setSelectedNft}
        onSuccess={() => !sanitizeInputDisable(search) && submitSearch(search)}
      />
      <div className={styles['home-nft']}>
        <div className={styles['home-nft__head']}>
          <div>
            <div className={styles['home-nft__title']}>NFT</div>
            <span className={styles['home-nft__subtitle']}>
              {t('nft.nftSupported')} ETH, BSC, Polygon, Optimism {t('and')} Solana networks
            </span>
          </div>
          <Link to="/nft-wallet" className={styles['home-nft__link']}>
            <Button type="secondary">{t('nft.nftPage')}</Button>
          </Link>
        </div>
        <div className={styles['home-nft__list']}>{nftData}</div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  typeNetwork: state.networkFilter,
  nftWallets: state.nft.wallets.data,
  nftWalletsFetching: state.nft.wallets.fetching,
});

const mapDispatchToProps = dispatch => ({
  getNftWallets: getNftWallets(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeNftPreview);
