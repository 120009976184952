import React, { useState } from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import { Form } from 'antd';
import TEXT from '../Text';
import { ButtonPrimary, ButtonSecondary } from '../Button';
import { createAdminFeeWallets } from '../../../redux/actions/adminPanel/adminFeeWallets';
import CurrencyDropdown from '../CurrencyDropdown';
import './style.scss';

const AddWalletModal = ({
  typeNetwork,
  showAddNewWalletModal,
  updateWalletsList,
  setShowAddNewWalletModal,
  createAdminFeeWallets,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [form] = Form.useForm();

  const handleClose = () => {
    setShowAddNewWalletModal(false);
    form.resetFields();
  };

  const setCurrencies = values => {
    form.setFieldsValue({ currencyId: values });
  };

  const handleSubmit = async (currencyId, body) => {
    setIsFetching(true);
    const response = await createAdminFeeWallets(currencyId, body);
    setIsFetching(false);
    if (response) {
      handleClose();
      updateWalletsList();
    }
  };

  return (
    <Modal
      title="Create new wallet"
      visible={showAddNewWalletModal}
      width={385}
      footerComponent={
        <div className="add-user-modal__buttons-wrap">
          <ButtonSecondary onClick={handleClose}>Cancel</ButtonSecondary>
          <ButtonPrimary loading={isFetching} disabled={isFetching} onClick={form.submit}>
            Add
          </ButtonPrimary>
        </div>
      }
      onCancel={handleClose}
    >
      <div className="add-wallet-modal">
        <Form
          form={form}
          name="add-wallet-modal"
          autoComplete="off"
          initialValues={{
            currencyId: '',
            // setMain: true,
          }}
          onFinish={values => handleSubmit(values.currencyId, { setMain: true, typeNetwork })}
        >
          <div className="add-wallet-modal__currencies">
            <TEXT.label>Currency</TEXT.label>
            <Form.Item name="currencyId" valuePropName="merchantCurrencies">
              <CurrencyDropdown setFunc={setCurrencies} noTokens={true} />
            </Form.Item>
          </div>
          <button type="submit" hidden></button>
        </Form>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  typeNetwork: state.networkFilter,
});

const mapDispatchToProps = {
  createAdminFeeWallets,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWalletModal);
