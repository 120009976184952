import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Card from '../../components/Card';
import { Row, Col } from 'antd';
import TEXT from '../../components/Text';
import WalletsList from '../../components/Withdraw/WalletsList';
import WithdrawForm from '../../components/Withdraw/WithdrawForm';
import {
  getAdminFeeWallets,
  getMoreAdminFeeWallets,
  getAdminFeeWalletsBalance,
} from '../../../redux/actions/adminPanel/adminFeeWallets';
import { ButtonPrimary } from '../../components/Button';
import FeeTransactionsList from '../../components/Withdraw/FeeTransactionsList';
import { scrollContainerIntoView } from '../../helpers/scrollIntoView';
import './style.scss';
import { identity } from '../../../utils/getIdentity';
import AdminCurrencySelector from './AdminCurrencySelector';

const Withdraw = ({
  currencies,
  feeWallets,
  feeWalletsFetching,
  feeWalletsFetchingMore,
  countItem,
  page,
  getAdminFeeWallets,
  getMoreAdminFeeWallets,
  typeNetwork,
  maxAmount,
  getAdminFeeWalletsBalance,
  getAdminBalances,
  adminBalancesFetching,
}) => {
  const [from, setFrom] = useState('');
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [currency, setCurrency] = useState('');
  const [to, setTo] = useState('');
  const [amount, setAmount] = useState('');
  const [isTransactionsActive, setIsTransactionsActive] = useState(false);
  const adminWithdrawRef = useRef();

  useEffect(() => {
    !isTransactionsActive && getAdminFeeWallets({ typeNetwork, limit: 99999, positiveUsdBalance: true });
  }, [typeNetwork]);

  useEffect(() => {
    setAmount(maxAmount);
  }, [maxAmount]);

  useEffect(() => {
    scrollContainerIntoView(adminWithdrawRef);
  }, []);

  useEffect(() => {
    scrollContainerIntoView(adminWithdrawRef);
  }, [from, currency]);

  useEffect(() => {
    setAmount('');
    getAdminFeeWalletsBalance({ typeNetwork });
  }, [typeNetwork]);

  useEffect(() => {
    if (!isTransactionsActive) {
      if (from && selectedWallet) {
        getAdminFeeWallets({
          typeNetwork,
          limit: 99999,
          currencyIds:  selectedWallet?.currencyId || undefined,
          positiveUsdBalance: false,
        });
      } else {
        getAdminFeeWallets({
          typeNetwork,
          limit: 99999,
          currencyIds: currency ? currency : undefined,
          positiveUsdBalance: true,
        });
      }
    }
  }, [currency, isTransactionsActive, from]);

  return (
    <>
      <div className={!from ? `admin-fee-withdraw-wrapper` : `send-form-admin-fee-withdraw-wrapper`}>
        {!from && (
          <Col span={8}>
            <AdminCurrencySelector
              getAdminBalances={getAdminBalances}
              adminBalancesFetching={adminBalancesFetching}
              currencies={currencies}
              currency={currency}
              setCurrency={setCurrency}
              getAdminFeeWalletsBalance={getAdminFeeWalletsBalance}
            />
          </Col>
        )}

        <Col span={!from ? 16 : 24}>
          <div className="admin-fee-withdraw" ref={adminWithdrawRef}>
            <Card>
              <TEXT.pageTitle>{isTransactionsActive ? 'System fee transaction' : 'Withdrawal'}</TEXT.pageTitle>
              {!from && (
                <div className="admin-fee-withdraw__tabs-switcher">
                  <div
                    className={`admin-fee-withdraw__tabs-switcher-item
              admin-fee-withdraw__tabs-switcher-item-${identity}
              ${isTransactionsActive ? '' : 'admin-fee-withdraw__tabs-switcher-item_active'}`}
                    onClick={() => setIsTransactionsActive(false)}
                  >
                    <TEXT.gray>Wallets</TEXT.gray>
                  </div>
                  <div
                    className={`admin-fee-withdraw__tabs-switcher-item
              admin-fee-withdraw__tabs-switcher-item-${identity} ${
                      isTransactionsActive ? 'admin-fee-withdraw__tabs-switcher-item_active' : ''
                    }`}
                    onClick={() => setIsTransactionsActive(true)}
                  >
                    <TEXT.gray>Transaction list</TEXT.gray>
                  </div>
                </div>
              )}
              {isTransactionsActive ? (
                <FeeTransactionsList currency={currency} setBlanceCurrency={setCurrency} />
              ) : from ? (
                <WithdrawForm
                  currencies={currencies}
                  from={from}
                  setFrom={setFrom}
                  to={to}
                  setTo={setTo}
                  amount={amount}
                  setAmount={setAmount}
                  selectedWallet={selectedWallet}
                  setSelectedWallet={setSelectedWallet}
                  feeWallets={feeWallets}
                />
              ) : (
                <>
                  <WalletsList
                    withdrawWallets={feeWallets}
                    walletsFetching={feeWalletsFetching}
                    selectedWallet={selectedWallet}
                    setSelectedWallet={setSelectedWallet}
                    from={from}
                    setFrom={setFrom}
                  />
                  {/* {feeWallets.length && feeWallets.length < countItem ? (
                    <div className="admin-fee-withdraw__load-more">
                      <ButtonPrimary
                        loading={feeWalletsFetchingMore}
                        onClick={() => getMoreAdminFeeWallets({ page: page + 1, typeNetwork, positiveBalance: true })}
                      >
                        Load More
                      </ButtonPrimary>
                    </div>
                  ) : null} */}
                </>
              )}
            </Card>
          </div>
        </Col>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  currencies: state.currencies.data,
  feeWallets: state.adminFeeWallets.entities,
  feeWalletsFetching: state.adminFeeWallets.fetching,
  feeWalletsFetchingMore: state.adminFeeWallets.fetchingMore,
  countItem: state.adminFeeWallets.countItem,
  page: state.adminFeeWallets.page,
  typeNetwork: state.networkFilter,
  maxAmount: state.adminFeeWithdraw.maxAmount,
  getAdminBalances: state.adminFeeWallets.adminWalletsBalance.data,
  adminBalancesFetching: state.adminFeeWallets.adminWalletsBalance.fetching,
});

const mapDispatchToProps = {
  getAdminFeeWallets,
  getMoreAdminFeeWallets,
  getAdminFeeWalletsBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdraw);
