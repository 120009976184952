import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import TEXT from '../../components/Text';
import { ButtonSecondary } from '../../components/Button';
import { NumberInputSettings, PercentInput } from '../../components/FormFields';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Spin } from 'antd';
import {
  getUserMerchants,
  updateMerchantsSystemFee,
  updateMerchantsSystemFeeReplenish,
  updateMerchantsSystemSwapReplenish,
  updateMerchantsRefundFee,
  updateMerchantsMinimumWithdrawal,
} from '../../../redux/actions/adminPanel/adminUsers';
import { getAdminSettings } from '../../../redux/actions/adminPanel/adminSettings';
import { ReactComponent as SaveSVG } from '../../../img/adminPanel/save.svg';
import { scrollContainerIntoView } from '../../helpers/scrollIntoView';
import moment from 'moment';
import './style.scss';

const UserMerchants = ({
  userMerchants,
  userMerchantsFetching,
  adminSettingsData,
  systemFeeFetching,
  getUserMerchants,
  updateMerchantsSystemFee,
  getAdminSettings,
  updateMerchantsSystemFeeReplenish,
  systemFeeReplenishFetching,
  systemFeeSwapFetching,
  updateMerchantsSystemSwapReplenish,
  updateMerchantsRefundFee,
  systemRefundFetching,
  updateMerchantsMinimumWithdrawal,
  systemsMinimumWithdrawalFetching,
}) => {
  const [fetchingId, setFetchingId] = useState({});
  const { userId } = useParams();
  const userMerchantsRef = useRef();

  useEffect(() => {
    getAdminSettings();
  }, []);

  useEffect(() => {
    setFetchingId(
      userMerchants.reduce((resultObj, merchant) => {
        resultObj[merchant._id] = false;
        return resultObj;
      }, {})
    );
  }, [userMerchants]);

  useEffect(() => {
    if (userId) {
      getUserMerchants(userId);
    }
  }, [userId]);

  useEffect(() => {
    scrollContainerIntoView(userMerchantsRef);
  }, []);

  const updateMerchantFetchingStatus = (merchantId, isFetching) => {
    setFetchingId({ ...fetchingId, [merchantId]: isFetching });
  };

  const updateMerchant = async (merchantId, value, type) => {
    updateMerchantFetchingStatus(merchantId, true);
    if (type === 'withdraw') await updateMerchantsSystemFee(merchantId, value);
    if (type === 'replenish') await updateMerchantsSystemFeeReplenish(merchantId, value);
    if (type === 'swap') await updateMerchantsSystemSwapReplenish(merchantId, value);
    if (type === 'refund') await updateMerchantsRefundFee(merchantId, value);
    if (type === 'minWithdraw') await updateMerchantsMinimumWithdrawal(merchantId, value);

    updateMerchantFetchingStatus(merchantId, false);
  };

  const spinIndicator = <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />;

  return (
    <div className="user-merchants" ref={userMerchantsRef}>
      <Card>
        <TEXT.pageTitle>Merchants</TEXT.pageTitle>
        <div className="user-merchants__list">
          {!userMerchantsFetching ? (
            userMerchants.map(merchant => (
              <div className="user-merchants__item" key={merchant._id}>
                <div>
                  <div className="user-merchants__name">
                    <TEXT.darkBold>{merchant.name}</TEXT.darkBold>
                  </div>
                  <div className="user-merchants__created-date">
                    <TEXT.sectionDescription>
                      {moment(merchant.createdAt).format('MMMM D, YYYY, HH:mm;')}
                    </TEXT.sectionDescription>
                  </div>
                </div>
                <div className="user-merchants__right-col">
                  <div className="user-merchants__right-col_wrapper">
                    <TEXT.sectionDescription>% System fee for withdrawal</TEXT.sectionDescription>
                    <Form
                      name="user-merchant-form"
                      initialValues={{
                        systemFeePercent:
                          merchant.systemFee >= 0 ? merchant.systemFee : adminSettingsData.systemFeePercent,
                      }}
                      onFinish={({ systemFeePercent }) => updateMerchant(merchant._id, systemFeePercent, 'withdraw')}
                    >
                      <div className="user-merchants__input-wrap">
                        <PercentInput name="systemFeePercent" className="user-merchants__input" />
                        <button
                          type="submit"
                          className="user-merchants__save"
                          disabled={systemFeeFetching && fetchingId[merchant._id]}
                        >
                          {systemFeeFetching && fetchingId[merchant._id] ? spinIndicator : <SaveSVG />}
                        </button>
                      </div>
                    </Form>
                  </div>

                  <div className="user-merchants__right-col_wrapper">
                    <TEXT.sectionDescription>% System fee for replenishment</TEXT.sectionDescription>
                    <Form
                      name="user-merchant-form"
                      initialValues={{
                        systemFeeReplenish:
                          merchant.systemFeeReplenish >= 0
                            ? merchant.systemFeeReplenish
                            : adminSettingsData.systemFeePercentReplenish,
                      }}
                      onFinish={({ systemFeeReplenish }) =>
                        updateMerchant(merchant._id, systemFeeReplenish, 'replenish')
                      }
                    >
                      <div className="user-merchants__input-wrap">
                        <PercentInput name="systemFeeReplenish" className="user-merchants__input" />
                        <button
                          type="submit"
                          className="user-merchants__save"
                          disabled={systemFeeFetching && fetchingId[merchant._id]}
                        >
                          {systemFeeReplenishFetching && fetchingId[merchant._id] ? spinIndicator : <SaveSVG />}
                        </button>
                      </div>
                    </Form>
                  </div>

                  <div className="user-merchants__right-col_wrapper">
                    <TEXT.sectionDescription>% System fee for Swap</TEXT.sectionDescription>
                    <Form
                      name="user-merchant-form"
                      initialValues={{
                        systemFeeSwap: merchant?.systemFeeSwap ?? adminSettingsData.systemFeePercentSwap,
                      }}
                      onFinish={({ systemFeeSwap }) => updateMerchant(merchant._id, systemFeeSwap, 'swap')}
                    >
                      <div className="user-merchants__input-wrap">
                        <PercentInput name="systemFeeSwap" className="user-merchants__input" />
                        <button
                          type="submit"
                          className="user-merchants__save"
                          disabled={systemFeeSwapFetching && fetchingId[merchant._id]}
                        >
                          {systemFeeSwapFetching && fetchingId[merchant._id] ? spinIndicator : <SaveSVG />}
                        </button>
                      </div>
                    </Form>
                  </div>

                  <div className="user-merchants__right-col_wrapper">
                    <TEXT.sectionDescription>$ Refund Limit USD</TEXT.sectionDescription>
                    <Form
                      name="user-merchant-form"
                      initialValues={{
                        refund: merchant?.refundLimitUsd ?? adminSettingsData.refundLimitUsd,
                      }}
                      onFinish={({ refund }) => updateMerchant(merchant._id, refund, 'refund')}
                    >
                      <div className="user-merchants__input-wrap">
                        <NumberInputSettings name="refund" className="user-merchants__input" />
                        <button
                          type="submit"
                          className="user-merchants__save"
                          disabled={systemRefundFetching && fetchingId[merchant._id]}
                        >
                          {systemRefundFetching && fetchingId[merchant._id] ? spinIndicator : <SaveSVG />}
                        </button>
                      </div>
                    </Form>
                  </div>
                  <div className="user-merchants__right-col_wrapper">
                    <TEXT.sectionDescription>$ Minimum Withdrawal USD</TEXT.sectionDescription>
                    <Form
                      name="user-merchant-form"
                      initialValues={{
                        minWithdraw: merchant?.minimumWithdrawalUsd ?? adminSettingsData.minimumWithdrawalUsd,
                      }}
                      onFinish={({ minWithdraw }) => updateMerchant(merchant._id, minWithdraw, 'minWithdraw')}
                    >
                      <div className="user-merchants__input-wrap">
                        <NumberInputSettings name="minWithdraw" className="user-merchants__input" />
                        <button
                          type="submit"
                          className="user-merchants__save"
                          disabled={systemsMinimumWithdrawalFetching && fetchingId[merchant._id]}
                        >
                          {systemsMinimumWithdrawalFetching && fetchingId[merchant._id] ? spinIndicator : <SaveSVG />}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Spin className="user-merchants__loader" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          )}
        </div>
      </Card>
      <div className="user-merchants-buttons">
        <Link to="/admin/users">
          <ButtonSecondary>Back</ButtonSecondary>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userMerchants: state.adminUsers.userMerchants.data,
  userMerchantsFetching: state.adminUsers.userMerchants.fetching,
  adminSettingsData: state.adminSettings.data,
  systemFeeFetching: state.adminUsers.userMerchants.systemFeeFetching,
  systemFeeReplenishFetching: state.adminUsers.userMerchants.systemFeeReplenishFetching,
  systemFeeSwapFetching: state.adminUsers.userMerchants.systemFeeSwapFetching,
  systemRefundFetching: state.adminUsers.userMerchants.systemRefundFetching,
  systemsMinimumWithdrawalFetching: state.adminUsers.userMerchants.systemsMinimumWithdrawalFetching,
});

const mapDispatchToProps = {
  getUserMerchants,
  updateMerchantsSystemFee,
  getAdminSettings,
  updateMerchantsSystemFeeReplenish,
  updateMerchantsSystemSwapReplenish,
  updateMerchantsRefundFee,
  updateMerchantsMinimumWithdrawal,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMerchants);
