export const AppConfig = {
    showFooter: true,
    repeatGuide: true,
    preOrderCard: true,
    showReleaseNotes: false,
    showDomainInstruction: true,
    tawkPlagin: true,
    title: 'CPAY',
    titleSecondary: 'CPAY',
    mainColor: '#2988EF',
    identity: 'cpay',
    showNftBlock: true,
};