import React, { useRef, useState, useEffect } from 'react';
import { Tooltip } from "antd";
import '../MerchantEdit/MerchantDataForm.scss';
import './AutoswapDataForm.scss';

export default function AutoswapListItem({ autoswap }) {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const { clientWidth, scrollWidth } = textRef.current;
    setIsOverflowed(scrollWidth > clientWidth);
  }, [autoswap.swapName]);

  return (
    <Tooltip
      title={isOverflowed ? autoswap.swapName : undefined}
      placement="top"
    >
      <div className="swap-amount-field__list">
        <div ref={textRef} className="swap-amount-field__list__crop">
          {autoswap.swapName}
        </div>
      </div>
    </Tooltip>
  )
}

