import React from 'react';
import { ReactComponent as NoImageIcon } from '../../../img/default-svg/no-checkout-image.svg';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../LanguageSelector';
import { getContrastTextColor } from '../../../utils/getTextColorByBg';
import styles from './SaleTokenWrapper.module.scss';

const SaleTokenWrapper = ({ title, description, children, hideSubtitle, image, logoImage, backgroundColor }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['sale-token-wrapper']} style={{ backgroundColor }}>
      {logoImage && (
        <div className={styles['sale-token-wrapper__custom-logo']}>
          <img src={process.env.REACT_APP_API_URL + logoImage} alt="Checkout logo" />
        </div>
      )}
      <span
        className={styles['sale-token-wrapper__title']}
        style={{ color: getContrastTextColor(backgroundColor || '#f1f3f6') }}
      >
        {t('checkouts.checkoutForm.title')}
      </span>
      <LanguageSelector isFromSaleTokenCheckout />
      {!hideSubtitle && (
        <span
          className={styles['sale-token-wrapper__subtitle']}
          style={{ color: getContrastTextColor(backgroundColor || '#f1f3f6') }}
        >
          {t('checkouts.checkoutForm.description')}
        </span>
      )}
      <div className={styles['sale-token-wrapper__payment-data']}>
        {image ? (
          <img
            src={`${process.env.REACT_APP_API_URL}${image}`}
            alt="Sale token logo"
            className={styles['sale-token-wrapper__payment-data-image']}
          />
        ) : (
          <NoImageIcon className={styles['sale-token-wrapper__payment-data-image']} />
        )}
        <div>
          <span className={styles['sale-token-wrapper__payment-data-title']}>{title}</span>
          <span className={styles['sale-token-wrapper__payment-data-description']}>{description}</span>
        </div>
      </div>
      <div className={styles['sale-token-wrapper__children-wrapper']}>{children}</div>
    </div>
  );
};

export default SaleTokenWrapper;
