import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import TokenIcon from '../../../../../components/TokenIcon';
import formatCurrency from '../../../../../utils/currencyFormatter';
import { connect } from 'react-redux';
import CopyWrapper from '../../../../../components/CopyWrapper';
import { ReactComponent as CopyIconCpay } from '../../../../../img/cpay-svg/copy-blue.svg';
import { ReactComponent as CopyIconNfg } from '../../../../../img/nfgpay-svg/copy-blue-nfg.svg';
import { ReactComponent as CopyIconFinvaro } from '../../../../../img/finvaro/copy-blue-finvaro.svg';
import { ReactComponent as CopyIconClarnium } from '../../../../../img/clarnium/copy-blue-clarnium.svg';
import './style.scss';
import { tokenNodeTypeObj } from '../../../../../utils/tokenNodeTypes';
import { getHashFromLink } from '../../../../../utils/getHashFromLink';
import { useTranslation } from 'react-i18next';
import { identity } from '../../../../../utils/getIdentity';

const copyIcon = {
  cpay: <CopyIconCpay/>,
  nfg: <CopyIconNfg/>,
  finvaro: <CopyIconFinvaro />,
  clarnium: <CopyIconClarnium />
}

const Transaction = ({ transaction, networkFilter }) => {
  const [isOpened, setIsOpened] = useState(false);

  const { t } = useTranslation();


  return (
    <div className="fee-transaction">
    <Row align="middle" onClick={() => setIsOpened(!isOpened)}>
      <Col span={1}>
        <div className="fee-transaction__logo">
          <TokenIcon tokenName={transaction.info.currency} alt="Currency logo" />
        </div>
      </Col>
      <Col span={7}>
        <div className="fee-transaction__type">{transaction.type}</div>
      </Col>
      <Col span={7}>
        <div className="fee-transaction__date">
          {moment(transaction.createdAt).format("MMM D, YYYY, HH:mm;")}
        </div>
      </Col>
      <Col span={3}>
        <div
          className={`transaction__status transaction__status_${transaction.systemStatus.toLowerCase()}`}
        >
          {transaction.systemStatus}
        </div>
      </Col>
      <Col span={6}>
        <div className="fee-transaction__amount">
          <div className="fee-transaction__amount_tokens">
            {transaction.info.amount.value}{" "}
            {transaction.info.amount.value || transaction.info.amount.value === 0
              ? transaction.info.currency
              : ""}{" "}
            {transaction.info.currencyType === "token"
              ? tokenNodeTypeObj[transaction.info.nodeType]
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "optimism"
              ? transaction.info.currency === "OP"
                ? null
                : "OP"
              : null}{" "}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            transaction.info.currency === "ETH" &&
            transaction.info.currencyType === "currency" &&
            transaction.info.nodeType === "optimism"
              ? "OP"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            transaction.info.currencyType === "currency" &&
            transaction.info.nodeType === "arbitrum"
              ? transaction.info.currency === "arbitrum"
                ? null
                : "ARB"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            (transaction.info.currency === "USDT" ||
              transaction.info.currency === "USDC" ||
              transaction.info.currency === "ETH") &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "arbitrum"
              ? "ARB"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            ((transaction.info.currency === "USDT" ||
              transaction.info.currency === "USDC") &&
              transaction.info.currencyType === "token" &&
              transaction.info.nodeType) === "matic"
              ? "MATIC"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            transaction.info.currency === "USDT" &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "avax"
              ? "AVAX"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            transaction.info.currency === "USDC" &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "solana"
              ? "SOL"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            transaction.info.currency === "USDT" &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "ftm"
              ? "FTM"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            (transaction.info.currency === "USDT" ||
              transaction.info.currency === "USDC") &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "moonbeam"
              ? "GLMR"
              : null}
            {(transaction.info.amount.value ||
             transaction.info.amount.value === 0) &&
             transaction.info.currency === "ETH" &&
             transaction.info.currencyType === "currency" &&
             transaction.info.nodeType === "blast"
              ? "Blast"
              : null}
            {(transaction.info.amount.value ||
             transaction.info.amount.value === 0) &&
             transaction.info.currency === "USDT" &&
             transaction.info.currencyType === "token" &&
             transaction.info.nodeType === "ton"
              ? "TON"
              : null}
          </div>
          <div className="fee-transaction__amount_usd">
            ${formatCurrency(transaction.info.amount.usd)}
          </div>
        </div>
      </Col>
    </Row>
    {transaction.errorMessage && (
      <div className="fee-transaction__error-wrapper">
        <div className="fee-transaction__error-title">Transaction error</div>
        <div className="fee-transaction__error-description">
          {transaction.errorMessage}
        </div>
      </div>
    )}
    <div
      className={`transaction__details ${isOpened && "transaction__details_opened"}`}
    >
      <Row>
        <Col>
          <div className="fee-transaction__details-id">
            <b>Payment ID</b>: {transaction._id}
          </div>
          {transaction.info.to && (
            <div className="fee-transaction__details-to">
              <b>To</b>: {transaction.info.to}{" "}
              <CopyWrapper copyContent={transaction.info.to}>
                {copyIcon[identity]}
              </CopyWrapper>
            </div>
          )}
          <div className="fee-transaction__details-from">
            <b>From</b>: {transaction.info.from}{" "}
            <CopyWrapper copyContent={transaction.info.from}>
              {copyIcon[identity]}
            </CopyWrapper>
          </div>
          <div className="fee-transaction__details-fee">
            <b>Miner Fee</b>: {transaction.info.minerFee.value}{" "}
            {transaction.info.minerFee.currency}{" "}
            {transaction.info.currencyType === "token"
              ? tokenNodeTypeObj[transaction.info.nodeType]
              : null}
            {transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "optimism"
              ? transaction.info.currency === "OP"
                ? null
                : "OP"
              : null}
            {transaction.info.currency === "ETH" &&
            transaction.info.currencyType === "currency" &&
            transaction.info.nodeType === "optimism"
              ? "OP"
              : null}
            {transaction.info.currencyType === "currency" &&
            transaction.info.nodeType === "arbitrum"
              ? transaction.info.currency === "arbitrum"
                ? null
                : "ARB"
              : null}
            {(transaction.info.currency === "USDT" ||
              transaction.info.currency === "USDC" ||
              transaction.info.currency === "ETH") &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "arbitrum"
              ? "ARB"
              : null}
            {(transaction.info.currency === "USDT" ||
              transaction.info.currency === "USDC") &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "matic"
              ? "MATIC"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            transaction.info.currency === "USDT" &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "avax"
              ? "AVAX"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            transaction.info.currency === "USDC" &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "solana"
              ? "SOL"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            transaction.info.currency === "USDT" &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "ftm"
              ? "FTM"
              : null}
            {(transaction.info.amount.value ||
              transaction.info.amount.value === 0) &&
            (transaction.info.currency === "USDT" ||
              transaction.info.currency === "USDC") &&
            transaction.info.currencyType === "token" &&
            transaction.info.nodeType === "moonbeam"
              ? "GLMR"
              : null}
            ⸺ $
            {transaction.info.minerFee.usd
              ? formatCurrency(transaction.info.minerFee.usd)
              : "USD"}
          </div>
          {transaction.info.systemFee && (
            <div className="fee-transaction__details-fee">
              <b>System Fee</b>: {transaction.info.systemFee.amount}{" "}
              {transaction.info.systemFee.currency}{" "}
              {transaction.info.currencyType === "token"
                ? tokenNodeTypeObj[transaction.info.nodeType]
                : null}
              {transaction.info.currencyType === "token" &&
              transaction.info.nodeType === "optimism"
                ? transaction.info.currency === "OP"
                  ? null
                  : "OP"
                : null}
              {transaction.info.currency === "ETH" &&
              transaction.info.currencyType === "currency" &&
              transaction.info.nodeType === "optimism"
                ? "OP"
                : null}
              {transaction.info.currencyType === "currency" &&
              transaction.info.nodeType === "arbitrum"
                ? transaction.info.currency === "arbitrum"
                  ? null
                  : "ARB"
                : null}
              {(transaction.info.currency === "USDT" ||
                transaction.info.currency === "USDC" ||
                transaction.info.currency === "ETH") &&
              transaction.info.currencyType === "token" &&
              transaction.info.nodeType === "arbitrum"
                ? "ARB"
                : null}
              {(transaction.info.currency === "USDT" ||
                transaction.info.currency === "USDC") &&
              transaction.info.currencyType === "token" &&
              transaction.info.nodeType === "matic"
                ? "MATIC"
                : null}
              {transaction.info.currency === "USDT" &&
              transaction.info.currencyType === "token" &&
              transaction.info.nodeType === "avax"
                ? "AVAX"
                : null}
              {transaction.info.currency === "USDC" &&
              transaction.info.currencyType === "token" &&
              transaction.info.nodeType === "solana"
                ? "SOL"
                : null}
              {transaction.info.currency === "USDT" &&
              transaction.info.currencyType === "token" &&
              transaction.info.nodeType === "ftm"
                ? "FTM"
                : null}
              {(transaction.info.currency === "USDT" ||
                transaction.info.currency === "USDC") &&
              transaction.info.currencyType === "token" &&
              transaction.info.nodeType === "moonbeam"
                ? "GLMR"
                : null}{" "}
              ⸺ {formatCurrency(transaction.info.systemFee.amountUSD)}
            </div>
          )}

          {transaction.type === "ExternalCall" && (
            <div className="transaction__details-external-call">
              {!!Object.keys(transaction.info.call).length &&(
                <>
                 <b>contract Address</b>:{" "}
                <span>{transaction.info.call?.contractAddress}</span>
                <br />
                <div className="transaction__details-external-call-item">
                  <b>method</b>: <span>{transaction.info.call?.method}</span>
                </div>
                </>
              )}
             
              <div className="transaction__details-external-call-item">
                <b>arguments</b>: <br />
                <ul className="transaction__details-external-call-arguments">
                  {!!Object.keys(transaction.info.call).length &&
                      transaction.info.call.arguments.map(item => (
                        <>
                          <li>{item}</li>
                        </>
                      ))}
                    {Object.keys(transaction.info.call).length === 0 &&
                      !!Object.keys(transaction.info?.solanaMint) &&
                      Object.entries(transaction.info?.solanaMint)?.map(([key, value], index) => (
                        <>
                          <li key={index}>
                            {key} - {value}{' '}
                          </li>
                        </>
                    ))}
                </ul>
              </div>
            </div>
          )}

          {transaction.info.explorerUrl.current ? (
            <div
              className={`fee-transaction__details-hash fee-transaction__details-hash-${identity}`}
            >
              <>
                <b>Hash:</b>{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    transaction.type === "InternalTransfer"
                      ? null
                      : `${transaction.info.explorerUrl.current}`
                  }
                  className={`link link-${identity}`}
                >
                  {getHashFromLink(transaction.info.explorerUrl.current)}
                </a>
                <CopyWrapper
                  copyContent={getHashFromLink(
                    transaction.info.explorerUrl.current,
                  )}
                >
                  {copyIcon[identity]}
                </CopyWrapper>
              </>
            </div>
          ) : null}

          {transaction.info.explorerUrl.incoming ? (
            <div
              className={`fee-transaction__details-hash fee-transaction__details-hash-${identity}`}
            >
              <>
                <b>{t("homeTransactions.incomingHash")}</b>:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${transaction.info.explorerUrl.incoming}`}
                  className={`link link-${identity}`}
                >
                  {getHashFromLink(transaction.info.explorerUrl.incoming)}
                </a>
                <CopyWrapper
                  copyContent={getHashFromLink(
                    transaction.info.explorerUrl.incoming,
                  )}
                >
                  {copyIcon[identity]}
                </CopyWrapper>
              </>
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  </div>

  );
};

const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
  currencies: state.currencies.data,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
