import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsSuccessShow, notificationsErrorShow } from './notifications';
import { notification } from "antd";

// CONSTANTS
export const MERCHANT_WALLETS_RESET_PAGE = 'MERCHANT_WALLETS_RESET_PAGE';
export const MERCHANT_WALLETS_GET = 'MERCHANT_WALLETS_GET';
export const MERCHANT_WALLETS_GET_MORE = 'MERCHANT_WALLETS_GET_MORE';
export const MERCHANT_WALLETS_SET = 'MERCHANT_WALLETS_SET';
export const MERCHANT_WALLETS_RESET = 'MERCHANT_WALLETS_RESET';
export const MERCHANT_WALLETS_CREATE = 'MERCHANT_WALLETS_CREATE';
export const MERCHANT_WALLETS_DELETE = 'MERCHANT_WALLETS_DELETE';
export const MERCHANT_WALLETS_GET_PRIVATE_KEY = 'MERCHANT_WALLETS_GET_PRIVATE_KEY';
export const MERCHANT_WALLETS_GET_PRIVATE_KEY_SUCCESS = 'MERCHANT_WALLETS_GET_PRIVATE_KEY_SUCCESS';
export const MERCHANT_WALLETS_GET_MNEMONIC_PHRASE = 'MERCHANT_WALLETS_GET_MNEMONIC_PHRASE';
export const MERCHANT_WALLETS_GET_MNEMONIC_PHRASE_SUCCESS = 'MERCHANT_WALLETS_GET_MNEMONIC_PHRASE_SUCCESS';
export const MERCHANT_WALLETS_GET_PASSPHRASE = 'MERCHANT_WALLETS_GET_PASSPHRASE';
export const MERCHANT_WALLETS_GET_PASSPHRASE_SUCCESS = 'MERCHANT_WALLETS_GET_PASSPHRASE_SUCCESS';
export const MERCHANT_WALLETS_SET_APP_TWO_FA = 'MERCHANT_WALLETS_SET_IS_APP_TWO_FA';
export const MERCHANT_WALLETS_SET_EMAIL_TWO_FA = 'MERCHANT_WALLETS_SET_IS_EMAIL_TWO_FA';
export const MERCHANT_WALLETS_SET_PHONE_TWO_FA = 'MERCHANT_WALLETS_SET_IS_PHONE_TWO_FA';
export const MERCHANT_WALLETS_RESET_TWO_FA = 'MERCHANT_WALLETS_RESET_TWO_FA';
export const MERCHANT_WALLETS_ERROR = 'MERCHANT_WALLETS_ERROR';
export const MERCHANT_WALLETS_SET_MAIN = 'MERCHANT_WALLETS_SET_MAIN';

// ACTIONS GENERATORS
export const resetPageCounter = () => ({
  type: MERCHANT_WALLETS_RESET_PAGE,
});

export const merchantWalletsGet = () => ({
  type: MERCHANT_WALLETS_GET,
});

export const merchantWalletsGetMore = () => ({
  type: MERCHANT_WALLETS_GET_MORE,
});

export const merchantWalletsSet = data => ({
  type: MERCHANT_WALLETS_SET,
  payload: data,
});

export const merchantWalletsReset = () => ({
  type: MERCHANT_WALLETS_RESET,
});

export const merchantWalletsCreate = () => ({
  type: MERCHANT_WALLETS_CREATE,
});

export const merchantWalletsDelete = () => ({
  type: MERCHANT_WALLETS_DELETE,
});

export const merchantWalletsGetPrivateKey = () => ({
  type: MERCHANT_WALLETS_GET_PRIVATE_KEY,
});

export const merchantWalletsGetPrivateKeySuccess = () => ({
  type: MERCHANT_WALLETS_GET_PRIVATE_KEY_SUCCESS,
});

export const merchantWalletsGetMnemonicPhrase = () => ({
  type: MERCHANT_WALLETS_GET_MNEMONIC_PHRASE,
});

export const merchantWalletsGetMnemonicPhraseSuccess = () => ({
  type: MERCHANT_WALLETS_GET_MNEMONIC_PHRASE_SUCCESS,
});

export const merchantWalletsSetAppTwoFa = isAppTwoFa => ({
  type: MERCHANT_WALLETS_SET_APP_TWO_FA,
  isAppTwoFa,
});

export const merchantWalletsGetPassphrase = () => ({
  type: MERCHANT_WALLETS_GET_PASSPHRASE,
});

export const merchantWalletsGetPassphraseSuccess = () => ({
  type: MERCHANT_WALLETS_GET_PASSPHRASE_SUCCESS,
});

export const merchantWalletsSetEmailTwoFa = isEmailTwoFa => ({
  type: MERCHANT_WALLETS_SET_EMAIL_TWO_FA,
  isEmailTwoFa,
});

export const merchantWalletsSetPhoneTwoFa = isPhoneTwoFa => ({
  type: MERCHANT_WALLETS_SET_PHONE_TWO_FA,
  isPhoneTwoFa,
});

export const merchantWalletsResetTwoFa = () => ({
  type: MERCHANT_WALLETS_RESET_TWO_FA,
});

export const merchantWalletsError = () => ({
  type: MERCHANT_WALLETS_ERROR,
});

export const merchantWalletsSetMain = () => ({
  type: MERCHANT_WALLETS_SET_MAIN,
});

// ASYNC ACTIONS
export const getMerchantWallets =
  dispatch =>
  async (merchantId, { positiveBalance, ...params }, fromSockets = false) => {
    !fromSockets && dispatch(merchantWalletsGet());
    let wallets;

    try {
      wallets = await cryptoApi.getMerchantWallets(merchantId, params, positiveBalance);
    } catch (err) {
      dispatch(merchantWalletsError());
      console.log('err in getMerchantWallets', err, err.data);
      dispatch(notificationsErrorShow(err.data));
    }

    if (wallets && wallets.data) {
      dispatch(merchantWalletsSet(wallets.data));
      return;
    }

    dispatch(merchantWalletsError());
  };

export const getMoreMerchantWallets = dispatch => async (merchantId, { positiveBalance, ...params }) => {
  dispatch(merchantWalletsGetMore());
  let wallets;

  try {
    wallets = await cryptoApi.getMerchantWallets(merchantId, params, positiveBalance);
  } catch (err) {
    dispatch(merchantWalletsError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (wallets && wallets.data) {
    dispatch(merchantWalletsSet(wallets.data));
    return;
  }

  dispatch(merchantWalletsError());
};

export const createMerchantWallet =
  dispatch =>
  async (
    merchantId,
    walletType,
    walletData = null,
    page,
    walletsFilters,
    networkFilter,
    isFromMerchantsWallets = false
  ) => {
    dispatch(merchantWalletsCreate());
    let wallet;

    try {
      wallet = await cryptoApi.createMerchantWallet(merchantId, walletData);
    } catch (err) {
      dispatch(merchantWalletsError());
      if (walletData.isMnemonic && err?.data?.message === 'PrivateKey is Invalid') {
        dispatch(notificationsErrorShow({ message: 'Mnemonic phrase is invalid' }));
        return err;
      }
      dispatch(notificationsErrorShow(err.data));
    }

    if (wallet && wallet.data) {
      const updateWalletsList = getMerchantWallets(dispatch);
      updateWalletsList(merchantId, {
        currencyIds: walletsFilters.currencyIds.join(',') || [],
        typeWallet: walletType,
        page: page,
        limit: walletsFilters.limit || 5,
        order: walletsFilters.order || 'DESC',
        typeNetwork: networkFilter,
        showTokens: isFromMerchantsWallets ? false : undefined,
      });
      dispatch(notificationsSuccessShow({}));
      return;
    }

    dispatch(merchantWalletsError());
  };

export const deleteMerchantWallet =
  dispatch =>
  async (
    merchantId,
    walletId,
    walletType,
    page,
    pages,
    countItem,
    walletsFilters,
    appTwoFaCode,
    emailTwoFaCode,
    verifyCode,
    networkFilter,
    isFromMerchantsWallets = false
  ) => {
    dispatch(merchantWalletsDelete());
    let response;

    try {
      response = await cryptoApi.deleteMerchantWallet(merchantId, walletId, appTwoFaCode, emailTwoFaCode, verifyCode);
    } catch (err) {
      dispatch(merchantWalletsError());
      dispatch(notificationsErrorShow(err.data));
    }

    if (response && response.status && response.status !== 'fail') {
      dispatch(merchantWalletsSetEmailTwoFa(false));
      dispatch(merchantWalletsSetAppTwoFa(false));
      dispatch(merchantWalletsSetPhoneTwoFa(false));

      const updateWalletsList = getMerchantWallets(dispatch);
      updateWalletsList(merchantId, {
        currencyIds: walletsFilters.currencyIds.join(',') || '',
        typeWallet: walletType,
        page: (countItem - 1) % (walletsFilters.limit || 5) > 0 ? page : pages - 1 > 0 ? pages - 1 : 1,
        limit: walletsFilters.limit || 5,
        order: walletsFilters.order || 'DESC',
        typeNetwork: networkFilter,
        showTokens: isFromMerchantsWallets ? false : undefined,
      });
      dispatch(notificationsSuccessShow({}));
      return true;
    }

    if (response && response.data.emailSent) {
      dispatch(merchantWalletsSetEmailTwoFa(true));
      return;
    }

    if (response && response.data.twoFactorToken) {
      dispatch(merchantWalletsSetAppTwoFa(true));
      return;
    }

    if (response && response.data.codeSent) {
      dispatch(merchantWalletsSetPhoneTwoFa(true));
      return;
    }

    dispatch(merchantWalletsError());
  };

export const resetTwoFaState = dispatch => async () => {
  dispatch(merchantWalletsSetEmailTwoFa(false));
  dispatch(merchantWalletsSetAppTwoFa(false));
  dispatch(merchantWalletsSetPhoneTwoFa(false));
};

export const getWalletPrivateKey =
  dispatch =>
  async (merchantId, walletId, appTwoFaCode, emailTwoFaCode, verifyCode, type = 'client') => {
    dispatch(merchantWalletsGetPrivateKey());
    let response;

    try {
      response = await cryptoApi.getWalletPrivateKey(merchantId, walletId, appTwoFaCode, emailTwoFaCode, verifyCode);
    } catch (err) {
      dispatch(merchantWalletsError());
      dispatch(notificationsErrorShow(err?.data));
      return err;
    }

    if (response && response.data.twoFactorToken) {
      dispatch(merchantWalletsSetAppTwoFa(response.data.twoFactorToken));
      return response;
    }

    if (response && response.data.emailSent) {
      dispatch(merchantWalletsSetEmailTwoFa(response.data.emailSent));
      return response;
    }

    if (response && response.data.codeSent) {
      dispatch(merchantWalletsSetPhoneTwoFa(response.data.codeSent));
      return response;
    }

    if (response && response.data) {
      dispatch(merchantWalletsGetPrivateKeySuccess());
      return response;
    }
  };

export const getWalletMnemonicPhrase =
  dispatch => async (merchantId, walletId, appTwoFaCode, emailTwoFaCode, verifyCode) => {
    dispatch(merchantWalletsGetMnemonicPhrase());
    let response;

    try {
      response = await cryptoApi.getWalletMnemonicPhrase(
        merchantId,
        walletId,
        appTwoFaCode,
        emailTwoFaCode,
        verifyCode
      );
    } catch (err) {
      dispatch(merchantWalletsError());
      dispatch(notificationsErrorShow(err.data));
      return err;
    }

    if (response && response.data.twoFactorToken) {
      dispatch(merchantWalletsSetAppTwoFa(response.data.twoFactorToken));
      return response;
    }

    if (response && response.data.emailSent) {
      dispatch(merchantWalletsSetEmailTwoFa(response.data.emailSent));
      return response;
    }

    if (response && response.data.codeSent) {
      dispatch(merchantWalletsSetPhoneTwoFa(response.data.codeSent));
      return;
    }

    if (response && response.data) {
      dispatch(merchantWalletsGetMnemonicPhraseSuccess());
      return response;
    }
  };

export const getPassphrase =
  dispatch => async (merchantId, walletId, appTwoFaCode, emailTwoFaCode, verifyCode) => {
    dispatch(merchantWalletsGetPassphrase());
    let response;

    try {
      response = await cryptoApi.getPassphrase(
        merchantId,
        walletId,
        appTwoFaCode,
        emailTwoFaCode,
        verifyCode
      );
    } catch (err) {
      dispatch(merchantWalletsError());
      dispatch(notificationsErrorShow(err.data));
      return err;
    }

    if (response && response.data.twoFactorToken) {
      dispatch(merchantWalletsSetAppTwoFa(response.data.twoFactorToken));
      return response;
    }

    if (response && response.data.emailSent) {
      dispatch(merchantWalletsSetEmailTwoFa(response.data.emailSent));
      return response;
    }

    if (response && response.data.codeSent) {
      dispatch(merchantWalletsSetPhoneTwoFa(response.data.codeSent));
      return;
    }

    if (response && response.data) {
      dispatch(merchantWalletsGetPassphraseSuccess());
      return response;
    }
  };

export const setWalletAsMain =
  dispatch => async (merchantId, walletId, currencyId, typeWallet, page, walletsFilters, networkFilter) => {
    dispatch(merchantWalletsSetMain());

    let response;

    try {
      response = await cryptoApi.setWalletAsMain(merchantId, walletId, currencyId);
    } catch (err) {
      dispatch(merchantWalletsError());
      dispatch(notificationsErrorShow(err.data));
      return err;
    }

    if (response && response.status && response.status === 'success') {
      let positive;

      if (typeWallet === 'merchant') {
        positive = JSON.parse(localStorage.getItem('cpayHideZeroBalancesMerchant')) || false
      } else {
        positive = JSON.parse(localStorage.getItem('cpayHideZeroBalancesClient')) || false
      }
      const updateWalletsList = getMerchantWallets(dispatch);
      updateWalletsList(merchantId, {
        currencyIds: walletsFilters.currencyIds.join(',') || '',
        typeWallet,
        page: page,
        limit: walletsFilters.limit || 5,
        order: walletsFilters.order || 'DESC',
        typeNetwork: networkFilter,
        showTokens: false,
        positiveBalance: positive
      });
      dispatch(notificationsSuccessShow({}));
      return;
    }
  };
