import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import TypesFilter from "./TypesFilter";
import StatusFilter from "./StatusFilter";
import NftFilters from "./NftFilters";
import { identity } from "../../utils/getIdentity";
import "./styles.scss";

const TransactionFilters = ({
  type,
  setType,
  onlyNft,
  setOnlyNft,
  nftType,
  setNftType,
  setHideNft,
  hideNft,
  resetFilters,
}) => {
  const { t } = useTranslation();
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);


  const resettingFilters = () => {
    resetFilters();
    setCheckedList([]);
    setIndeterminate(false);
  }

  return (
    <div className="transaction-filters">
      <div className="transaction-filters__content">
        <div className="transaction-filters__title">
          <p>{t("transactionHistory.transactionFilters")}</p>
          <p className={`transaction-filters__title-reset transaction-filters__title-reset-${identity}`} onClick={resettingFilters}>{t("transactionHistory.resetAll")}</p>
        </div>
        <TypesFilter
        setType={setType}
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        indeterminate={indeterminate}
        setIndeterminate={setIndeterminate}
        />
        {/*
        // temporarily commented until api won't update
        <StatusFilter />
        */}
        <NftFilters
         onlyNft={onlyNft}
         setOnlyNft={setOnlyNft}
         hideNft={hideNft}
         setHideNft={setHideNft}
         nftType={nftType}
         setNftType={setNftType}
        />

      </div>
    </div>
  );
};

export default TransactionFilters;
