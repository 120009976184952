import { useState, useEffect } from 'react';
import BigNumber from "bignumber.js";

const CURRENCY_DATA_LONGER_PAYMENTS = {
    TRX: { nodeType: 'trx', title: 'TRX' },
    USDC: { nodeType: 'trx', title: 'USDC TRC-20' },
    USDT: { nodeType: 'trx', title: 'USDT TRC-20' }
}

const BITCOIN_NAME="BTC";

const usePartialPayment = (selectedCurrency, replenish, systemStatus) => {

    const { currency } = replenish || {};
    const { value } = replenish?.totalAmount || {};
    const { amountCurrency, currency: { name, nodeType } } = selectedCurrency || {};

    //full sum - total
    const restAmountToPayValue = BigNumber(amountCurrency).minus(value).toString();

    //FOR PENDING & PARTIAL PAYMENT
    //paid w/o last trans
    const amountPaidWOLastTrans = BigNumber(value).minus(replenish?.amount?.value).toString();
    //full sum - total, w/o last trans in pending
    const restAmountToPayPending = BigNumber(amountCurrency).minus(amountPaidWOLastTrans).toString();

    const [restAmountsToPay, setRestAmountsToPay] = useState({ totalPaid: value, leftToPay: restAmountToPayValue });

    const isCurrencyBtcOrToken = (currency === BITCOIN_NAME) || (replenish?.nodeType === 'trx' && CURRENCY_DATA_LONGER_PAYMENTS[currency]);
    // const isPendingPartial = systemStatus === 'Pending' && CURRENCY_DATA_LONGER_PAYMENTS[currency] && nodeType === replenish?.nodeType && currency === name;
    const isPendingPartial = systemStatus === 'Pending' && isCurrencyBtcOrToken && (nodeType === replenish?.nodeType && currency === name);

    useEffect(() => {
        if (systemStatus && replenish)
            setRestAmountsToPay((prev) => {
                return isPendingPartial ? { ...prev, totalPaid: amountPaidWOLastTrans, leftToPay: restAmountToPayPending } : { ...prev, totalPaid: value, leftToPay: restAmountToPayValue }
            })
    }, [systemStatus, replenish])

    return {
        restAmountToPay: restAmountsToPay.leftToPay,
        currency,
        value: restAmountsToPay.totalPaid
    }
};


export default usePartialPayment