import React, { useState, useEffect } from "react";
import { Modal, Button, Spin } from "antd";
import { useTranslation } from "react-i18next";
import '../../layout/Modal.scss';
import './style.scss';
import { LoadingOutlined } from "@ant-design/icons";

const RemoveAutoswapModal = ({ visible, closeModal, deleteAutoswap, name, isLoading }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(visible)
  }, [visible]);

  const handleCancel = e => {
    setIsVisible(false);
    closeModal();
  };

  return (
    <React.Fragment>
      <Modal
        width={275}
        visible={isVisible}
        onCancel={handleCancel}
        footer={null}
        className="remove-autoswap"
      >
        <div className="remove-autoswap-content">
          <div className="modal-form__title">Delete</div>
          <div className="modal-form__subtitle">Do you want to delete “{name}”</div>
          <div className="modal-form__btnWrapper">
            <Button type="default" className="form__button component__button remove-autoswap-btn" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="primary" danger className="form__button component__button" onClick={deleteAutoswap}>
              {isLoading ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              ) : (
                'Delete'
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
};

export default RemoveAutoswapModal;