import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsSuccessShow, notificationsErrorShow } from './notifications';
import { saveAs } from 'file-saver';
import { notification } from "antd";

// CONSTANTS
export const WITHDRAW_WALLETS_GET = 'WITHDRAW_WALLETS_GET';
export const WITHDRAW_WALLETS_SET = 'WITHDRAW_WALLETS_SET';
export const WITHDRAW_SET_APP_TWO_FA = 'WITHDRAW_SET_APP_TWO_FA';
export const WITHDRAW_SET_EMAIL_TWO_FA = 'WITHDRAW_SET_EMAIL_TWO_FA';
export const WITHDRAW_SET_PHONE_TWO_FA = 'WITHDRAW_SET_PHONE_TWO_FA';
export const WITHDRAW_RESET_TWO_FA = 'WITHDRAW_RESET_TWO_FA';
export const WITHDRAW_CREATE_TRANSACTION = 'WITHDRAW_CREATE_TRANSACTION';
export const WITHDRAW_ERROR = 'WITHDRAW_ERROR';
export const WITHDRAW_ESTIMATE_MAX_REQUEST = 'WITHDRAW_ESTIMATE_MAX_REQUEST';
export const WITHDRAW_ESTIMATE_MAX_SUCCESS = 'WITHDRAW_ESTIMATE_MAX_SUCCESS';
export const WITHDRAW_ESTIMATE_MAX_ERROR = 'WITHDRAW_ESTIMATE_MAX_ERROR';
export const WITHDRAW_ESTIMATE_MAX_RESET = 'WITHDRAW_ESTIMATE_MAX_RESET';
export const CHECK_AUTOSIGN_STATUS_REQUEST = 'CHECK_AUTOSIGN_STATUS_REQUEST';
export const CHECK_AUTOSIGN_STATUS_SUCCESS = 'CHECK_AUTOSIGN_STATUS_SUCCESS';
export const CHECK_AUTOSIGN_STATUS_ERROR = 'CHECK_AUTOSIGN_STATUS_ERROR';
export const CHECK_PASSWORD_STATUS_REQUEST = 'CHECK_PASSWORD_STATUS_REQUEST';
export const CHECK_PASSWORD_STATUS_SUCCESS = 'CHECK_PASSWORD_STATUS_SUCCESS';
export const CHECK_PASSWORD_STATUS_ERROR = 'CHECK_PASSWORD_STATUS_ERROR';
export const UPLOAD_NEW_AUTOSIGN_REQUEST = 'UPLOAD_NEW_AUTOSIGN_REQUEST';
export const UPLOAD_NEW_AUTOSIGN_SUCCESS = 'UPLOAD_NEW_AUTOSIGN_SUCCESS';
export const UPLOAD_NEW_AUTOSIGN_ERROR = 'UPLOAD_NEW_AUTOSIGN_ERROR';
export const RESET_AUTOSIGN_STEP = 'RESET_AUTOSIGN_STEP';
export const RESET_NEW_AUTOSIGN = 'RESET_NEW_AUTOSIGN';
export const SET_PASSWORD_AUTOSIGN_REQUEST = 'SET_PASSWORD_AUTOSIGN_REQUEST';
export const SET_PASSWORD_AUTOSIGN_SUCCESS = 'SET_PASSWORD_AUTOSIGN_SUCCESS';
export const SET_PASSWORD_AUTOSIGN_ERROR = 'SET_PASSWORD_AUTOSIGN_ERROR';

export const DISABLE_NEW_AUTOSIGN_REQUEST = 'DISABLE_NEW_AUTOSIGN_REQUEST';
export const DISABLE_NEW_AUTOSIGN_SUCCESS = 'DISABLE_NEW_AUTOSIGN_SUCCESS';
export const DISABLE_NEW_AUTOSIGN_ERROR = 'DISABLE_NEW_AUTOSIGN_ERROR';

export const DOWNLOAD_NEW_AUTOSIGN_REQUEST = 'DOWNLOAD_NEW_AUTOSIGN_REQUEST';
export const DOWNLOAD_NEW_AUTOSIGN_SUCCESS = 'DOWNLOAD_NEW_AUTOSIGN_SUCCESS';
export const DOWNLOAD_NEW_AUTOSIGN_ERROR = 'DOWNLOAD_NEW_AUTOSIGN_ERROR';
export const RESET_DOWNLOAD_AUTOSIGN_STEP = 'RESET_DOWNLOAD_AUTOSIGN_STEP';

export const withdrawWalletsGet = () => ({
  type: WITHDRAW_WALLETS_GET,
});

export const withdrawWalletsSet = data => ({
  type: WITHDRAW_WALLETS_SET,
  payload: data,
});

export const withdrawSetAppTwoFa = isAppTwoFa => ({
  type: WITHDRAW_SET_APP_TWO_FA,
  isAppTwoFa,
});

export const withdrawSetEmailTwoFa = isEmailTwoFa => ({
  type: WITHDRAW_SET_EMAIL_TWO_FA,
  isEmailTwoFa,
});

export const withdrawSetPhoneTwoFa = isPhoneTwoFa => ({
  type: WITHDRAW_SET_PHONE_TWO_FA,
  isPhoneTwoFa,
});

export const withdrawResetTwoFa = () => ({
  type: WITHDRAW_RESET_TWO_FA,
});

export const withdrawCreateTransaction = () => ({
  type: WITHDRAW_CREATE_TRANSACTION,
});

export const withdrawError = () => ({
  type: WITHDRAW_ERROR,
});

export const getWithdrawWallets =
  dispatch =>
  async (merchantId, currencyIds, networkFilter, positiveUsdBalance = false, fromSockets = false) => {
    !fromSockets && dispatch(withdrawWalletsGet());
    let wallets;

    try {
      wallets = await cryptoApi.getMerchantWallets(
        merchantId,
        {
          order: 'DESC',
          limit: 99999,
          typeWallet: 'merchant',
          currencyIds: currencyIds || undefined,
          typeNetwork: networkFilter,
          positiveUsdBalance,
        }
        // positiveBalance
      );
    } catch (err) {
      dispatch(withdrawError());
      console.log('err in getWithdrawWallets', err, err.data);
      dispatch(notificationsErrorShow(err.data));
    }

    if (wallets && wallets.data.entities) {
      dispatch(withdrawWalletsSet([...wallets.data.entities]));
      return;
    }

    dispatch(withdrawError());
  };

export const makeWithdraw =
  dispatch =>
  async (
    from,
    to,
    amount,
    merchantId,
    currencyToken,
    priority,
    comment,
    password,
    sign,
    appTwoFaCode,
    emailTwoFaCode,
    verifyCode
  ) => {
    dispatch(withdrawCreateTransaction());
    let response;

    try {
      response = await cryptoApi.makeWithdraw(
        from,
        to,
        amount,
        merchantId,
        currencyToken,
        priority,
        comment,
        password,
        sign,
        appTwoFaCode,
        emailTwoFaCode,
        verifyCode
      );
    } catch (err) {
      dispatch(withdrawError());
      dispatch(notificationsErrorShow(err.data));
    }

    if (response && response.data.id) {
      dispatch(withdrawResetTwoFa());
      dispatch(notificationsSuccessShow({}));
      return response;
    }

    if (response && response.data.twoFactorToken) {
      dispatch(withdrawSetAppTwoFa(true));
      return response;
    }

    if (response && response.data.emailSent) {
      dispatch(withdrawSetEmailTwoFa(true));
      return response;
    }

    if (response && response.data.codeSent) {
      dispatch(withdrawSetPhoneTwoFa(true));
      return response;
    }
  };

// withdrawal max estimation
export const withdrawalEstimateMaxRequest = () => ({
  type: WITHDRAW_ESTIMATE_MAX_REQUEST,
});

export const withdrawalEstimateMaxSuccess = payload => ({
  type: WITHDRAW_ESTIMATE_MAX_SUCCESS,
  payload,
});

export const withdrawalEstimateMaxError = payload => ({
  type: WITHDRAW_ESTIMATE_MAX_ERROR,
  payload,
});

export const withdrawalEstimateMaxReset = () => ({
  type: WITHDRAW_ESTIMATE_MAX_RESET,
});

export const getWithdrawalEstimateMax = dispatch => async (merchantId, walletId, data) => {
  dispatch(withdrawalEstimateMaxRequest());

  try {
    const response = await cryptoApi.withdrawalEstimateMax(merchantId, walletId, data);
    dispatch(withdrawalEstimateMaxSuccess(response.data));
  } catch (error) {
    dispatch(withdrawalEstimateMaxError(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

export const getCurrenciesRate = async () => {
  const response = await cryptoApi.getCurrenciesRate();
  return response;
};

export const checkAutosignStatusRequest = () => ({
  type: CHECK_AUTOSIGN_STATUS_REQUEST,
});

export const checkAutosignStatusSuccess = payload => ({
  type: CHECK_AUTOSIGN_STATUS_SUCCESS,
  payload,
});

export const checkAutosignStatusError = payload => ({
  type: CHECK_AUTOSIGN_STATUS_ERROR,
  payload,
});

export const checkAutosignStatus = dispatch => async (merchantId, walletId) => {
  dispatch(checkAutosignStatusRequest());

  try {
    const response = await cryptoApi.checkAutosignStatus(merchantId, walletId);
    dispatch(checkAutosignStatusSuccess(response));
  } catch (error) {
    dispatch(checkAutosignStatusError(error));
  }
};

export const checkPasswordStatusRequest = () => ({
  type: CHECK_PASSWORD_STATUS_REQUEST,
});

export const checkPasswordStatusSuccess = payload => ({
  type: CHECK_PASSWORD_STATUS_SUCCESS,
  payload,
});

export const checkPasswordStatusError = payload => ({
  type: CHECK_PASSWORD_STATUS_ERROR,
  payload,
});

export const checkPasswordStatus = dispatch => async (merchantId, walletId) => {
  dispatch(checkPasswordStatusRequest());

  try {
    const response = await cryptoApi.checkPasswordStatus(merchantId, walletId);
    dispatch(checkPasswordStatusSuccess(response));
  } catch (error) {
    dispatch(checkPasswordStatusError(error));
  }
};

export const uploadNewAutosignRequest = () => ({
  type: UPLOAD_NEW_AUTOSIGN_REQUEST,
});

export const uploadNewAutosignSuccess = payload => ({
  type: UPLOAD_NEW_AUTOSIGN_SUCCESS,
  payload,
});

export const uploadNewAutosignError = payload => ({
  type: UPLOAD_NEW_AUTOSIGN_ERROR,
  payload,
});

export const downloadNewAutosignRequest = () => ({
  type: DOWNLOAD_NEW_AUTOSIGN_REQUEST,
});

export const downloadNewAutosignSuccess = payload => ({
  type: DOWNLOAD_NEW_AUTOSIGN_SUCCESS,
  payload,
});

export const downloadNewAutosignError = payload => ({
  type: DOWNLOAD_NEW_AUTOSIGN_ERROR,
  payload,
});

export const resetNewAutosign = () => ({
  type: RESET_NEW_AUTOSIGN
})

export const disableNewAutosignRequest = () => ({
  type: DISABLE_NEW_AUTOSIGN_REQUEST,
});

export const disableNewAutosignSuccess = payload => ({
  type: DISABLE_NEW_AUTOSIGN_SUCCESS,
  payload,
});

export const disableNewAutosignError = payload => ({
  type: DISABLE_NEW_AUTOSIGN_ERROR,
  payload,
});

export const resetAutosignStep = payload => ({
  type: RESET_AUTOSIGN_STEP,
});

export const resetDownloadAutosignStep = payload => ({
  type: RESET_DOWNLOAD_AUTOSIGN_STEP,
});

export const uploadNewAutosign = dispatch => async (merchantId, walletId, data) => {
  dispatch(uploadNewAutosignRequest());

  try {
    const response = await cryptoApi.uploadNewAutosign(merchantId, walletId, data);
    dispatch(uploadNewAutosignSuccess(response));
  } catch (error) {
    dispatch(uploadNewAutosignError(error));
    dispatch(notificationsErrorShow(error?.data));
  }
};

export const disableNewAutosign = dispatch => async (merchantId, walletId, data) => {
  dispatch(disableNewAutosignRequest());

  try {
    const response = await cryptoApi.disableAutosign(merchantId, walletId, data);

    if (response.status === 'fail') {
      dispatch(disableNewAutosignSuccess(response));

      return
    }

    dispatch(disableNewAutosignSuccess(response));

    const blob = new Blob([response], { type: 'text/plain;charset=utf-8;' });

    saveAs(blob, 'autosign.txt');
  } catch (error) {
    dispatch(disableNewAutosignError(error));
    dispatch(notificationsErrorShow(error?.data));
  }
};

export const setPasswordAutosignRequest = () => ({
  type: SET_PASSWORD_AUTOSIGN_REQUEST,
});

export const setPasswordAutosignSuccess = payload => ({
  type: SET_PASSWORD_AUTOSIGN_SUCCESS,
  payload,
});

export const setPasswordAutosignError = payload => ({
  type: SET_PASSWORD_AUTOSIGN_ERROR,
  payload,
});

export const setPasswordSignature = dispatch => async (merchantId, walletId, data) => {
  dispatch(setPasswordAutosignRequest());

  try {
    const response = await cryptoApi.setPasswordSignature(merchantId, walletId, data);
    dispatch(setPasswordAutosignSuccess(response));
    return response;
  } catch (error) {
    dispatch(setPasswordAutosignError(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

export const downloadAutosign = dispatch => async (merchantId, walletId, data) => {
  dispatch(downloadNewAutosignRequest());
  let response;
  try {
    response = await cryptoApi.downloadAutosign(merchantId, walletId, data, {
      headers: {
        'Content-Type': 'text/plain',
      },
      responseType: 'text',
    });

    if (response.status === 'fail') {
      dispatch(downloadNewAutosignSuccess(response));

      return;
    }

    dispatch(downloadNewAutosignSuccess(response));

    const blob = new Blob([response], { type: 'text/plain;charset=utf-8;' });

    saveAs(blob, 'autosign.txt');
  } catch (error) {
    dispatch(downloadNewAutosignError(true))
    dispatch(notificationsErrorShow(error?.data))
  }
};
