import React, { useEffect, useState, Suspense } from 'react';
import { connect } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import 'antd/dist/antd.css';
import { notification } from 'antd';
import { getCurrencies } from './redux/actions/currencies';
import { getUser } from './redux/actions/user';
import { getMerchantData } from './redux/actions/merchantData';
import './App.scss';
import ScrollToTop from './components/ScrollToTop';
import AuthPagesWrapper from './pages/AuthPagesWrapper/AuthPagesWrapper';
import LayoutWrapper from './layout/LayoutWrapper';
import Home from './pages/Home';
import Merchants from './pages/Merchants';
import MerchantEdit from './pages/MerchantEdit';
import MerchantWallets from './pages/MerchantWallets';
import WalletsPage from './pages/WalletsPage';
import ClientsPage from './pages/ClientsPage';
import MerchantClientsPage from './pages/MerchantClientsPage';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Withdraw from './pages/Withdraw';
import Swap from './pages/Swap';
import SwapNew from './pages/SwapNew';
import NftWallet from './pages/NftWallet';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import LoginOptions from './components/LoginOptions';
import SignUpOptions from './components/SignUpOptions';
import NewPasswordForm from './components/NewPasswordForm/NewPasswordForm';
import PublicRoute from './components/PublicRoute/PublicRoute';
import SendPasswordForm from './components/SendPasswordForm/SendPasswordForm';
import ResetPasswordForm from './components/ResetPasswordForm/ResetPasswordForm';
import EmailVerification from './components/EmailVerification/EmailVerification';
import Notifications from './components/Notifications/Notifications';
import VersionOverlay from './components/VersionOverlay/VersionOverlay';
import Support from './pages/Support';
import PageNotFound from './components/PageNotFound/PageNotFound';
import CheckoutsPage from './pages/CheckoutsPage';
import CheckoutCreator from './pages/CheckoutCreator';
import CheckoutPaymentPage from './pages/CheckoutPaymentPage';
import SaleTokenPaymentPage from './pages/SaleTokenPaymentPage';
import SaleTokenCreator from './pages/SaleTokenCreator';
import WelcomeModal from './components/WelcomeModal';
import ReleaseNotesModal from './components/ReleaseNotes/Modal';
import { hotjar } from 'react-hotjar';
import DeletedCharge from './pages/DeletedCharge';
import { getMerchants } from './redux/actions/merchants';
// ADMIN COMPONENTS
import AdminLayoutWrapper from './adminPanel/layout/LayoutWrapper';
import AdminDashboard from './adminPanel/pages/Dashboard';
import AdminSettings from './adminPanel/pages/Settings';
import AdminUsers from './adminPanel/pages/Users';
import AdminUserEdit from './adminPanel/pages/UserEdit';
import AdminUserMerchants from './adminPanel/pages/UserMerchants';
import AdminFeeWallets from './adminPanel/pages/FeeWallets';
import AdminWithdraw from './adminPanel/pages/Withdraw';
import AdminTransactions from './adminPanel/pages/Transactions';
import EmailChangeVerify from './components/EmailChangeVerify/EmailChangeVerify';
import TransactionsByUser from './adminPanel/pages/TransactionsByUser';
import PriceChart from './adminPanel/pages/PriceChart';
import { identity } from './utils/getIdentity';
import { NftSupportProvider } from './utils/nftSupportContext';

// Login check for SEO
import WelcomeUser from './pages/WelcomeUser';
import TransactionsHistory from './pages/TransactionsHistory';
import { AppConfig } from "./config";
import { Helmet } from "react-helmet";

function App({ sessionData, isShowWelcomeModal, getUser, merchantId, getCurrencies, getMerchantData, getMerchants }) {
  const [socket, setSocket] = useState(undefined);

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_VERSION) {
      hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION);
    }

    if (process.env.REACT_APP_FACEBOOK_PIXEL) {
      ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL);
      ReactPixel.pageView();
    }
  }, []);

  useEffect(() => {
    if (sessionData) {
      getUser();
      getCurrencies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData]);

  useEffect(() => {
    if (window?.google) {
      // global google
      const google = window.google;
      google.accounts.id.initialize({
        client_id: process?.env.REACT_APP_GOOGLE_CLIENT,
        // callback: loginByGoogle
      });
    }
  }, []);

  useEffect(() => {
    socket && socket.disconnect();
    setSocket(undefined);
    createSocket(merchantId);
  }, [merchantId]);

  const createSocket = merchantId => {
    const io = window.io;
    const socket = io(process.env.REACT_APP_API_URL, {
      allowEIO3: true,
      withCredentials: true,
    });
    if (merchantId) {
      let socketStatus;
      socket.on('connect', () => {
        socket.emit('sign-in-merchant', { token: localStorage.getItem('authToken'), merchantId });
        setSocket(socket);
      });

      socket.on('confirmedTransaction', data => {
        if (merchantId) {
          socketStatus = data?.systemStatus;

          if (socketStatus === 'Error' || socketStatus === 'Failed') {
            notification.error({
              message: data?.systemStatus || 'Error',
              description: (
                <span style={{ whiteSpace: 'initial', wordWrap: 'break-word' }}>
                  {data?.errorMessage ? data?.errorMessage : 'Error'}
                </span>
              ),
            });
            return;
          }
        }
      });

      socket.on('confirmedTransactionHash', data => {
        if (merchantId) {
          if (data.type !== 'SystemFee' && socketStatus !== 'Error' && socketStatus !== 'Failed') {
            notification.success({
              message: 'Successful transaction',
              description: (
                <div>
                  {
                    <div style={{ height: '100%' }}>
                      <h3 style={{ fontSize: '14px', color: '#818A98', marginBottom: '-3px' }}>Hash:</h3>
                      <a
                        href={`${data.explorerUrl}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{ color: '#2A92FF', whiteSpace: 'initial', wordWrap: 'break-word', cursor: 'pointer' }}
                      >
                        {data.explorerUrl}
                      </a>
                    </div>
                  }
                </div>
              ),
              duration: 8,
            });
          }
        }
      });
    }
  };

  return (
    <BrowserRouter basename={AppConfig.identity === 'clarnium' ? '/pay' : ''}>
    <NftSupportProvider>
    {AppConfig.identity === 'cpay' && (
      <Helmet>
        <link rel="icon" href="/cpay-icons/favicon.ico" type="image/x-icon" />
      </Helmet>
    )}

    {AppConfig.identity === 'nfg' && (
      <Helmet>
        <link rel="icon" href="/nfgpay-icons/favicon.ico" type="image/x-icon" />
      </Helmet>
    )}

    {AppConfig.identity === 'finvaro' && (
      <Helmet>
        <link rel="icon" href="/finvaro-icons/favicon.ico" type="image/x-icon" />
      </Helmet>
    )}

    {AppConfig.identity === 'clarnium' && (
      <Helmet>
        <link rel="icon" href="/clarnium-icons/favicon.ico" type="image/x-icon" />
      </Helmet>
    )}
    <Suspense fallback={null}>
      <Notifications />
        <ScrollToTop />
        <ReleaseNotesModal sessionData={sessionData} isShowWelcomeModal={isShowWelcomeModal} />
        <VersionOverlay />
        <Switch>
          <Route exact path="/support">
            <AuthPagesWrapper childComponent={Support} withWelcomeBlock={false} />
          </Route>

          <Route exact path="/checkout/:identifier">
            <CheckoutCreator />
          </Route>

          <Route exact path="/charge/:chargeId">
            <CheckoutPaymentPage />
          </Route>

          <Route exact path="/sale-token-checkout/:identifier">
            <SaleTokenCreator />
          </Route>

          <Route exact path="/sale-token-charge/:chargeId">
            <SaleTokenPaymentPage />
          </Route>

          <Route exact path="/charge-not-found">
            <DeletedCharge />
          </Route>

          <Route exact path="/user/email/verify/:key">
            <AuthPagesWrapper childComponent={EmailVerification} withWelcomeBlock={false} />
          </Route>

          <PrivateRoute exact path="/">
            <LayoutWrapper>
              <Home />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/welcome-user">
            <LayoutWrapper>
              <WelcomeUser />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/registration-completed">
            {isShowWelcomeModal ? (
              <LayoutWrapper>
                <WelcomeModal />
                <Home />
              </LayoutWrapper>
            ) : (
              <Redirect to="/" />
            )}
          </PrivateRoute>

          <PrivateRoute exact path="/merchants">
            <LayoutWrapper>
              <Merchants />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/merchants/edit/:merchantId">
            <LayoutWrapper>
              <MerchantEdit />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/merchants/wallets/:merchantId">
            <LayoutWrapper>
              <MerchantWallets />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/wallets">
            <LayoutWrapper>
              <WalletsPage />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/merchants/clients/:merchantId">
            <LayoutWrapper>
              <MerchantClientsPage />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/clients">
            <LayoutWrapper>
              <ClientsPage />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/profile">
            <LayoutWrapper>
              <Profile />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/settings">
            <LayoutWrapper>
              <Settings />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/withdraw">
            <LayoutWrapper>
              <Withdraw />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/nft-wallet">
            <LayoutWrapper>
              <NftWallet />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/swap">
            <LayoutWrapper>
              <Swap />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/swap/create">
            <LayoutWrapper>
              <SwapNew />
            </LayoutWrapper>
          </PrivateRoute>
          {identity === 'cpay' && (
            <PrivateRoute exact path="/swap/create/:stable">
              <LayoutWrapper>
                <SwapNew />
              </LayoutWrapper>
            </PrivateRoute>
          )}

          <PrivateRoute exact path="/checkouts">
            <LayoutWrapper>
              <CheckoutsPage getMerchantData={getMerchantData} getMerchants={getMerchants} />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/transactions">
            <LayoutWrapper>
              <TransactionsHistory />
            </LayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/admin/settings">
            <AdminLayoutWrapper>
              <AdminSettings />
            </AdminLayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/admin/price-chart">
            <AdminLayoutWrapper>
              <PriceChart />
            </AdminLayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/admin">
            <AdminLayoutWrapper>
              <AdminDashboard />
            </AdminLayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/admin/users">
            <AdminLayoutWrapper>
              <AdminUsers />
            </AdminLayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/admin/user-edit/:userId">
            <AdminLayoutWrapper>
              <AdminUserEdit />
            </AdminLayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/admin/user-merchants/:userId">
            <AdminLayoutWrapper>
              <AdminUserMerchants />
            </AdminLayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/admin/wallets">
            <AdminLayoutWrapper>
              <AdminFeeWallets />
            </AdminLayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/admin/withdraw">
            <AdminLayoutWrapper>
              <AdminWithdraw />
            </AdminLayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/admin/transactions">
            <AdminLayoutWrapper>
              <AdminTransactions />
            </AdminLayoutWrapper>
          </PrivateRoute>

          <PrivateRoute exact path="/admin/user-transactions/:userId">
            <AdminLayoutWrapper>
              <TransactionsByUser />
            </AdminLayoutWrapper>
          </PrivateRoute>

          <PublicRoute exact path="/login">
            <AuthPagesWrapper childComponent={LoginOptions} withWelcomeBlock={true} />
          </PublicRoute>

          {identity !== 'finvaro' && (
            <PublicRoute exact path="/sign-up">
              <AuthPagesWrapper childComponent={SignUpOptions} withWelcomeBlock={true} />
            </PublicRoute>
          )}

          <PublicRoute exact path="/password-send">
            <AuthPagesWrapper childComponent={SendPasswordForm} withWelcomeBlock={true} />
          </PublicRoute>

          <PublicRoute exact path="/password-reset">
            <AuthPagesWrapper childComponent={ResetPasswordForm} withWelcomeBlock={true} />
          </PublicRoute>

          <Route exact path="/user/changeEmail/verify/:token">
            <AuthPagesWrapper childComponent={EmailChangeVerify} />
          </Route>

          <PublicRoute exact path="/forgotten/verify/:token">
            <AuthPagesWrapper childComponent={NewPasswordForm} withWelcomeBlock={true} />
          </PublicRoute>

          <Route component={PageNotFound} />
        </Switch>
    </Suspense>
    </NftSupportProvider>
    </BrowserRouter>
  );
}

const mapStateToProps = state => ({
  sessionData: state.login.data,
  isShowWelcomeModal: state.user.showWelcomeModal,
  merchantId: state.transactions.merchantId,
});

const mapDispatchToProps = dispatch => ({
  getUser: getUser(dispatch),
  getCurrencies: getCurrencies(dispatch),
  getMerchantData: getMerchantData(dispatch),
  getMerchants: getMerchants(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
