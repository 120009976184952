import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsSuccessShow, notificationsErrorShow } from './notifications';

// CONSTANTS
//--GET CHECKOUTS LIST
export const GET_CHECKOUTS_LIST_REQUEST = 'GET_CHECKOUTS_LIST_REQUEST';
export const GET_CHECKOUTS_LIST_SUCCESS = 'GET_CHECKOUTS_LIST_SUCCESS';
export const GET_CHECKOUTS_LIST_FAIL = 'GET_CHECKOUTS_LIST_FAIL';
//--CREATE CHECKOUT DONATION
export const CREATE_CHECKOUT_DONATION_REQUEST = 'CREATE_CHECKOUT_DONATION_REQUEST';
export const CREATE_CHECKOUT_DONATION_SUCCESS = 'CREATE_CHECKOUT_DONATION_SUCCESS';
export const CREATE_CHECKOUT_DONATION_FAIL = 'CREATE_CHECKOUT_DONATION_FAIL';
//--CREATE CHECKOUT SALE
export const CREATE_CHECKOUT_SALE_REQUEST = 'CREATE_CHECKOUT_SALE_REQUEST';
export const CREATE_CHECKOUT_SALE_SUCCESS = 'CREATE_CHECKOUT_SALE_SUCCESS';
export const CREATE_CHECKOUT_SALE_FAIL = 'CREATE_CHECKOUT_SALE_FAIL';
//--CREATE CHECKOUT SELL CURRENCY
export const CREATE_CHECKOUT_SELL_CURRENCY_REQUEST = 'CREATE_CHECKOUT_SELL_CURRENCY_REQUEST';
export const CREATE_CHECKOUT_SELL_CURRENCY_SUCCESS = 'CREATE_CHECKOUT_SELL_CURRENCY_SUCCESS';
export const CREATE_CHECKOUT_SELL_CURRENCY_FAIL = 'CREATE_CHECKOUT_SELL_CURRENCY_FAIL';
//--CREATE CHECKOUT CART
export const CREATE_CHECKOUT_CART_REQUEST = 'CREATE_CHECKOUT_CART_REQUEST';
export const CREATE_CHECKOUT_CART_SUCCESS = 'CREATE_CHECKOUT_CART_SUCCESS';
export const CREATE_CHECKOUT_CART_FAIL = 'CREATE_CHECKOUT_CART_FAIL';
//--DELETE CHECKOUT
export const DELETE_CHECKOUT_REQUEST = 'DELETE_CHECKOUT_REQUEST';
export const DELETE_CHECKOUT_SUCCESS = 'DELETE_CHECKOUT_SUCCESS';
export const DELETE_CHECKOUT_FAIL = 'DELETE_CHECKOUT_FAIL';
//--PATCH CHECKOUT
export const PATCH_CHECKOUT_REQUEST = 'PATCH_CHECKOUT_REQUEST';
export const PATCH_CHECKOUT_SUCCESS = 'PATCH_CHECKOUT_SUCCESS';
export const PATCH_CHECKOUT_FAIL = 'PATCH_CHECKOUT_FAIL';
//--SET DETAILED CHECKOUT
export const SET_DETAILED_CHECKOUT = 'SET_DETAILED_CHECKOUT';
export const SET_EDIT_CHECKOUT = 'SET_EDIT_CHECKOUT';
// ACTIONS GENERATORS

//--GET CHECKOUTS LIST
const getCheckoutListRequest = () => ({
  type: GET_CHECKOUTS_LIST_REQUEST,
});

const getCheckoutListSuccess = payload => ({
  type: GET_CHECKOUTS_LIST_SUCCESS,
  payload,
});

const getCheckoutListFail = payload => ({
  type: GET_CHECKOUTS_LIST_FAIL,
  payload,
});

//--CREATE CHECKOUT DONATION
const createCheckoutDonationRequest = () => ({
  type: CREATE_CHECKOUT_DONATION_REQUEST,
});
const createCheckoutDonationSuccess = payload => ({
  type: CREATE_CHECKOUT_DONATION_SUCCESS,
  payload,
});
const createCheckoutDonationFail = payload => ({
  type: CREATE_CHECKOUT_DONATION_FAIL,
  payload,
});

//--CREATE CHECKOUT SALE
const createCheckoutSaleRequest = () => ({
  type: CREATE_CHECKOUT_SALE_REQUEST,
});
const createCheckoutSaleSuccess = payload => ({
  type: CREATE_CHECKOUT_SALE_SUCCESS,
  payload,
});
const createCheckoutSaleFail = payload => ({
  type: CREATE_CHECKOUT_SALE_FAIL,
  payload,
});

//--CREATE CHECKOUT SELL CURRENCY
const createCheckoutSellCurrencyRequest = () => ({
  type: CREATE_CHECKOUT_SELL_CURRENCY_REQUEST,
});
const createCheckoutSellCurrencySuccess = payload => ({
  type: CREATE_CHECKOUT_SELL_CURRENCY_SUCCESS,
  payload,
});
const createCheckoutSellCurrencyFail = payload => ({
  type: CREATE_CHECKOUT_SELL_CURRENCY_FAIL,
  payload,
});

//--CREATE CHECKOUT CART
const createCheckoutCartRequest = () => ({
  type: CREATE_CHECKOUT_CART_REQUEST,
});
const createCheckoutCartSuccess = payload => ({
  type: CREATE_CHECKOUT_CART_SUCCESS,
  payload,
});
const createCheckoutCartFail = payload => ({
  type: CREATE_CHECKOUT_CART_FAIL,
  payload,
});

//--DELETE CHECKOUT
const deleteCheckoutRequest = () => ({
  type: DELETE_CHECKOUT_REQUEST,
});
const deleteCheckoutSuccess = payload => ({
  type: DELETE_CHECKOUT_SUCCESS,
  payload,
});
const deleteCheckoutFail = payload => ({
  type: DELETE_CHECKOUT_FAIL,
  payload,
});

//--PATCH CHECKOUT
const patchCheckoutRequest = () => ({
  type: PATCH_CHECKOUT_REQUEST,
});
const patchCheckoutSuccess = payload => ({
  type: PATCH_CHECKOUT_SUCCESS,
  payload,
});
const patchCheckoutFail = payload => ({
  type: PATCH_CHECKOUT_FAIL,
  payload,
});

//--SET DETAILED CHECKOUT
const setDetailed = payload => ({
  type: SET_DETAILED_CHECKOUT,
  payload,
});

const setEdit = payload => ({
  type: SET_EDIT_CHECKOUT,
  payload,
});

// ASYNC ACTIONS

//--GET CHECKOUTS LIST
export const getCheckoutList = data => async dispatch => {
  dispatch(getCheckoutListRequest());
  try {
    const response = await cryptoApi.getCheckoutsList(data.merchantId, data.params);
    dispatch(getCheckoutListSuccess(response));
  } catch (error) {
    dispatch(getCheckoutListFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--CREATE CHECKOUT DONATION
export const createCheckoutDonation = data => async dispatch => {
  dispatch(createCheckoutDonationRequest());
  try {
    const response = await cryptoApi.createCheckoutDonation(data.merchantId, data.body);
    dispatch(createCheckoutDonationSuccess(response));
    dispatch(notificationsSuccessShow({}));
    dispatch(getCheckoutList({ merchantId: data.merchantId, params: { page: 1, limit: 5, order: 'DESC' } }));
  } catch (error) {
    dispatch(createCheckoutDonationFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--CREATE CHECKOUT SALE
export const createCheckoutSale = data => async dispatch => {
  dispatch(createCheckoutSaleRequest());
  try {
    const response = await cryptoApi.createCheckoutSale(data.merchantId, data.body);
    dispatch(createCheckoutSaleSuccess(response));
    dispatch(notificationsSuccessShow({}));
    dispatch(getCheckoutList({ merchantId: data.merchantId, params: { page: 1, limit: 5, order: 'DESC' } }));
  } catch (error) {
    dispatch(createCheckoutSaleFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--CREATE CHECKOUT SELL CURRENCY
export const createCheckoutSellCurrency = data => async dispatch => {
  dispatch(createCheckoutSellCurrencyRequest());
  try {
    const response = await cryptoApi.createCheckoutSellCurrency(data.merchantId, data.body);
    dispatch(createCheckoutSellCurrencySuccess(response));
    dispatch(notificationsSuccessShow({}));
    dispatch(getCheckoutList({ merchantId: data.merchantId, params: { page: 1, limit: 5, order: 'DESC' } }));
  } catch (error) {
    dispatch(createCheckoutSellCurrencyFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--CREATE CHECKOUT CART
export const createCheckoutCart = data => async dispatch => {
  dispatch(createCheckoutCartRequest());
  try {
    const response = await cryptoApi.createCheckoutCart(data.merchantId, data.body);
    dispatch(createCheckoutCartSuccess(response));
    dispatch(notificationsSuccessShow({}));
    dispatch(getCheckoutList({ merchantId: data.merchantId, params: { page: 1, limit: 5, order: 'DESC' } }));
  } catch (error) {
    dispatch(createCheckoutCartFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--DELETE CHECKOUT
export const deleteCheckout = (data, page) => async dispatch => {
  dispatch(deleteCheckoutRequest());
  try {
    await cryptoApi.deleteCheckout(data.merchantId, data.checkoutId);
    dispatch(deleteCheckoutSuccess(data.checkoutId));
    dispatch(notificationsSuccessShow({}));
    dispatch(getCheckoutList({ merchantId: data.merchantId, params: { page: page, limit: 5, order: 'DESC' } }));
  } catch (error) {
    dispatch(deleteCheckoutFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--PATCH CHECKOUT
export const patchCheckout = data => async dispatch => {
  dispatch(patchCheckoutRequest());
  try {
    const response = await cryptoApi.patchCheckout(data);
    dispatch(patchCheckoutSuccess(response));
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(patchCheckoutFail());
    dispatch(notificationsErrorShow(error.data));
    throw error.data;
  }
};

//--SET DETAILED CHECKOUT
export const setDetailedCheckout = data => dispatch => {
  dispatch(setDetailed(data));
};

export const setEditCheckout = data => dispatch => {
  dispatch(setEdit(data));
};
