import React, { useState, useEffect } from 'react';
import { ReactComponent as ClockIcon } from '../../../img/default-svg/lock.svg';
import { useTranslation } from 'react-i18next';
import styles from './SaleTokenSummary.module.scss';
import Button from '../../Button';
import { tokenNodeTypeObj } from '../../../utils/tokenNodeTypes';
import { getContrastTextColor } from '../../../utils/getTextColorByBg';
import { mainColor } from '../../../utils/getMainColor';
import { identity } from '../../../utils/getIdentity';

const SaleTokenSummary = ({
  identifier,
  summaryData,
  setSummaryData,
  setShowSummary,
  checkoutData,
  createSaleTokenChargeId,
  estimate,
  setEstimate,
  getEstimate,
  timer,
  isEstimateExpired,
  estimateDirection,
}) => {
  const [showRateUpdate, setShowRateUpdate] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    isEstimateExpired && setShowRateUpdate(true);
  }, [isEstimateExpired]);

  const getExchangeTimer = async () => {
    setShowRateUpdate(false);
    const response = await getEstimate(
      summaryData.currencyFrom,
      (estimateDirection === 'direct' ? summaryData.amount : summaryData.amountSecond) || 0,
      estimateDirection
    );
    setEstimate(response.data);
    setSummaryData({
      ...summaryData,
      estimate: response.data,
      [estimateDirection === 'direct' ? 'amountSecond' : 'amount']: response.data.estimatedAmount,
    });
  };

  return (
    <>
      <style>
        {`
          .sale-token-summary__custom-button:not([disabled]):not([type="secondary"]), 
          .sale-token-summary__rates-button {
            background: ${checkoutData.accentColor} !important;
            color: ${getContrastTextColor(checkoutData.accentColor || mainColor)} !important;
          }
          .sale-token-summary__custom-button[type="secondary"] {
            border-color: ${checkoutData.accentColor} !important;
            color: ${checkoutData.accentColor} !important;
          }
          .sale-token-summary__custom-button[type="secondary"]:hover {
            background: ${checkoutData.accentColor} !important;
            color: ${getContrastTextColor(checkoutData.accentColor || mainColor)} !important;
          }
        `}
      </style>
      <div className={styles['sale-token-summary']}>
        <div className={styles['sale-token-summary__title']}>{t('checkouts.confirmSummary.title')}</div>
        {showRateUpdate && (
          <div className={styles[`sale-token-summary__rate-update sale-token-summary__rate-update-${identity}`]}>
            <span className={styles['sale-token-summary__rate-update-title']}>
              {t('checkouts.confirmSummary.updatedTitle')}
            </span>
            <span className={styles['sale-token-summary__rate-update-subtitle']}>
              {t('checkouts.confirmSummary.subtitle')}
            </span>
            <Button
              className={`${styles['sale-token-summary__rate-update-button']} sale-token-summary__rates-button`}
              onClick={async () => {
                getExchangeTimer();
              }}
            >
              {t('checkouts.confirmSummary.updButton')}
            </Button>
          </div>
        )}
        <div className={styles['sale-token-summary__section']}>
          <div className={styles['sale-token-summary__section-title']}>{t('checkouts.saleToken.send')}</div>
          <div className={styles['sale-token-summary__section-value']}>
            {summaryData.amount}{' '}
            {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name}{' '}
            {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType ===
            'token'
              ? tokenNodeTypeObj[
                  checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType
                ]
              : null}

            {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'optimism' ?
              checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'OP' ? null : 'OP' : null }

            {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'ETH' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'currency' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'optimism' ?
             'OP' : null}

                {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'ETH' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'currency' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'optimism' ?  'OP' : null} 
                  {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'currency' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'arbitrum' ? checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'arbitrum' ? null : 'ARB' : null}

                    {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'ETH' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'arbitrum' ? 'ARB' : null}

                    {(checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDT' || checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDC') && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'matic' ? 'MATIC' : null}

                    {(checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDT' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'avax') ? 'AVAX' : null}

                      {(checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDC' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'solana') ? 'SOL' : null}

                      {(checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDT'  && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'ftm') ? 'FTM' : null}

                      {((checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDT' || checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDC') && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'moonbeam') ? 'GLMR' : null}

          </div>
        </div>
        <div className={styles['sale-token-summary__section']}>
          <div className={styles['sale-token-summary__section-title']}>{t('checkouts.confirmSummary.estRate')}</div>
          <div className={styles['sale-token-summary__section-value']}>
            1 {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name}{' '}
            {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType ===
            'token'
              ? tokenNodeTypeObj[
                  checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType
                ]
              : null}

            {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'optimism' ?
              checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'OP' ? null : 'OP' : null }


            {checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'currency' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'arbitrum' ? checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'arbitrum' ? null : 'ARB' : null}

            {
            (checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDT' || checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDC' ||
            checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'ETH') && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'arbitrum' ? 'ARB' : null}

            {(checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDT' || checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDC') && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'matic' ? 'MATIC' : null}

            {(checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDT' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'avax') ? 'AVAX' : null}

            {(checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDC' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'solana') ? 'SOL' : null}

            {(checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDT'  && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'ftm') ? 'FTM' : null}

            {((checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDT' || checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).name === 'USDC') && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).currencyType === 'token' && checkoutData.currencies.find(currency => currency._id === summaryData.currencyFrom).nodeType === 'moonbeam') ? 'GLMR' : null}

            ~
            {summaryData.estimate.fixedRate} {checkoutData.tradedCurrency.name}{' '}
            {checkoutData.tradedCurrency.currencyType === 'token'
              ? tokenNodeTypeObj[checkoutData.tradedCurrency.nodeType]
              : null}

              {checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'optimism' ?
              checkoutData.tradedCurrency.name === 'OP' ? null : 'OP' : null }

            {checkoutData.tradedCurrency.name === 'ETH' && checkoutData.tradedCurrency.currencyType === 'currency' && checkoutData.tradedCurrency.nodeType === 'optimism' ?
             'OP' : null}

            {checkoutData.tradedCurrency.currencyType === 'currency' && checkoutData.tradedCurrency.nodeType === 'arbitrum' ? checkoutData.tradedCurrency.name === 'arbitrum' ? null : 'ARB' : null}

            {(checkoutData.tradedCurrency.name === 'USDT' || checkoutData.tradedCurrency.name === 'USDC' || checkoutData.tradedCurrency.name === 'ETH') && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'arbitrum' ? 'ARB' : null}

            {(checkoutData.tradedCurrency.name === 'USDT' || checkoutData.tradedCurrency.name === 'USDC') && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'matic' ? 'MATIC' : null}

            {(checkoutData.tradedCurrency.name === 'USDT' && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'avax') ? 'AVAX' : null}

          {(checkoutData.tradedCurrency.name === 'USDC' && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'solana') ? 'SOL' : null}

          {(checkoutData.tradedCurrency.name === 'USDT'  && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'ftm') ? 'FTM' : null}

          {((checkoutData.tradedCurrency.name === 'USDT' || checkoutData.tradedCurrency.name === 'USDC') && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'moonbeam') ? 'GLMR' : null}


          </div>
        </div>
        <div className={styles['sale-token-summary__section']}>
          <div className={styles['sale-token-summary__section-title']}>{t('checkouts.saleToken.get')}</div>
          <div className={styles['sale-token-summary__section-value']}>
            {summaryData.amountSecond} {checkoutData.tradedCurrency.name}{' '}
            {checkoutData.tradedCurrency.currencyType === 'token'
              ? tokenNodeTypeObj[checkoutData.tradedCurrency.nodeType]
              : null}

              {checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'optimism' ?
              checkoutData.tradedCurrency.name === 'OP' ? null : 'OP' : null }

              {checkoutData.tradedCurrency.name === 'ETH' && checkoutData.tradedCurrency.currencyType === 'currency' && checkoutData.tradedCurrency.nodeType === 'optimism' ?
             'OP' : null}

                {checkoutData.tradedCurrency.currencyType === 'currency' && checkoutData.tradedCurrency.nodeType === 'arbitrum' ? checkoutData.tradedCurrency.name === 'arbitrum' ? null : 'ARB' : null}

                {(checkoutData.tradedCurrency.name === 'USDT' || checkoutData.tradedCurrency.name === 'USDC' || checkoutData.tradedCurrency.name === 'ETH') && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'arbitrum' ? 'ARB' : null}

                {(checkoutData.tradedCurrency.name === 'USDT' || checkoutData.tradedCurrency.name === 'USDC') && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'matic' ? 'MATIC' : null}

                {(checkoutData.tradedCurrency.name === 'USDT' && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'avax') ? 'AVAX' : null}

              {(checkoutData.tradedCurrency.name === 'USDC' && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'solana') ? 'SOL' : null}

              {(checkoutData.tradedCurrency.name === 'USDT'  && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'ftm') ? 'FTM' : null}

              {((checkoutData.tradedCurrency.name === 'USDT' || checkoutData.tradedCurrency.name === 'USDC') && checkoutData.tradedCurrency.currencyType === 'token' && checkoutData.tradedCurrency.nodeType === 'moonbeam') ? 'GLMR' : null}
            {timer && (
              <div className={styles['sale-token-summary__timer']}>
                <ClockIcon />
                {t('checkouts.saleToken.fixedPrice')} {timer} {t('checkouts.saleToken.minutes')}
              </div>
            )}
          </div>
        </div>
        <div className={styles['sale-token-summary__section']}>
          <div className={styles['sale-token-summary__section-title']}>{t('checkouts.summary.recepient')}</div>
          <div className={styles['sale-token-summary__section-value']}>{summaryData.recipient}</div>
        </div>
        <div className={styles['sale-token-summary__buttons']}>
          <Button
            className={`${styles['sale-token-summary__button']} sale-token-summary__custom-button`}
            disabled={showRateUpdate}
            onClick={async () =>
              createSaleTokenChargeId(identifier, {
                estimationId: estimate.estimationId,
                recipient: summaryData.recipient,
                clickId: summaryData.clickId,
              })
            }
          >
            {t('withdraw.confirm')}
          </Button>
          <Button
            className={`${styles['sale-token-summary__button']} sale-token-summary__custom-button`}
            type="secondary"
            onClick={() => setShowSummary(false)}
          >
            {t('backButton')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SaleTokenSummary;
