import { adminApi } from '../../../service/admin-api';
import { notificationsSuccessShow, notificationsErrorShow } from '../notifications';

// WITHDRAW FROM SYSTEM FEE WALLET

export const WITHDRAW_ADMIN_SYSTEM_FEE_REQUEST = 'WITHDRAW_ADMIN_SYSTEM_FEE_REQUEST';
export const WITHDRAW_ADMIN_SYSTEM_FEE_SUCCESS = 'WITHDRAW_ADMIN_SYSTEM_FEE_SUCCESS';
export const WITHDRAW_ADMIN_SYSTEM_FEE_ERROR = 'WITHDRAW_ADMIN_SYSTEM_FEE_ERROR';
export const WITHDRAW_ADMIN_CHANGE_TWO_FA_STATUS = 'WITHDRAW_ADMIN_CHANGE_TWO_FA_STATUS';
export const WITHDRAW_ADMIN_CHANGE_EMAIL_TWO_FA_STATUS = 'WITHDRAW_ADMIN_CHANGE_EMAIL_TWO_FA_STATUS';
export const WITHDRAW_ADMIN_CHANGE_PHONE_TWO_FA_STATUS = 'WITHDRAW_ADMIN_CHANGE_PHONE_TWO_FA_STATUS'
export const WITHDRAW_ADMIN_RESET_TWO_FA = 'WITHDRAW_ADMIN_RESET_TWO_FA';
export const WITHDRAW_ADMIN_ESTIMATE_MAX_REQUEST = 'WITHDRAW_ADMIN_ESTIMATE_MAX_REQUEST';
export const WITHDRAW_ADMIN_ESTIMATE_MAX_SUCCESS = 'WITHDRAW_ADMIN_ESTIMATE_MAX_SUCCESS';
export const WITHDRAW_ADMIN_ESTIMATE_MAX_ERROR = 'WITHDRAW_ADMIN_ESTIMATE_MAX_ERROR';
export const WITHDRAW_ADMIN_ESTIMATE_MAX_RESET = 'WITHDRAW_ADMIN_ESTIMATE_MAX_RESET';

export const withdrawAdminSystemFeeRequest = () => ({
  type: WITHDRAW_ADMIN_SYSTEM_FEE_REQUEST,
});

export const withdrawAdminSystemFeeSuccess = () => ({
  type: WITHDRAW_ADMIN_SYSTEM_FEE_SUCCESS,
});

export const withdrawAdminSystemFeeError = () => ({
  type: WITHDRAW_ADMIN_SYSTEM_FEE_ERROR,
});

export const withdrawAdminChangeTwoFaStatus = payload => ({
  type: WITHDRAW_ADMIN_CHANGE_TWO_FA_STATUS,
  payload,
});

export const withdrawAdminChangeEmailTwoFaStatus = payload => ({
  type: WITHDRAW_ADMIN_CHANGE_EMAIL_TWO_FA_STATUS,
  payload,
});

export const withdrawAdminChangePhoneTwoFaStatus = payload => ({
  type: WITHDRAW_ADMIN_CHANGE_PHONE_TWO_FA_STATUS,
  payload,
})

export const withdrawResetTwoFaAdmin = () => ({
  type: WITHDRAW_ADMIN_RESET_TWO_FA,
});

export const withdrawAdminSystemFee = dispatch => async (walletId, body) => {
  dispatch(withdrawAdminSystemFeeRequest());

  try {
    const response = await adminApi.withdrawAdminSystemFee(walletId, body);

    if (response && response.data.id) {
      dispatch(withdrawResetTwoFaAdmin());
      dispatch(withdrawAdminSystemFeeSuccess());
      dispatch(notificationsSuccessShow({}));
      return response;
    }

    if (response && response.data.twoFactorToken) {
      dispatch(withdrawAdminChangeTwoFaStatus(true));
      return response;
    }

    if (response && response.data.emailSent) {
      dispatch(withdrawAdminChangeEmailTwoFaStatus(true));
      return response;
    }

    if (response && response.data.codeSent) {
      dispatch(withdrawAdminChangePhoneTwoFaStatus(true));
      return response;
    }
  } catch (error) {
    dispatch(withdrawAdminSystemFeeError());
    dispatch(notificationsErrorShow(error.data));
  }
};

export const withdrawalAdminEstimateMaxRequest = () => ({
  type: WITHDRAW_ADMIN_ESTIMATE_MAX_REQUEST,
});

export const withdrawalAdminEstimateMaxSuccess = payload => ({
  type: WITHDRAW_ADMIN_ESTIMATE_MAX_SUCCESS,
  payload,
});

export const withdrawalAdminEstimateMaxError = payload => ({
  type: WITHDRAW_ADMIN_ESTIMATE_MAX_ERROR,
  payload,
});

export const withdrawalAdminEstimateMaxReset = () => ({
  type: WITHDRAW_ADMIN_ESTIMATE_MAX_RESET,
});

export const getWithdrawalAdminEstimateMax = dispatch => async (walletId, data) => {
  dispatch(withdrawalAdminEstimateMaxRequest());

  try {
    const response = await adminApi.withdrawAdminSystemFeeEstimateMax(walletId, data);
    dispatch(withdrawalAdminEstimateMaxSuccess(response.data));
  } catch (error) {
    dispatch(withdrawalAdminEstimateMaxError(error));
    dispatch(notificationsErrorShow(error.data));
  }
};
