import { twoFactorApi } from '../../service/two-factor-api';
import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsSuccessShow, notificationsErrorShow } from './notifications';

// CONSTANTS
export const TWO_FACTOR_GET = 'TWO_FACTOR_GET';
export const TWO_FACTOR_SET = 'TWO_FACTOR_SET';
export const TWO_FACTOR_ERROR = 'TWO_FACTOR_ERROR';
export const TWO_FACTOR_EMAIL_CHANGE = 'TWO_FACTOR_EMAIL_CHANGE';
export const TWO_FACTOR_EMAIL_DONE = 'TWO_FACTOR_EMAIL_DONE';
export const TWO_FACTOR_APP_CHANGE = 'TWO_FACTOR_APP_CHANGE';
export const TWO_FACTOR_APP_DONE = 'TWO_FACTOR_APP_DONE';
export const TWO_FACTOR_PHONE_CHANGE = 'TWO_FACTOR_PHONE_CHANGE';
export const TWO_FACTOR_PHONE_DONE = 'TWO_FACTOR_PHONE_DONE';

// ACTIONS GENERATORS
const twoFactorGet = () => ({
  type: TWO_FACTOR_GET,
});

const twoFactorSet = data => ({
  type: TWO_FACTOR_SET,
  payload: data,
});

const twoFactorError = () => ({
  type: TWO_FACTOR_ERROR,
});

const twoFactorEmailChange = () => ({
  type: TWO_FACTOR_EMAIL_CHANGE,
});

const twoFactorEmailDone = () => ({
  type: TWO_FACTOR_EMAIL_DONE,
});

const twoFactorAppChange = () => ({
  type: TWO_FACTOR_APP_CHANGE,
});

const twoFactorAppDone = () => ({
  type: TWO_FACTOR_APP_DONE,
});

const twoFactorPhoneChange = () => ({
  type: TWO_FACTOR_PHONE_CHANGE,
});

const twoFactorPhoneDone = () => ({
  type: TWO_FACTOR_PHONE_DONE,
});

// ASYNC ACTIONS
export const getTwoFactor = dispatch => async () => {
  dispatch(twoFactorGet());
  let data;

  try {
    data = await twoFactorApi.getTwoFactor();
  } catch (err) {
    dispatch(twoFactorError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (data && Object.keys(data).length !== 0) {
    dispatch(twoFactorSet(data));
    return;
  }

  dispatch(twoFactorError());
};

export const confirmTwoFactor = dispatch => async twoFactorCode => {
  let data;
  dispatch(twoFactorAppChange());

  try {
    data = await twoFactorApi.confirmTwoFactor(twoFactorCode);
  } catch (err) {
    dispatch(twoFactorAppDone());
    dispatch(twoFactorError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (data && data.data) {
    dispatch(twoFactorAppDone());
    dispatch(notificationsSuccessShow({}));
  }

  return data;
};

export const disableTwoFactor = dispatch => async twoFactorCode => {
  let data;
  dispatch(twoFactorAppChange());

  try {
    data = await twoFactorApi.disableTwoFactor(twoFactorCode);
  } catch (err) {
    dispatch(twoFactorAppDone());
    dispatch(twoFactorError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (data && data.data) {
    dispatch(twoFactorAppDone());
    dispatch(notificationsSuccessShow({}));
  }

  return data;
};

export const activateEmailTwoFactor = dispatch => async () => {
  let data;
  dispatch(twoFactorEmailChange());

  try {
    data = await twoFactorApi.activateEmailTwoFactor();
  } catch (err) {
    dispatch(twoFactorEmailDone());
    dispatch(twoFactorError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (data && data.status === 'success') {
    dispatch(twoFactorEmailDone());
    dispatch(notificationsSuccessShow({}));
  }

  return data;
};

export const deactivateEmailTwoFactor = dispatch => async code => {
  let data;
  dispatch(twoFactorEmailChange());

  try {
    data = await twoFactorApi.deactivateEmailTwoFactor(code);
  } catch (err) {
    dispatch(twoFactorEmailDone());
    dispatch(twoFactorError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (data && data.status === 'success') {
    dispatch(twoFactorEmailDone());
    code && dispatch(notificationsSuccessShow({}));
  }

  return data;
};

export const activatePhoneTwoFactor = dispatch => async () => {
  let data;
  dispatch(twoFactorPhoneChange());

  try {
    data = await twoFactorApi.activatePhoneTwoFactor();
  } catch (err) {
    dispatch(twoFactorPhoneDone());
    dispatch(twoFactorError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (data && data.status === 'success') {
    dispatch(twoFactorPhoneDone());
    dispatch(notificationsSuccessShow({}));
  }

  return data;
};

export const deactivatePhoneTwoFactor = dispatch => async verifyCode => {
  let data;
  dispatch(twoFactorPhoneChange());

  try {
    data = await twoFactorApi.deactivatePhoneTwoFactor(verifyCode);
  } catch (err) {
    dispatch(twoFactorPhoneDone());
    dispatch(twoFactorError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (data && data.status === 'success') {
    dispatch(twoFactorPhoneDone());
    verifyCode && dispatch(notificationsSuccessShow({}));
  }

  return data;
};

export const resendPhoneTwoFa = dispatch => async () => {
  try {
    const data = await cryptoApi.resendPhoneInApp();
  } catch (error) {
    console.log('error', error);
    dispatch(notificationsErrorShow(error.data));
  }
};

export const resendEmailTwoFa = dispatch => async email => {
  try {
    const data = await cryptoApi.resendEmail2FA(email);
  } catch (error) {
    console.log('error', error);
    dispatch(notificationsErrorShow(error.data));
  }
};
